import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box } from "@mui/material";
import { COLORS } from "components";
import { chartColors } from "../const";

const commonStyles = {
  color: "black",
  fontFamily: "Inter",
  fontWeight: "600",
}

export const TotalChart = ({
  chartTitle = 'EmissionsChart',
  dateRangeData,
  dateRangeType,
  type,
}: {
  chartTitle?: string;
  dateRangeData?: any;
  dateRangeType?: string;
  type?: string;
}) => {
  const { total } = dateRangeData;

  const isEmissionsChart = type === "location" || type === "market";

  const data: any[] = total ? Object.values(total).flat() : [];
  const totalCategoriesXAxis = data.map((entry: any) =>
    dateRangeType === "MONTHLY" && entry.year !== null
      ? entry.month + entry.year.replace("20", " '")
      : dateRangeType === "QUARTERLY"
        ? (`Q${entry.fiscalYearQuarter}`).concat(entry.fiscalYear.replace("20", " '")).concat(" FY")
        : entry.fiscalYear.concat(" FY")
  );

  const totalData = data.map((entry: any) => isEmissionsChart ? entry[`${type}BasedEmission`] : entry.consumption ?? entry.amount ?? 0);

  const unitYAxis = isEmissionsChart ? "tCO2e" : data[0]?.unit ?? data[0]?.currency ?? "No Data Available";

    useEffect(() => {}, [total])

  const chartOptions = {
    chart: {
      type: "column",
      spacing: [30, 50, 20, 40],
      width: null,
      height: 450,
    },
    title: {
      text: chartTitle,
      align: "left",
      margin: 40,
      style: {
        ...commonStyles,
        color: COLORS.Mainblue,
        fontSize: "20px",
      },
    },
    xAxis: {
      categories: totalCategoriesXAxis,
      labels: {
        style: commonStyles,
      },
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: {
        text: unitYAxis,
        style: commonStyles,
        offset: 50
      },
      labels: {
        style: commonStyles,
      },
    },
    series: [
      {
        name: "Total",
        data: totalData,
        color: chartColors[0],
        fillOpacity: 0.3,
        lineWidth: 6,
        marker: { enabled: false },
      },
    ],
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          }
        },
      },
    },
    legend: {
      title: {
        style: commonStyles,
      },
      align: "left",
      verticalAlign: "bottom",
      x: 20,
      margin: 30,
      maxHeight: 100,
    },
    tooltip: {
      shared: true,
      borderRadius: 8,
      shadow: false,
      padding: 14,
      useHTML: true,
      outside: true,
      positioner(labelWidth: any, labelHeight: any, point: any) {
        return {
          x: point.plotX,
          y: point.plotY - labelHeight,
        };
      },
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return `
              <div style="font-size: 12px; padding: 8px; display: flex; flex-direction: column; gap: 5px;">
                <strong>${this!.x}</strong>
                ${this!.points
            ?.map(
              (point) => `
                  <div style="display: flex; align-items: center; justify-content: space-between; width: 180px;">
                    <div style="display: flex; align-items: center; gap: 6px;">
                      <span style="width: 10px; height: 10px; background: ${point.series.color}; border-radius: 50%; display: inline-block;"></span>
                      <span>${point.series.name}:</span>
                    </div>
                    <span style="font-weight: bold;">${Number(point.y!.toFixed(2)).toLocaleString('en-US')} ${unitYAxis}</span>
                  </div>
                `
            ).join("")}
              </div>
            `;
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      fontFamily: "Inter",
      "& > div": {
        borderRadius: 2,
      },
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </Box>
  );
};
