import React, { FunctionComponent, useState } from "react";
import { ResizableBox } from "react-resizable";
import { Box } from "@mui/material";
import { MaybeNull } from "types";
import { PDF_DOCUMENT_DEFAULT_HEIGHT, PDF_DOCUMENT_DEFAULT_WIDTH } from "components/pdf-preview/consts";
import { PurchasePdfDocument, PurchasePdfEmptyData, PurchasePdfPagination } from "./components";
import "./css/style.css";

interface PdfPreviewProps {
  documentId: MaybeNull<number>,
  downloadUrl: MaybeNull<string>;
  scale: number;
  isScaleChange: boolean;
  setFileName: any
}

export const PurchasePdfPreview: FunctionComponent<PdfPreviewProps> = ({
  documentId,
  downloadUrl,
  scale,
  isScaleChange,
  setFileName
}) => {
  const [numberOfPages, setNumberOfPages] = useState<null | number>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [resizeWidth, setResizeWidth] = useState(PDF_DOCUMENT_DEFAULT_WIDTH);
  const [isLoading, setIsLoading] = useState(true);

  const file = downloadUrl;

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages);
    setPageNumber(1);
    setIsLoading(false);
  };

  const onPageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };
  const onResize = (_: any, { size }: any) => {
    setResizeWidth(size.width);
  };

  const handleFileName = (fileName: any) => {
    setFileName(fileName);
  }

  return (
    <ResizableBox
      height={PDF_DOCUMENT_DEFAULT_HEIGHT}
      width={resizeWidth}
      onResize={onResize}
      minConstraints={[PDF_DOCUMENT_DEFAULT_WIDTH, (PDF_DOCUMENT_DEFAULT_HEIGHT)]}
      maxConstraints={[900, (PDF_DOCUMENT_DEFAULT_HEIGHT)]}
      resizeHandles={["e"]}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: 2,
          mr: 5,
          minWidth: PDF_DOCUMENT_DEFAULT_WIDTH,
          position: "relative",
          height: "100%",
          padding: 0,
          justifyContent: !file ? "center" : "flex-start",
        }}
      >
        {!file ? (
          <PurchasePdfEmptyData fileName={handleFileName} documentId={documentId}/>
        ) : (
          <>
            <PurchasePdfDocument
              width={resizeWidth}
              scale={scale}
              file={file}
              onLoadSuccess={onLoadSuccess}
              pageNumber={pageNumber}
              isScaleChange={isScaleChange}
            />

            {!isLoading && (
              <PurchasePdfPagination
                pageNumber={pageNumber}
                numberOfPages={numberOfPages}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </Box>
    </ResizableBox>
  );
};
