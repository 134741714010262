import {
  LockedIcon,
  SignedOffIcon,
  StatusApprovedIcon, // MatchedIcon,
  StatusCleanedIcon,
  StatusDeclinedIcon,
  StatusErrorIcon,
  StatusMissingIcon,
  StatusParsedIcon,
  StatusProcessingIcon,
  statusFlagIcon,
} from "assets";
import { COLORS } from "components/consts";

export const UTILITY_BILL_STATUSES = {
  APPROVED: "APPROVED",
  DECLARED: "DECLARED",
  MATCHED: "MATCHED",
  CLEANED: "CLEANED",
  DECLINED: "DECLINED",
  ERROR: "ERROR",
  INCOMPLETE_DATA: "INCOMPLETE_DATA",
  PARSED: "PARSED",
  PROCESSING: "PROCESSING",
  VERIFICATION_FAILED: "VERIFICATION_FAILED",
  PENDING: "PENDING",
  SIGNED: "SIGNED",
  LOCKED: "LOCKED",
  REVIEWED: "REVIEWED",
} as const;

export type UtilityBillStatusKey = keyof typeof UTILITY_BILL_STATUSES;

export const UTILITY_BILL_STATUS_TO_ICON: any = {
  [UTILITY_BILL_STATUSES.APPROVED]: StatusApprovedIcon,
  [UTILITY_BILL_STATUSES.DECLARED]: StatusDeclinedIcon,
  [UTILITY_BILL_STATUSES.MATCHED]: StatusErrorIcon,
  [UTILITY_BILL_STATUSES.CLEANED]: StatusCleanedIcon,
  [UTILITY_BILL_STATUSES.DECLINED]: StatusDeclinedIcon,
  [UTILITY_BILL_STATUSES.ERROR]: StatusErrorIcon,
  [UTILITY_BILL_STATUSES.INCOMPLETE_DATA]: StatusMissingIcon,
  [UTILITY_BILL_STATUSES.PARSED]: StatusParsedIcon,
  [UTILITY_BILL_STATUSES.PROCESSING]: StatusProcessingIcon,
  [UTILITY_BILL_STATUSES.PENDING]: StatusProcessingIcon,
  [UTILITY_BILL_STATUSES.VERIFICATION_FAILED]: statusFlagIcon,
  "Approved (Signed Off)": SignedOffIcon,
  "Approved (Locked)": LockedIcon,
} as const;

export const UTILITY_BILL_STATUS_TO_BACKGROUND_COLOR: any = {
  [UTILITY_BILL_STATUSES.APPROVED]: "#9EE766",
  [UTILITY_BILL_STATUSES.DECLARED]: " rgba(255, 0, 0, 0.12)",
  [UTILITY_BILL_STATUSES.MATCHED]: "rgba(255, 121, 0, 0.12)",
  [UTILITY_BILL_STATUSES.CLEANED]: "#9EE766",
  [UTILITY_BILL_STATUSES.DECLINED]: "rgba(231, 2, 2, 0.12)",
  [UTILITY_BILL_STATUSES.ERROR]: "#7F7F7F",
  [UTILITY_BILL_STATUSES.INCOMPLETE_DATA]: "#DD1515",
  [UTILITY_BILL_STATUSES.PARSED]: "rgba(36, 63, 54, 0.12)",
  [UTILITY_BILL_STATUSES.PROCESSING]: "rgba(36, 63, 54, 0.12)",
  [UTILITY_BILL_STATUSES.PENDING]: "rgba(36, 63, 54, 0.12)",
  [UTILITY_BILL_STATUSES.VERIFICATION_FAILED]: "#DD7615",
  "Approved (Signed Off)": COLORS.Siggned_Off_Color,
  "Approved (Locked)": COLORS.Locked_Color,
  "Warning (Conversion Error)": "#DD1515",
  "Warning (Missing Fields)": "#DD1515",
  "Warning (Potential Error Identified)": "#DD1515",
  "Emissions Included (User Confirmed)": "#9EE766",
  "Emissions Included (AI Generated)": "#9EE766",
  "Reviewed": "#00A1FF",

} as const;

export const UTILITY_BILL_STATUS_TO_MESSAGE: any = {
  [UTILITY_BILL_STATUSES.APPROVED]: "Approved",
  [UTILITY_BILL_STATUSES.DECLARED]: "Declared",
  [UTILITY_BILL_STATUSES.MATCHED]: "Matched",
  [UTILITY_BILL_STATUSES.CLEANED]: "Reviewed",
  [UTILITY_BILL_STATUSES.DECLINED]: "Decline",
  [UTILITY_BILL_STATUSES.ERROR]: "Error",
  [UTILITY_BILL_STATUSES.INCOMPLETE_DATA]: "Incomplete",
  [UTILITY_BILL_STATUSES.PARSED]: "Parsed",
  [UTILITY_BILL_STATUSES.PROCESSING]: "Processing",
  [UTILITY_BILL_STATUSES.PENDING]: "Pending",
  [UTILITY_BILL_STATUSES.VERIFICATION_FAILED]: "Data Warning",
  "Approved (Signed Off)": "Approved (Signed Off)",
  "Approved (Locked)": "Approved (Locked)",
  "Warning (Conversion Error)": "Warning (Conversion Error)",
  "Warning (Missing Fields)": "Warning (Missing Fields)",
  "Warning (Potential Error Identified)": "Warning (Potential Error Identified)",
  "Emissions Included (User Confirmed)": "Emissions Included (User Confirmed)",
  "Emissions Included (AI Generated)": "Emissions Included (AI Generated)",
  "Reviewed": "Reviewed",
} as const;
