/* eslint-disable */
import { Box } from "@mui/system";
import React from "react";
import CurrencyInput from "react-currency-input-field";
import { Controller, ControllerProps, FieldValues } from "react-hook-form";
import { FieldPath } from "react-hook-form/dist/types";

import { Label12SemiBold } from "components/typography";
import { formatNumber } from "utils";

export const IMaskFormTextFieldNumber = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  rules,
  label,
  disabled
}: {
  control: ControllerProps<TFieldValues, TName>["control"];
  name: ControllerProps<TFieldValues, TName>["name"];
  rules?: ControllerProps<TFieldValues, TName>["rules"];
  label?: any;
  disabled?: boolean;
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field }) => {
      const locale = navigator.language;
      return (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ mb: "4px" }}>
            <Label12SemiBold>{label}</Label12SemiBold>
          </Box>
          <div>
            <CurrencyInput
              disabled={disabled}
              className="custom-currency-input"
              intlConfig={{ locale: locale }}
              value={undefined}
              defaultValue={field?.value.length > 0 ? formatNumber(Number(field?.value), 2) : ''}
              disableAbbreviations={true}
              onValueChange={(value: any) => field.onChange(value)}
              style={{
                height: "40px",
                width: "100%",
                borderRadius: "3px",
                boxShadow: "none",
                font: "inherit",
                padding: "8.5px 14px",
                borderStyle: "none",
                border: "1px solid #C4C4C4",
                color: disabled ? "grey" : "#000302",
              }}
            />
          </div>
        </Box>
      );
    }}
  />
);
