import { Box } from "@mui/system";
import React, { FunctionComponent } from "react";

import { EMPTY_CELL_VALUE } from "components";

import { formatNumber, isNullOrUndefined } from "utils";

export const UsageDisplay: FunctionComponent<{
  usage: number | null;
  unit: string;
}> = ({ usage, unit }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "flex-end",
      gap: "3px",
      fontWeight: 600,
    }}
  >
    <p style={{ margin: 0 }}>
      {isNullOrUndefined(usage)
        ? EMPTY_CELL_VALUE
        : formatNumber(Number(usage), 4)}
    </p>
    {unit && !isNullOrUndefined(usage) && (
      <p style={{ fontSize: "10px", margin: 0, marginBottom: "2px" }}>{unit}</p>
    )}
  </Box>
);
