import { Box, Button, Grid } from "@mui/material";
import qs from "qs";
import React from "react";
import { useLocation } from "react-router-dom";
import { TraceraLogo } from "assets";
import "./style.css"
import {
  Body1SemiBold,
  COLORS,
  FLEX_CENTER_POSITIONING_STYLE,
  H3Bold,
  Label16Medium,
  TextField,
} from "components";

import { useSignIn } from "hooks";

import { SIGN_IN_BUTTON_TITLE, SIGN_IN_SUBTITLE } from "providers";

import { SIGN_IN_PAGE } from "./consts";

type Props = {
  isForm?: boolean;
};
export const SignInPage = ({ isForm = false }: Props) => {
  const onSignIn = useSignIn();

  return (
    <Grid container sx={{ height: "100%", minWidth: "1200px" }}>
        <Grid
          item
          xs={6}
          sx={{
            ...FLEX_CENTER_POSITIONING_STYLE,
            flexDirection: "column",
          }}
        >
          <div className="signIn-Bg">
          <Box sx={{ position: "relative", right: '150px' }}>
            <TraceraLogo fill={COLORS.white} />
            <Body1SemiBold sx={{ color: COLORS.white, mt: 1 }}>
              Trace. Trust. Transform
              {/* <br />
              powered by AI */}
            </Body1SemiBold>
          </Box>
      </div>
        </Grid>
      <Grid
        item
        xs={6}
        sx={{ ...FLEX_CENTER_POSITIONING_STYLE, backgroundColor: COLORS.white }}
      >
        <Box>
          <H3Bold sx={{ color: COLORS.black }}>{SIGN_IN_PAGE.TITLE}</H3Bold>
          {isForm ? (
            <MetaData />
          ) : (
            <>
              <Label16Medium sx={{ mt: 2 }}>{SIGN_IN_SUBTITLE}</Label16Medium>
                <button type="submit" className="button" onClick={onSignIn}>
                  <span className="btn-txt">{SIGN_IN_BUTTON_TITLE}</span>
                </button>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const MetaData = () => {
  const location = useLocation();

  const { state } = qs.parse(
    location.search.substring(1, location.search.length),
  );

  const warningMessage = "Required field..";

  const [metaData, setMetaData] = React.useState({
    firstName: "",
    lastName: "",
  });
  const [submitClicked, setSubmitClicked] = React.useState(false);
  const onSubmit = () => {
    setSubmitClicked(true);
    if (metaData.firstName && metaData.lastName) {
      setTimeout(() => {
        window.location.href = `https://${
          process.env.REACT_APP_AUTH_DOMAIN
        }/continue?state=${state}&firstname=${encodeURI(
          metaData.firstName,
        )}&lastname=${encodeURI(metaData.lastName)}`;
      }, 500);
    }
  };
  const onChange = (event: any) =>
    setMetaData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

  return (
    <>
      <Box
        sx={{
          marginTop: "40px",
        }}
      >
        <TextField
          sx={{
            width: "400px",
          }}
          label="First name"
          name="firstName"
          onChange={onChange}
          value={metaData.firstName}
          color={submitClicked && !metaData.firstName ? "warning" : undefined}
          message={submitClicked && !metaData.firstName ? warningMessage : ""}
        />
      </Box>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <TextField
          sx={{
            width: "400px",
          }}
          label="Last name"
          name="lastName"
          onChange={onChange}
          value={metaData.lastName}
          color={submitClicked && !metaData.lastName ? "warning" : undefined}
          message={submitClicked && !metaData.lastName ? warningMessage : ""}
        />
      </Box>
      <Button
        sx={{ mt: 5, backgroundColor: COLORS.greenDarker }}
        fullWidth
        variant="contained"
        onClick={onSubmit}
      >
        Submit and Redirect to Home
      </Button>
    </>
  );
};
