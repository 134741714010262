/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable */
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import drilldow from "highcharts/modules/drilldown";

import { COLORS, Esg12Typography, EsgPrimaryLabel, H6, Label14SemiBold } from "components";
import { Box, positions } from "@mui/system";
import { Divider } from "@mui/material";

drilldow(Highcharts);
export const ScopeEmissionScopePieChart = (sitesEmissionsData: any) => {
  const categories = ["CO2", "CH4", "N2O"];

  const data = [
    {
      y: sitesEmissionsData?.sitesEmissionsData?.totalScope1Co2OnlyEmission,
      color: "#9EE766",
      drilldown: {
        name: "Scope 1",
        categories: ["scope"],
        subcat:
          sitesEmissionsData?.sitesEmissionsData?.totalScope1Co2OnlyEmission,
      },
    },
    {
      y: sitesEmissionsData?.sitesEmissionsData?.totalScope1Ch4OnlyEmission,
      color: "#57BEED",
      drilldown: {
        name: "Scope 2",
        categories: ["scope"],
        subcat:
          sitesEmissionsData?.sitesEmissionsData?.totalScope1Ch4OnlyEmission,
      },
    },
    {
      y: sitesEmissionsData?.sitesEmissionsData?.totalScope1N2oOnlyEmission,

      color: "#054254",
      drilldown: {
        name: "Scope 2",
        categories: ["scope"],
        subcat:
          sitesEmissionsData?.sitesEmissionsData?.totalScope1N2oOnlyEmission,
      },
    },
  ];

  const browserData = [];
  const versionsData = [];
  let i;
  let j;
  const dataLen = data.length;
  let drillDataLen;
  let brightness;

  // Build the data arrays
  for (i = 0; i < dataLen; i += 1) {
    browserData.push({
      name: categories[i],
      y: data[i].y,
      color: data[i].color,
    });
    // add version data
    drillDataLen = data[i]?.drilldown?.subcat?.length;
    for (j = 0; j < drillDataLen; j += 1) {
      brightness = 0.2 - j / drillDataLen / 5;
      versionsData.push({
        name: data[i].drilldown.categories[j],
        y: data[i].drilldown.subcat[j],
        color: Highcharts.color(data[i].color).brighten(brightness).get(),
      });
    }
  }

  // Create the chart
  const options = {
    chart: {
      type: "pie",
      height: 120,
      width: 120,
      backgroundColor: "white"
    },
    title: {
      align: "left",
      floating: true,
      text: ``,
      y: 51,
      x: 4,
    },
    subtitle: {
      text: ``,
      verticalAlign: "bottom",
      floating: true,
      align: "left",
      x: 4,
      y: 15,
      width: 280,
    },

    plotOptions: {
      pie: {
        showInLegend: false,
      },
    },
    credits: {
      enabled: false,
    },

    legend: {
      align: "right",
      layout: "vertical",
      verticalAlign: "middle",
      x: -60,
      itemStyle: { fontSize: "10px", fontfamily: "Manrope" },
      fontfamily: "Manrope",
    },

    series: [
      {
        name: "scope",
        data: browserData,
        size: "100%",
        innerSize: "80%",
        dataLabels: {
          enabled: false,
          color: "#ffffff",
          distance: 0,
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 100,
          },
          chartOptions: {
            series: [
              {},
              {
                id: "versions",
                dataLabels: {
                  enabled: false,
                },
              },
            ],
          },
        },
      ],
    },
  };

  return (
    <>
      <div style={{ backgroundColor: "white", width: "240px", borderRadius: "10px", }}>
        <Box sx={{ display: "flex", pt: 2, pl: 2.8, gap: "4px" }}>
          <Box sx={{ width: "20px", height: "16px", backgroundColor: "#9EE766", borderRadius: "4px" }} />
          <Label14SemiBold >Scope 1</Label14SemiBold>
        </Box>
        <Box sx={{ fontSize: 17, display: "flex", gap: "4px", pl: 5, mt: "6px" }}>
          <H6 sx={{ color: COLORS.Romance }}>{sitesEmissionsData?.sitesEmissionsData?.totalScope1Emission} tCO2eq</H6>
        </Box>
        <Divider sx={{ width: "262px", my: "10px" }} />
        <EsgPrimaryLabel sx={{ pl: 5 }}>BreakDown</EsgPrimaryLabel>
        <PieChart highcharts={Highcharts} options={options} />
        <Box sx={{ display: "flex", gap: "8px", pl: 2.8 }}><Box sx={{ width: "20px", height: "16px", backgroundColor: "#9EE766", borderRadius: "4px" }} /> <Esg12Typography sx={{ fontWeight: "800", color: "black" }}>CO2</Esg12Typography> <Esg12Typography>{(sitesEmissionsData?.sitesEmissionsData?.totalScope1Co2OnlyEmission)?.toFixed(3)} tCO2eq</Esg12Typography> </Box>
        <Box sx={{ display: "flex", gap: "8px", pl: 2.8, my: "5px" }}><Box sx={{ width: "20px", height: "16px", backgroundColor: "#57BEED", borderRadius: "4px" }} /> <Esg12Typography sx={{ fontWeight: "800", color: "black" }}>CH4 Fossil</Esg12Typography> <Esg12Typography>{(sitesEmissionsData?.sitesEmissionsData?.totalScope1Ch4FossilEmission)?.toFixed(3)} tCO2eq</Esg12Typography> </Box>
        <Box sx={{ display: "flex", gap: "8px", pl: 2.8, my: "5px" }}><Box sx={{ width: "20px", height: "16px", backgroundColor: "#57BEED", borderRadius: "4px" }} /> <Esg12Typography sx={{ fontWeight: "800", color: "black" }}>CH4 Non-Fossil</Esg12Typography> <Esg12Typography>{sitesEmissionsData?.sitesEmissionsData?.totalScope1Ch4NonFossilEmission
          ? (sitesEmissionsData?.sitesEmissionsData?.totalScope1Ch4NonFossilEmission)?.toFixed(3)
          : 0} tCO2eq</Esg12Typography></Box>
        <Box sx={{ display: "flex", gap: "8px", pl: 2.8, mt: "5px" }}><Box sx={{ width: "20px", height: "16px", backgroundColor: "#054254", borderRadius: "4px" }} /> <Esg12Typography sx={{ fontWeight: "800", color: "black" }}>N2O</Esg12Typography> <Esg12Typography>{(sitesEmissionsData?.sitesEmissionsData?.totalScope1N2oOnlyEmission)?.toFixed(3)} tCO2eq</Esg12Typography></Box>
      </div>
    </>
  );
};
