/* eslint-disable */

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";

import React, { FC, useEffect, useMemo, useState } from "react";

import { CloseButton, COLORS, EsgDatePickerField, InputFieldWrapper, Label14Medium, Label16Medium, PrimaryLabel, usePagination } from "components";
import { useForm } from "react-hook-form";
import { Box, width } from "@mui/system";
import MaterialReactTable, { MRT_RowSelectionState } from "material-react-table";
import { useFilteredSitesMultiselect } from "hooks/multiselect/filteredSites";
import { LOCAL_STORAGE_KEYS } from "consts";
import moment from "moment";
import dayjs from "dayjs";
import { getApprovalBillData, getApprovalLock, getApprovalSignOff, getLockedBillData, undoApprovalLock, undoApprovalSignOff } from "api";
import { useMutation } from "@tanstack/react-query";
import { useNotify } from "hooks";

interface AddSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  status: any;
}

export const ApprovalsModal: FC<AddSiteModalProps> = ({
  isOpen,
  onClose,
  status,
}) => {
  // ---- State ----
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [step, setStep] = useState(1);
  const [confirmartionData, setConfirmartionData] = useState<any>([]);
  const [key, setKey] = useState(0);
  const [signatureValue, setSignatureValue] = useState("");
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
      {},
    );

  // ---- Const ----
  const totalSteps = 2;

  // ---- API Calls ----
    const { mutateAsync: approvalDataMutate } = useMutation(getApprovalSignOff);
    const {  mutateAsync: undoApprovalDataMutate } = useMutation(undoApprovalSignOff);
    const {  mutateAsync: lokckDataMutate } = useMutation(getApprovalLock);
    const {  mutateAsync: unLockDataMutate } = useMutation(undoApprovalLock);

  const notify = useNotify();


  const detailColumn = [
    {
      header: "Select All",
      Header: <Box sx={{ paddingLeft: "3px" }}>Select All</Box>,
      accessorKey: "label",
      Cell: ({ cell, row }: any) => {
        const value = cell.getValue()

        return (
          <>{value}</>
        );
      },
      enableSorting: false,
      size: 125,
    },
  ]

  const handleClose = () => {
    setStep(1)
    onClose()
    setStartDate(null)
    setEndDate(null)
    setRowSelection({})
    setSignatureValue("")
  }


  const {
    setInitialPage,
    updateQueryParamsWithInitialPage,
  } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
  });

  const { sitesOptions } =
    useFilteredSitesMultiselect({
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    });

    const selectedRow = Object.keys(rowSelection)
    const selectedRowPayload = selectedRow.join(",")

    const { data, mutateAsync: getBillData  } = useMutation(getApprovalBillData);
    const { data: lockedData ,mutateAsync: getLockedData  } = useMutation(getLockedBillData);

    const startDatePayload = dayjs(startDate).format("YYYY-MM-DD")
    const endDatePayload = dayjs(endDate).format("YYYY-MM-DD")
    const siteIds = selectedRowPayload

  const handleNextButtonClick = () => {
    if ((startDatePayload && startDatePayload !== "Invalid Date") && (endDatePayload && endDatePayload !== "Invalid Date")) {
      if (status === "signoff") {
        getBillData({startDatePayload, endDatePayload, siteIds}, {
          onError: (e: any) => {
             return notify.error(e?.response?.data ?? 
              `Something went wrong!`,
            )},
            onSuccess: () => {
              setStep(2)
            },
        })
      }
      if (status === "lock") {
        getLockedData({startDatePayload, endDatePayload, siteIds}, {
          onError: (e: any) => {
             return notify.error(e?.response?.data ?? 
              `Something went wrong!`,
            )},
            onSuccess: () => {
              setStep(2)  
            },
        })
      }
    }
  }

  useEffect(() => {
    if (data) {
      setConfirmartionData(data)
    }
    if (lockedData) {
      setConfirmartionData(lockedData)
    }
  },[data, lockedData])

  const handleConfirmButtonClick = () => {
    const payload = {
      startDate: startDatePayload,
      endDate: endDatePayload,
      siteIds: selectedRow,
      userSign: signatureValue
    }

    
    if (status === "signoff") {
      approvalDataMutate(payload, {
        onError: (e: any) => {
           notify.error(e?.response?.data ?? 
            `Some error has happen while performing Sign Off`,
          )},
        onSuccess: () => {
          notify.success(`Data Signed Off Successfully!`);
          handleClose();
        },
      })
    }
   
    if (status === "lock") {
      lokckDataMutate(payload, {
        onError: (e: any) => {
          notify.error(e?.response?.data ??
            `Some error has happen while performing data Locking!`,
          )},
        onSuccess: () => {
          notify.success(`Data Locked Successfully!`);
          handleClose();
        },
      })
    }
    
  }

  const handleUndoApi = () => {
    if (status === "undosignoff") {
      undoApprovalDataMutate(selectedRowPayload)
    }
    if (status === "unlock") {
      unLockDataMutate(selectedRowPayload)
    }
  }

  useEffect(() => {
      setKey((prev) => prev + 1);
    }, []);

  return (
    <Dialog open={isOpen} onClose={handleClose} 
    PaperProps={{
      sx: { width: "680px" },
    }}>
      <DialogTitle sx={{padding: 0}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer',py: "12px", px: "24px", alignItems: 'center' }}>
          <PrimaryLabel sx={{ color: 'black' }}>Step {step}/{totalSteps}</PrimaryLabel>
          <span onClick={() => handleClose()}>
            <CloseButton />
          </span>
        </Box>
        <Divider sx={{width: '100%', mb: "12px"}}/>
      </DialogTitle>
      <DialogContent>
        {step === 1 ?
          <Box>
            {status === "signoff" || status === "undosignoff" ? <Label16Medium>
              Please select the sites you are confirming. Data providers will no longer be able to make changes to the data points for this period.
            </Label16Medium> : (
              <Label16Medium>
              Please select the sites you are locking.
            </Label16Medium>
            )}
            <Box>
              {sitesOptions && <MaterialReactTable
                key={key}
                enableTopToolbar={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableRowSelection={true}
                enableSelectAll={true}
                data={sitesOptions}
                enablePagination={false}
                columns={detailColumn}
                enableStickyHeader
                enableColumnFilters
                initialState={{ density: 'compact' }}
                muiTableContainerProps={{
                  sx: {
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent"
                    },

                    "&::-webkit-scrollbar": {
                      right: "0",
                      width: "8px",
                      height: "12px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#D9D9D9",
                      marginTop: "40px",
                      borderRadius: "8px",
                      width: "3px",
                      height: "40px"
                    },
                    "& thead tr:first-of-type th:first-of-type": {
                      paddingLeft: "20px",
                    },
                    "& tbody tr td:first-of-type": {
                      paddingLeft: "20px",
                    },
                    "& tbody tr:first-of-type td > p": {
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    },
                    height: "200px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "6px",
                    width: "40%",
                    my: 3
                  },
                }}
                muiTablePaperProps={{
                  sx: {
                    borderRadius: "10px",
                    boxShadow: "none",
                  },
                }}
                muiTopToolbarProps={{
                  sx: {
                    backgroundColor: 'white',
                    '& .MuiIconButton-root': {
                      display: 'none',
                    },
                  },
                }}
                muiBottomToolbarProps={{
                  sx: {
                    display: "none",

                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    lineHeight: "30px",
                    "& .MuiBadge-root": {
                      display: "none",
                    },
                  },
                }}
                muiTableHeadRowProps={{
                  sx: {
                    "& > th": {
                      paddingTop: 1,
                      paddingBottom: 1,
                    },
                    height: "auto",
                    backgroundColor: "white",
                  },
                }}
                muiTableBodyRowProps={{
                  sx: {
                    cursor: "pointer",
                    backgroundColor: "",
                  },
                }}
                getRowId={(row: any) => row.value}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
              />}
            </Box>
          {status === "signoff" || status === "lock" ? <Box>
              <Label16Medium>
                Please enter the start and end dates for the data you are {status === "signoff" || status === "undosignoff" ? "confirming" : "locking"}:
              </Label16Medium>
              <Box sx={{ display: 'flex', my: 3, maxWidth: "450px" }}>
                <EsgDatePickerField
                  views={["year", "month", "day"]}
                  placeholdervalue="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  openTo="year"
                  onAccept={(date: any) => setStartDate(date)}
                  onChange={() => { }}
                  value={startDate}
                  clearDate={false}
                  label="Start Date"
                  disableFuture
                  disableHighlightToday
                />
                <EsgDatePickerField
                  views={["year", "month", "day"]}
                  placeholdervalue="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  openTo="year"
                  disableFuture
                  disableHighlightToday
                  onAccept={(date: any) => setEndDate(date)}
                  onChange={() => { }}
                  value={endDate}
                  clearDate={false}
                  label="End Date"
                />
              </Box>
            </Box> : <></>}
            <Box>

            </Box>
          </Box> : (
            <Box>
              <Label16Medium>
                Please write your name as a digital signature for the following:
              </Label16Medium>
              <Box sx={{my: 3}}>
                {confirmartionData?.data?.map((t: any) => {
                  return (<>
                  <Label16Medium>{t?.siteName}</Label16Medium>
                  <Label14Medium sx={{ fontStyle: 'italic', fontWeight: 300, my: 1 }}><span style={{ color: "#60A034", fontWeight: 600 }}>{t.statusCount}</span><span style={{ fontWeight: 600 }}>/{t.total}</span> {`bills ready to be ${status === "signoff" ? "signed off" : "locked"}`}</Label14Medium>
                  </>)
                })}

                {(confirmartionData?.data?.length === 0 ) && <Label16Medium sx={{color: "#DD7615"}}>{"Warning :- There are no bills associated with this time period, would you like to proceed?"}</Label16Medium> }
                {/* {(confirmartionData?.data?.length === 0 && status === "lock") && <Label16Medium sx={{color: "#DD7615"}}>{"No Bills are available for Locking"}</Label16Medium> } */}
              </Box>
              <Box sx={{ display: 'flex', gap: 3, my: 3 }}>
                <InputFieldWrapper label="Type your name below to sign off">
                  <TextField
                    sx={{ width: "100%", borderRadius: "20px", borderColor: signatureValue ? "" : "red" }}
                    name={"signOff"}
                    value={signatureValue}
                    onChange={(e) => setSignatureValue(e.target.value)}
                    helperText={""}
                    multiline
                    rows={3}
                  />
                
                </InputFieldWrapper>
              </Box>
              <Box>

              </Box>
            </Box>
          )}
        {status === "signoff" || status === "lock" ? <Button
          onClick={() => {
            if (step === 1) {
              handleNextButtonClick()
            } else {
              handleConfirmButtonClick()
            }
          }}
          variant="contained"
          color="primary"
          sx={{ width: '150px', borderRadius: 2 }}
          // disabled={(step === 2 && confirmartionData?.data?.length === 0)}
        >
          {step === 1 ? "Next" : "Confirm"}
        </Button> : <Button
          onClick={() =>  handleUndoApi()}
          variant="contained"
          color="primary"
          sx={{ width: '150px', borderRadius: 2 }}
        >
          Confirm
        </Button>}
        
      </DialogContent>
    </Dialog>
  );
};
