import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useGetCountries } from "hooks/api/renewable-energy-purchases";
import dayjs, { Dayjs } from "dayjs";
import { createPurchase, RenewableEnergyPurchasesDTO, DistributionDTO, SITES_API_PATHS, updatePurchase, updatePurchaseDistributions } from "api";
import { CloseButton, COLORS, Loading, PurchasePdfFooter, PurchasePdfPreview } from "components";
import { EsgPencilIcon } from "assets";
import { useNotify } from "hooks";
import { APPLICATION_JSON, FORM_DATA } from "consts";

export const RenewableEnergyPurchasesUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { data: dict, isLoading } = useGetCountries();

  const { mutateAsync: createPurchaseRequest } = useMutation(createPurchase);
  const { mutateAsync: updatePurchaseRequest } = useMutation(updatePurchase);
  const { mutateAsync: updatePurchaseDistributionsRequest } = useMutation(updatePurchaseDistributions);

  const { state } = location;

  const [currentPurchase, setCurrentPurchase] = useState<RenewableEnergyPurchasesDTO>(state.purchase);
  const [purchaseCreated, setPurchaseCreated] = useState<boolean>(state.isEdit);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [countries, setCountries] = useState<string[]>([]);
  const [geography, setGeography] = useState<string>(state.isEdit ? currentPurchase.geography : "");
  const [energyAmount, setEnergyAmount] = useState<string>(state.isEdit ? currentPurchase.totalRenewableEnergy.toString() : "");
  const [energyAmountUnits, setEnergyAmountUnits] = useState<string[]>(state.isEdit ? [currentPurchase.renewableEnergyUnit] : []);
  const [selectedEnergyAmountUnit, setSelectedEnergyAmountUnit] = useState<string>(state.isEdit ? currentPurchase.renewableEnergyUnit : "");
  const [emissionFactor, setEmissionFactor] = useState<string>(state.isEdit ? currentPurchase.emissionFactorValue.toString() : "");
  const [emissionFactorUnits, setEmissionFactorUnits] = useState<string[]>(state.isEdit ? [currentPurchase.emissionFactorUnit] : []);
  const [selectedEmissionFactorUnit, setSelectedEmissionFactorUnit] = useState<string>(state.isEdit ? currentPurchase.emissionFactorUnit : "");
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(state.isEdit ? currentPurchase.startDate.substring(0, 11) : null));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(state.isEdit ? currentPurchase.endDate.substring(0, 11) : null));
  const [dataDistributions, setDataDistributions] = useState<DistributionDTO[]>(state.isEdit ? currentPurchase.distributions! : []);
  const [editable, setEditable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [scaleChange, setScaleChange] = useState(false);
  const [scale, setScale] = useState(0.75);

  const formatNumber = (value: number) => new Intl.NumberFormat("en-US").format(value);

  const goBackToSites = () => {
    navigate("/sites");
  };

  const handlePageChange = async (action: string) => {
    if (action === "prev") {
      setCurrentPage(currentPage - 1);
    } else if (action === "next") {
      if (
        geography.length > 0 &&
        energyAmount.toString().length > 0 &&
        selectedEnergyAmountUnit.length > 0 &&
        emissionFactor.toString().length > 0 &&
        selectedEmissionFactorUnit.length > 0 &&
        startDate?.isValid() &&
        endDate?.isValid()
      ) {
        if (!purchaseCreated) {
          await handleSaveChanges();
        }
        setCurrentPage(currentPage + 1);
      }
      else {
        notify.error('Please complete all fields!');
      }
    }
  }

  const handleChangePercentageDistribution = (value: string, siteId: number | string) => {
    const updatedDistributions = dataDistributions.map((site: any) =>
      site.siteId === siteId
        ? { ...site, percentageDistribution: (value.length > 0 ? parseFloat(value) : "0") }
        : site
    );
    setDataDistributions(updatedDistributions);
  };

  const handleFileName = async (file: any) => {
    if (file === null) {
      setCurrentPurchase({
        ...currentPurchase,
        fileName: null,
        s3Path: null,
        downloadUrl: null,
      })
    } else {
      setLoading(true);
      const actualPurchase: RenewableEnergyPurchasesDTO = {
        geography: geography.replaceAll(" ", "_"),
        totalRenewableEnergy: parseInt(energyAmount, 10),
        renewableEnergyUnit: selectedEnergyAmountUnit,
        emissionFactorValue: Number(parseFloat(emissionFactor).toFixed(2)),
        emissionFactorUnit: selectedEmissionFactorUnit,
        startDate: startDate?.isValid() ? startDate.utc().toISOString().replace(".000", "") : "",
        endDate: endDate?.isValid() ? endDate!.utc().toISOString().replace(".000", "") : "",
      }

      const formData = new FormData();

      const jsonBlob = new Blob([JSON.stringify(actualPurchase)], {
        type: APPLICATION_JSON,
      });

      if (jsonBlob && file) {
        formData.append("file", new Blob([file], { type: FORM_DATA }), file.name);
        formData.append("data", jsonBlob)

        await updatePurchaseRequest({ id: currentPurchase.id!, purchase: formData }, {
          onError: () => {
            notify.error('Some error has happened while updating the purchase!');
            setLoading(false);
          },
          onSuccess: () => {
            notify.success('Renewable Energy Purchase successfully updated!');
            setLoading(false);
          },
        }).then((response: any) => setCurrentPurchase(response.data));
      }
    }
  }

  const handleSaveChanges = async () => {
    setLoading(true);
    setEditable(false);
    const actualPurchase: RenewableEnergyPurchasesDTO = {
      geography: geography.replaceAll(" ", "_"),
      totalRenewableEnergy: parseInt(energyAmount, 10),
      renewableEnergyUnit: selectedEnergyAmountUnit,
      emissionFactorValue: Number(parseFloat(emissionFactor).toFixed(2)),
      emissionFactorUnit: selectedEmissionFactorUnit,
      startDate: startDate!.utc().toISOString().replace(".000", ""),
      endDate: endDate!.utc().toISOString().replace(".000", ""),
    }

    const formData = new FormData();
    const jsonBlob = new Blob([JSON.stringify(actualPurchase)], {
      type: APPLICATION_JSON,
    });

    formData.append("data", jsonBlob);

    if (purchaseCreated) {
      await updatePurchaseDistributionsRequest({ id: currentPurchase.id!, distributions: dataDistributions }, {
        onError: () => {
          notify.error('Some error has happened while updating distributions!');
          setLoading(false);
        },
        onSuccess: () => {
          queryClient.invalidateQueries([SITES_API_PATHS.RENEWABLE_ENERGY_PURCHASES]);
        },
      });
      await updatePurchaseRequest({ id: currentPurchase.id!, purchase: formData }, {
        onError: () => {
          notify.error('Some error has happened while updating the purchase!');
          setLoading(false);
        },
        onSuccess: () => {
          setLoading(false);
          queryClient.invalidateQueries([SITES_API_PATHS.RENEWABLE_ENERGY_PURCHASES]);
          notify.success('Purchase successfully updated!');
        },
      }).then((response: any) => {
        setCurrentPurchase(response.data)
        setDataDistributions(response.data.distributions)
      });
    } else {
      await createPurchaseRequest(formData, {
        onError: () => {
          notify.error('Some error has happened while adding the purchase!');
          setLoading(false);
        },
        onSuccess: () => {
          setLoading(false);
          notify.success('Purchase successfully created!');
          queryClient.invalidateQueries([SITES_API_PATHS.RENEWABLE_ENERGY_PURCHASES]);
          setPurchaseCreated(true);
        },
      }).then((response: any) => {
        setCurrentPurchase(response.data)
        setDataDistributions(response.data.distributions)
      });
    }
  };

  const RENEWABLE_ENERGY_PURCHASES_DETAILS_PER_SITE: MRT_ColumnDef<any>[] = [
    {
      id: "site",
      header: "Site",
      accessorKey: "siteName",
      Cell: ({ row }: { row: any }) => {
        const { siteName } = row.original;
        return (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            maxHeight: "48px",
            padding: "12px 16px",
            textOverflow: "ellipsis",
          }}>
            <p style={{
              maxHeight: "48px",
              margin: 0,
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "14px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}>
              <Tooltip title={siteName} placement="top">
                {siteName}
              </Tooltip>
            </p>
          </Box>
        );
      },
      size: 60,
    },
    {
      id: "latestCalendarYearUsage",
      header: "Latest Calendar Year Usage",
      accessorKey: "nonRenewableEnergyUsage",
      Cell: ({ row }: { row: any }) => {
        const { nonRenewableEnergyUsage } = row.original;
        return (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            maxHeight: "48px",
            padding: "12px 16px",
            textOverflow: "ellipsis",
          }}>
            <p style={{
              fontFamily: "Inter",
              fontWeight: "400",
              fontSize: "14px",
            }}>
              {nonRenewableEnergyUsage}
            </p>
          </Box>
        );
      },
      size: 60,
    },
    {
      id: "percentage",
      header: "Percentage",
      accessorKey: "percentageDistribution",
      Cell: ({ row }: { row: any }) => {
        const { percentageDistribution, siteId } = row.original;
        return (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            height: "48px",
            padding: "12px 16px",
            borderRight: "1px solid #C0D0D4",
            borderLeft: "1px solid #C0D0D4",
          }}>
            {editable
              ? <FormControl sx={{ width: "100%" }}>
                <TextField
                  sx={{
                    "& > div": {
                      borderRadius: "8px",
                    },
                    "& input": {
                      height: "36px",
                      padding: 0,
                      paddingLeft: "10px",
                    },
                  }}
                  variant="outlined"
                  value={percentageDistribution}
                  onChange={e => handleChangePercentageDistribution(e.target.value, siteId)}
                />
              </FormControl>
              : <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
                <p style={{
                  fontFamily: "Inter",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>
                  {`${percentageDistribution} %`}
                </p>
                <EsgPencilIcon
                  style={{ opacity: 0.5, cursor: "pointer" }}
                  onClick={() => setEditable(true)}
                  color={COLORS.Mainblue}
                />
              </Box>
            }
          </Box>
        );
      },
      size: 60,
    },
    {
      id: "renewableEnergyDistribution",
      header: "Renewable Energy Distribution",
      accessorKey: "distributions",
      Cell: ({ row }: { row: any }) => {
        const value = row.original.renewableEnergyDistribution;
        return (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            height: "48px",
            padding: "12px 16px",
          }}>
            <p style={{
              fontFamily: "Inter",
              fontWeight: "400",
              fontSize: "14px",
            }}>
              {formatNumber(value)} {currentPurchase.renewableEnergyUnit}
            </p>
          </Box>
        );
      },
      size: 120,
    },
  ];

  useEffect(() => {
    setCountries(dict?.countries?.sort().map(country => country.replaceAll("_", " ")) || []);
    setEnergyAmountUnits(dict?.utilityTypes.find((utility: any) => utility.name === "Electricity")?.unitDescription.map(itemUnit => itemUnit.unit) || []);
    setEmissionFactorUnits(["kg/kWh", "g/kWh", "lb/MWh"]);
  }, [dict])

  return (
    <Box sx={{
      height: "calc(100vh - 167px)",
    }}>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "row",
          overflow: "auto",
          height: "calc(100vh - 167px)",
          "& > div: first-of-type": {
            height: "calc(100vh - 167px) !important",
            "& > div": {
              height: "calc(100vh - 167px)",
              "& > div": {
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              },
            }
          },
        }}
      >
        <PurchasePdfPreview
          documentId={currentPurchase?.id === undefined ? null : currentPurchase?.id}
          downloadUrl={currentPurchase?.downloadUrl === undefined ? null : currentPurchase?.downloadUrl}
          scale={scale}
          isScaleChange={scaleChange}
          setFileName={handleFileName}
        />
        <Box
          sx={{
            width: "40%",
            ml: 4,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              "& > div > div:nth-of-type(2) > div > div:first-of-type > div:nth-of-type(2)": {
                display: "flex",
                "@media (max-width: 1440px)": {
                  "& > button:first-of-type": {
                    marginLeft: 2,
                  },
                  "& > button": {
                    height: "45px",
                  }
                }
              },
              "@media (min-width: 1440px)": {
                "& > div > div:nth-of-type(2) > div > div:nth-of-type(2) > div": {
                  width: "100%",
                },
              },
              flexGrow: 1,
              overflow: "hidden"
            }}
          >
            {isLoading ? (
              <Box sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40%",
              }}>
                <Loading />
              </Box>
            ) : (
              <Box>
                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "32px",
                }}>
                  <h1 style={{
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: 500,
                    marginTop: "24px",
                    marginBottom: "32px",
                  }}>
                    Renewable Energy Purchase Details
                  </h1>
                  <CloseButton
                    onClick={goBackToSites}
                    sx={{
                      width: "40px",
                      height: "40px",
                      maxHeight: "40px",
                      "& > svg": {
                        width: "40px",
                        height: "40px"
                      }
                    }}
                  />
                </Box>
                {currentPage === 1
                  ? <>
                    <Box sx={{
                      marginBottom: "18px",
                      paddingRight: "32px",
                    }}>
                      <Box sx={{
                        display: "flex",
                        gap: "24px",
                      }}>
                        <Box sx={{
                          width: "50%",
                        }}>
                          <InputLabel
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 600,
                              marginBottom: "8px",
                            }}
                            id="country-label"
                          >
                            Geography
                          </InputLabel>
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              displayEmpty
                              sx={{ height: "36px", borderRadius: "8px" }}
                              labelId="country-label"
                              id="country-select"
                              value={geography}
                              onChange={(event) => setGeography(event.target.value as string)}
                            >
                              {countries.map((country: string) => (
                                <MenuItem key={country} value={country} sx={{ color: COLORS.Mainblue, fontFamily: "Inter", fontWeight: 500, fontSize: "14px" }}>
                                  {country}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box sx={{ width: "50%" }} />
                      </Box>
                    </Box>
                    <Box sx={{
                      marginBottom: "18px",
                      paddingRight: "32px",
                    }}>
                      <Box sx={{
                        display: "flex",
                        gap: "24px",
                      }}>
                        <Box sx={{
                          width: "50%",
                        }}>
                          <InputLabel
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 600,
                              marginBottom: "8px",
                            }}
                            id="total-renewable-energy-amount-label"
                          >
                            Total Renewable Energy Amount
                          </InputLabel>
                          <FormControl sx={{ width: "100%" }}>
                            <TextField
                              sx={{
                                "& > div": {
                                  borderRadius: "8px",
                                },
                                "& input": {
                                  height: "36px",
                                  padding: 0,
                                  paddingLeft: "10px",
                                },
                              }}
                              variant="outlined"
                              value={energyAmount}
                              onChange={e => setEnergyAmount(e.target.value as string)}
                            />
                          </FormControl>
                        </Box>
                        <Box sx={{
                          width: "50%",
                        }}>
                          <InputLabel
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 600,
                              marginBottom: "8px",
                            }}
                            id="renewable-energy-unit-label"
                          >
                            Renewable Energy Unit
                          </InputLabel>
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              displayEmpty
                              sx={{ height: "36px", borderRadius: "8px" }}
                              labelId="renewable-energy-unit-label"
                              id="renewable-energy-unit-select"
                              value={selectedEnergyAmountUnit}
                              onChange={(e) => setSelectedEnergyAmountUnit(e.target.value)}
                            >
                              {energyAmountUnits.map((unit: string) => (
                                <MenuItem key={unit} value={unit} sx={{ color: COLORS.Mainblue, fontFamily: "Inter", fontWeight: 500, fontSize: "14px" }}>
                                  {unit}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{
                      marginBottom: "18px",
                      paddingRight: "32px",
                    }}>
                      <Box sx={{
                        display: "flex",
                        gap: "24px",
                      }}>
                        <Box sx={{
                          width: "50%",
                        }}>
                          <InputLabel
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 600,
                              marginBottom: "8px",
                            }}
                            id="emission-factor-label"
                          >
                            Emission Factor Value
                          </InputLabel>
                          <FormControl sx={{ width: "100%" }}>
                            <TextField
                              sx={{
                                "& > div": {
                                  borderRadius: "8px",
                                },
                                "& input": {
                                  height: "36px",
                                  padding: 0,
                                  paddingLeft: "10px",
                                },
                              }}
                              variant="outlined"
                              value={emissionFactor}
                              onChange={e => setEmissionFactor(e.target.value as string)}
                            />
                          </FormControl>
                        </Box>
                        <Box sx={{
                          width: "50%",
                        }}>
                          <InputLabel
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 600,
                              marginBottom: "8px",
                            }}
                            id="emission-factor-unit-label"
                          >
                            Emission Factor Unit
                          </InputLabel>
                          <FormControl sx={{ width: "100%" }}>
                            <Select
                              displayEmpty
                              sx={{ height: "36px", borderRadius: "8px" }}
                              labelId="emission-factor-unit-label"
                              id="emission-factor-unit-select"
                              value={selectedEmissionFactorUnit}
                              onChange={(event) => setSelectedEmissionFactorUnit(event.target.value as string)}
                            >
                              {emissionFactorUnits.map((unit: string) => (
                                <MenuItem key={unit} value={unit} sx={{ color: COLORS.Mainblue, fontFamily: "Inter", fontWeight: 500, fontSize: "14px" }}>
                                  {unit}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{
                      marginBottom: "18px",
                      paddingRight: "32px",
                    }}>
                      <Box sx={{
                        display: "flex",
                        gap: "24px",
                      }}>
                        <Box sx={{
                          width: "50%",
                        }}>
                          <InputLabel
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 600,
                              marginBottom: "8px",
                            }}
                            id="start-date-label"
                          >
                            Start Date
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                width: "100%",
                                "& > div": {
                                  borderRadius: "8px",
                                  "& input": {
                                    fontFamily: "Inter",
                                    height: "36px",
                                    padding: "0 10px",
                                  },
                                },
                              }}
                              format="MM-DD-YY"
                              value={startDate}
                              onChange={(date) => setStartDate(date)}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box sx={{
                          width: "50%",
                        }}>
                          <InputLabel
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 600,
                              marginBottom: "8px",
                            }}
                            id="end-date-label"
                          >
                            End Date
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                width: "100%",
                                "& > div": {
                                  borderRadius: "8px",
                                  "& input": {
                                    fontFamily: "Inter",
                                    height: "36px",
                                    padding: "0 10px",
                                  },
                                },
                              }}
                              format="MM-DD-YY"
                              value={endDate}
                              onChange={(date) => setEndDate(date)}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "16px",
                      alignItems: "center",
                      marginTop: "36px",
                      marginBottom: "18px",
                      paddingRight: "32px",
                    }}>
                      <Button onClick={goBackToSites}>Cancel</Button>
                      <Button
                        sx={{ width: "136px" }}
                        variant="contained"
                        onClick={() => handlePageChange("next")}
                      >
                        {loading ? <CircularProgress size={24} color="info" /> : "Next"}
                      </Button>
                    </Box>
                  </>
                  : <>
                    <p style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: 500, paddingRight: "32px" }}>
                      Here are all the sites in the {geography} region where you&apos;ve uploaded renewable energy purchase details. We&apos;ve pre-set the percentages based on last calendar year&apos;s non-renewable energy usage, but you can adjust them as needed.
                    </p>
                    <Box sx={{
                      position: "relative",
                      width: "calc(100vw - 780px)",
                      borderRadius: "10px",
                      "& > div": {
                        margin: 0,
                        "& > div": {
                          height: "calc(100vh - 440px)",
                          maxHeight: "calc(100vh - 440px)",
                          overflowY: "auto",
                        },
                      },
                      "& table": {
                        "& > thead": {
                          position: "sticky",
                          top: 0,
                          zIndex: 2,
                          "& > tr:first-of-type > th": {
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: 500,
                          },
                          "& > tr:first-of-type > th:first-of-type": {
                            maxWidth: "30px",
                          },
                          "& > tr:first-of-type > th:nth-of-type(3)": {
                            maxWidth: "30px",
                            borderRight: "1px solid #C0D0D4",
                            borderLeft: "1px solid #C0D0D4",
                            "& > div": {
                              justifyContent: "center",
                            },
                          },
                        },
                      },
                    }}>
                      <MaterialReactTable
                        muiTableContainerProps={{
                          sx: {
                            border: "1px solid #C0D0D4",
                            borderRadius: "10px",
                          },
                        }}
                        muiTablePaperProps={{
                          sx: {
                            borderRadius: "10px",
                            boxShadow: "none",
                          },
                        }}
                        muiTopToolbarProps={{
                          sx: {
                            display: "none",
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            display: "none",
                          },
                        }}
                        muiTableHeadCellProps={{
                          sx: {
                            maxHeight: "45px",
                            color: "#054254",
                            "& .MuiBadge-root": {
                              display: "none",
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            maxHeight: "48px",
                            backgroundColor: "#fff",
                            padding: 0,
                            "& > div": {
                              maxHeight: "48px",
                            }
                          },
                        }}
                        columns={RENEWABLE_ENERGY_PURCHASES_DETAILS_PER_SITE}
                        data={dataDistributions}
                        enablePagination
                        enableStickyHeader
                      />
                    </Box>
                    <Box sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "16px",
                      alignItems: "center",
                      marginTop: "36px",
                      marginBottom: "18px",
                      paddingRight: "32px",
                    }}>
                      <Button onClick={() => handlePageChange("prev")}>Back</Button>
                      <Button
                        sx={{ width: "136px" }}
                        disabled={loading}
                        variant="contained"
                        onClick={handleSaveChanges}
                      >
                        {loading ? <CircularProgress size={24} color="info" /> : "Save"}
                      </Button>
                    </Box>
                  </>
                }
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <PurchasePdfFooter
        documentId={currentPurchase?.id === undefined ? null : currentPurchase?.id}
        setScale={setScale}
        downloadUrl={currentPurchase?.downloadUrl === undefined ? null : currentPurchase?.downloadUrl}
        actionsDisabled={false}
        setScaleChange={setScaleChange}
        setFileName={handleFileName}
        fileName={currentPurchase?.fileName === undefined ? null : currentPurchase?.fileName}
      />
    </Box>
  )
}