/* eslint-disable */
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller, ControllerProps, FieldValues } from "react-hook-form";
import { FieldPath } from "react-hook-form/dist/types";

import { getUTCDateFormat } from "utils";

import { InputFieldWrapper } from "./input-field-wrapper";
import { TextFieldProps } from "./text-field";
import { COLORS } from "components/consts";

export const DatePickerFormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  inputFormat = getUTCDateFormat(),
  label,
  rules,
  disabled,
  ...textFieldProps
}: {
  label: string;
  inputFormat?: string;
  control: ControllerProps<TFieldValues, TName>["control"];
  name: ControllerProps<TFieldValues, TName>["name"];
  rules?: ControllerProps<TFieldValues, TName>["rules"];
} & TextFieldProps) => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(""));
  const [isDateError, setIsDateError] = React.useState<boolean>(false);
  useEffect(() => {
    if (
      control &&
      name === "startDate" &&
      value?.format("MM-DD-YYYY") === "Invalid Date"
    ) {
      // typescript errors were fixed checking if the value is valid
      if (control && control._fields.startDate?._f) {
        const sDate = control._fields.startDate._f as { value?: any };
        if (sDate.value !== undefined) {
          setValue(sDate.value);
        }
      }
    } else if (
      control &&
      name === "endDate" &&
      value?.format("MM-DD-YYYY") === "Invalid Date"
    ) {
      // typescript errors were fixed checking if the value is valid
      if (control && control._fields.endDate?._f) {
        const eDate = control._fields.endDate._f as { value?: any };
        if (eDate.value !== undefined) {
          setValue(eDate.value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const date: any = new Date();
    const currentDate = moment(date, "MM-DD-YYYY")
    const selectedDate: any = moment(value! as any, "MM-DD-YYYY") || "";
    if (value! !== null && selectedDate! !== "") {
      setIsDateError(moment(currentDate) < moment(selectedDate));
    }
  }, [value]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        if (field.value !== null) {
          setValue(field.value);
        }
        
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <InputFieldWrapper label={label}>
              <DatePicker
                format={inputFormat}
                value={field.value === null ? dayjs(null) : dayjs(field.value)}
                onChange={(e) => {
                  setValue(e); 
                  field.onChange(e); 
                }}
                disabled={disabled}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: {
                      border: isDateError === true ? 1 : 0,
                      borderRadius: isDateError === true ? 1 : 0,
                      borderColor: isDateError === true ? COLORS.Fuzzy_Brown : "",
                      width: "100%",
                    },
                  },
                }}
              />
            </InputFieldWrapper>
          </LocalizationProvider>
        );
      }}
    />
  );
};