/* eslint-disable */
import JSZip from "jszip";
import {
  AppBar,
  Box,
  Button,
  Stack,
  Toolbar,
} from "@mui/material";
//  Accordian

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Dialog
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
// Date
import dayjs from "dayjs";
import MaterialReactTable, {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowSelectionState,
} from "material-react-table";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  Anomaly,
  UTILITY_BILLS_API_PATHS,
  UtilityBillDTO,
  deleteUtilityBill,
  downloadUtilityBills,
  getDocumentBills,
  getDuplicateBillsData,
  getSavedSearchFilters,
  getSavedSearchFiltersList,
} from "api";

import { CommentTextIcon, EsgDeleteIcon, EsgDownloadIcon, EsgExportIcon, EyeIcon, FileDownloadIcon, TrashIcon } from "assets";

import {
  Avatar,
  COLORS,
  DeleteDialog,
  EMPTY_CELL_VALUE,
  EmptyData,
  FLEX_ALIGN_CENTER_GAP_2_SWITCH,
  Loading,
  MEASURE_UNIT_DISPLAY_MAP,
  MarginWrapper,
  SelectionCounter,
  Switch,
  Tooltip,
  UtilityBillType,
  UtilityBillTypeKey,
  useDialogControls,
  useMonthPeriodFilter,
  usePagination,
  useSearch,
  useSwitchFilter,
  useTableSorting,
  useYearPeriodFilter,
} from "components";

import {
  useAccountNumbersMultiselect,
  useMeterNumbersMultiselect,
  useNotify,
  useSitesMultiselect,
  useUtilityBillStatusesMultiselect,
  useUtilityBills,
  useUtilityTypesMultiselect,
} from "hooks";

import { LOCAL_STORAGE_KEYS, QUERY_KEYS, TABLE_HEADERS } from "consts";

import {
  useSaveCurrentUrl,
  useSetDefaultUtilityBillsSelection,
  useUserRole,
} from "recoils";

import {
  downloadFile,
  getDateFormat,
  getInitials,
  getUTCDateFormat,
  httpClient,
  isNullOrUndefined,
  setToLocalStorage,
  toUSNumber,
} from "utils";

import { AnomalyLogCell } from "./components/table/anomaly-log-cell";
import { EmissionCell } from "./components/table/emission-cell";
import { EmissionCellTooltip } from "./components/table/emission-cell-tooltip";
import { UTILITY_BILLS_TABLE } from "./consts";
import { UTILITY_BILL_COMMENTS_VIEW, UTILITY_BILL_PAGE } from "./utility-bill";

export const UtilityBillsPage = () => {
  // ---- Hooks
  const csvLinkRef = useRef<any>(null);
  const currentUrl = useLocation();
  const notify = useNotify();
  const userRole = useUserRole();
  const queryClient = useQueryClient();
  const setDefaultSelection = useSetDefaultUtilityBillsSelection();
  const deleteDialogControls = useDialogControls();
  const navigate = useNavigate();
  const { mutateAsync: deleteUB } = useMutation(deleteUtilityBill);
  const saveCurrentUrl = useSaveCurrentUrl();
  const tableInstanceRef = useRef<any>(null);
  const rowVirtualizerInstanceRef = useRef<any>(null);

  // ---- Const
  const sessionFilterValue = window.sessionStorage.getItem("columnFilters");
  const filterValue = (sessionFilterValue && JSON.parse(sessionFilterValue)) || [];
  const summaryDocumentID = window.sessionStorage.getItem("summaryDocumentID");
  const url = new URL(window.location.href);
  const queryName = url.pathname;

  // ---- States
  const [globalFilter, setGlobalFilter] = useState('');
  const [showAnomaly, setShowAnomaly] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [csvData, setCsvData] = useState<any>([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvError, setCsvError] = useState<string | null>(null);
  const [tableRowCount, setTableRowCount] = useState();
  const [DuplicateData, setDuplicateData] = useState<any>([]);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>(filterValue);
  const [jsonRes, setJsonRes] = useState<any[]>([]);
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );
  const [editFilterIndex, setEditFilterIndex] = useState<any>("");
  const [applyFilterIndex, setApplyFilterIndex] = useState<any>("");
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const [customerSiteId, setCustomerSiteId] = useState<any>("");
  const [businessUnit, setBusinessUnit] = useState<any>("");
  const [siteName, setSiteName] = useState<any>("");
  const [provider, setProvider] = useState<any>("");
  const [accountNumber, setAccountNumber] = useState<any>("");
  const [meterNumber, setMeterNumber] = useState<any>("");
  const [scope, setScope] = useState<any>("");
  const [addedBy, setAddedBy] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [measuredValue, setMeasuredValue] = useState<any>("");
  const [co2Emission, setCo2Emission] = useState<any>("");
  const [amount, setAmount] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [searchAllClicked, setSearchAllClicked] = useState(false);
  const [applyFilterClicked, setApplyFilterClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [filterName, setFilterName] = useState("");
  const [selectedRow, setSelectedRow] = useState<any>("");

  // ---- API Call's
  const { sortBy, sortDirection } = useTableSorting(
    UTILITY_BILLS_TABLE.COLUMN_TO_SORT_COLUMN_MAP,
    UTILITY_BILLS_TABLE.INITIAL_SORTING_STATE,
  );

  const { pageNumber, setInitialPage, updateQueryParamsWithInitialPage } =
    usePagination({
      pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
    });

  const { siteIds } = useSitesMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
  });

  const { utilityTypeIds } = useUtilityTypesMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const { utilityBillStatusesValues } = useUtilityBillStatusesMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
  });

  const { accountNumbers } = useAccountNumbersMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const { meterNumbers } = useMeterNumbersMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const { search } = useSearch({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
  });

  const { monthValue } = useMonthPeriodFilter({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
  });

  const { yearValue } = useYearPeriodFilter({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
  });

  const { value: isEdited, onSwitchChange: onIsEditedChange } = useSwitchFilter(
    {
      key: "isEdited",
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    },
  );

  const { value: isMyTask, onSwitchChange: onIsMyTaskChange } = useSwitchFilter(
    {
      key: "isMyTask",
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    },
  );

  const { mutateAsync: getSavedFilterValue, data: savedFilterValueResponse } =
    useMutation(getSavedSearchFiltersList);

  const { mutateAsync: getSavedFilterList, data: savedFilterData } =
    useMutation(getSavedSearchFilters);

  // ---- Use Effects
  useEffect(() => {
    window.sessionStorage.setItem(
      "columnFilters",
      JSON.stringify(columnFilters),
    );
  }, [columnFilters]);

  useEffect(() => {
    getSavedFilterList();
  }, [getSavedFilterList]);

  useEffect(() => {
    getSavedFilterList();
  }, [savedFilterValueResponse, getSavedFilterList]);

  useEffect(() => {
    if (showDuplicate || showAnomaly) {
      handleDuplicatedData();
    }
  }, [showDuplicate, showAnomaly]);

  useEffect(() => {
    if (csvData.length !== 0) {
      triggerCsvDownload();
    }
  }, [csvData]);

  useEffect(() => setShowDuplicate(false), [pageNumber]);

  useEffect(() => {
    if (tableInstanceRef?.current && summaryDocumentID) {
      const selectedIndex = data.bills.findIndex(
        (row: any) => row.id === summaryDocumentID,
      );
      if (selectedIndex !== -1) {
        const elementToScroll = rowVirtualizerInstanceRef.current;

        elementToScroll.scrollToIndex(selectedIndex + 10);
      }
    }
  }, [summaryDocumentID]);

  useEffect(() => {
    const json: any[] = [];
    if (columnFilters.length > 0) {
      json.push(...json, columnFilters);
    }
    setJsonRes(json);
  }, [columnFilters]);

  // ---- Page Handler Functions
  const setColumnFilter = () => {
    setCustomerSiteId(columnFilters?.filter((t) => t.id === "customerSiteId")[0]?.value);
    setBusinessUnit(columnFilters?.filter((t) => t.id === "businessUnit")[0]?.value);
    setSiteName(columnFilters?.filter((t) => t.id === "siteName")[0]?.value);
    setProvider(columnFilters?.filter((t) => t.id === "provider")[0]?.value);
    setAccountNumber(
      columnFilters?.filter((t) => t.id === "accountNumber")[0]?.value,
    );
    setMeterNumber(
      columnFilters?.filter((t) => t.id === "meterNumber")[0]?.value,
    );
    const scope: any = columnFilters?.filter((t) => t.id === "scope")[0]?.value;
    setScope(scope?.replace(/ /g, ""));
    setAddedBy(columnFilters?.filter((t) => t.id === "addedBy")[0]?.value);
    setType(columnFilters?.filter((t) => t.id === "type")[0]?.value);
    setMeasuredValue(
      columnFilters?.filter((t) => t.id === "measuredValue")[0]?.value,
    );
    setCo2Emission(
      columnFilters?.filter((t) => t.id === "co2Emission")[0]?.value,
    );
    setAmount(columnFilters?.filter((t) => t.id === "amount")[0]?.value);

    const startDate: any = columnFilters?.filter((t) => t.id === "startDate")[0]
      ?.value;
    const formattedStartDate =
      startDate && dayjs(startDate)?.format("YYYY-MM-DD");
    setStartDate(formattedStartDate);

    const endDate: any = columnFilters?.filter((t) => t.id === "endDate")[0]
      ?.value;
    const formattedEndDate = endDate && dayjs(endDate)?.format("YYYY-MM-DD");
    setEndDate(formattedEndDate);
  };

  const { data, isLoading }: any = useUtilityBills({
    isEdited,
    isMyTask,
    periodMonth: monthValue,
    periodYear: yearValue,
    siteIds,
    statuses: utilityBillStatusesValues,
    utilityTypeIds,
    sortColumn: sortBy,
    direction: sortDirection,
    consumptionStartDate: startDate,
    consumptionEndDate: endDate,
    customerSiteId,
    businessUnit,
    siteName,
    provider,
    accountNumber,
    measuredValue,
    meterNumber,
    scope,
    addedBy,
    type,
    co2Emission,
    amount,
    ...(!isMyTask && !isEdited ? { size: 1000 } : ""),
  });

  const handleDuplicatedData = async () => {
    setLoadingDuplicate(true);
    if (showDuplicate) {
      const duplicateBillData = await getDuplicateBillsData();
      setDuplicateData(duplicateBillData);
    }
    setLoadingDuplicate(false);
  };
  const handleCsvDownload = () => {
    setCsvError(null);

    if (!searchAllClicked && applyFilterClicked) {
      notify.error(
        `For the filter to be applied, please click 'Search All' before exporting`,
      );
      return;
    }

    setCsvLoading(true);

    const query: any = {
      isEdited,
      isMyTask,
      periodMonth: monthValue,
      periodYear: yearValue,
      siteIds,
      statuses: utilityBillStatusesValues,
      utilityTypeIds,
      customerSiteId,
      businessUnit,
      siteName,
      provider,
      accountNumber,
      measuredValue,
      meterNumber,
      scope,
      addedBy,
      type,
      co2Emission,
      amount,
      consumptionStartDate: startDate,
      consumptionEndDate: endDate,
    };

    httpClient
      .get(
        query && `${UTILITY_BILLS_API_PATHS.UTILITY_BILLSCSV}`,
        { responseType: "arraybuffer" }
      )
      .then(async (response) => {
        setCsvLoading(false);
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(response.data);
        let csvFileFound = false;

        if (
          isEdited === false &&
          isMyTask === false &&
          siteIds?.length === 0 &&
          utilityBillStatusesValues?.length === 0 &&
          utilityTypeIds.length === 0 &&
          customerSiteId === "" &&
          businessUnit === "" &&
          siteName === "" &&
          provider === "" &&
          accountNumber === "" &&
          measuredValue === "" &&
          meterNumber === "" &&
          scope === "" &&
          addedBy === "" &&
          type === "" &&
          co2Emission === "" &&
          amount === "" &&
          startDate === "" &&
          endDate === ""
        ) {
          if (jsonRes.length > 0) {
            notify.error(
              `For the filter to be applied, please click 'Search All' before exporting`,
            );
            return;
          }
          zipContent.forEach(async (relativePath, file) => {
            if (!file.dir && relativePath.endsWith(".csv")) {
              csvFileFound = true;

              const csvContent = await file.async("string");

              const blob = new Blob([csvContent], { type: "text/csv" });
              const url = URL.createObjectURL(blob);

              const link = document.createElement("a");
              link.href = url;
              link.download = relativePath;
              link.click();
              URL.revokeObjectURL(url);
            }
          });
          setCsvLoading(false);
        } else {
          setCsvData(showDuplicate || showAnomaly ? DuplicateData : data.bills);
          setCsvLoading(false);
        }

        // Trigger CSV download after the data is fetched
      })
      .catch((error) => {
        setCsvLoading(false);
        setCsvError("Error fetching data. Please try again.");
      });

  };
  const triggerCsvDownload = () => {
    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };
  const handleRowSelection = () => {
    setRowSelection({});
  };
  const onResetAll = () => {
    window.sessionStorage.setItem("columnFilters", JSON.stringify([]));
    setColumnFilters([]);
    setEditFilterIndex("");
    setApplyFilterIndex("");
    setCustomerSiteId("");
    setBusinessUnit("");
    setSiteName("");
    setProvider("");
    setAccountNumber("");
    setMeterNumber("");
    setScope("");
    setAddedBy("");
    setType("");
    setMeasuredValue("");
    setCo2Emission("");
    setAmount("");
    setStartDate("");
    setEndDate("");
  };
  const handleSaveButton = () => {
    const columnData = {
      key: filterName,
      value: jsonRes,
    };

    if (editFilterIndex !== "") {
      const filterValues = localData !== undefined ? localData : [];

      const index = editFilterIndex;
      if (index > -1) {
        // only splice array when item is found
        filterValues.splice(index, 1); // 2nd parameter means remove one item only
      }

      const finalValue = [...filterValues, columnData];
      if (finalValue) {
        getSavedFilterValue({
          queryName,
          searchFilters: finalValue,
        });
      }
    } else {
      const filterValues = localData !== undefined ? localData : [];
      const finalValue = [...filterValues, columnData];

      if (finalValue) {
        getSavedFilterValue({
          queryName,
          searchFilters: finalValue,
        });
      }
    }
    setOpenDialog(false);
    setEditFilterIndex("");
    setFilterName("");
    setApplyFilterIndex("");
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleApplyFilter = (index: any) => {
    setOpen(false);
    setFilterName("");
    setEditFilterIndex("");
    setApplyFilterIndex(index);
    setApplyFilterClicked(true);
    const filterValues = localData !== null ? localData : [];
    const objectAtIndex = filterValues.length > 0 ? filterValues[index] : null;
    if (objectAtIndex) {
      const { value } = objectAtIndex;
      setColumnFilters(value[0]);
    }
  };
  const handleEditFilter = (index: any) => {
    setApplyFilterIndex("");
    setEditFilterIndex(index);
    const filterValues = localData !== null ? localData : [];
    const objectAtIndex = filterValues.length > 0 ? filterValues[index] : null;
    if (objectAtIndex) {
      const { value } = objectAtIndex;
      setColumnFilters(value[0]);
    }
    setFilterName(filterValues[index]?.key);
  };
  const handleDeleteFilter = (index: any) => {
    setApplyFilterIndex("");
    const filterValues = localData !== undefined ? localData : [];

    const indexOfDelete = index;
    if (indexOfDelete > -1) {
      filterValues.splice(indexOfDelete, 1);
    }

    const finalValue = [filterValues];
    if (finalValue) {
      getSavedFilterValue({
        queryName,
        searchFilters: filterValues,
      });
    }
  };

  const localData = savedFilterData && savedFilterData[0]?.searchFilters;
  const rows: any = (localData && localData!.map((t: any) => t.key)) || [];
  const selected: any[] = [];
  selected.push(...Object.keys(rowSelection));

  const onReviewClick = (row: any) => {
    saveCurrentUrl();
    setToLocalStorage("dataEntries", JSON.stringify(row));
    setToLocalStorage("Summary_URL", currentUrl.pathname + currentUrl.search);
    setToLocalStorage("type", "Summary");
    navigate(`${UTILITY_BILL_PAGE.ABSOLUTE_PATH(selected[0])}`);
  };

  const onDeleteClick = () => {
    Promise.allSettled(
      selected.map((id) =>
        deleteUB(id, {
          onError: () =>
            notify.error(
              `Some error has happen while deleting utility bill: ${id}!`,
            ),
        }),
      ),
    ).finally(() => {
      deleteDialogControls.close();
      notify.success("Selected utility bills were deleted!"); // [AW] if error success notification will be fired
      setDefaultSelection();
      handleRowSelection();
      queryClient.invalidateQueries([QUERY_KEYS.UTILITY_BILLS]);
    });
  };

  const onDownloadClick = () =>
    downloadFile(() => downloadUtilityBills(selected), "utility-bills.zip")
      .then(() =>
        notify.success("Selected utility bills were successfully downloaded!"),
      )
      .catch(() => notify.error("Failed to download selected utility bills!"));

  // ---- Data for CSV
  const CsvMatchedSummary = csvData.map((item: any) => ({
    "Start date": dayjs(item.startDate).utc().format("YYYY-MM-DD"),
    "End date": dayjs(item.endDate).utc().format("YYYY-MM-DD"),
    "Number of Days": item.daysCovered,
    Uploader: item.addedBy,
    "Customer Site Id": item?.customerSiteId,
    "Business Unit": item?.businessUnit,
    Site: item.siteName,
    Type: item.type,
    "Sub Type": item.subtype,
    Source: item.provider,
    "Account #": item.accountNumber,
    "Meter #": item.meterNumber,
    "GHG Emission": item.scope,
    Usage: item.measuredValue,
    Unit: item.measuredUnit,
    "Renewable Energy Usage": item?.recsUsage,
    "Non Renewable Energy Usage": item?.nonRecsUsage,
    "Standardized Usage Value": item?.standardizedUsageValue + item?.standardizedUsageUnit,
    "Location-based Emissions": item?.co2Emission,
    "Location Renewable Usage": item?.locationRenewableUsage + item?.standardizedUsageUnit,
    "Location Non Renewable Usage": item?.locationNonRenewableUsage + item?.standardizedUsageUnit,
    "Market Based Emissions": item?.marketBasedEmission,
    "Market Renewable Usage": item?.marketRenewableUsage + item?.standardizedUsageUnit,
    "Market Non Renewable Usage": item?.marketNonRenewableUsage + item?.standardizedUsageUnit,
    Cost: item.amount,
    Currency: item.currency,
    "Created Date": moment(item.createDate).utc().format("YYYY-MM-DD"),
    "Last Modified Date": item.updateDate === null ? "-" : moment(item.updateDate).utc().format("YYYY-MM-DD"),
    "Calculation Method": item.calculationMethod,
    "Bill Superseded Details": item.billSuperceededDetails === null ? "-" : `${String(item.billSuperceededDetails).replace(",", ";")}`,
  }));

  // ---- Table Column
  const columns = [
    {
      id: 'items',
      header: '',
      Header: <Box sx={{ height: "24px" }} />,
      muiTableHeadCellProps: {
        sx: {
          fontSize: {
            xs: '16px',
            sm: '16px',
            md: '16px',
            lg: '16px',
            xl: '16px',

          },
        },
      },
      columns: [
        {
          id: "comments",
          header: "",
          size: 80,
          accessorKey: "numberOfComments",
          enableColumnFilter: false,
          Cell: ({ cell, row }: any) => {
            const comments = cell.getValue() as number;
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const handleClick = (e: any) => {
              e.stopPropagation();
              setToLocalStorage("dataEntries", JSON.stringify(row));
              setToLocalStorage("type", "Summary");
              window.sessionStorage.setItem("summaryDocumentID", row.original.id);
              getDocumentBills(row.original.documentGuid)
                .then((bills) => {
                  setToLocalStorage("documentbills", JSON.stringify(bills));
                })
                .catch((error) => {
                  console.error("Error fetching document bills:", error);
                });
              setToLocalStorage(
                "Summary_URL",
                currentUrl.pathname + currentUrl.search,
              );

              setToLocalStorage("documentIdForBills", row.original.id);
            };
            return !row.original.isEstimate ? (
              <Link to={UTILITY_BILL_COMMENTS_VIEW.ABSOLUTE_PATH(row.original.id)}>
                <Button
                  sx={{
                    minWidth: "24px",
                    height: "24px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    paddingRight: "4px",
                  }}
                  onClick={handleClick}
                >
                  {/* <Badge
                    sx={{ ".MuiBadge-badge": { top: "2px" } }}
                    color="error"
                    variant="dot"
                  > */}
                  <CommentTextIcon />
                  {/* </Badge> */}
                </Button>
              </Link>
            ) : (
              EMPTY_CELL_VALUE
            );
          },
        },
        {
          header: "Customer Site ID",
          accessorKey: "customerSiteId",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return <Box>{EMPTY_CELL_VALUE}</Box>;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {value}
              </Box>
            );
          },
        },
        {
          Header: <Box sx={{ paddingLeft: "2px" }}>Business Unit</Box>,
          accessorKey: "businessUnit",
          header: "Business Unit",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;

            if (!value) {
              return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
            }
            return (
              <Tooltip title={value}>
                <Box>{value}</Box>
              </Tooltip>
            );
          },
        },
        {
          header: "Site",
          Header: <Box> Site</Box>,
          accessorKey: "siteName",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;

            return (
              <Tooltip title={value}>
                <Box
                  sx={{
                    alignItems: "center",
                    Width: "25px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {value}
                </Box>
              </Tooltip>
            );
          },
          size: 180,
        },
        {
          header: "Type",
          Header: <Box sx={{ paddingLeft: "2px" }}>Type</Box>,
          accessorKey: "type",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as UtilityBillTypeKey;

            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }

            return (
              <Tooltip title={value}>
                <Box
                  sx={{
                    alignItems: "center",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {value}
                </Box>
              </Tooltip>
            );
          },
          size: 150,
        },
        {
          header: "subType",
          Header: <Box>Sub-Type</Box>,
          accessorKey: "subtype",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as any;

            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }

            return <UtilityBillType type={value} />;
          },
          size: 130,
        },
        {
          header: "Provider",
          Header: <Box>Source</Box>,
          accessorKey: "provider",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;

            if (!value) {
              return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
            }
            return (
              <Tooltip title={value}>
                <Box
                  sx={{
                    alignItems: "center",
                    Width: "25px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {value}
                </Box>
              </Tooltip>
            );
          },
          enableSorting: false,
          size: 125,
        },
        {
          header: TABLE_HEADERS.accountNumber,
          Header: <Box>{TABLE_HEADERS.accountNumber}</Box>,
          accessorKey: "accountNumber",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;

            if (!value) {
              return <Box sx={{ paddingLeft: "8px" }}>{EMPTY_CELL_VALUE}</Box>;
            }

            return (
              <Tooltip title={value}>
                <Box
                  sx={{
                    alignItems: "center",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {value}
                </Box>
              </Tooltip>
            );
          },
          size: 122,
        },
        {
          header: TABLE_HEADERS.meterNumber,
          Header: <Box>{TABLE_HEADERS.meterNumber}</Box>,
          accessorKey: "meterNumber",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;

            if (!value) {
              return <Box sx={{ paddingLeft: "8px" }}>{EMPTY_CELL_VALUE}</Box>;
            }

            return (
              <Tooltip title={value}>
                <Box
                  sx={{
                    alignItems: "center",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {value}
                </Box>
              </Tooltip>
            );
          },
          size: 122,
        },
        {
          header: "GHG Emission",
          Header: <div>GHG Emission</div>,
          accessorKey: "scope",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;

            if (!value) {
              return <Box sx={{ paddingLeft: "18px" }}>{EMPTY_CELL_VALUE}</Box>;
            }

            return (
              <Tooltip title={value}>
                <Box
                  sx={{
                    alignItems: "center",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    paddingLeft: "2px",
                  }}
                >
                  {value}
                </Box>
              </Tooltip>
            );
          },
          size: 160,
        },
        {
          accessorKey: "addedBy",
          Header: <Box>Added By</Box>,
          header: TABLE_HEADERS.uploader,
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as UtilityBillDTO["addedBy"];

            if (!value) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Tooltip title={value}>
                <Box>
                  <Avatar
                    sx={{
                      width: "32px",
                      height: "32px",
                      paddingLeft: "5px",
                    }}
                  >
                    {getInitials(value)}
                  </Avatar>
                </Box>
              </Tooltip>
            );
          },
          size: 120,
        },
        {
          id: "startDate",
          header: "Start date",
          Header: <Box>Start date</Box>,
          accessorKey: "startDate",
          filterFn: (row: any, id: any, filterValue: any) => {
            const date1 = new Date(filterValue);
            const date2 = new Date(row.getValue(id));
            const date1WithoutTime = new Date(date1.getTime());
            const date2WithoutTime = new Date(date2.getTime());

            date1WithoutTime.setUTCHours(0, 0, 0, 0);
            date2WithoutTime.setUTCHours(0, 0, 0, 0);

            const compareData =
              date1WithoutTime.getTime() <= date2WithoutTime.getTime();
            if (compareData) {
              return true;
            }
            return false;
          },
          Filter: ({ column }: any) => {
            const dateValue =
              column.getFilterValue() === undefined
                ? null
                : dayjs(Date.parse(column.getFilterValue()));

            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dateValue}
                  onChange={(newValue: any) => column.setFilterValue(newValue)}
                  format={getUTCDateFormat()}
                  slotProps={{
                    textField: {
                      sx: { minWidth: "120px" },
                      variant: "standard",
                    },
                  }}
                />
              </LocalizationProvider>
            );
          },
          Cell: ({ cell }: any) => {
            const value = cell.getValue();

            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }

            return dayjs(value as string)
              .utc()
              .format(getUTCDateFormat());
          },
          size: 140,
        },
        {
          header: "End date",
          Header: <Box>End date</Box>,
          accessorKey: "endDate",
          Filter: ({ column }: any) => {
            const dateValue =
              column.getFilterValue() === undefined
                ? null
                : dayjs(Date.parse(column.getFilterValue()));

            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dateValue}
                  onChange={(newValue: any) => column.setFilterValue(newValue)}
                  format={getUTCDateFormat()}
                  slotProps={{
                    textField: {
                      sx: { minWidth: "120px" },
                      variant: "standard",
                    },
                  }}
                />
              </LocalizationProvider>
            );
          },
          filterFn: (row: any, id: any, filterValue: any) => {
            const date1 = new Date(filterValue);
            const date2 = new Date(row.getValue(id));
            const date1WithoutTime = new Date(date1.getTime());
            const date2WithoutTime = new Date(date2.getTime());

            date1WithoutTime.setUTCHours(0, 0, 0, 0);
            date2WithoutTime.setUTCHours(0, 0, 0, 0);

            const compareData = date2WithoutTime <= date1WithoutTime;
            if (compareData) {
              return true;
            }
            return false;
          },
          Cell: ({ cell }: any) => {
            const value = cell.getValue();

            if (isNullOrUndefined(value) || value === 0) {
              return EMPTY_CELL_VALUE;
            }

            return dayjs(value as string)
              .utc()
              .format(getUTCDateFormat());
          },
          size: 140,
        },
        {
          header: "Number of Days",
          Header: <Box>Number of Days</Box>,
          accessorKey: "daysCovered",
          size: 155,
          Cell: ({ cell }: any) => {
            const value = cell.getValue();

            if (isNullOrUndefined(value) || value === 0) {
              return EMPTY_CELL_VALUE;
            }

            return value;
          },
        },
        {
          header: "Usage",
          Header: <Box>Usage</Box>,
          accessorKey: "measuredValue",
          size: 155,

          Cell: ({ cell, row }: any) => {
            const value = cell.getValue() as string;

            return (
              <EmissionCell
                value={value}
                explanation={row.original.unitCalculationFormula}
                unit={row.original.measuredUnit}
                unitvalueshow={true}
              />
            );
          },
        },
        {
          header: "Standardized Usage Value",
          Header: <Box sx={{ paddingLeft: "2px" }}>Standardized Usage Value</Box>,
          accessorKey: "standardizedUsageValue",
          size: 250,
          Cell: ({ cell, row }: any) => {
            const value = cell.getValue();
            if (isNullOrUndefined(value)) {
              return <Box>{EMPTY_CELL_VALUE}</Box>;
            }
            return (
              <Tooltip title={value as string}>
                <Box
                  sx={{
                    alignItems: "center",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    paddingLeft: "4px",
                  }}
                >
                  <span>
                    {toUSNumber(value as string)}{" "}
                    {row.original.standardizedUsageUnit}
                  </span>
                </Box>
              </Tooltip>
            );
          },
        },
        {
          header: "Location-based emissions",
          Header: <Box sx={{ paddingLeft: "2px" }}>Location-based Emissions</Box>,
          accessorKey: "co2Emission",
          size: 250,
          Cell: ({ cell, row }: any) => {
            const value = cell.getValue() as string;
            let content;
            if (row.original.type === "Refrigerants" && parseFloat(value) === 0) {
              content = content = (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>-t {MEASURE_UNIT_DISPLAY_MAP.co2}</div>
                  <Box sx={{ mt: -1 }}>
                    <EmissionCellTooltip explanation={row.original.explanation} />
                  </Box>
                </Box>
              );
            } else {
              content = (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "4px",
                  }}
                >
                  <div>
                    {value} t {MEASURE_UNIT_DISPLAY_MAP.co2}
                  </div>
                  <Box sx={{ mt: -1 }}>
                    <EmissionCellTooltip explanation={row.original.explanation} />
                  </Box>
                </Box>
              );
            }

            return content;
          },
        },
      ]
    },
    {
      id: 'Location',
      header: 'Location',
      muiTableHeadCellProps: {
        sx: {
          fontSize: {
            xs: '16px',
            sm: '16px',
            md: '16px',
            lg: '16px',
            xl: '16px',

          },
        },
      },
      columns: [
        {
          Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Renewable Usage</Box>,
          header: "Renewable Usage",
          accessorKey: "locationRenewableUsage",
          enableGrouping: false,
          Cell: ({ cell, row }: any) => {
            const value = cell.getValue();
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }

            return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
          },
        },
        {
          Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Non-Renewable Usage</Box>,
          header: "Non-Renewable Usage",
          accessorKey: "locationNonRenewableUsage",
          enableGrouping: false,
          Cell: ({ cell, row }: any) => {
            const value = cell.getValue();
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }

            return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
          },
        },
      ]
    },
    {
      id: 'market-based',
      header: '',
      Header: <Box sx={{ height: "24px" }} />,
      muiTableHeadCellProps: {
        sx: {
          fontSize: {
            xs: '16px',
            sm: '16px',
            md: '16px',
            lg: '16px',
            xl: '16px',

          },
        },
      },
      columns: [
        {
          header: "Market Based Emissions",
          Header: <Box sx={{ paddingLeft: "2px" }}>Market Based Emissions</Box>,
          accessorKey: "marketBasedEmission",
          size: 240,
          Cell: ({ cell, row }: any) => {
            const value = cell.getValue();
            if (isNullOrUndefined(value)) {
              return <Box>{EMPTY_CELL_VALUE}</Box>;
            }
            return (
              <Tooltip title={value as string}>
                <Box
                  sx={{
                    alignItems: "center",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    paddingLeft: "4px",
                  }}
                >
                  <EmissionCell
                    value={value}
                    explanation={row.original.marketExplanation}
                  />
                </Box>
              </Tooltip>
            );
          },
        },
      ]
    },
    {
      id: 'Market',
      header: 'Market',
      muiTableHeadCellProps: {
        sx: {
          fontSize: {
            xs: '16px',
            sm: '16px',
            md: '16px',
            lg: '16px',
            xl: '16px',

          },
        },
      },
      columns: [
        {
          Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Renewable Usage</Box>,
          header: "Renewable Usage",
          accessorKey: "marketRenewableUsage",
          enableGrouping: false,
          Cell: ({ cell, row }: any) => {
            const value = cell.getValue();
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }

            return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
          },
        },
        {
          Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Non-Renewable Usage</Box>,
          header: "Non-Renewable Usage",
          accessorKey: "marketNonRenewableUsage",
          enableGrouping: false,
          Cell: ({ cell, row }: any) => {
            const value = cell.getValue();
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }

            return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
          },
        },
      ]
    },
    {
      id: 'item-2',
      header: '',
      Header: <Box sx={{ height: "24px" }} />,
      muiTableHeadCellProps: {
        sx: {
          fontSize: {
            xs: '16px',
            sm: '16px',
            md: '16px',
            lg: '16px',
            xl: '16px',

          },
        },
      },
      columns: [
        {
          header: "Cost",
          Header: <Box>Cost</Box>,
          accessorKey: "amount",
          Cell: ({ cell, row }: any) => {
            const value = cell.getValue();
            return (
              <>
                {!row.original.isEstimate ? (
                  <>
                    {row.original.currency}{" "}
                    {!isNullOrUndefined(value)
                      ? toUSNumber(value as string, true)
                      : EMPTY_CELL_VALUE}
                  </>
                ) : (
                  EMPTY_CELL_VALUE
                )}
              </>
            );
          },
          size: 120,
        },
        {
          header: "Date Created",
          Header: <Box>Date Created</Box>,
          accessorKey: "createDate",
          Cell: ({ cell }: any) => {
            const value = cell.getValue();

            if (isNullOrUndefined(value) || value === 0) {
              return EMPTY_CELL_VALUE;
            }

            return dayjs(value as string)
              .utc()
              .format(getDateFormat());
          },
          size: 160,
        },
        {
          header: "Last Modified Date",
          Header: <Box>Last Modified Date</Box>,
          accessorKey: "updateDate",
          Cell: ({ cell }: any) => {
            const value = cell.getValue();

            if (isNullOrUndefined(value) || value === 0) {
              return EMPTY_CELL_VALUE;
            }

            return dayjs(value as string)
              .utc()
              .format(getDateFormat());
          },
          size: 160,
        },
        {
          header: "Calculation Method",
          Header: <div>Calculation Method</div>,
          accessorKey: "CalculationMethod",
          Cell: ({ row }: any) => {
            const value1 =
              row.original.billType + " - " + row.original.calculationMethod;
            if (!row.original.interExtrapolate) {
              return <Box sx={{ paddingLeft: "18px" }}>{EMPTY_CELL_VALUE}</Box>;
            }

            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  // overflow: "hidden",
                  color: "#44853C",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {value1}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "Edited",
          Header: <div>Edited</div>,
          accessorKey: "edited",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {String(value)}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "Manually Approved",
          Header: <div>Manually Approved</div>,
          accessorKey: "manuallyApproved",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {String(value)}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "Duplicate Warning",
          Header: <div>Duplicate Warning</div>,
          accessorKey: "duplicateWarning",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {String(value)}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "Sequential Threshold Consumption Last Period",
          Header: <div>Sequential Threshold Consumption Last Period</div>,
          accessorKey: "thresholdExceededConsumptionLastPeriod",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {String(value)}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "Sequential Threshold Cost Last Period",
          Header: <div>Sequential Threshold Cost Last Period</div>,
          accessorKey: "thresholdExceededCostLastPeriod",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {String(value)}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "Sequential Threshold Last Year",
          Header: <div>Sequential Threshold Last Year</div>,
          accessorKey: "thresholdExceededLastYear",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {String(value)}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "Unit Cost",
          Header: <div>Unit Cost</div>,
          accessorKey: "unitCost",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {String(value)}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "Bill Superseded",
          Header: <div>Bill Superseded</div>,
          accessorKey: "billSuperceeded",
          Cell: ({ cell }: any) => {
            const value = cell.getValue() as string;
            if (isNullOrUndefined(value)) {
              return EMPTY_CELL_VALUE;
            }
            return (
              <Box
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Manrope",
                }}
              >
                {String(value)}
              </Box>
            );
          },
          size: 180,
        },
        {
          header: "",
          Header: <Box sx={{ paddingLeft: "3px" }}>Anomaly log</Box>,
          accessorKey: "anomalies",
          Cell: ({ cell, row }: any) => {
            const anomalies = cell.getValue() as Anomaly[];
            const { manuallyApproved, updateDate } = row.original;

            return (
              <AnomalyLogCell
                anomalies={anomalies}
                updateDate={updateDate}
                isManuallyApproved={manuallyApproved}
              />
            );
          },
          enableSorting: false,
          size: 125,
        },
      ]
    },
  ];

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleSearchMoreBtn = () => {
    setColumnFilter();
    setSearchAllClicked(true);
  };

  return (
    <>
      <MarginWrapper>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1.5 }}>
          {/* Switches */}
          <Box sx={FLEX_ALIGN_CENTER_GAP_2_SWITCH}>
            {/* Switches */}
            <Switch
              isChecked={isEdited}
              label="Show only edited"
              onChange={onIsEditedChange}
            />
            <Switch
              isChecked={isMyTask}
              label="Show my tasks"
              onChange={onIsMyTaskChange}
            />
            <Switch
              isChecked={showAnomaly}
              label="Show anomaly column"
              onChange={() => setShowAnomaly((prevState) => !prevState)}
            />
            <Tooltip title="This toggle will show all cases where the account #, meter #, start date, end date, usage, and cost are the same">
              <div>
                <Switch
                  isChecked={showDuplicate}
                  label="Show duplicate entries"
                  onChange={() => setShowDuplicate((prevState) => !prevState)}
                />
              </div>
            </Tooltip>
            {jsonRes?.length !== 0 && <Button
              variant="outlined"
              sx={{ borderRadius: "8px", width: '140px' }}
              onClick={onResetAll}
            >
              Clear Filters
            </Button>}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            mb: 2,
            pr: 1.5,
          }}
        >
          <Stack direction="row" spacing={2}>
            {selected?.length !== 0 && <>
              <Button
                startIcon={<EsgDownloadIcon />}
                variant="contained"
                sx={{ borderRadius: "8px", width: '140px' }}
                disabled={!selected?.length}
                onClick={onDownloadClick}
              >
                Download
              </Button>
              <Button
                startIcon={<EsgDeleteIcon color="white" />}
                variant="contained"
                sx={{ borderRadius: "8px", width: '140px' }}
                disabled={!selected?.length || userRole.isAuditor}
                onClick={deleteDialogControls.open}
                color="error"
              >
                Delete
              </Button>
            </>}
            {jsonRes?.length !== 0 && <Button
              disabled={jsonRes?.length === 0}
              onClick={handleSearchMoreBtn}
              variant="outlined"
              sx={{ borderRadius: "8px", width: '140px' }}
            >
              Search All
            </Button>}
          </Stack>

          <Stack direction="row" spacing={2}>
            <CSVLink
              ref={csvLinkRef}
              style={{ textDecoration: "none" }}
              data={CsvMatchedSummary}
              filename="Document_Summary.csv"
            />
            <Button
              variant="contained"
              startIcon={<EsgExportIcon />}
              sx={{ borderRadius: "8px", width: '200px' }}
              onClick={() => handleCsvDownload()}
              disabled={csvLoading}
            >
              {csvLoading ? "Loading csv..." : "Export CSV"}
            </Button>
            {csvError && (
              <p style={{ color: COLORS.Fuzzy_Brown }}>{csvError}</p>
            )}
            <DeleteDialog
              isOpen={deleteDialogControls.isOpen}
              onCancel={deleteDialogControls.close}
              onDeleteClick={onDeleteClick}
              selectedCount={selected?.length}
              target="utility bill"
              title="Delete utility bills?"
            />
          </Stack>
        </Box>
        {isLoading || loadingDuplicate ? (
          <Loading />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {columnFilters?.length !== 0 &&
                columnFilters?.length !== 0 &&
                (siteName === "" ||
                  provider === "" ||
                  accountNumber === "" ||
                  startDate === "" ||
                  endDate === "" ||
                  measuredValue === "" ||
                  meterNumber === "" ||
                  scope === "" ||
                  addedBy === "" ||
                  type === "" ||
                  co2Emission === "" ||
                  amount === "") && (
                  <AppBar
                    position="static"
                    sx={{
                      backgroundColor: COLORS.Romance,
                      height: "23px",
                      top: "auto",
                      bottom: 0,
                      textAlign: "center",
                      my: 2,
                      width: "100%",
                    }}
                  >
                    <Toolbar
                      sx={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ mb: 5 }} align="center">
                        Your filter is applied on the first 1,000
                        records. To extend the filter to the entire
                        dataset, please select <b>Search All</b>
                      </Typography>
                    </Toolbar>
                  </AppBar>
                )}
            </Box>
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
              data={showDuplicate || showAnomaly ? DuplicateData : data?.bills}
              enablePagination={true}
              onPaginationChange={setPagination}
              muiTablePaginationProps={{
                rowsPerPageOptions: [100, 200, 500, 1000],
              }}
              columns={
                showAnomaly
                  ? columns
                  : columns.filter((item) => item.id !== "anomalies")
              }
              enableRowVirtualization
              enableStickyHeader
              enableColumnFilters
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              onGlobalFilterChange={setGlobalFilter}
              rowCount={
                columnFilters?.length !== 0 &&
                  columnFilters?.length !== 0 &&
                  (siteName === "" ||
                    provider === "" ||
                    accountNumber === "" ||
                    startDate === "" ||
                    endDate === "" ||
                    measuredValue === "" ||
                    meterNumber === "" ||
                    scope === "" ||
                    addedBy === "" ||
                    type === "" ||
                    co2Emission === "" ||
                    amount === "")
                  ? tableInstanceRef?.current?.getFilteredRowModel()?.rows
                    ?.length
                  : data?.count
              }
              muiTableContainerProps={{
                sx: {
                  "& thead tr:first-of-type th:first-of-type": {
                    height: "40px",
                    paddingLeft: "20px",
                  },
                  "& tbody tr td:first-of-type": {
                    paddingLeft: "20px",
                  },
                  "& tbody tr:first-of-type td > p": {
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  },
                  height: "calc(100vh - 320px)",
                  border: "1px solid #D9D9D9",
                  borderRadius: "10px",
                  minWidth: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  "@media (min-width: 600px)": {
                    width: "100%",
                  },
                  "@media (min-width: 960px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1280px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1440px)": {
                    width: "1100px",
                  },
                  "@media (min-width: 1680px)": {
                    width: "1300px",
                  },
                  "@media (min-width: 2400px)": {
                    width: "100%",
                  },
                },
              }}
              muiTablePaperProps={{
                sx: {
                  borderRadius: "10px",
                  boxShadow: "none",
                },
              }}
              muiTopToolbarProps={{
                sx: {
                  display: 'none',
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  display: "none",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  lineHeight: "30px",
                  "& .MuiBadge-root": {
                    display: "none",
                  },
                },
              }}
              muiTableHeadRowProps={{
                sx: {
                  "& > th": {
                    paddingTop: 1,
                    paddingBottom: 1,
                    "& > div > div > div": {
                      minWidth: "max-content",
                    }
                  },
                  height: "auto",
                  alignItems: "center",
                  backgroundColor: "#F8FAFC",
                },
              }}
              state={{ rowSelection, columnFilters, pagination, globalFilter }}
              getRowId={(row: any) => row.id}
              muiTableBodyRowProps={(row: any) => {
                return {
                  onClick: () => {
                    saveCurrentUrl();
                    setToLocalStorage("dataEntries", JSON.stringify(row?.row));
                    window.sessionStorage.setItem(
                      "summaryDocumentID",
                      row?.row?.original?.id as any,
                    );
                    setToLocalStorage(
                      "Summary_URL",
                      currentUrl.pathname + currentUrl.search,
                    );
                    setToLocalStorage("type", "Summary");
                    navigate(
                      `${UTILITY_BILL_PAGE.ABSOLUTE_PATH(
                        row?.row?.original?.id,
                      )}`,
                    );
                  },
                  sx: {
                    cursor: "pointer",
                    backgroundColor:
                      row.row.original.id === summaryDocumentID
                        ? "#DCDCDC"
                        : "",
                  },
                };
              }}
              onColumnFiltersChange={setColumnFilters}
              initialState={{
                density: "compact",
              }}
              renderTopToolbarCustomActions={({ table }) => {
                setSelectedRow(table?.getSelectedRowModel());
                setTableRowCount(
                  tableInstanceRef.current.getFilteredRowModel().rows,
                );

                return (
                  <>

                  </>
                );
              }}
            />
            {columnFilters?.length !== 0 &&
              (siteName !== "" ||
                provider !== "" ||
                startDate !== "" ||
                endDate !== "" ||
                accountNumber !== "" ||
                measuredValue !== "" ||
                meterNumber !== "" ||
                scope !== "" ||
                addedBy !== "" ||
                type !== "" ||
                co2Emission !== "" ||
                amount !== "") &&
              data?.count > 1000 && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  Currently displaying only 1000 records with your filter.
                  &nbsp; <b>Export CSV</b>&nbsp; to view all records in the data
                  set.{" "}
                </Box>
              )}

            {columnFilters?.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                Currently displaying first 1000 records only.&nbsp;{" "}
                <b>Export CSV</b>&nbsp; to view all records in the data set.{" "}
              </Box>
            )}
          </>
        )}
      </MarginWrapper>
      {/* Save Filter Dialog */}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Save Filter</DialogTitle>
        <DialogContent>
          <DialogContentText>Provide Filter Name!</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Filter Name"
            type="email"
            fullWidth
            variant="standard"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveButton}>Save Filter</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
