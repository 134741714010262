import { Box } from "@mui/material";
import React, { FunctionComponent } from "react";

import { ReactChildren } from "types";

import { CELL_COLORS, COMMON_STYLES, CellColor } from "../styles/cellStyles";

export const DateWrapper: FunctionComponent<
  { color?: CellColor; onClick?: () => void } & ReactChildren
> = ({ color = "none", children, onClick }) => (
  <Box sx={{ ...COMMON_STYLES, ...CELL_COLORS[color] }} onClick={onClick}>
    {children}
  </Box>
);
