/* eslint-disable */
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  editEmissionFactorByCollector,
  getEmissionFactorsByCollector,
} from "api/rec";

import {
  Body1SemiBold,
  CloseButton,
  COLORS,
  Esg12LabelRegular,
  InputFieldWrapper,
} from "components";
import { RecDatePickerFormField } from "components/inputs/rec-date-picker-form-field";

import { useNotify } from "hooks";

import { checkNullValue, definedValueOrDefault } from "utils";

import { getTextFieldColorStateProps } from "../documents";
import { APPLICATION_JSON, FORM_DATA } from "consts";
import { EmissionFactorPdfPreview } from "components/emissionFactor-pdf-preview";
import { EmissionFactorPdfFooter } from "components/emissionFactor-pdf-preview/components/pdf-footer/emissionFactor-pdf-footer";

export const EmissionFactorDetailPage: React.FunctionComponent = () => {

  // ---- Hooks & Const ----
  const navigate = useNavigate();
  const notify = useNotify();
  const location = useLocation();
  const receivedData = location.state;
  const { collectorId } = useParams();
  dayjs.extend(timezone);

  // ---- API's ----
  const { data: emissionFactorsData, mutateAsync: getEmissionFactorsData } = useMutation(getEmissionFactorsByCollector);
  const { mutateAsync: editEmissionFactorDocument } = useMutation(editEmissionFactorByCollector);

  // ---- States ----
  const [emissionFactorsDetailData, setEmissionFactorsDetailData] = useState<any>([]);
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const [scaleChange, setScaleChange] = useState(false);
  const [scale, setScale] = useState(0.75);

  useEffect(() => {
    const data = (emissionFactorsData?.data as any)?.find((t: any) => Number(t.collectorId) === Number(collectorId))
    setEmissionFactorsDetailData(data)
  },[emissionFactorsData, collectorId])

  const defaultValues = useMemo(() => {
    return {
      provider: (emissionFactorsDetailData as any)?.provider,
      utilityType: (emissionFactorsDetailData as any)?.utilityType,
      renewableEnergy:
          emissionFactorsDetailData !== undefined &&
        definedValueOrDefault(emissionFactorsDetailData?.renewableEnergy, ""),
      startDate: moment(emissionFactorsDetailData?.startDate, "YYYY-MM-DD"),
      endDate: moment(emissionFactorsDetailData?.endDate, "YYYY-MM-DD"),
      efUnit: emissionFactorsDetailData?.efUnit,
      efValue: emissionFactorsDetailData?.efValue
    };
  }, [emissionFactorsDetailData]);

  const { control, formState, reset, getValues } = useForm<any>({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    if (getValues("startDate") === null) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [getValues("startDate")]);

  useEffect(() => {
      getEmissionFactorsData(collectorId)
    }, [collectorId])

    
    
  const handleSaveBtnClick = async () => {

    const createRecRequestData: any = {
      collectorId,
      utilityType: getValues()?.utilityType,
      utilityProvider: getValues()?.provider,
      efValue: getValues()?.efValue,
      efUnit: getValues()?.efUnit,
      renewableEnergy: getValues()?.renewableEnergy,
      startDate: checkNullValue(dayjs(getValues()?.startDate).format("YYYY-MM-DDT00:00:00[Z]")),
      endDate: checkNullValue(dayjs(getValues()?.endDate).format("YYYY-MM-DDT00:00:00[Z]")),
    };

    const formData = new FormData();

    const jsonBlob = new Blob([JSON.stringify(createRecRequestData)], {
      type: APPLICATION_JSON,
    });

    formData.append("data", jsonBlob)


    // Proceed with creating collector if all checks pass
    await editEmissionFactorDocument({ collectorId, payload: formData, id: emissionFactorsDetailData?.id }, {
      onError: () => {
        notify.error(`Some error has happened while editing Emission Factor!`);
      },
      onSuccess: () => {
        notify.success(`Emission Factor have been successfully edited!`);
        getEmissionFactorsData(collectorId)
      },
    });
  };

  const handleFileName = async (file: any) => {

    const createRecRequestData: any = {
      collectorId,
      utilityType: getValues()?.utilityType,
      utilityProvider: getValues()?.provider,
      efValue: getValues()?.efValue,
      efUnit: getValues()?.efUnit,
      renewableEnergy: getValues()?.renewableEnergy,
      startDate: checkNullValue(dayjs(getValues()?.startDate).format("YYYY-MM-DDT00:00:00[Z]")),
      endDate: checkNullValue(dayjs(getValues()?.endDate).format("YYYY-MM-DDT00:00:00[Z]")),
    };
    
    const formData = new FormData();

      const jsonBlob = new Blob([JSON.stringify(createRecRequestData)], {
          type: APPLICATION_JSON,
        });
     
    if (jsonBlob && file) {
      formData.append("file",new Blob([file], {type: FORM_DATA}), file.name);
      formData.append("data", jsonBlob)
  
      await editEmissionFactorDocument({ collectorId, payload: formData, id:emissionFactorsDetailData?.id},
        {
          onError: (error: any) => {
            if (error?.response?.data) {
              notify.error(error?.response?.data);
            } else {
              notify.error(`Some error has happened while adding Emission Factor!`);
            }
          },
          onSuccess: () => {
            notify.success(`Emission Factor have been successfully added!`);
            getEmissionFactorsData(collectorId)
          },
        }
      )
    }    
    
  }

  const handleFileReUploaded = (status: any) => {
    if (status === true) {
      getEmissionFactorsData(collectorId)
    }
  };

  return (
    <>
      {emissionFactorsDetailData !== undefined && (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              px: 3,
              flexGrow: 1,
              flexDirection: "row",
              overflow: "auto",
            }}
          >
            {/* Section 1 */}
            <EmissionFactorPdfPreview
              downloadUrl={(emissionFactorsDetailData  as any)?.downloadUrl}
              scale={scale}
              isScaleChange={scaleChange}
              setFileName={handleFileName}
            />
            {/* Section 2 */}
            <Box
              sx={{
                ml: 4,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
             
              <Box component="section" mb={3} mt={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Body1SemiBold mb={3}>Utility Specific Emission Factor Details</Body1SemiBold>
                  <CloseButton onClick={() => navigate(-1)} variant="outlined" />
                </Box>

                <Stack direction="row" mb={2} spacing={3}>
                  <InputFieldWrapper label="Utility Provider">
                    <Controller
                      name="provider"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          disabled
                          value={value}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                  <InputFieldWrapper label="Utility Type">
                    <Controller
                      name="utilityType"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          disabled
                          value={value}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                </Stack>
                <Stack direction="row" mb={2} spacing={3}>
                <InputFieldWrapper label="Emission Factor Value">
                    <Controller
                      name="efValue"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                  <InputFieldWrapper label="Emission Factor Unit">
                    <Controller
                      name="efUnit"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                </Stack>
                <Stack direction="row" mb={2} spacing={3}>
                  <RecDatePickerFormField
                    name="startDate"
                    label="REC Start Date"
                    control={control}
                    disabled={false}
                    rules={{
                      required: receivedData?.startDate,
                    }}
                    {...getTextFieldColorStateProps(
                      "startDate",
                      formState.errors,
                    )}
                  />

                  <RecDatePickerFormField
                    name="endDate"
                    label="REC End Date (Optional)"
                    control={control}
                    disabled={false}
                    rules={{
                      required: receivedData?.endDate,
                    }}
                    {...getTextFieldColorStateProps(
                      "endDate",
                      formState.errors,
                    )}
                  />
                </Stack>
                <Stack direction="row" mb={2} spacing={3}>
                <InputFieldWrapper label="Coverage Percentage">
                    <Controller
                      name="renewableEnergy"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">
                              <span style={{ color: COLORS.Romance }}>%</span>
                            </InputAdornment>,
                          }}
                        />
                      )}
                    />
                  <Esg12LabelRegular>Optional</Esg12LabelRegular>
                </InputFieldWrapper>
                </Stack>
              </Box>

              <Box
                sx={{
                  mt: 4,
                  lexGrow: 1,
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  onClick={() => handleSaveBtnClick()}
                  variant="contained"
                  disabled={disableBtn}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>

          <EmissionFactorPdfFooter
            fileName={(emissionFactorsDetailData  as any)?.recsFileName}
            setScale={setScale}
            downloadUrl={(emissionFactorsDetailData  as any)?.downloadUrl}
            actionsDisabled={false}
            setScaleChange={setScaleChange}
            isFileUploaded={handleFileReUploaded}
          />
        </Box>
      )}
    </>
  );
};