import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { updateVarianceEmissions, updateVarianceComparisonEmissions, updateDateRangeEmissions, updateYoYEmissions, updateSingleYearByScopeEmissions } from "api";
import { Box } from "@mui/material";
import { Tab } from "../../components";
import { DashboardTabs } from "../../../components/dashboardTabs";

const emissionsTabs = {
  INITIAL_TAB: "Location",
};

export const GHG_EMISSIONS = ({
  children,
  tabs,
  currentMainTab,
  fiscalYears,
  currentDate,
  defaultObject,
  setDefaultObject,
  currentActiveSearch,
  setCurrentActiveSearch,
}: {
  children?: React.ReactNode;
  tabs: string[];
  currentMainTab: string;
  fiscalYears: string[];
  currentDate: Dayjs;
  defaultObject: any;
  setDefaultObject: React.Dispatch<React.SetStateAction<any>>;
  currentActiveSearch: boolean;
  setCurrentActiveSearch: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { INITIAL_TAB } = emissionsTabs;

  const [varianceStartDate, setVarianceStartDate] = useState<Dayjs>(dayjs(currentDate.date(1)).subtract(2, "month"));
  const [varianceEndDate, setVarianceEndDate] = useState<Dayjs>(dayjs(currentDate));
  const [dateRangeStartDate, setDateRangeStartDate] = useState<Dayjs>(dayjs(currentDate.date(1)).subtract(2, "month"));
  const [dateRangeEndDate, setDateRangeEndDate] = useState<Dayjs>(dayjs(currentDate));

  const [varianceData, setVarianceData] = useState<any>([]);
  const [varianceComparisonData, setVarianceComparisonData] = useState<any>([]);
  const [comparisonPeriod, setComparisonPeriod] = useState<string>("mom");
  const [dateRangeData, setDateRangeData] = useState<any>([]);
  const [dateRangeType, setDateRangeType] = useState<string>("MONTHLY");
  const [yoYData, setYoYData] = useState<any>([]);
  const [yoYLength, setYoYLength] = useState<number>(1);
  const [currentFiscalYear, setCurrentFiscalYear] = useState<string>(`${new Date().getFullYear()}`);
  const [singleYearByScopeData, setSingleYearByScope] = useState<any>([]);

  const yoYMaxLength: number = 10;

  const { mutateAsync: updateVarianceEmissionsRequest, /* isLoading: loadingVariance */ } = useMutation(updateVarianceEmissions);
  const { mutateAsync: updateVarianceComparisonEmissionsRequest, /* isLoading: loadingComparison */ } = useMutation(updateVarianceComparisonEmissions);
  const { mutateAsync: updateDateRangeEmissionsRequest, /* isLoading: loadingDateRange */ } = useMutation(updateDateRangeEmissions);
  const { mutateAsync: updateYoYEmissionsRequest, /* isLoading: loadingYoY */ } = useMutation(updateYoYEmissions);
  const { mutateAsync: updateSingleYearByScopeEmissionsRequest, /* isLoading: loadingSingleYear */ } = useMutation(updateSingleYearByScopeEmissions);

  // ---- VARIANCE --------------------------------
  const handleVarianceDateChange = async (startDate: Dayjs, endDate: Dayjs) => {
    await updateVarianceEmissionsRequest({
      ...defaultObject,
      compareFromDate: startDate.toISOString().substring(0, 10),
      compareToDate: endDate.toISOString().substring(0, 10),
    }, {
      onSuccess: (response: any) => {
        setVarianceData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })

    await updateVarianceComparisonEmissionsRequest({
      ...defaultObject,
      compareFromDate: startDate.toISOString().substring(0, 10),
      compareToDate: endDate.toISOString().substring(0, 10),
      comparisonPeriod,
    }, {
      onSuccess: (response: any) => {
        setVarianceComparisonData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  const handleUpdateComparisonPeriod = async () => {
    await updateVarianceComparisonEmissionsRequest({
      ...defaultObject,
      compareFromDate: varianceStartDate.toISOString().substring(0, 10),
      compareToDate: varianceEndDate.toISOString().substring(0, 10),
      comparisonPeriod,
    }, {
      onSuccess: (response: any) => {
        setVarianceComparisonData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  // ---- DATE RANGE ------------------------------
  const handleDateRangeChange = async (startDate: Dayjs, endDate: Dayjs) => {
    await updateDateRangeEmissionsRequest({
      ...defaultObject,
      compareFromDate: startDate.toISOString().substring(0, 10),
      compareToDate: endDate.toISOString().substring(0, 10),
      groupBy: dateRangeType,
    }, {
      onSuccess: (response: any) => {
        setDateRangeData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  // ---- YEAR OVER YEAR --------------------------
  const handleYoYChange = async () => {
    await updateYoYEmissionsRequest({
      ...defaultObject,
      yearsToCompare: yoYLength,
    }, {
      onSuccess: (response: any) => {
        setYoYData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  // ---- SINGLE YEAR BY SCOPE --------------------
  const handleSingleYearByScopeChange = async () => {
    await updateSingleYearByScopeEmissionsRequest({
      ...defaultObject,
      fiscalYear: currentFiscalYear,
    }, {
      onSuccess: (response: any) => {
        setSingleYearByScope(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  // ---- ACTIVE SEARCH BUTTON --------------------
  const handleActiveSearchButton = async () => {
    await handleVarianceDateChange(varianceStartDate, varianceEndDate);
    await handleUpdateComparisonPeriod();
    await handleDateRangeChange(dateRangeStartDate, dateRangeEndDate);
    await handleYoYChange();
    await handleSingleYearByScopeChange();
    setCurrentActiveSearch(false);
  };

  useEffect(() => {
    handleVarianceDateChange(varianceStartDate, varianceEndDate);
  }, [varianceStartDate, varianceEndDate]); // eslint-disable-line

  useEffect(() => {
    handleUpdateComparisonPeriod();
  }, [comparisonPeriod]) // eslint-disable-line

  useEffect(() => {
    handleDateRangeChange(dateRangeStartDate, dateRangeEndDate);
  }, [dateRangeStartDate, dateRangeEndDate, dateRangeType]); // eslint-disable-line

  useEffect(() => {
    handleYoYChange();
  }, [yoYLength]); // eslint-disable-line

  useEffect(() => {
    handleSingleYearByScopeChange();
  }, [currentFiscalYear]); // eslint-disable-line

  useEffect(() => {
    handleActiveSearchButton();
  }, [currentActiveSearch]); // eslint-disable-line

  const allTabs = {
    [INITIAL_TAB]: [...tabs.map((tab: any) => ({
      label: tab,
      content: <Tab
        currentMainTab={currentMainTab}
        subTypeChart={tab.toLowerCase()}
        setDefaultObject={setDefaultObject}
        varianceStartDate={varianceStartDate}
        varianceEndDate={varianceEndDate}
        setVarianceStartDate={setVarianceStartDate}
        setVarianceEndDate={setVarianceEndDate}
        varianceData={varianceData}
        varianceComparisonData={varianceComparisonData}
        setComparisonPeriod={setComparisonPeriod}
        dateRangeType={dateRangeType}
        setDateRangeType={setDateRangeType}
        dateRangeStartDate={dateRangeStartDate}
        dateRangeEndDate={dateRangeEndDate}
        setDateRangeStartDate={setDateRangeStartDate}
        setDateRangeEndDate={setDateRangeEndDate}
        dateRangeData={dateRangeData}
        yoYMaxLength={yoYMaxLength}
        setYoYLength={setYoYLength}
        yoYData={yoYData}
        currentFiscalYear={currentFiscalYear}
        setCurrentFiscalYear={setCurrentFiscalYear}
        allFiscalYears={fiscalYears}
        singleYearByScopeData={singleYearByScopeData}
      >
        {children}
      </Tab>
    }))]
  }

  return (
    <Box sx={{ my: 2 }}>
      <DashboardTabs intensity tabs={allTabs[INITIAL_TAB]} selectedBtnOption={emissionsTabs.INITIAL_TAB} textColor="#99CD71" dashboard />
    </Box>
  )
};
