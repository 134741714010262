import {
  getResData,
  httpClient,
} from "utils";

import { REC_API_PATHS } from "./consts";

export interface CollectorRecsDto {
  provider: string;
  siteName: string;
  meterNumber: string;
  accountNumber: string;
  collectorId: number;
  recsActive: boolean;
}

export interface RecDetails {
  utilityTypeName: string;
  utilityProvider: string;
  country: string;
  renewableEnergy: string;
  startDate: string; 
  endDate: string; 
  recId: number;
  collectorRecsDtoList: CollectorRecsDto[];
  downloadUrl: string | null;
  recsFileName: string | null;
  recsUploaded: boolean;
}

export interface EmissionFactorDetails {
  utilityTypeName: string;
  utilityProvider: string;
  country: string;
  renewableEnergy: string;
  startDate: string; 
  endDate: string; 
  recId: number;
  collectorRecsDtoList: CollectorRecsDto[];
  downloadUrl: string | null;
  recsFileName: string | null;
  recsUploaded: boolean;
  efUnit: string;
  efValue: string;
}

export const createRecDocument = ({
  fileName,
  recId
}: {
  fileName: string[];
  recId: number;
}) =>
  httpClient
    .post(
      REC_API_PATHS.RECsDOCUMENT(),
      { fileName, recId },
    )
    .then(getResData);

export const RecDocumentUploaded = (payload: any) =>
  httpClient.post(REC_API_PATHS.RECsDOCUMENT_UPLOADED, payload);

export const getRecDocumentDetails = (recId: any) =>
  httpClient.get<RecDetails>(REC_API_PATHS.RECsDOCUMENT_DETAILS(recId));

export const getMultipleRecDocumentDetails = (recId: any) =>
  httpClient.post<RecDetails>(REC_API_PATHS.MULTIPLE_RECsDOCUMENT_DETAILS, recId);

export const getRecDataBySite = (siteId: any) =>
  httpClient.get<RecDetails>(REC_API_PATHS.SITE_REC_DATA(siteId));

export const getEmissionFactorsByCollector = (collectorId: any) =>
  httpClient.get<EmissionFactorDetails>(REC_API_PATHS.COLLECTOR_EMISSION_DATA(collectorId));

export const deleteEmissionFactorsByCollector = ({collectorId, emissionFactorId}: any) =>
  httpClient.delete<EmissionFactorDetails>(REC_API_PATHS.DELETE_COLLECTOR_EMISSION_DATA(collectorId, emissionFactorId));

export const addRecDataBySite = (payload: any) =>
  httpClient.post<RecDetails>(REC_API_PATHS.ADD_SITE_REC_DATA, payload);

export const addEmissionFactorDataByCollector = ({collectorId, payload}: any) =>
  httpClient.post<EmissionFactorDetails>(REC_API_PATHS.SET_COLLECTOR_EMISSION(collectorId), payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

export const editRecDataBySite = ({ recId, payload }: { recId: any; payload: any }) =>
  httpClient.put<RecDetails>(REC_API_PATHS.RECsDOCUMENT_DETAILS(recId), payload);

export const editEmissionFactorByCollector = ({ collectorId, payload, id }: { collectorId: any; payload: any, id : any }) =>
  httpClient.put<EmissionFactorDetails>(REC_API_PATHS.EDIT_COLLECTOR_EMISSION(collectorId, id), payload);

export const editRecDocumentDetails = ({
  recId,
  payload,
}: {
  recId: any;
  payload: any;
}) =>
  httpClient.put(REC_API_PATHS.RECsDOCUMENT_DETAILS(recId), payload);

export const activateRecDetails = (payload: any) =>
  httpClient.put(`recdocument/activate`, payload);

export const deActivateRecDetails = (payload: any) =>
  httpClient.put(`recdocument/de-activate`, payload);

  
export const deleteRecDocument = (recId: any) =>
  httpClient.delete(REC_API_PATHS.RECsDOCUMENT_DETAILS(recId));




