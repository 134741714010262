export const COMMON_STYLES = {
  height: "60px",
  display: "flex",
  alignItems: "center",
  p: 2,
  cursor: "pointer",
  fontWeight: 600,
};

export const CELL_COLORS = {
  error: {
    backgroundColor: "error.light",
    color: "error.main",
  },
  warning: {
    backgroundColor: "#e4ec81",
    color: "black",
  },
  selected: {
    backgroundColor: "gray",
    color: "gray.main",
  },
  deactivated: {
    backgroundColor: "#dddddd",
    color: "white",
  },
  deactivatedWithData: {
    backgroundColor: "#dddddd",
    color: "black",
  },
  none: {
    backgroundColor: "#cbf2ac",
    color: "black",
  },
  info: {
    backgroundColor: "#BFDBFE",
    color: "#000000",
  },
};

export type CellColor = keyof typeof CELL_COLORS;
