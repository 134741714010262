import React, { FunctionComponent } from "react";
import { Button, SxProps } from "@mui/material";
import { Theme } from "@mui/system";

import { CrossIcon } from "assets";

type Variant = "text" | "outlined" | "contained" | undefined;

interface CloseButtonProps {
  color?: string;
  variant?: Variant;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

export const CloseButton: FunctionComponent<CloseButtonProps> = ({
  color,
  onClick,
  variant = "text",
  sx = {},
}) => (
  <Button
    sx={{
      width: 28,
      minWidth: 28,
      maxHeight: 28,
      padding: 0,
      borderRadius: "8px",
      ...sx,
    }}
    variant={variant}
    onClick={onClick}
  >
    <CrossIcon color={color} />
  </Button>
);
