import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, {
    ChangeEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import {
    createDocuments,
    documentUploaded,
    getDocuments,
    uploadDocumentToS3,
    uploadSites,
} from "api";

import GridOnIcon from "@mui/icons-material/GridOn";

import { useNotify, useSites } from "hooks";

import { QUERY_KEYS } from "consts";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { updateFileCountContext } from "providers/uploadFileCount-provider";

import { USER_ROLES, useUserRole } from "recoils";

import { Body2Regular } from "components/typography";
import { EsgArrowDownWhite, EsgFileUpload } from "assets";
import { AxiosError } from "axios";
import { Box } from "@mui/system";
import { useDialogControls } from "../dialog";
import { SiteSelectionDialog } from "../site-selection-dialog";

const EXCEL_FILE_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const EsgDocumentsUpload: FunctionComponent<{
    isScope3: boolean;
    isLoading1?: boolean;
}> = ({ isScope3 = false, isLoading1 }) => {
    const notify = useNotify();
    const userRole = useUserRole();
    const queryClient = useQueryClient();
    const { mutate: create } = useMutation(createDocuments);
    const { mutateAsync: uploadToS3 } = useMutation(uploadDocumentToS3);
    const { mutateAsync: uploaded } = useMutation(documentUploaded);
    const { data: sites } = useSites();
    const approvalBoxRef = useRef<HTMLDivElement | null>(null);

    const sitesDialogControls = useDialogControls();
    const [selectedSite, setSelectedSite] = useState<null | number>(null);
    const [uploadCount, setUploadCount] = useState<any>(0);
    const [uploaddiseble, setUploadsdiseble] = useState(false);
    const [showBox, setShowBox] = useState(false);
 const { mutateAsync: upload, isLoading } = useMutation(uploadSites);
    const [openFirstDialog, setOpenFirstDialog] = useState(false);
    const [openSecondDialog, setOpenSecondDialog] = useState(false);
    const [overrideFlag, setOverrideFlag] = useState(false);

    const handlClick = () => {
        setShowBox(!showBox);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            approvalBoxRef.current &&
            !approvalBoxRef.current.contains(event.target as Node)
        ) {
            setShowBox(false);
        }
    };

    const { updateUploadedCount, updateTotalCount } = useContext(
        updateFileCountContext,
    );

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    let isOneSite = false;

    if (sites !== undefined) {
        isOneSite = sites?.length > 1;
    }
    const onSiteSelect = (id: number) => {
        sitesDialogControls.close();
        setSelectedSite(id);
        fileInputRef?.current?.click();
    };

    const onUploadButtonClick = () => {
        if (isOneSite && !userRole.isAdmin) {
            // sitesDialogControls.open();
            setSelectedSite(0);
            fileInputRef?.current?.click();
        } else if (sites?.length === 1 && !userRole.isAdmin) {
            setSelectedSite(0);
            fileInputRef?.current?.click();
        } else {
            setSelectedSite(0);
            fileInputRef?.current?.click();
        }
    };

    useEffect(() => {
        updateUploadedCount(uploadCount);
    }, [uploadCount, updateUploadedCount]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        let uploadedFilesCount = 0;
        updateTotalCount(files.length);
        setUploadsdiseble(true)
        create(
            {
                fileNames: files.map((file) => file.name),
                siteId: selectedSite ?? 1,
            },
            {
                onSuccess: (
                    documents: {
                        s3uploadUrl: string;
                        document: { uuid: string; originalFileName: string };
                    }[],
                ) => {
                    queryClient.invalidateQueries([QUERY_KEYS.UTILITY_BILLS]).then(() => {
                        Promise.allSettled(
                            documents.map((document) =>
                                uploadToS3({
                                    url: document.s3uploadUrl,
                                    file: files.find(
                                        (file) => file.name === document.document.originalFileName,
                                    ) as File,
                                })
                                    .then(() => uploaded(document.document.uuid))
                                    .then((res) => {
                                        if (res.data[0].status === "ERROR") {
                                            notify.error(
                                                `Failed to upload file: ${document.document.originalFileName}!`,
                                            );
                                        }
                                        if (res.data[0].status === "PROCESSING") {
                                            setUploadCount(uploadedFilesCount + 1);
                                            uploadedFilesCount += 1;
                                        }
                                    })
                                    .catch(() => {
                                        // TODO cleanup document if failed to upload
                                        notify.error(
                                            `Failed to upload file: ${document.document.originalFileName}!`,
                                        );
                                    }),
                            ),
                        ).then(() => {
                            if (uploadedFilesCount === files.length) {
                                notify.success(`Successfully uploaded all files!`);
                                setTimeout(() => {
                                    updateUploadedCount(0);
                                    updateTotalCount(0);
                                }, 2000);
                            } else {
                                notify.success(
                                    `Successfully uploaded files: ${uploadedFilesCount}/${files.length}`,
                                );
                                setTimeout(() => {
                                    updateUploadedCount(0);
                                    updateTotalCount(0);
                                }, 2000);
                            }
                            queryClient.invalidateQueries([QUERY_KEYS.UTILITY_BILLS]);
                            queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS]);
                            e.target.value = "";
                            getDocuments({})
                        });
                    });
                },
                onError() {
                    notify.error("Failed to upload files!");
                    e.target.value = "";
                },
            },
        );
    };

    const extraReqParams = [["configuration", "false"]]

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
          const { files } = e.target;
          const formData = new FormData();
    
          if (files) {
            formData.append("file", files[0], files[0].name);
            formData.append("overrideFlag", overrideFlag as any);
            if (extraReqParams) {
              extraReqParams.forEach(([key, value]) => {
                formData.append(key, value);
              });
            }
            setUploadsdiseble(true);
            upload(
              {
                file: formData,
                isScope3,
                overrideFlag
              },
              {
                onSuccess: (data) => {
                  if (data?.data?.message === "Records have been inserted !!! ") {
                    notify.success(`Successfully uploaded file ${files[0].name}!`);
                  } else {
                    notify.success(`Successfully uploaded file ${files[0].name}!`);
                  }
    
                  queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS]);
                },
                onError: (error) => {
                  notify.error(`Failed to upload file: ${files[0].name}! (${(error as AxiosError)?.response?.data || "Internal Server Error"})`);
                },
                onSettled() {
                  e.target.value = "";
                },
              },
            );
          }
        };

    useEffect(() => {
        if (uploaddiseble) {
            setUploadsdiseble(false)

        } else { /* empty */ }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading1])

    useEffect(() => {
        if (showBox) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showBox]);

    const handleFirstDialogClose = (choice: string) => {
        setOpenFirstDialog(false);
        if (choice === "Yes") {
          setOpenSecondDialog(true);
          setOverrideFlag(true);
        }
        if (choice === "Not Sure" || choice === "No") {
          document.getElementById("file-input")?.click();
          setOverrideFlag(false);
        }
      };
  
      const handleSecondDialogClose = (choice: string) => {
        setOpenSecondDialog(false);
        setOverrideFlag(false);
        if (choice === "Yes") {
          document.getElementById("file-input")?.click();
          setOverrideFlag(true);
        }
        if (choice === "No") {
          document.getElementById("file-input")?.click();
          setOverrideFlag(false);
        }
      };

    return (
        <>

            <Box
                ref={approvalBoxRef}

                sx={{ display: "flex", flexDirection: "column", gap: 1, width: "200px", position: "relative" }}>
                <Button
                    sx={{
                        borderRadius: 2,
                        color: "white",
                        width: "200px",
                        height: "36px",
                        p: 0

                    }}
                    variant="contained"
                    startIcon={<EsgFileUpload />}
                    endIcon={<Box
                        sx={{
                            ml: 2,
                            mt: showBox ? "0": "2px",
                            transform: showBox ? "rotate(180deg)" : "rotate(0deg)",
                            transition: "transform 0.3s ease"
                        }}>
                        <EsgArrowDownWhite /></Box>}
                    onClick={handlClick}>
                    <Body2Regular>Upload Data</Body2Regular>
                </Button>

                {showBox && (
                    <Box
                        sx={{
                            width: "200px",
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            mt: 1,
                            p: 0.5,
                            border: "1px solid #C1C1C1",
                            borderRadius: "8px",
                            backgroundColor: "#FBFBFB",
                            zIndex: 10,
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {!isScope3 && (
                            <>
                                <input
                                    ref={fileInputRef}
                                    hidden
                                    accept="application/pdf"
                                    multiple
                                    type="file"
                                    onChange={onChange}
                                />
                                <Button
                                    variant="contained"
                                    startIcon={<PictureAsPdfIcon />}
                                    title="Upload pdf"
                                    fullWidth
                                    onClick={onUploadButtonClick}
                                    disabled={!sites?.length || userRole.isAuditor || uploaddiseble}
                                >
                                    Upload pdf
                                </Button>
                            </>
                        )}
                        <Button
                            sx={{ mt: "4px" }}
                            variant="contained"
                            component="label"
                            fullWidth
                            startIcon={<GridOnIcon />}
                            disabled={isLoading || !userRole.isRoleIn([USER_ROLES.ADMIN, USER_ROLES.SITE_MANAGER]) || uploaddiseble}
                            onClick={() => setOpenFirstDialog(true)}
                        >
                            Upload Excel Data
                        </Button>
                    </Box>
                )}
            </Box>

            {isOneSite && (
                <SiteSelectionDialog
                    isDialogOpen={sitesDialogControls.isOpen}
                    onDialogClose={sitesDialogControls.close}
                    onSiteSelect={onSiteSelect}
                />
            )}

            <input id="file-input" hidden accept={`${EXCEL_FILE_TYPE}, application/vnd.ms-excel`} type="file" onChange={onFileChange} />

            {/* First Dialog */}
            <Dialog
                open={openFirstDialog}
                onClose={() => handleFirstDialogClose("No")}
                PaperProps={{
                    sx: {
                        width: 384,
                        bgcolor: "#FFFFFF",
                    },
                }}
            >
                <DialogContent sx={{ py: '2px', pl: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ErrorOutlineIcon sx={{ color: "#C55050" }} />
                        <DialogTitle sx={{ px: '10px', fontSize: '16px', fontWeight: 600, fontFamily: 'Inter', m: 0 }}>
                            Data Points Confirmed?
                        </DialogTitle>
                    </Box>
                    <DialogContentText sx={{ fontSize: '14px', fontWeight: 400, fontFamily: 'Inter', opacity: 0.65 }}>
                        Are you uploading confirmed data points (e.g., historically verified data, data from metering systems)?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ color: "#6B7280", fontSize: '14px', fontWeight: 600 }}
                        onClick={() => handleFirstDialogClose("Not Sure")}
                        variant="text"
                    >
                        Not Sure
                    </Button>
                    <Button
                        sx={{ color: "#6B7280", fontSize: '14px', fontWeight: 600 }}
                        onClick={() => handleFirstDialogClose("No")}
                        variant="text"
                    >
                        No
                    </Button>
                    <Button
                        sx={{
                            borderRadius: "8px",
                            background: "#C55050",
                            color: "white",
                            fontSize: '14px',
                            fontWeight: 600,
                            "&:hover": { background: "#C55050" },
                        }}
                        onClick={() => handleFirstDialogClose("Yes")}
                        variant="contained"
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Second Dialog */}
            <Dialog
                open={openSecondDialog} onClose={() => handleSecondDialogClose("No")}
                PaperProps={{
                    sx: {
                        width: 384,
                        bgcolor: "#FFFFFF",
                    },
                }}
            >
                <DialogContent sx={{ py: '2px', pl: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ErrorOutlineIcon sx={{ color: "#C55050" }} />
                        <DialogTitle sx={{ px: '10px', fontSize: '16px', fontWeight: 600, fontFamily: 'Inter', m: 0 }}>
                            Override All Flags?
                        </DialogTitle>
                    </Box>
                    <DialogContentText sx={{ fontSize: '14px', fontWeight: 400, fontFamily: 'Inter', opacity: 0.65 }}>
                        Do you want to override all anomaly flags? (e.g. Duplicate detection, YoY/MoM threshold detection, etc.)
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ color: "#6B7280", fontSize: '14px', fontWeight: 600 }}
                        onClick={() => handleSecondDialogClose("No")}
                        variant="text"
                    >
                        No
                    </Button>
                    <Button
                        sx={{
                            borderRadius: "8px",
                            background: "#C55050",
                            color: "white",
                            fontSize: '14px',
                            fontWeight: 600,
                            "&:hover": { background: "#C55050" },
                        }}
                        onClick={() => handleSecondDialogClose("Yes")}
                        variant="contained"
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
