export const chartColors = [
  "#5AB2DA",
  "#99CD71",
  "#D79E3F",
  "#E7CF50",
  "#A12E1F",
  "#031118",
  "#265E71",
  "#A1D0BE",
  "#BE6B27",
  "#054254",
  "#4A32CD",
  "#9D2E88",
]