
import { Box, Button } from "@mui/material";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, memo, useEffect, useRef, useState } from "react";

import {
  UserDTO,
  deleteUser,
  deleteUserSite,
  reActivateUser,
  updateUserRole,
  updateUserSite,
} from "api";

import { EsgDeleteIcon } from "assets";

import {
  AutocompleteField,
  Avatar,
  COLORS,
  Label16SemiBold,
  MultiselectControlledField,
  EsgPrimaryLabel,
} from "components";

import { useGetConfigParameter, useNotify, useSitesMultiselect } from "hooks";

import { QUERY_KEYS } from "consts";

import {
  USER_ROLES,
  USER_ROLES_NAMES,
} from "recoils";
import { getInitials } from "utils";

import { ROLE_SELECT_OPTIONS, ROLE_SELECT_OPTIONS_APPROVAL_FLOW } from "./consts";

interface UserSiteParams {
  userId: number;
  siteId: number;
}

export const User: FunctionComponent<{
  userData: UserDTO;
}> = memo(({ userData }) => {
  const queryClient = useQueryClient();
  const { data: configData } = useGetConfigParameter();
    
  const approvalFlowValue = configData?.find((t) => t.name === "second_layer_approval_feature")
  const isApprovalFlowActive = approvalFlowValue?.configValue

  const { mutateAsync: deleteUsers } = useMutation(deleteUser);
  const { mutateAsync: reactivateUsers } = useMutation(reActivateUser);
  const { mutateAsync: updateRole } = useMutation(updateUserRole);

  const notify = useNotify();
  const [roleSelectValue, setRoleSelectValue] = useState<null | string>(null);
  const [loggedUser, setLoggedUser] = useState<UserDTO>();
  const hasFetchedUser = useRef(false);
  const { isSitesLoading, selectedSites, setSelectedSites, sitesOptions } =
    useSitesMultiselect({ withQueryParams: false });

  const useUpdateUserSite = (): UseMutationResult<
    unknown,
    unknown,
    UserSiteParams
  > => useMutation(({ userId, siteId }: any) => updateUserSite(userId, siteId));

  const useDeleteUserSite = (): UseMutationResult<
    unknown,
    unknown,
    UserSiteParams
  > => useMutation(({ userId, siteId }: any) => deleteUserSite(userId, siteId));


  const { mutateAsync: updateUserSiteSetting } = useUpdateUserSite();
  const { mutateAsync: deleteUserSiteSetting } = useDeleteUserSite();

  const handleUpdateUserSite = async (userId: number, siteId: number, user: any) => {

    try {
      await updateUserSiteSetting({ userId, siteId });
      notify.success(`Site update successfully for ${user.email}!`);
    } catch (error) {
      console.error('Failed to update user site:', error);
    }
  };

  const handleDeleteUserSite = async (userId: number, siteId: number, user: any) => {
    try {
      await deleteUserSiteSetting({ userId, siteId });
      notify.success(`Site update successfully for ${user.email}!`);
    } catch (error) {
      console.error('Failed to delete user site:', error);
    }
  };

  const onDeleteUser = (user: UserDTO) => {
    deleteUsers(user.id, {
      onError: () =>
        notify.error(
          `Some error has happen while deleting user ${user.email}!`,
        ),
      onSuccess: () => {
        notify.success(`User successfully deleted ${user.email}!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  const onReActivateUser = (user: UserDTO) => {
    reactivateUsers(user.id, {
      onError: () =>
        notify.error(`Some error has happen while re-activating the user!`),
      onSuccess: () => {
        notify.success(`User successfully Enabled!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');

    if (storedUser) {
      setLoggedUser(JSON.parse(storedUser));
    }

    hasFetchedUser.current = true;
  }, []);

  const disableDelete = () => {
    if (loggedUser !== undefined) {
      if (userData.id === loggedUser.id) return true;
    }
    return false;
  };

  const onUpdateUser = (user: any) => {
    const body = {
      id: user?.id,
      roles: user?.roles,
      siteIds: user?.siteIds
    };

    updateRole(body, {
      onError: () =>
        notify.error(
          `Some error has happen while updating user ${user.email}!`,
        ),
      onSuccess: () => {
        notify.success(`User successfully updated ${user.email}!`);
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      },
    });
  };

  const [previousSelectedValues, setPreviousSelectedValues] = useState(sitesOptions.filter((option) => userData?.sites?.includes(option.value as number)));

  const handleSelectionChange = (newSelectedValues: any) => {
    const addedValues = newSelectedValues.filter(
      (val: any) => !previousSelectedValues.some((prev: any) => prev.value === val.value)
    );
    const removedValues = previousSelectedValues.filter(
      (prev: any) => !newSelectedValues.some((val: any) => val.value === prev.value)
    );

    addedValues.forEach((value: any) => {
      handleUpdateUserSite(userData?.id, value?.value, userData)
    });

    removedValues.forEach((value: any) => {
      handleDeleteUserSite(userData?.id, value?.value, userData)
    });

    setPreviousSelectedValues(newSelectedValues);
    setSelectedSites(newSelectedValues);
  };

  return (
      <Box display="flex" alignItems="center" width="100%" marginY="1">
        {/* Avatar */}
        <Box width="6%" my="8px">
          <Avatar
            sx={{
              bgcolor: COLORS.Romance,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EsgPrimaryLabel sx={{ color: "white" }}>
              {getInitials(`${userData.firstName} ${userData.lastName}`)}
            </EsgPrimaryLabel>
          </Avatar>
        </Box>

        {/* Name */}
        <Box width="16%">
          <Label16SemiBold>
            {userData.firstName} {userData.lastName}
          </Label16SemiBold>
        </Box>

        {/* User Email */}
        <Box width="30%">
          <Label16SemiBold>{userData.email}</Label16SemiBold>
        </Box>

        {userData.enabled && (
          <>
            <Box width="22%">
              <AutocompleteField
                textFieldProps={{
                  sx: {
                    width: "220px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: COLORS.Romance,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: COLORS.Romance,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: COLORS.Romance,
                    },
                    "& input.MuiInputBase-.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  },
                }}
                autocompleteProps={{
                  value:
                    roleSelectValue ||
                    (userData.roles?.includes("SUPER_ADMIN") || userData.roles?.includes("ADMIN")
                      ? USER_ROLES_NAMES[USER_ROLES.MODULE_ADMIN]
                      : USER_ROLES_NAMES[userData.roles[0]]),
                  onChange: (_, role) => {
                    if (role) {
                      const payload = {
                        id: userData?.id,
                        siteIds: userData?.sites,
                        roles: [String(role)?.replace(" ", "_")],
                        email: userData?.email,
                      };
                      onUpdateUser(payload);
                    }
                    setRoleSelectValue(role as string);
                  },
                  options: isApprovalFlowActive === "true" ? ROLE_SELECT_OPTIONS_APPROVAL_FLOW : ROLE_SELECT_OPTIONS,
                }}
              />
            </Box>

            <Box width="20%">
              <MultiselectControlledField
                label=""
                disabled={isSitesLoading}
                selectedValues={
                  selectedSites.length > 0
                    ? selectedSites
                    : sitesOptions.filter((option) =>
                      userData?.sites?.includes(option.value as number)
                    )
                }
                setSelectedValues={(data) => {
                  handleSelectionChange(data);
                }}
                options={sitesOptions}
              />
            </Box>

            <Box width="1%">
              <Button sx={{ padding: 0 }} onClick={() => onDeleteUser(userData)} disabled={disableDelete()}>
                <EsgDeleteIcon color="red" />
              </Button>
            </Box>
          </>
        )}
        {!userData.enabled && (
        <Box>
          <Button
            sx={{
              marginLeft: "6px",
              marginTop: "20px",
              backgroundColor: "lightgray",
            }}
            onClick={() => onReActivateUser(userData)}
          >
            Re-Activate
          </Button>
        </Box>
      )}
      </Box>
  );
});