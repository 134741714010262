/* eslint-disable */

import dayjs from "dayjs";
import { MaybeNull, ParsedQueryParams } from "types";


export const isNullOrUndefined = (value: unknown) =>
  value === undefined || value === null;

export const checkNullValue = (value: unknown) => {
  if (value === undefined || value === null || value === "") {
    return null;
  }
  return value;
}
  

export const definedValueOrDefault = <TValue, TDefault, TReturn = TDefault>(
  value: MaybeNull<TValue>,
  defaultValue: TDefault,
  mutationFn?: (value: TValue) => TReturn,
): TReturn | TDefault => {
  if (isNullOrUndefined(value)) {
    return defaultValue;
  }

  if (!mutationFn) {
    return value as TReturn;
  }

  return mutationFn(value!);
};

export const toUSNumber = (v: number | string, isCurrency = false) => {
  const navLanguage =
    window.sessionStorage.getItem("navigator_language") &&
    JSON.parse(window.sessionStorage.getItem("navigator_language") || "");

  return Number(v).toLocaleString(
    `${navLanguage || "en-GB"}`,
    isCurrency
      ? {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      : undefined,
  );
};

export const getLoggedUserData = () => {
  const loggedUserData =
    window.sessionStorage.getItem("user") &&
    JSON.parse(window.sessionStorage.getItem("user") || "");

  return loggedUserData;
};

export const getUTCDateFormat = () => {
  const dateFormat =
    window.sessionStorage.getItem("date_format") &&
    JSON.parse(window.sessionStorage.getItem("date_format") || "");

  if (dateFormat === null) {
    return "MM-DD-YYYY";
  }
  return dateFormat;
};

export const getDateFormat = () => {
  const dateFormat =
    window.sessionStorage.getItem("date_format") &&
    JSON.parse(window.sessionStorage.getItem("date_format") || "");

  if (dateFormat === null) {
    return "MM-DD-YYYY";
  }
  return dateFormat === "DD-MM-YYYY" ? "D MMM, YYYY" : "MMM D, YYYY";
};

export const getDateFormatset = () => {
  const dateFormat =
    window.sessionStorage.getItem("date_format") &&
    JSON.parse(window.sessionStorage.getItem("date_format") || "");

  if (dateFormat === null) {
    return "DD-MM-YYYY";
  }
  return dateFormat === "DD-MM-YYYY" ? "DD-MM-YYYY" : "MM-DD-YYYY";
};

export const getDateFormatmonthset = () => {
  const dateFormat =
    window.sessionStorage.getItem("date_format") &&
    JSON.parse(window.sessionStorage.getItem("date_format") || "");

  if (dateFormat === null) {
    return "MM-YYYY";
  }
  return dateFormat === "DD-MM-YYYY" ? "MM-YYYY" : "MM-YYYY";
};


export const getZeroTimeStamp = (date: any) => {
  const zeroTimeStampDate = date;
  zeroTimeStampDate.setHours(0);
  zeroTimeStampDate.setMinutes(0);
  zeroTimeStampDate.setSeconds(0);
  return zeroTimeStampDate;
};

export const buildQueryParams = (
  query: Record<string, string | number | boolean | string[] | number[]>,
) => {
  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length !== 0) {
        params.append(key, value.join());
      }
    } else if (!isNullOrUndefined(value) && value !== "" && value !== false) {
      params.append(key, String(value));
    }
  });

  return params;
};

export const buildQueryParamsAuth = (
  query: Record<string, string | number | boolean | string[] | number[]>,
) => {
  const params = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length !== 0) {
        params.append(key, value.join());
      }
    } else if (!isNullOrUndefined(value) && value !== "") {
      params.append(key, String(value));
    }
  });

  return params;
};

export const getParsedQueryParams = (searchParams: URLSearchParams) => {
  const queryParams: ParsedQueryParams = {};

  searchParams.forEach((value, key) => {
    if (!queryParams[key]) {
      queryParams[key] = value;
    } else {
      if (!Array.isArray(queryParams[key])) {
        queryParams[key] = [queryParams[key]] as string[];
      }

      (queryParams[key] as string[]).push(value);
    }
  });

  return queryParams;
};

export const sortBy = <T>({
  array,
  key,
  order = "ASC",
}: {
  array: T[];
  key?: keyof T;
  order?: "ASC" | "DESC";
}) => {
  if (key) {
    if (order !== "ASC") {
      return array.sort((a, b) =>
        a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0,
      );
    }

    return array.sort((a, b) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0,
    );
  }

  if (order !== "ASC") {
    return array.sort((a, b) => (a > b ? -1 : b > a ? 1 : 0));
  }

  return array.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
};

export const checkIsScope3 = (activeScope: string | string[]) =>
  activeScope.includes("scope3");

export const formatNumber = (num: number, decimalPlaces: number) => {
  const fixedNum = num?.toFixed(decimalPlaces);
  return parseFloat(fixedNum);
}

export function isValidISODate(dateString: string) {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;
  return isoDateRegex.test(dateString);
}

export const convertBlankStringsToNull = (obj: any) => {
  const newObj: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "string" && obj[key].trim() === "") {
        newObj[key] = null;
      } else if (Array.isArray(obj[key])) {
        newObj[key] = obj[key].map((item: any) => convertBlankStringsToNull(item)); 
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        newObj[key] = convertBlankStringsToNull(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  return newObj;
};

export const getFirstAndLastDateOfFiscalYear = (fiscalYear: number, startMonth: string, end_Month: string,) => {
  const adjustedYear = startMonth.toUpperCase() === "JAN" ? fiscalYear : fiscalYear - 1;
  const startDate = dayjs(`${startMonth} 1, ${adjustedYear}`);
  const endDate = dayjs(`${end_Month} 1, ${fiscalYear}`).endOf("month");
  const firstDate = startDate.format("YYYY-MM-DD");
  const lastDate = endDate.format("YYYY-MM-DD");
  return { firstDate, lastDate };
};

export const isValidDate = (dateString: any) => {
  const regex = /^(19[9]\d|2\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
  return regex.test(dateString);
};

