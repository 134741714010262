import React, { useEffect, useState } from "react";
import { Box, Pagination, Stack } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { COLORS } from "components";
import { chartColors } from "../const";

const commonStyles = {
  color: "black",
  fontFamily: "Inter",
  fontWeight: "600",
};

export const CountryChartSingleYear = ({
  chartTitle = 'EmissionsChart',
  singleYearByScopeData,
  mainTab,
  type,
}: {
  chartTitle?: string;
  singleYearByScopeData: any;
  mainTab?: string;
  type: string;
}) => {
  const { country } = singleYearByScopeData;

  const [currentPage, setcurrentPage] = useState<number>(1);
  const paginationLength: number = 4;

  const isEmissionsChart = type === "location" || type === "market";
  const isConsumption = mainTab === "Consumption";

  const data: any[] = country ? Object.values(country).flat() : [];
  const countryCategoriesXAxis = Array.from(new Set(data.map((entry: any) =>
    entry.country
  )));

  const emissionType = isEmissionsChart ? `${type}BasedEmission` : isConsumption ? "consumption" : "amount";

  const countries: any = data.reduce((acc: any, entry: any) => {
    if (!acc[entry.scope]) {
      acc[entry.scope] = [];
    }
    acc[entry.scope].push({
      ...entry,
      [emissionType]: entry[emissionType] ?? 0,
    });
    return acc;
  }, {});

  const countriesData: any = Object.keys(countries).reduce((acc: any, countryKey: string) => {
    acc[countryKey] = countryCategoriesXAxis.map((entry: string) => countries[countryKey].find((item: any) => item.country === entry)?.[emissionType] ?? 0);
    return acc;
  }, {});

  const totalCountriesData: any = Object.values(countriesData).reduce((acc: any, countryData: any) => {
    countryData.forEach((value: number, index: number) => {
      if (!acc[index]) {
        acc[index] = 0;
      }
      acc[index] += value;
    });
    return acc;
  }, []);

  const seriesData = Object.keys(countriesData).map((scopeKey, index) => ({
    name: scopeKey === "scope1" ? "Scope 1" : scopeKey === "scope2" ? "Scope 2" : scopeKey === "NO_SCOPE" ? "No Scope" : scopeKey,
    data: countriesData[scopeKey].length > 4 ? countriesData[scopeKey].slice((currentPage * paginationLength) - paginationLength, (currentPage * paginationLength)) : countriesData[scopeKey],
    color: chartColors[index % chartColors.length],
    fillOpacity: 0.3,
    lineWidth: 6,
    marker: { enabled: false },
  }));

  const isPaginationDisabled: boolean = totalCountriesData.length <= 4;
  const pages = totalCountriesData.length % paginationLength !== 0 ? Math.trunc(totalCountriesData.length / paginationLength) + 1 : totalCountriesData.length / paginationLength;

  const unitYAxis = isEmissionsChart ? "tCO2e" : data[0]?.unit ?? data[0]?.currency ?? "No Data Available";

  useEffect(() => {}, [country])

  const chartOptions = {
    chart: {
      type: "column",
      spacing: [30, 50, 20, 40],
      width: null,
    },
    title: {
      text: chartTitle,
      align: "left",
      margin: 40,
      style: {
        ...commonStyles,
        color: COLORS.Mainblue,
        fontSize: "20px",
      },
    },
    xAxis: {
      categories: countryCategoriesXAxis.length > 4 ? countryCategoriesXAxis.slice((currentPage * paginationLength) - paginationLength, (currentPage * paginationLength)) : countryCategoriesXAxis,
      labels: {
        style: commonStyles,
      },
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: {
        text: unitYAxis,
        style: commonStyles,
        offset: 50
      },
      labels: {
        style: commonStyles,
      },
    },
    series: [
      {
        name: "Total",
        type: "line",
        data: totalCountriesData.length > 4 ? totalCountriesData.slice((currentPage * paginationLength) - paginationLength, (currentPage * paginationLength)) : totalCountriesData,
        color: "transparent",
        dataLabels: {
          enabled: true,
          verticalAlign: "bottom",
          style: {
            ...commonStyles,
            textOutline: "none",
          },
          y: -1,
        },
      },
      ...seriesData,
    ],
    plotOptions: {
      line: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
    },
    legend: {
      title: {
        style: commonStyles,
      },
      align: "left",
      verticalAlign: "bottom",
      x: 20,
      margin: 30,
    },
    tooltip: {
      shared: true,
      borderRadius: 8,
      shadow: false,
      padding: 14,
      useHTML: true,
      outside: true,
      positioner(labelWidth: any, labelHeight: any, point: any) {
        return {
          x: point.plotX,
          y: point.plotY - labelHeight,
        };
      },
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return `
              <div style="font-size: 12px; padding: 8px; display: flex; flex-direction: column; gap: 5px;">
                <strong>${this!.x}</strong>
                ${this!.points
            ?.map(
              (point) => `
                  <div style="display: flex; align-items: center; justify-content: space-between; width: 180px;">
                    <div style="display: flex; align-items: center; gap: 6px;">
                      <span style="width: 10px; height: 10px; background: ${point.series.color}; border-radius: 50%; display: inline-block;"></span>
                      <span>${point.series.name}:</span>
                    </div>
                    <span style="font-weight: bold;">${Number(point.y!.toFixed(2)).toLocaleString('en-US')} ${unitYAxis}</span>
                  </div>
                `
            ).join("")}
              </div>
            `;
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      fontFamily: "Inter",
      position: "relative",
      "& > div": {
        borderRadius: 2,
      },
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
      {isPaginationDisabled
        ? ""
        : <Box sx={{
          position: "absolute",
          bottom: 22,
          right: 50,
        }}>
          <Stack spacing={2}>
            <Pagination
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "gray",
                },
                "& .Mui-selected": {
                  fontWeight: "600",
                  color: COLORS.Mainblue,
                }
              }}
              count={pages}
              page={currentPage}
              size="small"
              onChange={(event: any, page: number) => setcurrentPage(page)} />
          </Stack>
        </Box>
      }
    </Box>
  );
};
