/* eslint-disable */
import React from "react";
import { Box, Grid } from "@mui/material";
import { Label12Regular } from "components";
import { useGetConfigParameter } from "hooks";

const ProgressBar = ({ data }: any) => {
  const statusData = data?.billStatusCount;

  const { data: configData } = useGetConfigParameter();
  
  const approvalFlowValue = configData?.find((t) => t.name === "second_layer_approval_feature")
  const isApprovalFlowActive = approvalFlowValue?.configValue

  const statuses = [
    { label: "Warning", color: "#DD1515" },
    { label: "Emissions Included", color: "#9EE766" },
    { label: "Reviewed", color: "#00A1FF" },
    { label: "Approved (Signed Off)", color: "#0E25D5" },
    { label: "Approved (Locked)", color: "#1D1D1D" },
    { label: "Approved (Locked)", color: "#1D1D1D" },
  ];

  const statuses2 = [
    { label: "Warning", color: "#DD1515" },
    { label: "Emissions Included", color: "#9EE766" },
    { label: "Approved (Signed Off)", color: "#0E25D5" },
    { label: "Approved (Locked)", color: "#1D1D1D" },
    { label: "Approved (Locked)", color: "#1D1D1D" },
  ];

  const approvalFlowStatus = isApprovalFlowActive === "true" ? statuses : statuses2

  const statusToLabelMap: Record<string, string> = {
    Warning: "Warning",
    "Emissions Included": "Emissions Included",
    "Reviewed": "Reviewed",
    "Approved (Signed Off)": "Approved (Signed Off)",
    "Approved (Locked)": "Approved (Locked)",
  };

  const statusToLabelMap2: Record<string, string> = {
    Warning: "Warning",
    "Emissions Included": "Emissions Included",
    "Approved (Signed Off)": "Approved (Signed Off)",
    "Approved (Locked)": "Approved (Locked)",
  };

  const approvalFlowLabel = isApprovalFlowActive === "true" ? statusToLabelMap : statusToLabelMap2


  const result = Object.keys(approvalFlowLabel)?.map((statusKey) => {
    const label = approvalFlowLabel[statusKey];
    const statusEntry = approvalFlowStatus?.find((s) => s.label === label);
    const apiEntry = statusData?.find((item: any) => item.status === statusKey);

    return {
      status: statusKey,
      count: apiEntry?.count || 0,
      percentage: apiEntry?.percentage || 0,
      color: statusEntry?.color || "N/A",
      name: statusEntry?.label,
    };
  });

  return (
    <Box
      sx={{
        width: "350px",
        maxWidth: 350,
        padding: 2,
        border: "1px solid #E0E0E0",
        borderRadius: 2,
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid container spacing={1}>
        {result.map((status, index) => {
          let leftOffset = 0;
          for (let i = 0; i < index; i++) { // eslint-disable-line
            leftOffset += result[i].percentage;
          }

          return (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}>
                {/* Status Label */}
                <Label12Regular 
                sx={{
                  minWidth: "133px",
                  flexShrink: 0,
                  color: "#000000", 
                  fontWeight: 500 }}>
                  {status.name}
                </Label12Regular>

                {/* Progress Bar */}
                <Box
                  sx={{
                    height: 14,
                    flexGrow: 1,
                    backgroundColor: "#EAEAEA",
                    borderRadius: 1,
                    position: "relative",
                    minWidth: "20px", // Ensures visibility at small screens
                    maxWidth: "90%"
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: status.color,
                      width: `${status.percentage}%`,
                      borderRadius: 1,
                      marginLeft: `${leftOffset}%`,
                      minWidth: "0px", // Ensures bar remains visible at small sizes
                    }}
                  />
                </Box>

                {/* Percentage */}
                <span style={{minWidth: "30px", color: "#000000", fontSize: "10px", fontWeight: 600, fontStyle: "Inter", textAlign: "left" }}>
                  {status.percentage}%
                </span>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ProgressBar;
