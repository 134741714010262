import { CellContext } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { AdaptedCollectorsMissingDocuments, MonthUsage } from "api";

import { DateWrapper } from "./components/DateWrapper";
import { UsageDisplay } from "./components/UsageDisplay";
import { getCellState } from "./helpers/getCellState";

import { AddBillModal } from "../../AddBillModal";

import { MISSING_DOCUMENTS_PAGE_QUERY_PARAMS } from "../../../consts";

export const MonthCell = ({
  cell,
}: CellContext<AdaptedCollectorsMissingDocuments, unknown>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isBillModalOpen, setIsBillModalOpen] = useState(false);

  const value = cell.getValue() as MonthUsage;
  const {
    enabled,
    disableDate,
    accountNumber,
    providerName,
    meterNumber,
    startDate,
    endDate,
    id,
  } = cell.row.original.collector;
  const [year, month] = cell.column.id.split("-").map(Number);
  const unit = cell.row.original.unit || "";

  const hasHistoricalData = Boolean(
    providerName || accountNumber || meterNumber,
  );
  const hasMeterOrAccountNumber = Boolean(accountNumber || meterNumber);
  const isCurrentCellSelected =
    searchParams.get(MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.COLLECTOR_ID) ===
    String(id) &&
    searchParams.get(MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.MONTH) ===
    cell.column.id;

  const start = dayjs.utc(startDate);
  const end = dayjs.utc(endDate);
  const disable = dayjs.utc(disableDate);
  const current = dayjs.utc(`${year}-${month}-01`);

  const isBeforeStart = current.isBefore(start, "month");
  const isAfterEnd = current.isAfter(end, "month");
  const isBeforeDisableDate = current.isBefore(disable, "month");
  const isAfterDisableDate = current.isAfter(disable, "month");

  const toggleMonthSelectedModal = () => {
    const queryParams = Object.fromEntries(searchParams.entries());
    if (isCurrentCellSelected) {
      delete queryParams[MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.COLLECTOR_ID];
      delete queryParams[MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.MONTH];
    } else {
      queryParams[MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.COLLECTOR_ID] =
        String(id);
      queryParams[MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.MONTH] = cell.column.id;
    }
    setSearchParams(createSearchParams(queryParams));
  };

  const openBillModal = () => setIsBillModalOpen(true);
  const closeBillModal = () => setIsBillModalOpen(false);

  const { color, showModal } = getCellState({
    value,
    enabled,
    isBeforeStart,
    isAfterEnd,
    isBeforeDisableDate,
    isAfterDisableDate,
    isCurrentCellSelected,
    hasHistoricalData,
    hasMeterOrAccountNumber,
  });

  const handleDateWrapperClick = () => {
    if (showModal && enabled) {
      openBillModal();
    } else if (value.usage !== null) {
      toggleMonthSelectedModal();
    }
  };

  return (
    <>
      <DateWrapper
        color={color}
        onClick={enabled || value.usage ? handleDateWrapperClick : undefined}
      >
        <UsageDisplay usage={value.usage} unit={unit} />
      </DateWrapper>
      {showModal && (
        <AddBillModal
          isOpen={isBillModalOpen}
          collectorId={id}
          onClose={closeBillModal}
        />
      )}
    </>
  );
};
