import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { useGetAllRenewableEnergyPurchases } from 'hooks/api/renewable-energy-purchases';
import { deletePurchase, RenewableEnergyPurchasesDTO, SITES_API_PATHS } from 'api';
import { EsgDeleteIcon, EsgRecUploaded, EsgSearchIcon, PlusIcon } from 'assets';
import { useUserRole } from 'recoils';
import { COLORS, DeleteDialog, EMPTY_CELL_VALUE, Loading } from 'components';
import dayjs from 'dayjs';
import { isNullOrUndefined } from 'utils';
import { useNotify } from 'hooks';

export const RenewableEnergyPurchases = () => {
  const userRole = useUserRole();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const [purchases, setPurchases] = useState<RenewableEnergyPurchasesDTO[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [deletePurchaseModal, setDeletePurchaseModal] = useState<boolean>(false);
  const [currentPurchaseId, setCurrentPurchaseId] = useState<number | null>(null);

  const { data: renewableEnergyPurchases, isLoading } = useGetAllRenewableEnergyPurchases();

  const { mutateAsync: deletePurchaseRequest } = useMutation(deletePurchase);

  const formatNumber = (value: number) => {
    if (Number.isNaN(value) || isNullOrUndefined(value)) return EMPTY_CELL_VALUE;
    return new Intl.NumberFormat('en-US').format(value);
  }

  const RENEWABLE_ENERGY_PURCHASES: MRT_ColumnDef<RenewableEnergyPurchasesDTO>[] = [
    {
      header: "Geography",
      accessorKey: "geography",
      Cell: ({ row }: { row: any }) => {
        const { geography } = row.original;
        return (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            height: "84px",
            padding: "12px 16px",
          }}>
            <p style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '14px',
            }}>
              {geography}
            </p>
          </Box>
        );
      },
      size: 120,
    },
    {
      header: "Total Renewable Energy Amount",
      accessorKey: "totalRenewableEnergy",
      Cell: ({ row }: { row: any }) => {
        const { totalRenewableEnergy, renewableEnergyUnit } = row.original;
        return (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            height: "84px",
            padding: "12px 16px",
          }}>
            <p style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '14px',
            }}>
              {formatNumber(totalRenewableEnergy)} {renewableEnergyUnit}
            </p>
          </Box>
        );
      },
      size: 240,
    },
    {
      header: "Start Date",
      accessorKey: "startDate",
      Cell: ({ row }: { row: any }) => {
        const { startDate } = row.original;
        return (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            height: "84px",
            padding: "12px 16px",
          }}>
            <p style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '14px',
            }}>
              {dayjs(startDate).format('MM/DD/YY')}
            </p>
          </Box>
        );
      },
      size: 120,
    },
    {
      header: "End Date",
      accessorKey: "endDate",
      Cell: ({ row }: { row: any }) => {
        const { endDate } = row.original;
        return (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            height: "84px",
            padding: "12px 16px",
          }}>
            <p style={{
              fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: '14px',
            }}>
              {dayjs(endDate).format('MM/DD/YY')}
            </p>
          </Box>
        );
      },
      size: 120,
    },
  ];

  const handleEditPurchase = (purchase: RenewableEnergyPurchasesDTO) => {
    navigate('/sites/renewable-energy-purchases-upload', { state: { purchase, isEdit: true } })
  }

  const handlerOpenPurchaseDeleteModal = async (purchase: RenewableEnergyPurchasesDTO) => {
    const { id } = purchase;
    setCurrentPurchaseId(id || null);
    setDeletePurchaseModal(true);
  };

  const handleDeletePurchase = async (id: number) => {
    await deletePurchaseRequest(id, {
      onError: () => {
        notify.error('Some error has happened while deleting the purchase!');
      },
      onSuccess: () => {
        queryClient.invalidateQueries([SITES_API_PATHS.RENEWABLE_ENERGY_PURCHASES]);
        notify.success('Purchase successfully deleted!');
        setDeletePurchaseModal(false);
      },
    });
  };

  useEffect(() => {
    setPurchases(renewableEnergyPurchases || []);
  }, [renewableEnergyPurchases])

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 2 }}>
          <Button
            sx={{ height: "36px", width: "240px", borderRadius: 2, marginTop: "10px" }}
            variant="contained"
            onClick={() => navigate('/sites/renewable-energy-purchases-upload', { state: { isEdit: false } })}
            startIcon={<PlusIcon />}
            disabled={userRole.isAuditor || userRole.isDataProvider}
          >
            Add Renewable Energy
          </Button>
        </Box>
        <Box
          display="flex"
          gap="2"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
          sx={{
            width: "240px",
            height: "36px",
            borderRadius: 2,
            border: "1px solid  black",
            marginTop: "7px"
          }}
        >
          <IconButton>
            <EsgSearchIcon />
          </IconButton>
          <TextField
            placeholder="Search sites..."
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              border: "none",
              "& input": {
                padding: 0,
                fontSize: "14px",
              },
              "& fieldset": {
                border: "none",
              }
            }}
          />
        </Box>
      </Box>
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={{
          position: "relative",
          width: "calc(100vw - 151px)",
          borderRadius: "10px",
          "& > div": {
            margin: 0,
            "& > div": {
              height: "calc(100vh - 305px)",
              maxHeight: "calc(100vh - 305px)",
              overflowY: "auto",
            },
          },
          "& table": {
            "& > thead": {
              position: "sticky",
              top: 0,
              zIndex: 2,
              "& > tr:first-of-type > th:first-of-type": {
                paddingLeft: "50px",
              },
            },
          },
        }}>
          <MaterialReactTable
            muiTableContainerProps={{
              sx: {
                border: '1px solid #C0D0D4',
                borderRadius: "10px",
                "& tbody > tr > td:first-of-type": {
                  maxWidth: "70px",
                }
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                color: '#054254',
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                maxHeight: "96px",
                backgroundColor: '#fff',
                padding: 0,
                cursor: "pointer",
                "& > div": {
                  maxHeight: "96px",
                },
              },
            }}
            muiTableBodyRowProps={({ row }: { row: any }) => ({ onClick: () => handleEditPurchase(row.original) })}
            columns={RENEWABLE_ENERGY_PURCHASES}
            data={purchases}
            enablePagination
            enableStickyHeader
            enableRowActions
            renderRowActions={({ row }) => (
              <Box sx={{
                display: "flex",
                alignItems: "center",
                height: "84px",
                padding: "12px 16px",
                paddingLeft: "48px",
                gap: "12px",
              }}>
                {row.original?.documentUploaded
                  ? <Tooltip arrow placement="top" title="REP Document">
                      <EsgRecUploaded color="#18AA00" />
                    </Tooltip>
                  : <Tooltip arrow placement="top" title="REP Document">
                      <EsgRecUploaded color={COLORS.neutralDarkGray} />
                    </Tooltip>
                }
                <Tooltip arrow placement="top" title="Delete">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      handlerOpenPurchaseDeleteModal(row.original)
                    }}
                  >
                    <EsgDeleteIcon color="red" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
        </Box>
      )}
      <DeleteDialog
        isOpen={deletePurchaseModal}
        onCancel={() => setDeletePurchaseModal(false)}
        onDeleteClick={() => handleDeletePurchase(currentPurchaseId!)}
        target="Delete Purchase"
        title="Delete Renewable Energy Purchase?"
      />
    </Box>
  );
}