import React, { useEffect, useState } from "react";
import { Box, Pagination, Stack } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { COLORS } from "components";
import { chartColors } from "../const";

const commonStyles = {
  color: "black",
  fontFamily: "Inter",
  fontWeight: "600",
}

export const RenewableNonRenewableChartYearOverYear = ({
  chartTitle = 'EmissionsChart',
  yoYData,
  mainTab,
}: {
  chartTitle?: string;
  yoYData?: any;
  mainTab?: string;
}) => {
  const { renewableVsNoRenewable } = yoYData;

  const [currentPage, setcurrentPage] = useState<number>(1);
  const paginationLength: number = 4;

  const isConsumption = mainTab === "Consumption";

  const data: any[] = renewableVsNoRenewable ? Object.values(renewableVsNoRenewable) : [];

  const renewableVsNoRenewableCategoriesXAxis = renewableVsNoRenewable ? Object.keys(renewableVsNoRenewable) : [];

  const emissionType = isConsumption ? "consumption" : "amount";

  const fiscalYearsData: any = data.reduce((acc: any, entry: any) => {
    entry.forEach((item: any) => {
      if (!acc[item.fiscalYear]) {
        acc[item.fiscalYear] = [];
      }
      acc[item.fiscalYear].push({
        [item.type]: item[emissionType] ?? 0,
      });
    });
    return acc;
  }, {});

  const series = Object.values(fiscalYearsData).map((entries: any) => {
    const emissionsArray = new Array(renewableVsNoRenewableCategoriesXAxis.length).fill(0);

    entries.forEach((entry: any) => {
      Object.keys(entry).forEach((key) => {
        const index = renewableVsNoRenewableCategoriesXAxis.indexOf(key);
        if (index !== -1) {
          emissionsArray[index] = entry[key] ?? 0;
        }
      });
    });

    return emissionsArray;
  });

  const seriesData = Object.keys(fiscalYearsData).sort().map((fiscalYear, index) => ({
    type: "column",
    name: `FY${fiscalYear}`,
    data: series[index],
    color: chartColors[index % chartColors.length],
    fillOpacity: 0.3,
    lineWidth: 6,
    marker: { enabled: false },
  }));

  const isPaginationDisabled: boolean = renewableVsNoRenewableCategoriesXAxis.length <= 4;
  const unitYAxis = data[0]?.[0]?.unit ?? data[0]?.[0]?.currency ?? "No Data Available";

  useEffect(() => {}, [renewableVsNoRenewable])

  const chartOptions = {
    chart: {
      spacing: [30, 50, 20, 40],
      width: null,
    },
    title: {
      text: chartTitle,
      align: "left",
      margin: 40,
      style: {
        ...commonStyles,
        color: COLORS.Mainblue,
        fontSize: "20px",
      },
    },
    xAxis: {
      categories: renewableVsNoRenewableCategoriesXAxis.length > 4 ? renewableVsNoRenewableCategoriesXAxis.slice((currentPage * paginationLength) - paginationLength, (currentPage * paginationLength)) : renewableVsNoRenewableCategoriesXAxis,
      labels: {
        style: commonStyles,
      },
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: {
        text: unitYAxis,
        style: commonStyles,
        offset: 50
      },
      labels: {
        style: commonStyles,
      },
    },
    series: seriesData.map((serie: any) => ({
      ...serie,
      data: serie.data.length > 4 ? serie.data.slice((currentPage * paginationLength) - paginationLength, (currentPage * paginationLength)) : serie.data,
    })),
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
      column: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
    },
    legend: {
      title: {
        style: commonStyles,
      },
      align: "left",
      verticalAlign: "bottom",
      x: 20,
      margin: 30,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      fontFamily: "Inter",
      position: "relative",
      "& > div": {
        borderRadius: 2,
      },
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
      {isPaginationDisabled
        ? ""
        : <Box sx={{
          position: "absolute",
          bottom: 22,
          right: 50,
        }}>
          <Stack spacing={2}>
            <Pagination
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "gray",
                },
                "& .Mui-selected": {
                  fontWeight: "600",
                  color: COLORS.Mainblue,
                }
              }}
              count={Math.trunc(renewableVsNoRenewableCategoriesXAxis.length / paginationLength) + 1}
              page={currentPage}
              size="small"
              onChange={(event: any, page: number) => setcurrentPage(page)} />
          </Stack>
        </Box>
      }
    </Box>
  );
};
