import { useCallback } from "react";

import { SiteDTO } from "api";

import { QUERY_PARAMS_KEYS } from "consts";

import { sortBy } from "utils";

import { useMultiselectWithFetch } from "./multiselect";
import { OptionalMultiselectProps } from "./types";

import { useSites } from "../api";

export const useSitesMultiselect = ({
  updateExternalStates,
  getQueryParamsWithExternalChanges,
  withQueryParams = true,
}: OptionalMultiselectProps = {}) => {
  const getOptionsFromData = useCallback((sitesData?: SiteDTO[]) => {
    const sitesAscendingOrder = sortBy({
      array: sitesData || [],
      key: "name",
    });

    return sitesAscendingOrder.map((site) => ({
      name: site.name || `Site: ${site.id}`,
      label: site.name || `Site: ${site.id}`,
      value: site.id,
    }));
  }, []);

  const {
    isLoading: isSitesLoading,
    setSelectedOptions: setSelectedSites,
    selectedOptions: selectedSites,
    options: sitesOptions,
    selectedOptionValues: siteIds,
  } = useMultiselectWithFetch({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    withQueryParams,
    useGetData: useSites,
    getOptionsFromData,
    queryKey: QUERY_PARAMS_KEYS.SITE_IDS,
  });

  return {
    isSitesLoading,
    setSelectedSites,
    sitesOptions,
    selectedSites,
    siteIds: siteIds as number[],
  };
};
