import { USER_ROLES_DROPDOWN, USER_ROLES_DROPDOWN_APPROVAL_FLOW, USER_ROLES_NAMES_DROPDOWN, USER_ROLES_NAMES_DROPDOWN_APPROVAL_FLOW } from "recoils";

export const ROLE_SELECT_OPTIONS = Object.values(USER_ROLES_DROPDOWN).map(
  (role) => USER_ROLES_NAMES_DROPDOWN[role],
);

export const ROLE_SELECT_OPTIONS_APPROVAL_FLOW = Object.values(USER_ROLES_DROPDOWN_APPROVAL_FLOW).map(
  (role) => USER_ROLES_NAMES_DROPDOWN_APPROVAL_FLOW[role],
);

export const COMMA_WHITE_SPACE_SEPARATED_EMAILS_REGEX =
  /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, {1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/;
