import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { updateVarianceFinancials, updateVarianceComparisonFinancials, updateDateRangeFinancials, updateYoYFinancials, updateSingleYearByScopeFinancials } from "api";
import { Box } from "@mui/material";
import { Tab } from "../../components";
import { DashboardTabs } from "../../../components/dashboardTabs";

const emissionsTabs = {
  INITIAL_TAB: "Overview",
};

export const Financials = ({
  children,
  tabs,
  fiscalYears,
  currentDate,
  defaultObject,
  setDefaultObject, // eslint-disable-line
  currentMainTab,
  currentActiveSearch,
  setCurrentActiveSearch,
}: {
  children?: React.ReactNode;
  currentMainTab: string;
  tabs: string[];
  fiscalYears: string[];
  currentDate: Dayjs;
  defaultObject: any;
  setDefaultObject: React.Dispatch<React.SetStateAction<any>>;
  currentActiveSearch: boolean;
  setCurrentActiveSearch: React.Dispatch<React.SetStateAction<boolean>>;

}) => {
  const { INITIAL_TAB } = emissionsTabs;

  const [varianceStartDate, setVarianceStartDate] = useState<Dayjs>(dayjs(currentDate.date(1)).subtract(2, "month"));
  const [varianceEndDate, setVarianceEndDate] = useState<Dayjs>(dayjs(currentDate));
  const [dateRangeStartDate, setDateRangeStartDate] = useState<Dayjs>(dayjs(currentDate.date(1)).subtract(2, "month"));
  const [dateRangeEndDate, setDateRangeEndDate] = useState<Dayjs>(dayjs(currentDate));

  const [varianceData, setVarianceData] = useState<any>([]);
  const [varianceComparisonData, setVarianceComparisonData] = useState<any>([]); // eslint-disable-line
  const [comparisonPeriod, setComparisonPeriod] = useState<string>("mom"); // eslint-disable-line
  const [dateRangeData, setDateRangeData] = useState<any>([]);
  const [dateRangeType, setDateRangeType] = useState<string>("MONTHLY");
  const [yoYData, setYoYData] = useState<any>([]);
  const [yoYLength, setYoYLength] = useState<number>(1);
  const [currentFiscalYear, setCurrentFiscalYear] = useState<string>(`${new Date().getFullYear()}`);
  const [singleYearByScopeData, setSingleYearByScope] = useState<any>([]);

  const yoYMaxLength: number = 10;

  const { mutateAsync: updateVarianceFinancialsRequest /* , isLoading: loadingVariance */ } = useMutation(updateVarianceFinancials);
  const { mutateAsync: updateVarianceComparisonFinancialsRequest /* , isLoading: loadingComparison */ } = useMutation(updateVarianceComparisonFinancials);
  const { mutateAsync: updateDateRangeFinancialsRequest /* , isLoading: loadingDateRange */ } = useMutation(updateDateRangeFinancials);
  const { mutateAsync: updateYoYFinancialsRequest /* , isLoading: loadingDateRange */ } = useMutation(updateYoYFinancials);
  const { mutateAsync: updateSingleYearByScopeFinancialsRequest /* , isLoading: loadingDateRange */ } = useMutation(updateSingleYearByScopeFinancials);

  // ---- VARIANCE --------------------------------
  const handleVarianceDateChange = async (startDate: Dayjs, endDate: Dayjs, utilityTypeId: number | undefined = undefined) => {
    await updateVarianceFinancialsRequest({
      ...defaultObject,
      utilityTypeIds: utilityTypeId ? [utilityTypeId] : defaultObject.utilityTypeIds,
      compareFromDate: startDate.toISOString().substring(0, 10),
      compareToDate: endDate.toISOString().substring(0, 10),
    }, {
      onSuccess: (response: any) => {
        setVarianceData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })

    await updateVarianceComparisonFinancialsRequest({
      ...defaultObject,
      utilityTypeIds: utilityTypeId ? [utilityTypeId] : defaultObject.utilityTypeIds,
      compareFromDate: startDate.toISOString().substring(0, 10),
      compareToDate: endDate.toISOString().substring(0, 10),
      comparisonPeriod,
    }, {
      onSuccess: (response: any) => {
        setVarianceComparisonData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  const handleUpdateComparisonPeriod = async (utilityTypeId: number | undefined = undefined) => {
    await updateVarianceComparisonFinancialsRequest({
      ...defaultObject,
      utilityTypeIds: utilityTypeId ? [utilityTypeId] : defaultObject.utilityTypeIds,
      compareFromDate: varianceStartDate.toISOString().substring(0, 10),
      compareToDate: varianceEndDate.toISOString().substring(0, 10),
      comparisonPeriod,
    }, {
      onSuccess: (response: any) => {
        setVarianceComparisonData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  // ---- DATE RANGE ------------------------------
  const handleDateRangeChange = async (startDate: Dayjs, endDate: Dayjs, utilityTypeId: number | undefined = undefined) => {
    await updateDateRangeFinancialsRequest({
      ...defaultObject,
      utilityTypeIds: utilityTypeId ? [utilityTypeId] : defaultObject.utilityTypeIds,
      compareFromDate: startDate.toISOString().substring(0, 10),
      compareToDate: endDate.toISOString().substring(0, 10),
      groupBy: dateRangeType,
    }, {
      onSuccess: (response: any) => {
        setDateRangeData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  // ---- YEAR OVER YEAR --------------------------
  const handleYoYChange = async (utilityTypeId: number | undefined = undefined) => {
    await updateYoYFinancialsRequest({
      ...defaultObject,
      utilityTypeIds: utilityTypeId ? [utilityTypeId] : defaultObject.utilityTypeIds,
      yearsToCompare: yoYLength,
    }, {
      onSuccess: (response: any) => {
        setYoYData(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  // ---- SINGLE YEAR BY SCOPE --------------------
  const handleSingleYearByScopeChange = async (utilityTypeId: number | undefined = undefined) => {
    await updateSingleYearByScopeFinancialsRequest({
      ...defaultObject,
      utilityTypeIds: utilityTypeId ? [utilityTypeId] : defaultObject.utilityTypeIds,
      fiscalYear: currentFiscalYear,
    }, {
      onSuccess: (response: any) => {
        setSingleYearByScope(response.data);
      },
      onError: () => {
        // implement error handling
      },
    })
  };

  // ---- ACTIVE SEARCH BUTTON --------------------
  const handleActiveSearchButton = async () => {
    await handleVarianceDateChange(varianceStartDate, varianceEndDate);
    await handleUpdateComparisonPeriod();
    await handleDateRangeChange(dateRangeStartDate, dateRangeEndDate);
    await handleYoYChange();
    await handleSingleYearByScopeChange();
    setCurrentActiveSearch(false);
  };

  useEffect(() => {
    handleVarianceDateChange(varianceStartDate, varianceEndDate);
  }, [varianceStartDate, varianceEndDate]); // eslint-disable-line

  useEffect(() => {
    handleUpdateComparisonPeriod()
  }, [comparisonPeriod]) // eslint-disable-line

  useEffect(() => {
    handleDateRangeChange(dateRangeStartDate, dateRangeEndDate);
  }, [dateRangeStartDate, dateRangeEndDate, dateRangeType]); // eslint-disable-line

  useEffect(() => {
    handleYoYChange();
  }, [yoYLength]); // eslint-disable-line

  useEffect(() => {
    handleSingleYearByScopeChange();
  }, [currentFiscalYear]); // eslint-disable-line

  useEffect(() => {
    handleActiveSearchButton();
  }, [currentActiveSearch]); // eslint-disable-line

  const allTabs = {
    [INITIAL_TAB]: [...tabs.map((tab: any) => ({
      label: tab,
      content: <Tab
        currentMainTab={currentMainTab}
        subTypeChart={tab.toLowerCase()}
        setDefaultObject={setDefaultObject}
        varianceStartDate={varianceStartDate}
        varianceEndDate={varianceEndDate}
        setVarianceStartDate={setVarianceStartDate}
        setVarianceEndDate={setVarianceEndDate}
        varianceData={varianceData}
        varianceComparisonData={varianceComparisonData}
        setComparisonPeriod={setComparisonPeriod}
        dateRangeType={dateRangeType}
        setDateRangeType={setDateRangeType}
        dateRangeStartDate={dateRangeStartDate}
        dateRangeEndDate={dateRangeEndDate}
        setDateRangeStartDate={setDateRangeStartDate}
        setDateRangeEndDate={setDateRangeEndDate}
        dateRangeData={dateRangeData}
        yoYMaxLength={yoYMaxLength}
        setYoYLength={setYoYLength}
        yoYData={yoYData}
        currentFiscalYear={currentFiscalYear}
        setCurrentFiscalYear={setCurrentFiscalYear}
        allFiscalYears={fiscalYears}
        singleYearByScopeData={singleYearByScopeData}
        handleVarianceDateChange={handleVarianceDateChange}
        handleUpdateComparisonPeriod={handleUpdateComparisonPeriod}
        handleDateRangeChange={handleDateRangeChange}
        handleYoYChange={handleYoYChange}
        handleSingleYearByScopeChange={handleSingleYearByScopeChange}
      >
        {children}
      </Tab>
    }))]
  }

  return (
    <Box sx={{ my: 2 }}>
      <DashboardTabs intensity tabs={allTabs[INITIAL_TAB]} selectedBtnOption={emissionsTabs.INITIAL_TAB} textColor="#99CD71" dashboard />
    </Box>
  )
};
