import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { COLORS } from "components";
import { chartColors } from "../const";

const commonStyles = {
  color: "black",
  fontFamily: "Inter",
  fontWeight: "600",
}

export const CombinedChart = ({
  chartTitle = 'EmissionsChart',
  varianceData,
  setPeriod,
  type,
}: {
  setPeriod: (period: string) => void;
  chartTitle?: string;
  varianceData?: any;
  type?: string;
}) => {
  const { total } = varianceData;

  const isEmissionsChart = type === "location" || type === "market";

  const currentData: any[] = total ? Object.values(total).flat() : [];

  const totalCategories = currentData.map((entry: any) => entry.month + entry.year.replace("20", " '"));

  const currentPercentageVarianceAmount = currentData.map((entry: any) => isEmissionsChart ? entry[`${type}BasedEmission`] : entry.consumption ?? entry.amount).map(amount => amount === null ? 0 : amount);
  const currentPercentageVariance = currentData.map((entry: any) => isEmissionsChart ? entry[`${type}BasedEmissionVariance`] : entry.consumptionVariance ?? entry.amountVariance).map(variance => variance === null ? 0 : variance);

  const varianceOptions = [
    "Same Period Last Month",
    "Same Period Last Year",
  ];

  const [variancePeriodSelected, setVariancePeriodSelected] = useState<string>("Same Period Last Month");

  const handleChangePeriod = (period: string) => {
    setVariancePeriodSelected(period);
    if (period === "Same Period Last Month") setPeriod("mom");
    else setPeriod("yoy");
  };

  const unitYAxis = isEmissionsChart ? "tCO2e" : currentData[0]?.unit ?? currentData[0]?.currency ?? "No Data Available";

  useEffect(() => {}, [total])

  const chartOptions = {
    chart: {
      spacing: [30, 50, 20, 40],
      width: null,
    },
    title: {
      text: chartTitle,
      align: "left",
      margin: 40,
      style: {
        ...commonStyles,
        color: COLORS.Mainblue,
        fontSize: "20px",
      },
    },
    xAxis: {
      categories: totalCategories,
      labels: {
        style: commonStyles,
      },
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: {
        text: unitYAxis,
        style: {
          ...commonStyles,
        },
        offset: 50
      },
      labels: {
        style: commonStyles,
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          return `${this!.value} %`;
        }
      },
    },
    series: [
      {
        type: "column",
        name: "Variance %",
        data: currentPercentageVariance,
        color: chartColors[0],
        fillOpacity: 0.3,
        marker: { enabled: false },
      },
      {
        name: "Variance Amount",
        data: currentPercentageVarianceAmount.map(value => value * -1),
        color: chartColors[1],
        fillOpacity: 0.3,
        lineWidth: 2,
        marker: { enabled: true, symbol: "circle" },
      }
    ],
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')} %</b>`;
          }
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US').replace("-", "")}</b>`;
          },
          y: 100,
        },
      },
    },
    legend: {
      title: {
        style: commonStyles,
      },
      align: "left",
      verticalAlign: "bottom",
      x: 20,
      margin: 30,
    },
    tooltip: {
      enabled: false,
    },
    tickPositions: [-100, -50, 0, 50, 100],
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      fontFamily: "Inter",
      position: "relative",
      "& > div:first-of-type": {
        borderRadius: 2,
      },
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
      <Box sx={{
        width: 240,
        position: "absolute",
        top: 22,
        right: 50,
      }}>
        <FormControl sx={{ width: "100%" }}>
          <Select
            displayEmpty
            sx={{ height: "36px", borderRadius: "8px", color: COLORS.Mainblue }}
            value={variancePeriodSelected}
            onChange={(event) => handleChangePeriod(event.target.value as string)}
          >
            {varianceOptions.map((country: string) => (
              <MenuItem key={country} value={country} sx={{ color: COLORS.Mainblue, fontFamily: "Inter", fontWeight: 500, fontSize: "14px" }}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
