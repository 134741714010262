import { Box, Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { createUser } from "api";

import {
  AutocompleteField,
  COLORS,
  InputFieldWrapper,
  Label14Medium,
  MultiSelectControlledOption,
  TextField,
} from "components";

import { useGetConfigParameter, useNotify, useSitesMultiselect } from "hooks";
import { EsgMultiselectControlledField } from "components/inputs/esg-multi-select-field";

import {
  PROMISE_STATE,
  QUERY_KEYS,
  REQUIRED_VALIDATION_MESSAGE,
  STATUS_CODES,
} from "consts";

import {
  USER_ROLES_NAMES,
} from "recoils";
import { SendInviteIcon } from "assets";

import {
  COMMA_WHITE_SPACE_SEPARATED_EMAILS_REGEX,
  ROLE_SELECT_OPTIONS,
  ROLE_SELECT_OPTIONS_APPROVAL_FLOW,
} from "./consts";

interface InviteUsersFormValues {
  emails: string;
  role: string;
  sites: MultiSelectControlledOption[];
  firstName?: string;
  lastName?: string;
}

const DEFAULT_VALUES = {
  emails: "",
  role: ROLE_SELECT_OPTIONS[0],
  sites: [],
  firstName: "",
  lastName: "",
};

export const InviteUsersForm: FunctionComponent = () => {
  const {
    register,
    unregister,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InviteUsersFormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const queryClient = useQueryClient();
  const { data: configData } = useGetConfigParameter();
  
  const approvalFlowValue = configData?.find((t) => t.name === "second_layer_approval_feature")
  const isApprovalFlowActive = approvalFlowValue?.configValue

  const { mutateAsync: createUsersRequest } = useMutation(createUser);
  const notify = useNotify();
  const watchRole = watch("role");
  const isSitesAllowed = watchRole === USER_ROLES_NAMES.DATA_PROVIDER
    || USER_ROLES_NAMES.MODULE_ADMIN
    || USER_ROLES_NAMES.DATA_CONTROLLER
    || USER_ROLES_NAMES.AUDITOR
    || USER_ROLES_NAMES.MODULE_ADMIN;
  const { isSitesLoading, selectedSites, sitesOptions, setSelectedSites } = useSitesMultiselect({
    withQueryParams: false,
  });

  useEffect(() => {
    register("sites");

    return () => {
      unregister("sites");
    };
  }, [register, unregister, isSitesAllowed]);

  const onSubmit = (data: InviteUsersFormValues) => {
    const inviteUsersRequests = data.emails.split(",").map((email) => {
      const trimmedEmail = email.trim();

      return createUsersRequest({
        email: trimmedEmail,
        firstName: data.firstName ? data.firstName : null,
        lastName: data.lastName ? data.lastName : null,
        oktaIdentifier: trimmedEmail,
        siteIds: isSitesAllowed
          ? selectedSites.map((site) => site.value)
          : [],
        roles: [(data.role).replace(" ", "_")]
      });
    });

    Promise.allSettled(inviteUsersRequests)
      .then((results) => {
        results.forEach((result) => {
          if (result.status === PROMISE_STATE.rejected) {
            if (
              result.reason.response.request.status === STATUS_CODES.CONFLICT
            ) {
              notify.error(
                `User already invited ${JSON.parse(result.reason.config.data)?.email
                }!`,
              );
            } else {
              notify.error(
                `Some error has happen while sending invitation ${JSON.parse(result.reason.config.data)?.email
                }!`,
              );
            }
          } else {
            notify.success(
              `Invitation successfully sent ${result.value.data?.email}!`,
            );
          }
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      });
  };



  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        gap: 1,
        px: "24px"
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <InputFieldWrapper label="First Name">
          <TextField
            sx={{
              paddingRight: 0,
              width: "240px",
              height: "36px",
              borderColor: COLORS.Romance,
              "& .MuiInputBase-input": {
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                color: COLORS.Romance,
              },
              "& .MuiOutlinedInput-root": {
                color: COLORS.Romance,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "red",
                },
              },
            }}
            placeholder="First Name"
            {...register("firstName", {
              required: REQUIRED_VALIDATION_MESSAGE,
            })}
            error={!!errors?.firstName}
            helperText={errors?.firstName?.message}
          />
        </InputFieldWrapper>
      </Box>
      <Box>
        <InputFieldWrapper label="Last Name">
          <TextField
            sx={{
              paddingRight: 0,
              width: "240px",
              height: "36px",
              borderColor: COLORS.Romance,
              "& .MuiInputBase-input": {
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                color: COLORS.Romance,
              },
              "& .MuiOutlinedInput-root": {
                color: COLORS.Romance,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "red",
                },
              },
            }}
            placeholder="Last Name"
            {...register("lastName", {
              required: REQUIRED_VALIDATION_MESSAGE,
            })}
            message={errors?.lastName?.message}
            color={errors?.lastName?.message ? "error" : undefined}
          />
        </InputFieldWrapper>
      </Box>
      <Box>
        <InputFieldWrapper label="Emails (Separate by comma)">
          <TextField
            sx={{
              paddingRight: 0,
              width: "240px",
              height: "36px",
              borderColor: COLORS.Romance,
              "& .MuiInputBase-input": {
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                color: COLORS.Romance,
              },
              "& .MuiOutlinedInput-root": {
                color: COLORS.Romance,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLORS.Romance,
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "red",
                },
              },
            }}
            placeholder="Email"
            {...register("emails", {
              required: REQUIRED_VALIDATION_MESSAGE,
              pattern: {
                value: COMMA_WHITE_SPACE_SEPARATED_EMAILS_REGEX,
                message: "Invalid email address",
              },
            })}
            message={errors?.emails?.message}
            color={errors?.emails?.message ? "error" : undefined}
          />
        </InputFieldWrapper>
      </Box>

      <Box sx={{ paddingLeft: 0, width: "240px" }}>
        <InputFieldWrapper label="Reporter Title">
          <Controller
            name="role"
            control={control}
            rules={{ required: REQUIRED_VALIDATION_MESSAGE }}
            render={({ field }) => (
              <AutocompleteField
                textFieldProps={{
                  message: errors?.role?.message,
                  color: errors?.role?.message ? "error" : undefined,
                }}
                autocompleteProps={{
                  ...field,
                  onChange: (e, data) => {
                    field.onChange(data);
                  },
                  options: isApprovalFlowActive === "true" ? ROLE_SELECT_OPTIONS_APPROVAL_FLOW : ROLE_SELECT_OPTIONS,
                }}
              />
            )}
          />
        </InputFieldWrapper>
      </Box>

      {isSitesAllowed && (
        <Box sx={{ paddingLeft: 0, width: "240px" }}>
          <EsgMultiselectControlledField
            label="Sites"
            disabled={isSitesLoading}
            selectedValues={selectedSites}
            setSelectedValues={setSelectedSites}
            options={sitesOptions}
            isAllSelectedOnEmptySelection
          />
        </Box>
      )}

      <Button
        sx={{ height: "36px", marginLeft: "3px", borderRadius: "8px", fontSize: "14px", fontFamily: "Inter", mt: "21px", width: "160px" }}
        type="submit"
        variant="contained"
        startIcon={<SendInviteIcon />}>
        <Label14Medium>Send invite</Label14Medium>
      </Button>
    </Box>
  );
};
