import { Button, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { deleteDocument, downloadDocuments } from "api";

import { EsgDeleteIcon, EsgDownloadIcon } from "assets";

import { DeleteDialog, useDialogControls } from "components";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { useSetDefaultUploadedDocumentsSelection, useUserRole } from "recoils";

import { downloadFile } from "utils";

export const EsgActions: React.FunctionComponent<{
    rowSelection: any;
    setrowSelection: any;
}> = ({ rowSelection, setrowSelection }) => {
    const notify = useNotify();
    const userRole = useUserRole();
    const queryClient = useQueryClient();
    const setDefaultSelection = useSetDefaultUploadedDocumentsSelection();
    const deleteDialogControls: any = useDialogControls();
    const { mutateAsync: deleteUploadedDocument } = useMutation(deleteDocument);
    // const selected = useSelectedUploadedDocuments();

    const selected: any[] = [];

    selected.push(...Object.keys(rowSelection));

    const onDeleteClick = () => {
        Promise.allSettled(
            selected.map((id) =>
                deleteUploadedDocument(id, {
                    onError: () =>
                        notify.error(
                            `Some error has happen while deleting documents: ${id}!`,
                        ),
                    onSuccess: () => {
                        notify.success("Selected documents were deleted!");
                        setrowSelection();
                    },
                }),
            ),
        ).finally(() => {
            deleteDialogControls.close();
            setDefaultSelection();
            queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS]);
        });
    };

    const onDownloadClick = () => {
        downloadFile(() => downloadDocuments(selected), "documents.zip")
            .then(() => {
                notify.success("Selected documents were successfully downloaded!");
            })
            .catch(() => {
                notify.error("Failed to download selected documents!");
            });
    };

    return (
        <Stack direction="row" spacing={2} alignItems="center">

            <Button
                startIcon={<EsgDownloadIcon />}
                variant="contained"
                sx={{ borderRadius: "8px", width: '140px' }}
                disabled={!selected?.length}
                onClick={onDownloadClick}
            >
                Download
            </Button>
            <Button
                startIcon={<EsgDeleteIcon color="white" />}
                variant="contained"
                sx={{ borderRadius: "8px", width: '140px' }}
                disabled={!selected?.length || userRole.isAuditor}
                onClick={deleteDialogControls.open}
                color="error"
            >
                Delete
            </Button>



            <DeleteDialog
                isOpen={deleteDialogControls.isOpen}
                onCancel={deleteDialogControls.close}
                onDeleteClick={onDeleteClick}
                selectedCount={selected.length}
                target="document"
                title="Delete documents?"
            />
        </Stack>
    );
};
