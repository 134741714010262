import { Box } from "@mui/material";
import { Row } from "@tanstack/react-table";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DocumentDTO } from "api";

import {
  Body1SemiBold,
  CloseButton,
  DrawerView,
  Loading,
  PdfFooter,
  PdfPreview,
  Tabs,
} from "components";

import { useDocumentById } from "hooks";

import { getFromLocalStorage } from "utils";

// eslint-disable-next-line
import { AddDocumentBillFormView } from "./add-document-bill-form-view";
import { ADD_DOCUMENT_BILL_PAGE } from "./consts";

// eslint-disable-next-line
import { DocumentExpandedRow } from "../components";

import { UTILITY_BILL_ALL_DATAENTRIES_VIEW } from "../../document-summary-v2/utility-bill/utility-bill-allDataEntries-view/consts";

export const AddDocumentBillPage: React.FunctionComponent = () => {
  const { documentId } = useParams<{ [ADD_DOCUMENT_BILL_PAGE.KEY]: string }>();
  const navigate = useNavigate();

  const { data: document, isLoading } = useDocumentById(documentId!);
  const [isOpen, setIsOpen] = useState(true);
  const [scale, setScale] = useState(0.75);
  const [scaleChange, setScaleChange] = useState(false);

  const rowData: Row<DocumentDTO> = getFromLocalStorage("dataEntries")
    ? JSON.parse(getFromLocalStorage("dataEntries") || "")
    : [];

  if (scale <= 0.25) {
    setScale(0.75);
  }

  const documentbillId = getFromLocalStorage("documentIdForBills") || "";

  const onClose = useCallback(async () => {
    const rowDataIsEmpty = !rowData || Object.keys(rowData).length === 0;
    const idToNavigate = rowDataIsEmpty
      ? documentbillId
      : rowData?.original?.bills?.length !== 0
        ? rowData?.original?.bills![0]?.id
        : documentbillId;


    if (idToNavigate) {
      navigate(UTILITY_BILL_ALL_DATAENTRIES_VIEW.ABSOLUTE_PATH(idToNavigate));
      setIsOpen(false);
    } else {
      navigate(-1)
      setIsOpen(false);
    }
    window.localStorage.setItem("addEntry", "false");
    window.localStorage.removeItem("utilityBillFormData");
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <DrawerView isOpen={isOpen} onClose={onClose}>
      {isLoading ? (
        <Loading />
      ) : (
        document && (
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                p: 3,
                flexGrow: 1,
                flexDirection: "row",
                overflow: "auto",
              }}
            >
              <PdfPreview
                downloadUrl={document.downloadUrl}
                scale={scale}
                isScaleChange={scaleChange}
              />
              <Box
                sx={{
                  ml: 4,
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Body1SemiBold>
                    Add bill to document: {document.fileName}
                  </Body1SemiBold>

                  <CloseButton onClick={onClose} variant="outlined" />
                </Box>

                <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                  <Tabs
                    activeTab={1}
                    tabs={[
                      {
                        label: "ALL DATA ENTRIES",
                        content: (
                          <Box>
                            <DocumentExpandedRow row={rowData} />
                          </Box>
                        ),
                      },
                      {
                        label: `ADD DOCUMENT BILL`,
                        content: (
                          <Box>
                            <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                              <AddDocumentBillFormView
                                document={document}
                                onClose={onClose}
                              />
                            </Box>
                          </Box>
                        ),
                      },
                    ]}
                  />
                </Box>
              </Box>
            </Box>

            <PdfFooter
              fileName={document.fileName}
              setScale={setScale}
              downloadUrl={document.downloadUrl as string}
              actionsDisabled={isLoading}
              withPagination={false}
              setScaleChange={setScaleChange}
            />
          </Box>
        )
      )}
    </DrawerView>
  );
};
