import React from "react";

import { AuditLogIcon } from "assets";

export const APPROVALS = {
  ABSOLUTE_PATH: "/approvals",
  TITLE: "Approvals",
  ICON: <AuditLogIcon />,
  IS_TBD: false,
  renderMenu: false,
};
