import React, { useRef } from "react";
import { Box, Button } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNotify } from "hooks";

export const PurchasePdfEmptyData: React.FunctionComponent<{
  documentId: number | null,
  fileName?: any;
}> = ({ fileName, documentId }) => {
  const fileInputRef: any = useRef(null);
  const notify = useNotify();

  const handleFileChange = (e: any) => {
    const files = e.target.files[0];
    fileName(files)
  };


  const handleIconClick = () => {
    if (fileInputRef.current && documentId !== null) {
      fileInputRef.current.click();
    } else {
      notify.error("You have to add data to purchase and save it before add the document");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input
        ref={fileInputRef}
        hidden
        accept="application/pdf"
        type="file"
        onChange={handleFileChange}
      />
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        top: 20,
        cursor: "pointer",
      }}
      >
        <Button
          onClick={handleIconClick}
          style={{ all: 'unset', display: "flex", flexDirection: "column", }}
        >
          <CloudUploadIcon sx={{ marginLeft: "25px", fontSize: 60 }} color="disabled" />
          <span>Click To Upload</span>
        </Button>
      </Box>
    </Box>
  );
};

