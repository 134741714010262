/* eslint-disable */

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC, useEffect, useMemo, useState } from "react";

import { createSite, getRegion, updateSite } from "api";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { COLORS, InputFieldWrapper, TYPOGRAPHY_COMMON_STYLES } from "components";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getDateFormatset } from "utils";
import { AllowedFormValues } from "../types";

interface AddSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues: AllowedFormValues;
  editSite: any;
  clickType: string,
  editNotes: boolean,
}

export const AddSiteModal: FC<AddSiteModalProps> = ({
  isOpen,
  onClose,
  allowedValues,
  editSite,
  clickType,
  editNotes
}) => {
  const { countries, siteStatuses, siteTypes, countryRegions } = //eslint-disable-line
    allowedValues;
  const sortedCountries = countryRegions.sort((a, b) => a.localeCompare(b));
  const CountriesAccending = countries?.sort() || [];
  const siteTypesAccending = siteTypes?.sort() || [];

  const notify = useNotify();
  const queryClient = useQueryClient();

  const defaultValues: any = useMemo(() => {
    const getFormattedDate = (date: Date) => date.toISOString().split('T')[0] + 'T00:00:00Z'; //eslint-disable-line
    
    // Determine the endDate based on clickType
    const endDate = clickType === "Enable"
      ? "2999-12-31T00:00:00Z"
      : clickType === ""
        ? editSite?.endDate || null
        : getFormattedDate(new Date());

    return {
      name: editSite?.name || null,
      address: editSite?.address || null,
      city: editSite?.city || null,
      zipCode: editSite?.zipCode || null,
      type: editSite?.type || null,
      status: editSite?.status || null,
      country: editSite?.country || null,
      countryRegion: editSite?.countryRegion || null,
      region: editSite?.region || null,
      customerSiteId: editSite?.customerSiteId || null,
      businessUnit: editSite?.businessUnit || null,
      startDate: editSite?.startDate || null,
      endDate, // Use the determined endDate here
      notes: editSite?.notes
    };
  }, [editSite, clickType]);

  const { control, reset, getValues, watch } = useForm<any>({
    mode: "onChange",
    defaultValues,
  });

  // Value of Country and zipCode
  const [watchedCountryValue, watchedZipValue] = watch(['country', 'zipCode']);

  const [formValues, setFormValues] = useState<any>({});

  useEffect(() => {
    reset(defaultValues);
    setFormValues(editSite)
  }, [reset, defaultValues, editSite]);

  const { mutateAsync: createSiteRequest, isLoading } = useMutation(createSite);
  const { mutateAsync: updateSiteRequest, isLoading: updateLoading } = useMutation(updateSite);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (watchedCountryValue !== undefined) {
        getRegionCode(watchedZipValue, watchedCountryValue);
      }
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedCountryValue, watchedZipValue, editSite])

  async function fetchData(dataset: any) {
    const dataResponse = await getRegion(dataset);
    const regionValue = dataResponse;
    setFormValues((prevValues: any) => ({
      ...prevValues,
      ["region" as string]: regionValue,
    }));
  }

  const getRegionCode = (zipCode: any, country: any) => {
    if (country !== null) {
      const dataset = { zipCode: zipCode !== undefined ? zipCode : null, country }
      fetchData(dataset);
    }
  };

  const handleAddSite = async () => {
    await createSiteRequest(getValues(), {
      onError: () => notify.error(`Failed to create site: ${getValues().name}!`),
      onSuccess: () => {
        notify.success(`Site successfully created: ${getValues().name}!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
      },
    });
    reset(defaultValues);
    onClose();
  };

  const handleEditSite = async () => {
    const { id } = editSite;
    if (formValues.region === "MULTIPLE REGIONS FOUND") {
      notify.error("Please make sure to select an Electricity Region Code to calculate your emissions.");
    } else {

      const updatedSite = {
        ...formValues,
        id,
        name: getValues().name === "" ? null : getValues().name,
        address: getValues().address === "" ? null : getValues().address,
        city: getValues().city === "" ? null : getValues().city,
        zipCode: getValues().zipCode === "" ? null : getValues().zipCode,
        country: getValues().country === "" ? null : getValues().country,
        type: getValues().type === "" ? null : getValues().type,
        status: getValues().status === "" ? null : getValues().status,
        countryRegion: getValues().countryRegion === "" ? null : getValues().countryRegion,
        region: getValues().region === "" ? null : getValues().region,
        customerSiteId: getValues()?.customerSiteId === "" ? null : getValues()?.customerSiteId,
        businessUnit: getValues()?.businessUnit === "" ? null : getValues()?.businessUnit,
        startDate: getValues()?.startDate === "" ? null : getValues()?.startDate,
        endDate: getValues()?.endDate === "" ? null : getValues()?.endDate,
        notes: getValues()?.notes === "" ? null : getValues()?.notes
    };

      await updateSiteRequest(updatedSite, {
        onError: () => notify.error(`Failed to edit site: ${formValues.name}!`),
        onSuccess: () => {
          notify.success(`Site successfully edited: ${formValues.name}!`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        },
      });
      setFormValues({});
      onClose();
    }
  };

  const handleCancel = () => {
    if (editSite) {
      onClose();
    } else {
      onClose();
      setFormValues({});
      reset(defaultValues);
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{editNotes ? "Site Notes" : editSite ? "Edit Site" : "Add New Site"}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
              marginTop: "7px",
            }}
          >
            {editNotes
              ? <Grid item xs={6}>
                  <InputFieldWrapper label="Notes">
                    <Controller
                      name="notes"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          sx={{ width: "100%" }}
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          value={value}
                          multiline
                          rows={3}
                        />
                      )}
                    />
                  </InputFieldWrapper>
                </Grid>
              : <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Site Name">
                      <Controller
                        name="name"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            helperText={error ? error.message : null}
                            size="small"
                            error={!!error}
                            onChange={onChange}
                            value={value}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Customer Site ID">
                      <Controller
                        name="customerSiteId"
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            helperText={error ? error.message : null}
                            size="small"
                            error={!!error}
                            onChange={onChange}
                            value={value}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Business Unit">
                      <Controller
                        name="businessUnit"
                        control={control}
                        render={({
                          fieldState: { error },
                          field: { onChange, value },
                        }) => (
                          <TextField
                            helperText={error ? error.message : null}
                            size="small"
                            error={!!error}
                            onChange={onChange}
                            value={value}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Street Address">
                      <Controller
                        name="address"
                        control={control}
                        render={({
                          fieldState: { error },
                          field: { onChange, value },
                        }) => (
                          <TextField
                            helperText={error ? error.message : null}
                            size="small"
                            error={!!error}
                            onChange={onChange}
                            value={value}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </InputFieldWrapper>
                  </Grid>
                  {/* Start Date */}
                  <Grid item xs={6}>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                        >
                          <InputFieldWrapper label="Start Date">
                            <DatePicker
                              slotProps={{
                                textField: {
                                  size: "small",
                                  sx: {
                                    width: "100%",
                                  }
                                },
                              }}
                              value={
                                field?.value
                                  ? dayjs(field?.value).utc()
                                  : null
                              }
                              onChange={(date) => field.onChange(dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]'))}
                              format={getDateFormatset()}
                            />
                          </InputFieldWrapper>
                        </LocalizationProvider>
                      )
                      }
                    />
                  </Grid>

                  {/* End Date */}
                  <Grid item xs={6}>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                        >
                          <InputFieldWrapper label="End Date">
                            <DatePicker
                              slotProps={{
                                textField: {
                                  size: "small",
                                  sx: {
                                    width: "100%",
                                    "& fieldset": {
                                      border: clickType === "Disable" ? "1.2px solid red" : "",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&:hover fieldset": {
                                        borderColor: clickType === "Disable" ? "red" : "", // remove hover effect if needed
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: clickType === "Disable" ? "red" : "", // remove focus effect if needed
                                      },
                                    },
                                  }
                                },
                              }}
                              value={
                                field?.value
                                  ? dayjs(field?.value).utc()
                                  : null
                              }
                              onChange={(date) => field.onChange(dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]'))}
                              format={getDateFormatset()}
                              minDate={clickType === "" ? null : clickType === "Enable" ? dayjs() : null}
                              maxDate={clickType === "" ? null : clickType === "Enable" ? null : dayjs()}
                            />
                          </InputFieldWrapper>
                        </LocalizationProvider>
                      )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="City">
                      <Controller
                        name="city"
                        control={control}
                        render={({
                          fieldState: { error },
                          field: { onChange, value },
                        }) => (
                          <TextField
                            helperText={error ? error.message : null}
                            size="small"
                            error={!!error}
                            onChange={onChange}
                            value={value}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Zipcode">
                      <Controller
                        name="zipCode"
                        control={control}
                        render={({
                          fieldState: { error },
                          field: { onChange, value },
                        }) => (
                          <TextField
                            helperText={error ? error.message : null}
                            size="small"
                            error={!!error}
                            onChange={onChange}
                            value={value}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Type">
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            labelId="type-simple-select-label"
                            id="type-simple-select"
                            autoWidth
                            fullWidth
                            sx={{ height: 40 }}
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  minWidth: 260,
                                  maxWidth: 'auto',
                                  whiteSpace: 'normal',
                                  
                                },
                              },
                            }}
                          >
                            {siteTypesAccending.map((t) => (
                              <MenuItem
                                sx={{
                                  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                  fontSize: 14,
                                  lineHeight: "18px",
                                  color: '#90959E',
                                  '&:hover': {
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: 5,
                                    color: COLORS.Romance,
                                  },
                                  '&.Mui-selected': {
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: 5,
                                    color: COLORS.Romance,
                                  },
                                }} key={t} value={t}>
                                {t}</MenuItem>
                            ))}
                          </Select>
                        )
                        }
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Status">
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            labelId="status-simple-select-label"
                            id="status-simple-select"
                            autoWidth
                            fullWidth
                            sx={{ height: 40 }}
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  minWidth: 260,
                                  maxWidth: 'auto',
                                  whiteSpace: 'normal',
                                  
                                },
                              },
                            }}
                          >
                            {siteStatuses.map((t) => (
                              <MenuItem
                                sx={{
                                  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                  fontSize: 14,
                                  lineHeight: "18px",
                                  color: '#90959E',
                                  '&:hover': {
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: 5,
                                    color: COLORS.Romance,
                                  },
                                  '&.Mui-selected': {
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: 5,
                                    color: COLORS.Romance,
                                  },
                                }} key={t} value={t}>
                                {t}</MenuItem>
                            ))}
                          </Select>
                        )
                        }
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Country">
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Select
                            labelId="country-simple-select-label"
                            id="country-simple-select"
                            autoWidth
                            fullWidth
                            sx={{ height: 40 }}
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  minWidth: 260,
                                  maxWidth: 'auto',
                                  whiteSpace: 'normal',
                                  
                                },
                              },
                            }}
                          >
                            {CountriesAccending.map((t) => (
                              <MenuItem
                                sx={{
                                  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                  fontSize: 14,
                                  lineHeight: "18px",
                                  color: '#90959E',
                                  '&:hover': {
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: 5,
                                    color: COLORS.Romance,
                                  },
                                  '&.Mui-selected': {
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: 5,
                                    color: COLORS.Romance,
                                  },
                                }} key={t} value={t}>
                                {t}</MenuItem>
                            ))}
                          </Select>
                        )
                        }
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Country Code">
                      <Controller
                        name="countryRegion"
                        control={control}
                        render={({ field }) => (
                          <Select
                            labelId="countryRegion-simple-select-label"
                            id="countryRegion-simple-select"
                            autoWidth
                            fullWidth
                            sx={{ height: 40 }}
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  minWidth: 260,
                                  maxWidth: 'auto',
                                  whiteSpace: 'normal',
                                  
                                },
                              },
                            }}
                          >
                            {sortedCountries.map((t) => (
                              <MenuItem
                                sx={{
                                  fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                  fontSize: 14,
                                  lineHeight: "18px",
                                  color: '#90959E',
                                  '&:hover': {
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: 5,
                                    color: COLORS.Romance,
                                  },
                                  '&.Mui-selected': {
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: 5,
                                    color: COLORS.Romance,
                                  },
                                }} key={t} value={t}>
                                {t}</MenuItem>
                            ))}
                          </Select>
                        )
                        }
                      />
                    </InputFieldWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputFieldWrapper label="Electricity Region Code">
                      <Controller
                        name="region"
                        control={control}
                        render={({ field }) => {
                          useEffect(() => {
                            if (formValues?.region?.length === 1) {
                              field.onChange(formValues?.region[0]);
                            }
                          }, [formValues?.region, field]);
                          return (
                            <Select
                              labelId="region-simple-select-label"
                              id="region-simple-select"
                              name="region"
                              autoWidth
                              fullWidth
                              sx={{ height: 40, color: (formValues?.region === "MULTIPLE REGIONS FOUND") ? 'red' : 'inherit', }}
                              value={String(field.value)}
                              onChange={(e) => field.onChange(e.target.value)}
                              disabled={formValues?.region?.length <= 1}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    minWidth: 260,
                                    maxWidth: 'auto',
                                    whiteSpace: 'normal',
                                  },
                                },
                              }}
                            >
                              {Array.isArray(formValues?.region) && formValues?.region?.length > 0 && formValues?.region?.map((t: any) => (
                                <MenuItem
                                  sx={{
                                    // backgroundColor: formValues?.regionList?.includes(t) ? COLORS.neutralGhWhite : 'inherit', 
                                    fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    color: '#90959E',
                                    '&:hover': {
                                      backgroundColor: '#F6F8FA',
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#F6F8FA',
                                      borderRadius: 5,
                                      color: COLORS.Romance,
                                    },
                                  }} key={t} value={t}>
                                  {t}</MenuItem>
                              ))}
                            </Select>
                          )
                        }
                        }
                      />
                    </InputFieldWrapper>
                  </Grid>
                </Grid>
            }
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={editSite ? handleEditSite : handleAddSite}
          variant="contained"
          color="primary"
          disabled={updateLoading || isLoading}
        >
          {updateLoading && <CircularProgress sx={{ color: COLORS.Romance, font: '18px', mx: '4px', position: 'absolute' }} size={20} />}
          {editSite ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
