/* eslint-disable */
import {
  AppBar,
  Box,
  Button,
  LinearProgress,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import MaterialReactTable, {
  MRT_ColumnFiltersState,
  MRT_RowSelectionState,
} from "material-react-table";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { DocumentDTO } from "api";

import {
  Avatar,
  COLORS,
  EsgDatePickerField,
  EsgPrimaryLabel,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2,
  Loading,
  MarginWrapper,
  Switch,
  usePagination,
  useSwitchFilter,
  useYearMonthPeriodFilter,
} from "components";
import { EMPTY_CELL_VALUE, useTableExpandedRows } from "components/react-table";

import { useDocuments, useGetConfigParameter, useSitesMultiselect } from "hooks";

import { LOCAL_STORAGE_KEYS, TABLE_HEADERS } from "consts";

import { ParsedQueryParams } from "types";

import {
  getDateFormat,
  getDateFormatmonthset,
  getInitials,
  isNullOrUndefined,
  setToLocalStorage,
} from "utils";

import { ADD_DOCUMENT_BILL_PAGE } from "./add-document-bill";

import { UTILITY_BILL_ALL_DATAENTRIES_VIEW } from "../document-summary-v2/utility-bill/utility-bill-allDataEntries-view/consts";
import ApprovalFlowBar from "./components/approval-flow-bar";
import { EsgMultiselectControlledField } from "components/inputs/esg-multi-select-field";
import { EsgCommonButton } from "components/inputs/esg-buttons";
import { EsgActions } from "./components/table/esg-actions";
import { EsgDocumentsUpload } from "components/documents-upload/esg-flow-document-upload";

interface DateObject {
  datefrom: string;
  toDateValue: string;
}

export const DocumentUploadPage = () => {
  // ---- Hooks
  const navigate = useNavigate();
  const currentUrl = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();


  // ---- Const ---- 
  const currentDate = new Date();
  const firstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  const lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );
  const fromDateInitial = moment(firstDay)?.format("YYYY-MM-DD");
  const toDateInitial = moment(currentDate).add(1, "day").format("YYYY-MM-DD");
  const storedFromDate = localStorage.getItem("fromDate");
  const storedToDate = localStorage.getItem("toDate");
  const sessionFilterValue = window.sessionStorage.getItem(
    "columnFiltersDocumentUpload",
  );
  const filterValue =
    (sessionFilterValue && JSON.parse(sessionFilterValue)) || [];
  const viewedDocumentID = window.sessionStorage.getItem("viewedDocumentID");
  const maxDate = dayjs(lastDay).utc();
  // ---- States
  const [siteId, setSiteId] = useState<any>();
  const [clearDate, setClearDate] = useState(false);
  const [key, setKey] = useState(0);
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );

  const dataEntries = [
    {
      "label": "Warning (Conversion Error)",
      "name": "Warning (Conversion Error)",
      "value": 1
    },
    {
      "label": "Warning (Missing Fields)",
      "name": "Warning (Missing Fields)",
      "value": 2
    },
    {
      "label": "Warning (Potential Error Identified)",
      "name": "Warning (Potential Error Identified)",
      "value": 3
    },
    {
      "label": "Emissions Included (AI Generated)",
      "name": "Emissions Included (AI Generated)",
      "value": 4
    },
    {
      "label": "Emissions Included (User Confirmed)",
      "name": "Emissions Included (User Confirmed)",
      "value": 5
    },
    {
      "label": "Reviewed",
      "name": "Reviewed",
      "value": 6
    },
    {
      "label": "Approved (Signed Off)",
      "name": "Approved (Signed Off)",
      "value": 7
    },
    {
      "label": "Approved (Locked)",
      "name": "Approved (Locked)",
      "value": 8
    }
  ];

  const dataEntries2 = [
    {
      "label": "Warning (Conversion Error)",
      "name": "Warning (Conversion Error)",
      "value": 1
    },
    {
      "label": "Warning (Missing Fields)",
      "name": "Warning (Missing Fields)",
      "value": 2
    },
    {
      "label": "Warning (Potential Error Identified)",
      "name": "Warning (Potential Error Identified)",
      "value": 3
    },
    {
      "label": "Emissions Included (AI Generated)",
      "name": "Emissions Included (AI Generated)",
      "value": 4
    },
    {
      "label": "Emissions Included (User Confirmed)",
      "name": "Emissions Included (User Confirmed)",
      "value": 5
    },
    {
      "label": "Approved (Signed Off)",
      "name": "Approved (Signed Off)",
      "value": 6
    },
    {
      "label": "Approved (Locked)",
      "name": "Approved (Locked)",
      "value": 7
    }
  ];


  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(filterValue);

  const { data: configData } = useGetConfigParameter();

  const approvalFlowValue = configData?.find((t) => t.name === "second_layer_approval_feature")
  const isApprovalFlowActive = approvalFlowValue?.configValue
  const dropDownOption = isApprovalFlowActive === "true" ? dataEntries : dataEntries2

   dataEntries2
  const [dateError, setDateError] = useState(false);
  const fromDates =
    siteId !== undefined
      ? null
      : storedFromDate
        ? moment(storedFromDate)
        : fromDateInitial;
  const toDates =
    siteId !== undefined
      ? null
      : storedToDate
        ? moment(storedToDate)
        : toDateInitial;
  const initialState = {
    isYearly: false,
    startDate: moment(fromDates !== null ? fromDates : firstDay).format(
      "YYYY-MM-DD",
    ),
    endDate: moment(toDates !== null ? toDates : lastDay).format("YYYY-MM-DD"),
    fromDate: fromDates,
    toDate: toDates,
    view: "monthly",
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fromDate, toDate } = state;
  const [dateCurrent, setDateCurrent] = useState<DateObject>({
    datefrom: "",
    toDateValue: "",
  });
  const [showBanner, setShowBanner] = useState(false);
  const [dateReload, setDateReload] = useState(true);
  const [globalFilter, setGlobalFilter] = useState('');
  const [dataEntriesStatus, setDataEntriesStatus] = useState<any>([]);

  const storedValue = localStorage.getItem("togglebtn");
  const toggleBtn = storedValue ? JSON.parse(storedValue) : true;

  // ---- API's
  const { setInitialExpanded } =
    useTableExpandedRows();

  const updateExternalStates = useCallback(() => {
    // setInitialPage();
    setInitialExpanded();
  }, [setInitialExpanded]);

  const { period, availableYears } = useYearMonthPeriodFilter({
    updateExternalStates,
  });
  const minDate = dayjs(
    `${Math.min(...(availableYears || [dayjs().utc().year()]))}-01-01`,
  ).utc();

  const {
    siteIds,
    isSitesLoading,
    selectedSites,
    setSelectedSites,
    sitesOptions,
  } = useSitesMultiselect({
    updateExternalStates,
  });

  const { value: isUnmoved, onSwitchChange: onIsUnmovedChange } =
    useSwitchFilter({
      key: "isUnmoved",
      updateExternalStates,
    });

  function reducer(state: any, action: any) {
    let isYearly;
    let period;

    switch (action.type) {
      case "SET_YEARLY":
        ({ isYearly, period } = action.payload);
        return {
          ...state,
          isYearly,
          view: isYearly ? "yearly" : "monthly",
          startDate: isYearly
            ? period?.startOf("year").format("YYYY-MM-DD")
            : `${period.format("YYYY-MM")}-01`,
          endDate: isYearly
            ? period?.endOf("year").format("YYYY-MM-DD")
            : `${period.format("YYYY-MM")}-${period?.daysInMonth()}`,
        };
      case "SET_FROM_DATE":
        return {
          ...state,
          fromDate: action.payload.value,
          view: "daterange",
          startDate: action.payload.value,
        };
      case "SET_TO_DATE":
        return {
          ...state,
          toDate: action.payload.value,
          view: "daterange",
          endDate: action.payload.value,
        };
      case "SET_START_AND_END_DATES":
        return {
          ...state,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        };

      case "CLEAR_DATES":
        return {
          ...state,
          fromDate: null,
          toDate: null,
          startDate: moment(firstDay).format("YYYY-MM-DD"),
          endDate: moment(lastDay).format("YYYY-MM-DD"),
          // isYearly: true,
          view: "monthly",
        };
      default:
        return state;
    }
  }

  useEffect(() => {
    window.sessionStorage.setItem(
      "columnFiltersDocumentUpload",
      JSON.stringify(columnFilters),
    );
    if (columnFilters?.length === 0) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, [columnFilters]);

  useEffect(() => {
    const firstDate = new Date();
    const firstDateOfMonth = new Date(
      firstDate?.getFullYear(),
      firstDate?.getMonth(),
      1,
    );
    const formattedFirstDate = firstDateOfMonth;
    const datefrom = moment(formattedFirstDate).format("YYYY-MM-DD");
    const storedfromValue = localStorage.getItem("fromDate");
    const storedtoValue = localStorage.getItem("toDate");

    if (!storedfromValue) {
      localStorage.setItem(
        "fromDate",
        moment(formattedFirstDate)?.format("YYYY-MM-DD"),
      );
    }

    const lastDate = new Date();
    const currentYear = lastDate.getFullYear();
    const currentMonth = lastDate.getMonth();
    const storedYear = storedtoValue ? moment(storedtoValue).year() : null;
    const storedMonth = storedtoValue ? moment(storedtoValue).month() : null;
    const currentDates = lastDate.getDate();
    const storedDates = storedtoValue ? moment(storedtoValue).date() : null;
    // const lastDateOfMonth = new Date(lastDate?.getFullYear(), lastDate?.getMonth() + 1, 0);
    // const formattedLastDate = lastDateOfMonth
    const toDateValue = moment(currentDate).add(1, "day").format("YYYY-MM-DD");
    if (
      !storedtoValue ||
      (currentYear === storedYear &&
        currentMonth === storedMonth &&
        currentDates !== storedDates)
    ) {
      localStorage.setItem(
        "toDate",
        moment(currentDate).add(1, "day").format("YYYY-MM-DD"),
      );
    }
    ///end code //
    setDateCurrent({ datefrom, toDateValue });
  }, []);

  const determineAlgorithmName = (row: any) => {
    const { service, source, routing } = row;

    if (service === "default" && source === "idpv1" && routing === "fallback") {
      return "IDP Fallback";
    } else if (
      service === "default" &&
      source === "idpv1" &&
      routing === "default"
    ) {
      return "IDP A/B Testing";
    } else if (service === "default" && source === "abbyy") {
      return "Current";
    } else {
      return "-"; // Default value if none of the conditions are met
    }
  };

  // ---- Pagination Stock 
  const getSavedPagination = () => {
    const saved = localStorage.getItem("pagination");
    return saved ? JSON.parse(saved) : { pageIndex: 0, pageSize: 100 };
  };

  const [pagination, setPagination] = useState<any>(getSavedPagination);

  useEffect(() => {
    localStorage.setItem("pagination", JSON.stringify(pagination));
  }, [pagination]);
 
  const searchQuery = {
    uploadDateFrom: !dateError
      ? fromDates !== null
        ? moment(fromDates).format("YYYY-MM-DD")
        : siteId !== undefined
          ? null
          : fromDate
      : dateCurrent?.datefrom,
    uploadDateTo: !dateError
      ? toDates !== null
        ? moment(toDates).format("YYYY-MM-DD")
        : siteId !== undefined
          ? null
          : toDate
      : dateCurrent?.toDateValue,
    siteIds: siteId !== undefined ? siteId : siteIds,
    status: dataEntriesStatus.map((item: any) => item.label).join(","),
    isUnmoved,
    size: pagination?.pageSize,
    page: pagination?.pageIndex
  }
  
  const [query, setQueary] = useState(searchQuery)
  
  const [prevQuery, setPrevQuery] = useState(query);
  const [hasChanged, setHasChanged] = useState(false);
  const [isClearButtonClicked, setIsClearButtonClicked] = useState(false);


  const { data, isLoading1, refetch } = useDocuments(query);

  const handleSearch = () => {
    setIsClearButtonClicked(true);
    if (columnFilters?.length > 0) {
      const paginationPayload = {
        ...searchQuery,
        size: null,
        page: null
      }
      setQueary(paginationPayload)
      refetch();
    } else {
      setQueary(searchQuery)
    }
  }

  useEffect(() => {
    const paginationPayload = {
      ...searchQuery,
      size: pagination?.pageSize,
      page: pagination?.pageIndex
    }
    setQueary(paginationPayload)
  },[pagination]);

  useEffect(() => {
    const isDifferent = JSON.stringify(searchQuery) !== JSON.stringify(prevQuery);
    setHasChanged(isDifferent);
    if (isDifferent) {
      setPrevQuery(query); // Update previous state if changed
    }
  }, [searchQuery]);

  const tableRows = data?.documents || [];

  const customFilter = (rows: any, filterValue: string) => {
    return rows.filter(
      (row: {
        original: { uploader: { firstName: string; lastName: string } };
      }) => {
        const uploaderName =
          row?.original?.uploader?.firstName +
          " " +
          row?.original?.uploader?.lastName;

        // Customize the comparison logic based on your requirements
        return uploaderName.toLowerCase() === filterValue.toLowerCase();
      },
    );
  };

  // Page Handler Functions
  const handleRowSelection = () => {
    setRowSelection({});
  };

  const handleFromDateChange = (date: any) => {
    const firstDate = new Date(date);
    const firstDateOfMonth = new Date(
      firstDate?.getFullYear(),
      firstDate?.getMonth(),
      1,
    );
    const formattedFirstDate = firstDateOfMonth;

    localStorage.setItem(
      "fromDate",
      moment(formattedFirstDate).format("YYYY-MM-DD"),
    );
    const formDateValue = moment(formattedFirstDate).format("YYYY-MM-DD");
    const fromDateExists = localStorage.getItem("fromDate");
    let formattedtest = fromDateExists !== null ? fromDateExists : fromDate;
    const toDateExists = localStorage.getItem("toDate");
    if (formDateValue === formattedtest) {
      formattedtest = toDateExists;
    }
    if (fromDate && formDateValue > formattedtest) {
      setDateError(true);
    } else {
      dispatch({
        type: "SET_FROM_DATE",
        payload: {
          value: moment(formattedFirstDate).format("YYYY-MM-DD"),
          period,
        },
      });
      setDateError(false);
    }
  };

  const handleToDateChange = (date: any) => {
    const currentDate = new Date();
    const currentMonth = currentDate?.getMonth();
    const currentYear = currentDate?.getFullYear();

    const selectedDate = new Date(date);
    const selectedMonth = selectedDate?.getMonth();
    const selectedYear = selectedDate?.getFullYear();
    let toDateValue;
    if (currentMonth === selectedMonth && currentYear === selectedYear) {
      toDateValue = moment(currentDate)?.format("YYYY-MM-DD");
    } else {
      const lastDateOfMonth = new Date(
        selectedDate?.getFullYear(),
        selectedDate?.getMonth() + 1,
        0,
      );
      const formattedLastDate = lastDateOfMonth;
      toDateValue = moment(formattedLastDate)?.format("YYYY-MM-DD");
    }
    const fromDatevalidation = moment(fromDates)?.format("YYYY-MM-DD");

    localStorage.setItem("toDate", toDateValue);

    const datevaluecheck =
      fromDate !== null
        ? moment(fromDate)?.format("YYYY-MM-DD")
        : moment(fromDatevalidation)?.format("YYYY-MM-DD");

    if (toDateValue < datevaluecheck) {
      setDateError(true);
    } else {
      dispatch({
        type: "SET_TO_DATE",
        payload: { value: toDateValue, period },
      });
      setDateError(false);
    }
  };

  useEffect(() => {
    if (dateReload === false) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateReload]);

  useEffect(() => {
    if (!toggleBtn) {
      const fromDateExists = localStorage.getItem("fromDate") !== null;
      const toDateExists = localStorage.getItem("toDate") !== null;
      if (fromDateExists) {
        localStorage.removeItem("fromDate");
        setDateReload(false);
      }
      if (toDateExists) {
        localStorage.removeItem("toDate");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleBtn]);

  const handleResetDate = () => {
    const firstDate = new Date();
    const firstDateOfMonth = new Date(
      firstDate?.getFullYear(),
      firstDate?.getMonth(),
      1,
    );
    const formattedFirstDate = firstDateOfMonth;
    localStorage.setItem(
      "fromDate",
      moment(formattedFirstDate)?.format("YYYY-MM-DD"),
    );

    const lastDate = new Date();
    const toDateValue = moment(lastDate)?.format("YYYY-MM-DD");
    localStorage.setItem("toDate", toDateValue);
  };

  const handleClearDates = () => {
    dispatch({ type: "CLEAR_DATES", payload: null });
    setDateError(false);
    setClearDate(true);
    setSelectedSites([]);

    const fromDateExists = localStorage.getItem("fromDate") !== null;
    const toDateExists = localStorage.getItem("toDate") !== null;
    if (fromDateExists) {
      handleResetDate();
    }
    if (toDateExists) {
    }
    setTimeout(() => {
      setClearDate(false);
    }, 100);
    window.location.reload();
    setIsClearButtonClicked(false)
  };

  const onResetAll = () => {
    window.sessionStorage.setItem(
      "columnFiltersDocumentUpload",
      JSON.stringify([]),
    );
    setColumnFilters([]);
    setSiteId(undefined);
  };

  const setDateValue = (dateValue1: any, dateValue2: any) => {
    if (dateValue1 !== null) {
      return dayjs(dateValue1);
    }
    return dateValue2;
  };

  // ---- Table Column
  const columns: any = [
    {
      header: "File name",
      Header: "File name",
      accessorKey: "fileName",
      Cell: ({ cell, row }: any) => {
        const value = cell?.getValue() as string;
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                display: "block", // Ensure it's a block-level element
                width: "200px", // Set a fixed width
                overflow: "hidden",
                textOverflow: "ellipsis", // Show ellipsis if the text overflows
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
      size: 140,
    },
    {
      header: "Site",
      Header: <Box sx={{ paddingLeft: "12px" }}> Site</Box>,
      accessorKey: "site",
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as string;
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                display: "block", // Ensure it's a block-level element
                width: "180px", // Set a fixed width
                overflow: "hidden",
                textOverflow: "ellipsis", // Show ellipsis if the text overflows
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
      size: 120,
    },
    {
      header: "Utility Type",
      Header: <Box sx={{ paddingLeft: "10px" }}>Utility Type</Box>,
      accessorKey: "utilityType",
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as string;

        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return (
          <Tooltip title={value}>
            <Box
              sx={{
                paddingLeft: "12px",
                alignItems: "center",
                Width: "30px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
      size: 140,
    },
    {
      accessorFn: (row: any) =>
        `${row?.uploader?.firstName} ${row?.uploader?.lastName}`,
      filterMethod: customFilter,
      header: TABLE_HEADERS.uploader,
      Header: <Box sx={{ paddingLeft: "14px" }}>Uploader</Box>,
      id: "uploader",
      Cell: ({ cell, row }: any) => {
        const value = cell?.getValue();
        if (!value) {
          return EMPTY_CELL_VALUE;
        }
        return (
          <Tooltip title={row?.original?.uploader?.email}>
            <Box sx={{ paddingLeft: "14px" }}>
              <Avatar
                sx={{
                  width: "34px",
                  height: "34px",
                }}
              >
                {getInitials(value)}
              </Avatar>
            </Box>
          </Tooltip>
        );
      },
      size: 150,
    },
    {
      header: "Uploaded at",
      Header: <Box sx={{ paddingLeft: "0px" }}>Uploaded at</Box>,
      accessorKey: "uploadDate",
      Cell: ({ cell }: any) => {
        const date: string = cell?.getValue() as string;
        const time = dayjs(date)?.utc()?.local()?.format("HH:mm");
        const value = dayjs(date)?.utc()?.local()?.format(getDateFormat());
        if (isNullOrUndefined(value)) {
          return EMPTY_CELL_VALUE;
        }

        return <Box>{`${value}, ${time}`}</Box>;
      },
      size: 160,
    },
    {
      header: "Pages",
      Header: <Box>Pages</Box>,
      accessorKey: "pages",
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as DocumentDTO["pages"];

        return (
          <Box sx={{ textAlign: "center", paddingLeft: "5px" }}>
            {value || EMPTY_CELL_VALUE}
          </Box>
        );
      },
      size: 100,
    },
    {
      header: "Data entries",
      Header: <Box>Data Entries</Box>,
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as DocumentDTO["pages"];

        return (
          <Box sx={{ textAlign: "center", paddingLeft: "5px" }}>
            {value || EMPTY_CELL_VALUE}
          </Box>
        );
      },
      accessorFn: (originalRow: any) =>
        originalRow?.bills?.length === null ||
          originalRow?.bills?.length === undefined
          ? "-"
          : originalRow?.bills?.length,
      size: 140,
    },
    {
      header: "AI Extraction",
      Header: <Box>AI Extraction</Box>,
      accessorFn: (originalRow: any) => originalRow?.bills,
      filterFn: (row: any, id: any, filterValue: any) => {
        const userInput = filterValue;

        const value = row?.original?.bills;

        const totalFields: number = value?.length * 5;
        const filled = value?.reduce((acc: any, bill: any) => {
          if (bill?.ocrInputFields !== null) {
            return acc + bill?.ocrInputFields;
          }
          return acc;
        }, 0);

        let progress: number = (filled / totalFields) * 100;

        const finalProcessValue = Math.round(progress);

        const compareData = parseInt(userInput) === finalProcessValue;

        if (compareData) {
          return true;
        }
        return false;
      },
      Cell: ({ cell }: any) => {
        const value = cell?.getValue() as Array<any>;

        const totalFields: number = value?.length * 5;
        const filled = value?.reduce((acc, bill) => {
          if (bill?.ocrInputFields !== null) {
            return acc + bill?.ocrInputFields;
          }
          return acc;
        }, 0);
        let progress: number = (filled / totalFields) * 100;

        if (isNaN(progress)) {
          progress = 0;
        }

        function getFileExtension(filename: any) {
          const parts = filename?.split(".");
          if (parts?.length > 1) {
            return parts?.pop()?.toLowerCase();
          }
          return "";
        }

        const fileExtension = getFileExtension(
          cell?.row?.original?.fileName?.toLowerCase(),
        );

        return (
          <Box
            sx={{
              width: "100px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {(cell?.row?.original?.uploadDate < "2023-09-12" &&
              progress === 0) ||
              fileExtension !== "pdf" ? (
              <>
                <LinearProgress
                  sx={{
                    minWidth: "90px",
                    height: "8px",
                    borderRadius: "5px",
                    border: "2px solid #DCDCDC",
                    backgroundColor: "#f3f3f3",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#DCDCDC",
                    },
                  }}
                  variant="determinate"
                  value={100}
                />
                <p style={{ paddingLeft: "4px", fontSize: "12px" }}>NA</p>
              </>
            ) : (
              <>
                <LinearProgress
                  sx={{
                    minWidth: "90px",
                    height: "8px",
                    borderRadius: "5px",
                    border: `1px solid ${COLORS.Mainblue}`,
                    backgroundColor: "#f3f3f3",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: COLORS.Mainblue,
                    },
                  }}
                  variant="determinate"
                  value={progress}
                />
                <p
                  style={{ paddingLeft: "4px", fontSize: "12px" }}
                >{`${Math?.round(progress)}%`}</p>
              </>
            )}
          </Box>
        );
      },
      size: 150,
    },
    {
      header: "AI Algorithm",
      Header: <Box sx={{ paddingLeft: "12px" }}> AI Algorithm</Box>,
      accessorKey: "algorithm",
      Cell: ({ cell }: any) => {
        const row = cell.row.original;
        const value = determineAlgorithmName(row);

        return (
          <Tooltip title={value}>
            <Box
              sx={{
                paddingLeft: "12px",
                alignItems: "center",
                Width: "30px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
      size: 200,
      filterFn: (row: any, id: any, filterValue: any) => {
        const algorithmName = determineAlgorithmName(row.original);
        return algorithmName.toLowerCase().includes(filterValue.toLowerCase());
      },
    },
    {
      header: "Data entries status",
      Header: <Box>Data Entries Status</Box>,
      accessorKey: "bills",
      filterFn: (row: any, id: any, filterValue: any) => {
        const userInput = filterValue;
        const bills = row?.original?.bills;
        const statusOfDocument = row?.original?.dateEntryStatus;

        const getStatusPriority = (status: string) => {
          if (status === "Warning (Conversion Error)") {
            return 0;
          } else if (status === "Warning (Missing Fields)") {
            return 1;
          } else if (status === "Warning (Potential Error Identified)") {
            return 2;
          } else if (status === "Emissions Included (AI Generated)") {
            return 3;
          } else if (status === "Emissions Included (User Confirmed)") {
            return 4;
          } else if (status === "Approved (Signed Off)") {
            return 5;
          } else if (status === "Approved (Locked)") {
            return 6;
          } else {
            return 7;
          }
        };

        const getStatusLabel = (statusKey: any) => {
          if (statusKey === "Warning (Conversion Error)") {
            return "Warning (Conversion Error)";
          } else if (statusKey === "Warning (Missing Fields)") {
            return "Warning (Missing Fields)";
          } else if (statusKey === "Warning (Potential Error Identified)") {
            return "Warning (Potential Error Identified)";
          } else if (statusKey === "Emissions Included (AI Generated)") {
            return "Emissions Included (AI Generated)";
          } else if (statusKey === "Emissions Included (User Confirmed)") {
            return "Emissions Included (User Confirmed)";
          } else if (statusKey === "Approved (Signed Off)") {
            return "Approved (Signed Off)";
            
          } else if (statusKey === "Approved (Locked)") {
            return "Approved (Locked)";
          } else if (statusKey === "Reviewed") {
              return "Reviewed";
          } else {
            return "";
          };
        };

        const finalProcessValue = getStatusLabel(statusOfDocument);

        const compareData = finalProcessValue
          .toLowerCase()
          .includes(userInput.toLowerCase());

        if (compareData) {
          return true;
        }
        return false;
      },
      Cell: ({ cell, row }: any) => {
        const bills = cell?.getValue() as Array<any>;
        const statusOfDocument = row?.original?.dateEntryStatus;
        const missingFields = row?.original?.missingFields;
        const errorMessage = row?.original?.errorMessage;

        const getStatusPriority = (status: string) => {
          if (status === "Warning (Conversion Error)") {
            return 0;
          } else if (status === "Warning (Missing Fields)") {
            return 1;
          } else if (status === "Warning (Potential Error Identified)") {
            return 2;
          } else if (status === "Emissions Included (AI Generated)") {
            return 3;
          } else if (status === "Emissions Included (User Confirmed)") {
            return 4;
          } else if (status === "Approved (Signed Off)") {
            return 5;
          } else if (status === "Approved (Locked)") {
            return 6;
          } else if (status === "Reviewed") {
            return 7;
          } else {
            return 8;
          }
        };

        const getMissingProperties = (bill: any) => {
          const missingProperties = [];
          if (bill?.status === "INCOMPLETE_DATA" && bill?.errorMessage) {
            if (!bill?.siteName || bill?.siteName === "Generic") {
              missingProperties.push("Site");
            }

            if (!bill?.consumptionPeriodFrom) {
              missingProperties.push("Start Date");
            }

            if (!bill?.consumptionPeriodTo) {
              missingProperties.push("End Date");
            }

            if (bill?.measuredUsageValue === null) {
              missingProperties.push("Usage");
            }

            if (bill?.amount === null) {
              missingProperties.push("Cost");
            }
          }

          return missingProperties;
        };

        const getStatusLabel = (statusKey: any) => {
          if (statusKey === "Warning (Conversion Error)") {
            return "Warning (Conversion Error)";
          } else if (statusKey === "Warning (Missing Fields)") {
            return `Warning (Missing Fields) ${missingFields.length > 0 ? `(${missingFields.length})` : ""}`;
          } else if (statusKey === "Warning (Potential Error Identified)") {
            return "Warning (Potential Error Identified)";
          } else if (statusKey === "Emissions Included (AI Generated)") {
            return "Emissions Included (AI Generated)";
          } else if (statusKey === "Emissions Included (User Confirmed)") {
            return "Emissions Included (User Confirmed)";
          } else if (statusKey === "Approved (Signed Off)") {
            return "Approved (Signed Off)";
          } else if (statusKey === "Approved (Locked)") {
            return "Approved (Locked)";
          } else if (statusKey === "Reviewed") {
            return "Reviewed";
          } else {
            return "";
          };
        };

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "4px",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            <Tooltip
              placement="top"
              title={
                getStatusPriority(statusOfDocument) === 0
                  ? "There was an error converting the bill information to emissions data"
                  : getStatusPriority(statusOfDocument) === 1
                    ? missingFields.length > 0
                      ? (
                        <div style={{ textAlign: "left" }}>
                          {missingFields.map((t: any, index: any) => (
                            <div key={index}>• {t}</div>
                          ))}
                        </div>
                      )
                      : ""
                    : getStatusPriority(statusOfDocument) === 2
                      ? <span style={{ whiteSpace: "pre-line" }}>{errorMessage}</span>
                      : getStatusPriority(statusOfDocument) === 3
                        ? "Associated emissions from 100% AI extraction have been included"
                        : getStatusPriority(statusOfDocument) === 4
                          ? "Associated emissions have been included following user confirmation of a warning message"
                          : getStatusPriority(statusOfDocument) === 5 || getStatusPriority(statusOfDocument) === 6
                            ? "This bill is for a site and time period that has been signed off or locked"
                            : ""
              }
            >
              <span style={{ position: "relative" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 5 5"
                  fill={
                    getStatusPriority(statusOfDocument) === 0
                      ? "#DD1515"
                      : getStatusPriority(statusOfDocument) === 1
                        ? "#DD1515"
                        : getStatusPriority(statusOfDocument) === 2
                          ? "#DD1515" :
                          getStatusPriority(statusOfDocument) === 3
                            ? "#9EE766" :
                            getStatusPriority(statusOfDocument) === 4
                              ? "#9EE766" :
                              getStatusPriority(statusOfDocument) === 5
                                ? "#0E25D5" :
                                getStatusPriority(statusOfDocument) === 6
                                  ? "#1D1D1D" :
                                  getStatusPriority(statusOfDocument) === 7
                                    ? "#00A1FF"
                                    : "none"
                  }
                  style={{ marginRight: "9px" }}
                >
                  <circle cx="2.5" cy="2.5" r="2.5" />
                </svg>
                {getStatusLabel(statusOfDocument)}
                {/* <span>({totalMissingFields})</span> */}
              </span>
            </Tooltip>
          </Box>
        );
      },
      size: 220,
    },
  ];

  localStorage.removeItem("setid");

  function getCircularReplacer() {
    const seen = new WeakSet();
    return (key: any, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }


  useEffect(() => {
    setKey((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (columnFilters?.length > 0) {
      const paginationPayload = {
        ...searchQuery,
        size: null,
        page: null
      }
      setQueary(paginationPayload)
      refetch();
    } else {
      const paginationPayload = {
        ...searchQuery,
        size: pagination?.pageSize,
        page: pagination?.pageIndex
      }
      setQueary(paginationPayload)
      refetch();
    }
  }, [columnFilters]);


  return (
    <MarginWrapper>
      <Box sx={FILTERS_AND_ACTIONS_WRAPPER}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          gap: 2,
        }}>
          <Box sx={{
            maxWidth: '75%',
            "@media (max-width: 1323px)": {
              maxWidth: '60%',
            },
          }}>
            <Box sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 2,
            }}>
              <Stack direction="row" spacing={2}>
                <EsgDatePickerField
                  label="From Month"
                  views={["year", "month"]}
                  openTo="month"
                  minDate={minDate}
                  maxDate={maxDate}
                  format={getDateFormatmonthset()}
                  disableFuture
                  disableHighlightToday
                  onAccept={handleFromDateChange}
                  setDate={handleFromDateChange}
                  value={setDateValue(fromDates, fromDate)}
                  placeholdervalue="MM, YYYY"
                  filterAll={siteId !== undefined ? true : false}
                  width="140px"
                  clearDate={false}
                  disabled={!toggleBtn}
                />
                <EsgDatePickerField
                  width="140px"
                  views={["year", "month"]}
                  label="To Month"
                  minDate={minDate}
                  maxDate={maxDate}
                  openTo="month"
                  format={getDateFormatmonthset()}
                  disableHighlightToday
                  onAccept={handleToDateChange}
                  setDate={handleToDateChange}
                  value={setDateValue(toDates, toDate)}
                  clearDate={clearDate}
                  disabled={!toggleBtn}
                  placeholdervalue="MM, YYYY"
                  filterAll={siteId !== undefined ? true : false}
                />
              </Stack>

              <Box sx={{ minWidth: "210px", width: "fit-content", paddingLeft: "0px" }}>
                <EsgMultiselectControlledField
                  label="Sites"
                  disabled={isSitesLoading}
                  selectedValues={selectedSites}
                  setSelectedValues={setSelectedSites}
                  options={sitesOptions}
                  isAllSelectedOnEmptySelection
                  type="rounded"
                />
              </Box>

              <Box sx={{ minWidth: "210px", width: "fit-content" }}>
                <EsgMultiselectControlledField
                  label="Data Entries Status"
                  disabled={isSitesLoading}
                  selectedValues={dataEntriesStatus}
                  setSelectedValues={setDataEntriesStatus}
                  options={dropDownOption}
                  isAllSelectedOnEmptySelection
                  type="rounded"
                />
              </Box>

              <Box sx={{
                paddingTop: "22px",
                maxWidth: {
                  xl: "125px",
                },
                "@media (min-width: 1600px)": {
                  maxWidth: "200px",
                }
              }}>
                <Switch
                  isChecked={isUnmoved}
                  label="Show only unmoved"
                  onChange={onIsUnmovedChange}
                />
              </Box>

              <EsgCommonButton variant={hasChanged ? "contained" : "outlined"} sx={{ width:"120px", color: hasChanged ? "white" : COLORS.Romance }} onClick={handleSearch}>Search</EsgCommonButton>
              <EsgCommonButton variant={isClearButtonClicked ? "contained" : "outlined"} sx={{ width:"120px", color: isClearButtonClicked ? "white" : COLORS.Romance }} onClick={handleClearDates}> Clear Filters</EsgCommonButton>
            </Box> 

            <Box sx={[{ my: 3, width: '100%' }]}>
              <Box sx={[FLEX_ALIGN_CENTER_GAP_2]}>

                <EsgDocumentsUpload isScope3={false} isLoading1={isLoading1} />
                {Object.keys(rowSelection).length > 0 && <EsgActions
                  rowSelection={rowSelection}
                  setrowSelection={handleRowSelection}
                />}
              </Box>
            </Box>

          </Box>

          <Box>
            <ApprovalFlowBar data={data} />
          </Box>

        </Box>
        {dateError && (
          <Box sx={[FLEX_ALIGN_CENTER_GAP_2, { mt: 1 }]}>
            <EsgPrimaryLabel sx={{ color: "red" }}>
              Invalid date selection. Please choose a month after the From Month.
            </EsgPrimaryLabel>
          </Box>
        )}
      </Box>

      {(!data && columnFilters?.length === 0) ? (
        <Loading />
      ) : (
        <>
          {data?.documents.length === 0 && (
            <Typography
              variant="body1"
              align="center"
              sx={{
                mt: 3,
                backgroundColor: COLORS.Romance,
                color: "#FFFFFF",
                padding: "2px",
              }}
            >
              No bills have been uploaded for this month yet. Please check the
              previous month's bills or upload more bills.
            </Typography>
          )}
          {tableRows && <MaterialReactTable
            key={key}
            enableTopToolbar={true}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            data={data?.documents || []}
            columns={columns}
            enableRowSelection
            enablePagination={columnFilters?.length > 0 ? false : true}
            manualPagination={true}
            onRowSelectionChange={setRowSelection}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            initialState={{ density: "compact", pagination }}
            state={{ rowSelection, columnFilters, globalFilter, pagination }}
            getRowId={(row: any) => row.id}
            rowCount={data?.count}
            muiTablePaginationProps={{
              rowsPerPageOptions: [100, 200, 500],
            }}
            muiTableContainerProps={{
              sx: {
                "& thead tr:first-of-type th:first-of-type": {
                  paddingLeft: "20px",
                },
                "& tbody tr td:first-of-type": {
                  paddingLeft: "20px",
                },
                "& tbody tr:first-of-type td > p": {
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                },
                height: "calc(100vh - 350px)",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
                "@media (min-width: 600px)": {
                  width: "100%",
                },
                "@media (min-width: 960px)": {
                  width: "1100px",
                },
                "@media (min-width: 1280px)": {
                  width: "1100px",
                },
                "@media (min-width: 1440px)": {
                  width: "1100px",
                },
                "@media (min-width: 1680px)": {
                  width: "1300px",
                },
                "@media (min-width: 2400px)": {
                  width: "100%",
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                '& .MuiIconButton-root': {
                        display: 'none',
                },
                backgroundColor: "white",
                display : Object.keys(rowSelection).length > 0 ? 'block' : 'none'
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                // display: "none",
                backgroundColor: "white"
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                lineHeight: "30px",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                "& > th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
                backgroundColor: "#F8FAFC",
              },
            }}
            muiTableBodyRowProps={(row) => ({
              onClick: () => {
                setToLocalStorage("dataEntries", JSON.stringify(row?.row));
                const handleClick = async () => {
                  if (row?.row?.original?.bills !== null) {
                    if (row?.row?.original?.bills?.length > 0) {
                      window.sessionStorage.setItem(
                        "summaryDocumentID",
                        row?.row?.original?.bills[0]?.id,
                      );
                      navigate(
                        UTILITY_BILL_ALL_DATAENTRIES_VIEW.ABSOLUTE_PATH(
                          row?.row?.original?.bills[0]?.id,
                        ),
                      );
                    } else {
                      window.sessionStorage.setItem(
                        "summaryDocumentID",
                        row?.row?.original?.id as any,
                      );
                      navigate(
                        ADD_DOCUMENT_BILL_PAGE.ABSOLUTE_PATH(
                          row?.row?.original?.id as string,
                        ),
                      );
                    }
                  }

                  window.sessionStorage.setItem(
                    "viewedDocumentID",
                    row?.row?.original?.id as string,
                  );
                  setToLocalStorage("type", "Upload");
                  setToLocalStorage(
                    "Upload_URL",
                    currentUrl?.pathname + currentUrl?.search,
                  );
                  setToLocalStorage(
                    "dataEntries",
                    JSON.stringify(row, getCircularReplacer()),
                  );
                  setToLocalStorage(
                    "algorithm",
                    determineAlgorithmName(row?.row?.original),
                  );
                };
                handleClick();
              },
              sx: {
                cursor: "pointer",
                backgroundColor:
                  String(row?.row?.original?.id) === String(viewedDocumentID)
                    ? "#DCDCDC"
                    : "",
              },
            })}
            muiTableBodyCellProps={(row) => ({
              sx: {
                opacity: row.row.original.moved ||
                  row.row.original.status === "PARSED" ||
                  row.row.original.status === "NO_RESPONSE"
                  ? 1
                  : 0.5,
                ":disabled":
                  !(
                    row.row.original.moved ||
                    row.row.original.status === "PARSED" ||
                    row.row.original.status === "NO_RESPONSE"
                  ),
              },
            })}
            renderTopToolbarCustomActions={() => (
              <Box>
                <Box>
                  {siteIds?.length !== 0 || showBanner ? (
                    <AppBar
                      position="static"
                      sx={{
                        backgroundColor: COLORS.Romance,
                        height: "23px",
                        top: "auto",
                        bottom: 0,
                        textAlign: "center",
                        my: "1rem",
                        width: "140%",
                      }}
                    >
                      <Toolbar
                        sx={{
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography
                          sx={{ mb: 5, paddingLeft: 50, paddingRight: 70 }}
                          align="center"
                        >
                          Filter is only being applied to the current date range
                          selected
                        </Typography>
                      </Toolbar>
                    </AppBar>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            )}
          />}
        </>
      )}
    </MarginWrapper>
  );
};
