/* eslint-disable */
import { Box, Tooltip } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import React from "react";

import {
  COLORS,
  EMPTY_CELL_VALUE, Label12Regular,
} from "components";

import { isNullOrUndefined, toUSNumber } from "utils";

export const SITE_EMISSIONS_COLUMNS_MAIN: MRT_ColumnDef<any>[] = [
  {
    id: 'Properties',
    header: 'Properties',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',

        },
      },
    },
    columns: [
      {
        header: "Start Date",
        accessorKey: "startDate",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
      {
        header: "End Date",
        accessorKey: "endDate",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
      {
        header: "Number of Days",
        accessorKey: "daysCovered",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Number of Unique Account/Meters</Box>,
        header: "Number of Unique Account/Meters",
        accessorKey: "numberOfUniqueCollectors",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
      {
        header: "Country",
        accessorKey: "country",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
      {
        header: "Type of Site",
        accessorKey: "sitetype",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
      {
        Header: <Box sx={{ paddingLeft: "2px" }}>Business Unit</Box>,
        accessorKey: "businessUnit",
        header: "Business Unit",
        Cell: ({ cell }: any) => {
          const value = cell.getValue() as string;

          if (!value) {
            return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
          }
          return (
            <Tooltip title={value}>
              <Box>
                {value}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        header: "Customer Site ID",
        accessorKey: "customerSiteId",
        Cell: ({ cell }: any) => {
          const value = cell.getValue() as string;
          if (isNullOrUndefined(value)) {
            return <Box sx={{ mx: "auto" }}>{EMPTY_CELL_VALUE}</Box>;
          }
          return (
            <Box
              sx={{
                alignItems: "center",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Manrope",
              }}
            >
              {value}
            </Box>
          )
        },
      },
      {
        header: "Site",
        accessorKey: "sitename",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },

      {
        header: "Utility Type",
        accessorKey: "utilityName",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Estimation Methodology</Box>,
        header: "Estimation Methodology",
        accessorKey: "estimationMethodology",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Tooltip title={value}>
            <Box>{(value as number)}</Box>
          </Tooltip>;
        },
        size: 200,
      },
      {
        header: "Scope",
        accessorKey: "scope",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
      {
        header: "Source",
        accessorKey: "source",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}</Box>;
        },
      },
    ]
  },
  {
    id: 'Usage',
    header: 'Usage',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',

        },
      },
    },
    columns: [
      {
        header: "Usage",
        accessorKey: "usage",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }


          const { unit } = row.original

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(Number(value)?.toFixed(2))}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const { unit } = row.original

          return (
            <>
              {cell.row.groupingColumnId === 'utilityName' &&
                <Box sx={{ display: 'flex', color: 'info.main' }}>
                  <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
                  <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>{unit}</Label12Regular>
                </Box>}
            </>
          )
        },
        aggregationFn: 'sum',
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Standardized Usage Value</Box>,
        header: "Standardized Usage Value",
        accessorKey: "standardizedUsageValue",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
        },
      },
    ]
  },
  {
    id: 'Location',
    header: 'Location',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',

        },
      },
    },
    columns: [
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Renewable Usage</Box>,
        header: "Renewable Usage",
        accessorKey: "locationRenewableUsage",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Non-Renewable Usage</Box>,
        header: "Non-Renewable Usage",
        accessorKey: "locationNonRenewableUsage",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
        },
      },
    ]
  },
  {
    id: 'Market',
    header: 'Market',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',

        },
      },
    },
    columns: [
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Renewable Usage</Box>,
        header: "Renewable Usage",
        accessorKey: "marketRenewableUsage",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>Non-Renewable Usage</Box>,
        header: "Non-Renewable Usage",
        accessorKey: "marketNonRenewableUsage",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{(value as number)}{' '}{row.original.standardizedUsageUnit}</Box>;
        },
      },
    ]
  },
  {
    id: 'Cost',
    header: 'Cost',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',

        },

      },
    },
    columns: [
      {
        header: "Cost",
        accessorKey: "cost",
        enableGrouping: false,
        size: 100,
        aggregationFn: 'sum',
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.currency

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const { currency } = row.original

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>{currency}</Label12Regular>
            </Box>
          )
        },
      },
    ]
  },
  {
    id: 'Emissions',
    header: 'Emissions',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',

        },
      },
    },
    columns: [
      {
        header: "Emissions",
        accessorKey: "co2Emission",
        enableGrouping: false,
        size: 140,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "CO2",
        accessorKey: "co2OnlyEmission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "CH4 Fossil",
        accessorKey: "ch4OnlyEmission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "CH4 Non-Fossil",
        accessorKey: "ch4NonFossilEmission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "N2O",
        accessorKey: "n2oOnlyEmission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
    ]
  },
  {
    id: 'Emissions - Market',
    header: 'Emissions - Market',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',
        },
      },
    },
    columns: [
      {
        header: "Emissions - Market",
        accessorKey: "marketCo2Emission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "CO2 - Market",
        accessorKey: "marketCo2OnlyEmission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "CH4 - Market",
        accessorKey: "marketCh4OnlyEmission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "CH4 Non-Fossil - Market",
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>CH4 Non-Fossil - Market</Box>,
        accessorKey: "marketCh4NonFossilEmission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "N2O - Market",
        accessorKey: "marketN2oOnlyEmission",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>tCO2eq</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell }) => {
          const value = cell.getValue();

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(4)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq</Label12Regular>
            </Box>
          )
        },
      },

    ]
  },
  {
    id: 'Intensity Input',
    header: 'Intensity Input',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',
        },
      },
    },
    columns: [
      {
        header: "Area",
        accessorKey: "intensityArea",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }
          const unit = row.original.intensityAreaUnit

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const unit = row.original.intensityAreaUnit

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(2)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>{unit}</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "Production",
        accessorKey: "intensityProductUnit",
        enableGrouping: false,
        aggregationFn: 'sum',

        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.unitsForIntensityProduct

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const unit = row.original.unitsForIntensityProduct

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(2)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>{unit}</Label12Regular>
            </Box>
          )
        },
      },
      {
        header: "Revenue",
        accessorKey: "intensityRevenue",
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.intensityRevenueUnit

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px" }}>{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const unit = row.original.intensityRevenueUnit

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              <Box sx={{ color: COLORS.lightgray }}>{(value as number).toFixed(2)}</Box>
              <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>{unit}</Label12Regular>
            </Box>
          )
        },
      },
    ]
  },
  {
    id: 'Emissions Intensity',
    header: 'Emissions Intensity',
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '16px',
          sm: '16px',
          md: '16px',
          lg: '16px',
          xl: '16px',
        },
      },
    },
    columns: [
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>By Area</Box>,
        header: "By Area",
        accessorKey: "emissionByIntensityArea",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.intensityAreaUnit

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "4px", mt: "2px" }}>tCO2eq/{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const totalEmission = cell.row._groupingValuesCache.co2Emission;
          const unit = row.original.intensityAreaUnit

          const getAverageValue = () => {
            if (totalEmission === 0 || value === 0) {
              return "-";
            } else {
              const avgValue = totalEmission / (value as number);
              return (
                <>
                  <Box sx={{ color: COLORS.lightgray }}>{(avgValue).toFixed(4)}</Box>
                  <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq/{unit}</Label12Regular>
                </>
              )
            }
          }

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              {getAverageValue()}
            </Box>
          )
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>By Production</Box>,
        header: "By Production",
        accessorKey: "emissionByIntensityProductUnit",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.unitsForIntensityProduct

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "4px", mt: "2px" }}>tCO2eq/{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const totalEmission = cell.row._groupingValuesCache.co2Emission;
          const unit = row.original.unitsForIntensityProduct

          const getAverageValue = () => {
            if (totalEmission === 0 || value === 0) {
              return "-";
            } else {
              const avgValue = totalEmission / (value as number);
              return (
                <>
                  <Box sx={{ color: COLORS.lightgray }}>{(avgValue).toFixed(4)}</Box>
                  <Label12Regular sx={{ ml: "5px", mt: "2px", color: COLORS.lightgray }}>tCO2eq/{unit}</Label12Regular>
                </>
              )
            }
          }

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              {getAverageValue()}
            </Box>
          )
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>By Revenue</Box>,
        header: "By Revenue",
        accessorKey: "emissionByIntensityRevenue",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.intensityRevenueUnit

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "4px", mt: "2px" }}>tCO2eq/{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const totalEmission = cell.row._groupingValuesCache.co2Emission;
          const unit = row.original.intensityRevenueUnit

          const getAverageValue = () => {
            if (totalEmission === 0 || value === 0) {
              return "-";
            } else {
              const avgValue = totalEmission / (value as number);
              return (
                <>
                  <Box sx={{ color: COLORS.lightgray }}>{(avgValue).toFixed(4)}</Box>
                  <Label12Regular sx={{ ml: "4px", mt: "2px", color: COLORS.lightgray }}>tCO2eq/{unit}</Label12Regular>
                </>
              )
            }
          }

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              {getAverageValue()}
            </Box>
          )
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>By Area - Market</Box>,
        header: "By Area - Market",
        accessorKey: "marketEmissionByIntensityArea",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.intensityAreaUnit

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "4px", mt: "2px" }}>tCO2eq/{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const totalEmission = cell.row._groupingValuesCache.co2Emission;
          const unit = row.original.intensityAreaUnit

          const getAverageValue = () => {
            if (totalEmission === 0 || value === 0) {
              return "-";
            } else {
              const avgValue = totalEmission / (value as number);
              return (
                <>
                  <Box sx={{ color: COLORS.lightgray }}>{(avgValue).toFixed(4)}</Box>
                  <Label12Regular sx={{ ml: "4px", mt: "2px", color: COLORS.lightgray }}>tCO2eq/{unit}</Label12Regular>
                </>
              )
            }
          }

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              {getAverageValue()}
            </Box>
          )
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>By Production - Market</Box>,
        header: "By Production - Market",
        accessorKey: "marketEmissionByIntensityProductUnit",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.unitsForIntensityProduct

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "4px", mt: "2px" }}>tCO2eq/{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const totalEmission = cell.row._groupingValuesCache.co2Emission;
          const unit = row.original.unitsForIntensityProduct

          const getAverageValue = () => {
            if (totalEmission === 0 || value === 0) {
              return "-";
            } else {
              const avgValue = totalEmission / (value as number);
              return (
                <>
                  <Box sx={{ color: COLORS.lightgray }}>{(avgValue).toFixed(4)}</Box>
                  <Label12Regular sx={{ ml: "4px", mt: "2px", color: COLORS.lightgray }}>tCO2eq/{unit}</Label12Regular>
                </>
              )
            }
          }

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              {getAverageValue()}
            </Box>
          )
        },
      },
      {
        Header: <Box sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>By Revenue - Market</Box>,
        header: "By Revenue - Market",
        accessorKey: "marketEmissionByIntensityRevenue",
        enableGrouping: false,
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          const unit = row.original.intensityRevenueUnit

          return (
            <Box sx={{ display: 'flex' }}>
              <Box>{(value as number)}</Box>
              <Label12Regular sx={{ ml: "4px", mt: "2px" }}>tCO2eq/{unit}</Label12Regular>
            </Box>
          );
        },
        AggregatedCell: ({ cell, row }) => {
          const value = cell.getValue();
          const totalEmission = cell.row._groupingValuesCache.co2Emission;
          const unit = row.original.intensityRevenueUnit

          const getAverageValue = () => {
            if (totalEmission === 0 || value === 0) {
              return "-";
            } else {
              const avgValue = totalEmission / (value as number);
              return (
                <>
                  <Box sx={{ color: COLORS.lightgray }}>{(avgValue).toFixed(4)}</Box>
                  <Label12Regular sx={{ ml: "4px", mt: "2px", color: COLORS.lightgray }}>tCO2eq/{unit}</Label12Regular>
                </>
              )
            }
          }

          return (
            <Box sx={{ display: 'flex', color: 'info.main' }}>
              {getAverageValue()}
            </Box>
          )
        },
      },
    ]
  },
];
