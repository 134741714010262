import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { CloseButton, COLORS, H5Medium, Label12SemiBold, TYPOGRAPHY_COMMON_STYLES } from "components";
import { useMutation } from "@tanstack/react-query";
import { updateUtilityFormat } from "api";
import { useNotify } from "hooks";

const DateFormatEditor: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  collectorId: number;
  data:any;
}> = ({ isOpen, onClose, collectorId, data }) => {
  const menuItemStyle = {
    fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
    fontSize: 14,
    lineHeight: "18px",
    color: "#90959E",
    "&:hover": {
      backgroundColor: "#F6F8FA",
      borderRadius: 5,
      color: COLORS.Romance,
    },
    "&.Mui-selected": {
      backgroundColor: "#F6F8FA",
      borderRadius: 5,
      color: COLORS.Romance,
    },
  };

  const formControlStyle = {
    minWidth: 140,
    height: 36,
    borderRadius: "8px",
    border: "1px solid #054254",
    overflow: "hidden",
    fontFamily: "Inter",
    "& .MuiOutlinedInput-root": {
      height: "100%",
      padding: 0,
      "& fieldset": {
        border: "none",
      },
    },
    "& .MuiSelect-select": {
      padding: "8px 12px",
      fontFamily: "Inter",
      fontSize: "14px",
    },
  };

  const notify = useNotify();

  const [firstBlock, setFirstBlock] = useState("");
  const [firstDiv, setFirstDiv] = useState("");
  const [secondBlock, setSecondBlock] = useState("");
  const [secondDiv, setSecondDiv] = useState("");
  const [thirdBlock, setThirdBlock] = useState("");

  // ---- API ----
  const useUpdateUtilityFormat = ({ onSuccess, onError }: any) =>
    useMutation({
      mutationFn: ({ id, body }: { id: any; body: any }) =>
        updateUtilityFormat(id, body),
      onSuccess,
      onError,
    });


  const { mutate: updateMutate} = useUpdateUtilityFormat({
    onSuccess: () => {
      notify.success('Date format updated successfully')
    },
    onError: (error: any) => {
      console.error('Update failed:', error);
    },
  });

  const blockOption = [
    { id: 1, name: "1 (DAY)", type: "day" },
    { id: 2, name: "1 (MONTH)", type: "month" },
    { id: 3, name: "JAN (MONTH)", type: "month" },
    { id: 4, name: "JANUARY (MONTH)", type: "month" },
    { id: 5, name: "YYYY (YEAR)", type: "year" },
    { id: 6, name: "YY (YEAR)", type: "year" },
    { id: 7, name: "NA", type: "na" },
  ];

  const dividerOption = [
    { id: 1, name: "/", value: "/"},
    { id: 2, name: "Space", value: " " },
    { id: 3, name: "-", value: "-" },
    { id: 4, name: ":", value: ":" },
    { id: 5, name: ".", value: "." },
    { id: 6, name: "NA", value: "_" },
  ];

  const getFilteredBlockOptions = (selectedType: string | null, includeNA = false) =>
    blockOption.filter(
      (option) =>
        option.type !== selectedType && // Exclude already selected type
        (includeNA ? true : option.type !== "na") // Exclude NA unless explicitly allowed
    );
  
  const filteredFirstBlockOptions = getFilteredBlockOptions(null, false); // First Block excludes NA
  
  const filteredSecondBlockOptions = getFilteredBlockOptions(
    blockOption.find((option) => option.name === firstBlock)?.type || null,
    false // Second Block excludes NA
  );
  
  const filteredThirdBlockOptions = blockOption.filter(
    (option) =>
      option.type !==
        blockOption.find((opt) => opt.name === firstBlock)?.type && // Exclude firstBlock type
      option.type !==
        blockOption.find((opt) => opt.name === secondBlock)?.type && // Exclude secondBlock type
      (option.type === "na" || option.type) // Allow "NA" or any valid type
  );

  const finalValue = firstBlock + firstDiv + secondBlock + secondDiv + thirdBlock;

  const handleSave = () => {
      if (collectorId && finalValue) {
        updateMutate({ 
          id: collectorId, 
          body: { dateFormat: finalValue } 
        });
      }
      onClose(); 
      setFirstBlock("")
      setFirstDiv("")
      setSecondBlock("")
      setSecondDiv("")
      setThirdBlock("") 
  };

  useEffect(() => {
    if (data?.dateFormat) {
      const formatParts = data.dateFormat.split(/([/:\-._])/); 

      setFirstBlock(formatParts[0] || "");
      setFirstDiv(formatParts[1] || "");
      setSecondBlock(formatParts[2] || "");
      setSecondDiv(formatParts[3] || "");
      setThirdBlock(formatParts[4] || "");
    }
  }, [data?.dateFormat]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle
      sx={{
        color: "#054254",
        fontWeight: "600",
        borderBottom: "1px solid #D9D9D9",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <CloseButton onClick={onClose} variant="text" />
    </DialogTitle>
    <DialogContent sx={{ padding: "24px" }}>
      <Box display="flex" flexDirection="column" gap="12px">
        <H5Medium sx={{ mt: 2 }}>Date Format Editor</H5Medium>
        <Box display="flex" gap="12px" alignItems="center" mt={2}>
          {/* First Block */}
          <Box>
            <Label12SemiBold>First Block</Label12SemiBold>
            <FormControl sx={formControlStyle}>
              <Select
                value={firstBlock}
                onChange={(e) => setFirstBlock(e.target.value)}
                displayEmpty
              >
                {filteredFirstBlockOptions.map((t) => (
                  <MenuItem key={t.id} sx={menuItemStyle} value={t.name}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* First Divider */}
          <FormControl sx={{ ...formControlStyle, mt: "18px" }}>
            <Select
              value={firstDiv}
              onChange={(e) => setFirstDiv(e.target.value)}
              displayEmpty
            >
              {dividerOption.map((t) => (
                <MenuItem key={t.id} sx={menuItemStyle} value={t.value}>
                  {t.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Second Block */}
          <Box>
            <Label12SemiBold>Second Block</Label12SemiBold>
            <FormControl sx={formControlStyle}>
              <Select
                value={secondBlock}
                onChange={(e) => setSecondBlock(e.target.value)}
                displayEmpty
              >
                {filteredSecondBlockOptions.map((t) => (
                  <MenuItem key={t.id} sx={menuItemStyle} value={t.name}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* Second Divider */}
          <FormControl sx={{ ...formControlStyle, mt: "18px" }}>
            <Select
              value={secondDiv}
              onChange={(e) => setSecondDiv(e.target.value)}
              displayEmpty
            >
              {dividerOption.map((t) => (
                <MenuItem key={t.id} sx={menuItemStyle} value={t.value}>
                  {t.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Third Block */}
          <Box>
            <Label12SemiBold>Third Block</Label12SemiBold>
            <FormControl sx={formControlStyle}>
              <Select
                value={thirdBlock}
                onChange={(e) => setThirdBlock(e.target.value)}
                displayEmpty
              >
                {filteredThirdBlockOptions.map((t) => (
                  <MenuItem key={t.id} sx={menuItemStyle} value={t.name}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ mt: 4 }}>
            <Label12SemiBold>New Format</Label12SemiBold>
            <H5Medium sx={{ color: COLORS.Romance }}>
              {firstBlock || "JAN (MONTH)"} {firstDiv || "/"}{" "}
              {secondBlock || "1 (DAY)"} {secondDiv || "/"}{" "}
              {thirdBlock || "YYYY (YEAR)"}
            </H5Medium>
          </Box>
          <Box sx={{ mt: 6 }}>
            <Button
              sx={{
                color: "#6B7280",
                fontWeight: "500",
                width: "140px",
                borderRadius: "8px",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "140px",
                borderRadius: "8px",
                backgroundColor: "#054254",
                color: "#fff",
                fontWeight: "500",
                "&:hover": { backgroundColor: "#033840" },
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogContent>
  </Dialog>
  );
};

export default DateFormatEditor;
