import React, { useEffect, useState } from "react";
import { Box, Pagination, Stack } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { COLORS } from "components";
import { chartColors } from "../const";

const commonStyles = {
  color: "black",
  fontFamily: "Inter",
  fontWeight: "600",
}

export const SiteChartYearOverYear = ({
  chartTitle = 'EmissionsChart',
  yoYData,
  mainTab,
  type,
}: {
  chartTitle?: string;
  yoYData?: any;
  mainTab?: string;
  type?: string;
}) => {
  const { site } = yoYData;

  const [currentPage, setcurrentPage] = useState<number>(1);
  const paginationLength: number = 4;

  const isEmissionsChart = type === "location" || type === "market";
  const isConsumption = mainTab === "Consumption";

  const data: any[] = site ? Object.values(site) : [];

  const siteCategoriesXAxis = site ? Object.keys(site) : [];

  const emissionType = isEmissionsChart ? `${type}BasedEmission` : isConsumption ? "consumption" : "amount";

  const fiscalYearsData: any = data.reduce((acc: any, entry: any) => {
    entry.forEach((item: any) => {
      if (!acc[item.fiscalYear]) {
        acc[item.fiscalYear] = [];
      }
      acc[item.fiscalYear].push({
        site: item.site,
        [emissionType]: item[emissionType] ?? 0,
      });
    });
    return acc;
  }, {});

  const series = Object.values(fiscalYearsData).map((entries: any) => {
    const emissionsArray = new Array(siteCategoriesXAxis.length).fill(0);

    entries.forEach((entry: any) => {
      const siteIndex = siteCategoriesXAxis.indexOf(entry.site);
      if (siteIndex !== -1) {
        emissionsArray[siteIndex] = entry[emissionType];
      }
    });

    return emissionsArray;
  });

  const seriesData = Object.keys(fiscalYearsData).map((fiscalYear, index) => ({
    type: "column",
    name: `FY${fiscalYear}`,
    data: series[index],
    color: chartColors[index % chartColors.length],
    fillOpacity: 0.3,
    lineWidth: 6,
    marker: { enabled: false },
  }));

  const isPaginationDisabled: boolean = siteCategoriesXAxis.length <= 4;
  const pages = siteCategoriesXAxis.length % paginationLength !== 0 ? Math.trunc(siteCategoriesXAxis.length / paginationLength) + 1 : siteCategoriesXAxis.length / paginationLength;

  const unitYAxis = isEmissionsChart ? "tCO2e" : data[0]?.[0]?.unit ?? data[0]?.[0]?.currency ?? "No Data Available";

  useEffect(() => {}, [site])

  const chartOptions = {
    chart: {
      spacing: [30, 50, 20, 40],
      width: null,
    },
    title: {
      text: chartTitle,
      align: "left",
      margin: 40,
      style: {
        ...commonStyles,
        color: COLORS.Mainblue,
        fontSize: "20px",
      },
    },
    xAxis: {
      categories: siteCategoriesXAxis.length > 4 ? siteCategoriesXAxis.slice((currentPage * paginationLength) - paginationLength, (currentPage * paginationLength)) : siteCategoriesXAxis,
      labels: {
        style: commonStyles,
      },
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: {
        text: unitYAxis,
        style: commonStyles,
        offset: 50
      },
      labels: {
        style: commonStyles,
      },
    },
    series: seriesData.map((serie: any) => ({
      ...serie,
      data: serie.data.length > 4 ? serie.data.slice((currentPage * paginationLength) - paginationLength, (currentPage * paginationLength)) : serie.data,
    })),
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
      column: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
    },
    legend: {
      title: {
        style: commonStyles,
      },
      align: "left",
      verticalAlign: "bottom",
      x: 20,
      margin: 30,
    },
    tooltip: {
      shared: true,
      borderRadius: 8,
      shadow: false,
      padding: 14,
      useHTML: true,
      outside: true,
      positioner(labelWidth: any, labelHeight: any, point: any) {
        return {
          x: point.plotX,
          y: point.plotY - labelHeight,
        };
      },
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return `
              <div style="font-size: 12px; padding: 8px; display: flex; flex-direction: column; gap: 5px;">
                <strong>${this!.x}</strong>
                ${this!.points
            ?.map(
              (point) => `
                  <div style="display: flex; align-items: center; justify-content: space-between; width: 180px;">
                    <div style="display: flex; align-items: center; gap: 6px;">
                      <span style="width: 10px; height: 10px; background: ${point.series.color}; border-radius: 50%; display: inline-block;"></span>
                      <span>${point.series.name}:</span>
                    </div>
                    <span style="font-weight: bold;">${Number(point.y!.toFixed(2)).toLocaleString('en-US')} ${unitYAxis}</span>
                  </div>
                `
            ).join("")}
              </div>
            `;
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      fontFamily: "Inter",
      position: "relative",
      "& > div": {
        borderRadius: 2,
      },
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
      {isPaginationDisabled
        ? ""
        : <Box sx={{
          position: "absolute",
          bottom: 22,
          right: 50,
        }}>
          <Stack spacing={2}>
            <Pagination
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "gray",
                },
                "& .Mui-selected": {
                  fontWeight: "600",
                  color: COLORS.Mainblue,
                }
              }}
              count={pages}
              page={currentPage}
              size="small"
              onChange={(event: any, page: number) => setcurrentPage(page)} />
          </Stack>
        </Box>
      }
    </Box>
  );
};
