import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box } from "@mui/material";
import { COLORS } from "components";
import { chartColors } from "../const";

const commonStyles = {
  color: "black",
  fontFamily: "Inter",
  fontWeight: "600",
}

export const VarianceChart = ({
  chartTitle = 'EmissionsChart',
  varianceData,
  type,
}: {
  chartTitle?: string;
  varianceData?: any;
  type?: string;
}) => {
  const { total, totalLastYear } = varianceData;

  const isEmissionsChart = type === "location" || type === "market";

  const currentData: any[] = total ? Object.values(total).flat() : [];
  const lastYearData: any[] = totalLastYear ? Object.values(totalLastYear).flat() : [];

  const totalCategories = currentData.map((entry: any) => entry.month + entry.year.replace("20", " '"));

  const currentYearTotalVariance = currentData.map((entry: any) => isEmissionsChart ? entry[`${type}BasedEmission`] : entry.consumption ?? entry.amount).map(variance => variance === null ? 0 : variance);
  const lastYearTotalVariance = lastYearData.map((entry: any) => isEmissionsChart ? entry[`${type}BasedEmission`] : entry.consumption ?? entry.amount).map(variance => variance === null ? 0 : variance);

  const unitYAxis = isEmissionsChart ? "tCO2e" : currentData[0]?.unit ?? lastYearData[0]?.unit ?? currentData[0]?.currency ?? lastYearData[0]?.currency ?? "No Data Available";

  useEffect(() => {}, [total, totalLastYear])

  const chartOptions = {
    chart: {
      spacing: [30, 50, 20, 40],
      width: null,
    },
    title: {
      text: chartTitle,
      align: "left",
      margin: 40,
      style: {
        ...commonStyles,
        color: COLORS.Mainblue,
        fontSize: "20px",
      },
    },
    xAxis: {
      categories: totalCategories,
      labels: {
        style: commonStyles,
      },
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: {
        text: unitYAxis,
        style: commonStyles,
        offset: 50
      },
      labels: {
        style: commonStyles,
      },
    },
    series: [
      {
        type: "area",
        name: "Current Period",
        data: currentYearTotalVariance,
        color: chartColors[0],
        fillOpacity: 0.3,
        lineWidth: 6,
        marker: { enabled: false },
      },
      {
        type: "area",
        name: "Same Period Last Year",
        data: lastYearTotalVariance,
        color: chartColors[1],
        fillOpacity: 0.3,
        lineWidth: 6,
        marker: { enabled: false, symbol: "circle" },
      },
    ],
    legend: {
      title: {
        style: commonStyles,
      },
      align: "left",
      verticalAlign: "bottom",
      x: 20,
      margin: 30,
    },
    tooltip: {
      shared: false,
      borderRadius: 27,
      backgroundColor: COLORS.Mainblue,
      shadow: false,
      padding: 10,
      style: {
        ...commonStyles,
        color: "white",
        fontSize: "12px",
      },
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')} ${unitYAxis}</b>`;
      }
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      fontFamily: "Inter",
      "& > div": {
        borderRadius: 2,
      },
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </Box>
  );
};
