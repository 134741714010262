import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { Box, styled } from "@mui/system";
import dayjs from "dayjs";
import { MRT_ColumnDef } from "material-react-table";
import React from "react";

import { EmissionFactorDTO, FilesRepoDTO, ReferencesDTO } from "api";

import { getDateFormat, isNullOrUndefined } from "utils";

import { Link } from "react-router-dom";
import { COLORS } from "components";

import Co2ConversionRatioTooltip from "../co2ConversionRatioTooltip";


export const EMPTY_CELL_VALUE = "-";

function toScientificNotation(number: any) {
  const scientificNotation = Number(number)?.toExponential();
  const formattedScientificNotation = scientificNotation.replace(/\.0+e/, "e");
  return formattedScientificNotation;
}
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});
export const EMISSION_FACTORS_COLUMNS: MRT_ColumnDef<EmissionFactorDTO>[] = [
  {
    id: "Utility",
    header: "Utility",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Type",
        accessorKey: "emissionFactorType",
        size: 95,
      },
      {
        header: "Utility",
        accessorKey: "utilityTypeName",
        size: 95,
      },
      {
        header: "SubType",
        accessorKey: "utilitySubtype",
        size: 122,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "System Unit",
        accessorKey: "measurementUnit",
        size: 145,
        minSize: 5,
        maxSize: 220,
        enableResizing: true,
      },
    ],
  },
  {
    id: "Period",
    header: "Period",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Valid From         ",
        accessorKey: "validFrom",
        Cell: ({ cell }) => {
          const value = cell.getValue();

          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }

          return dayjs(value as string)
            .utc()
            .format(getDateFormat());
        },
        size: 132,
      },
      {
        header: "Valid To",
        accessorKey: "validTo",
        Cell: ({ cell }) => {
          const value = cell.getValue();

          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }

          return dayjs(value as string)
            .utc()
            .format(getDateFormat());
        },
        size: 115,
      },
    ],
  },
  {
    id: "Region",
    header: "Region",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Region",
        accessorKey: "region",
        size: 110,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "Country",
        accessorKey: "country",
        size: 150,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "Country Region",
        accessorKey: "countryRegion",
        size: 150,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "Apply To",
        accessorKey: "applyTo",
        size: 150,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
    ],
  },
  {
    id: "GWP",
    header: "GWP",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "GWP Multipliers",
        accessorKey: "gwpMultipliers",
        size: 170,
      },
      {
        header: "GWP Source",
        accessorKey: "gwpSource",
        size: 148,
      },
    ],
  },
  {
    id: "Emission Factors",
    header: "Emissions Factors",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "CO2eq EF",
        accessorKey: "co2ConversionRatio",
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <CustomWidthTooltip
              title={
                <Co2ConversionRatioTooltip
                  value={value}
                  tooltipvalue={row.original.co2CalculationFormula}
                />
              }
              placement="top"
              arrow
            >
              <Box>{toScientificNotation(value as number)}</Box>
            </CustomWidthTooltip>
          );
        },
        size: 130,
      },

      {
        header: "CO2eq EF Unit",
        accessorKey: "co2ConversionUnit",
        size: 160,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "CO2 EF",
        accessorKey: "co2EmissionFactorRatio",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 110,
      },

      {
        header: "CO2 EF Unit",
        accessorKey: "co2EmissionFactorUnit",
        size: 142,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "CH4 Fossil EF",
        accessorKey: "ch4FossilEmissionFactorRatio",
        id: "ch4FossilEmissionFactorRatio",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 150,
      },
      {
        header: "CH4 Fossil EF Unit",
        accessorKey: "ch4FossilEmissionFactorUnit",
        size: 152,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "CH4 Non-Fossil EF",
        accessorKey: "ch4NonFossilEmissionFactorRatio",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 150,
      },
      {
        header: "CH4 Non-Fossil EF Unit",
        accessorKey: "ch4NonFossilEmissionFactorUnit",
        size: 152,
      },

      {
        header: "N2O EF",
        accessorKey: "n2oEmissionFactorRatio",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 110,
      },
      {
        header: "N2O EF Unit",
        accessorKey: "n2oEmissionFactorUnit",
        size: 142,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
    ],
  },
  {
    id: "Source",
    header: "Source",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Source",
        accessorKey: "source",
        size: 112,
      },
      {
        header: "Source Update Date",
        accessorKey: "sourceUpdateDate",
        size: 200,
      },
      {
        header: "ESG Flo Access Date",
        accessorKey: "esgFloAccessDate",
        Cell: ({ cell }) => {
          const value = cell.getValue();

          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }

          return dayjs(value as string)
            .utc()
            .format(getDateFormat());
        },
        size: 200,
      },
      {
        header: "Source Link",
        accessorKey: "sourceLink",
        size: 142,
      },
    ],
  },
];

export const CURRENCY_COLUMNS: MRT_ColumnDef<ReferencesDTO>[] = [
  {
    header: "From",
    accessorKey: "fromCurrency",
    size: 5,
    minSize: 5,
    maxSize: 10,
    enableResizing: true,
  },
  {
    header: "To",
    accessorKey: "toCurrency",
    size: 5,
    minSize: 5,
    maxSize: 10,
    enableResizing: true,
  },
  {
    header: "Conversion",
    accessorKey: "conversionValue",
    Cell: ({ cell }: any) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return <Box>{toScientificNotation(value as number)}</Box>;
    },
    size: 8,
    minSize: 5,
    maxSize: 10,
    enableResizing: true,
  },
  {
    header: "Last Updated",
    accessorKey: "updatedDate",
    Cell: ({ cell }) => {
      const value = cell.getValue();

      if (isNullOrUndefined(value) || value === 0) {
        return EMPTY_CELL_VALUE;
      }

      return dayjs(value as string)
        .utc()
        .format(getDateFormat());
    },
    size: 9,
    minSize: 5,
    maxSize: 10,
    enableResizing: true,
  },
  {
    id: "null",
    header: "",
    accessorKey: "",
    size: 20,
    enableResizing: false,
    enableColumnActions: false,
  },
];

export const SCIENTIFIC_UNIT_COLUMNS: MRT_ColumnDef<ReferencesDTO>[] = [
  {
    header: "Utility Type",
    accessorKey: "utilityType",
    size: 8,
    minSize: 15,
    maxSize: 20,
    enableResizing: true,
  },
  {
    header: "Utility Sub-Type",
    accessorKey: "utilitySubtype",
    size: 8,
    minSize: 15,
    maxSize: 20,
    enableResizing: true,
    Cell: ({ cell }: any) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value) || value === "") {
        return EMPTY_CELL_VALUE;
      }

      return <Box>{value}11</Box>;
    },
  },
  {
    header: "From",
    accessorKey: "toUnit",
    size: 5,
    minSize: 15,
    maxSize: 20,
    enableResizing: true,
  },
  {
    header: "To",
    accessorKey: "fromUnit",
    size: 5,
    minSize: 15,
    maxSize: 20,
    enableResizing: true,
  },

  {
    header: "Conversion",
    accessorKey: "conversionValue",
    Cell: ({ cell }: any) => {
      const value = cell.getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      return <Box>{toScientificNotation(value as number)}</Box>;
    },
    size: 8,
    minSize: 15,
    maxSize: 20,
    enableResizing: true,
  },

  {
    header: "Last Updated",
    accessorKey: "updatedDate",
    Cell: ({ cell }) => {
      const value = cell.getValue();

      if (isNullOrUndefined(value) || value === 0) {
        return EMPTY_CELL_VALUE;
      }

      return dayjs(value as string)
        .utc()
        .format(getDateFormat());
    },
    size: 9,
    minSize: 15,
    maxSize: 20,
    enableResizing: true,
  },
  {
    id: "null",
    header: "",
    accessorKey: "",
    size: 20,
    enableResizing: false,
    enableColumnActions: false,
  },
];

export const FILES_COLUMNS: MRT_ColumnDef<FilesRepoDTO>[] = [
  {
    accessorKey: "documentFileName",
    header: "Name",
    size: 150,
  },
  {
    accessorKey: "uploader",
    header: "Uploader",
    size: 150,
  },
  {
    accessorKey: "uploadDate",
    header: "Upload Date",
    size: 150,
    Cell: ({ cell }) => {
      const value = cell.getValue();

      if (isNullOrUndefined(value) || value === 0) {
        return EMPTY_CELL_VALUE;
      }

      return dayjs(value as string)
        .utc()
        .format(getDateFormat());
    },
  },
  {
    accessorKey: "internalNotes",
    header: "Internal Notes",
    size: 150,
    Cell: ({ cell }) => {
      const value = cell.getValue();

      if (isNullOrUndefined(value) || value === 0) {
        return EMPTY_CELL_VALUE;
      }

      return <span>{value as any}</span>;
    },
  },
  {
    accessorKey: "fiscalYear",
    header: "Fiscal Year",
    size: 150,
    Cell: ({ cell }) => {
      const value = cell.getValue();

      if (isNullOrUndefined(value) || value === 0) {
        return EMPTY_CELL_VALUE;
      }

      return <span>{value as any}</span>;
    },
  },
];

export const EMISSION_FACTORS_BY_SITE_COLUMNS: MRT_ColumnDef<any>[] = [
  {
    id: "Site_BySite",
    header: "Site",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Customer Site ID",
        accessorKey: "customerSiteId",
        size: 95,
      },
      {
        header: "Site Name",
        accessorKey: "siteName",
        size: 95,
      },
    ],
  },
  {
    id: "Utility_BySite",
    header: "Utility",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Type",
        accessorKey: "utilityTypeConversions.emissionFactorType",
        id: "emissionFactorType_Utility",
        size: 95,
      },
      {
        header: "Utility",
        accessorKey: "utilityTypeConversions.utilityTypeName",
        id: "utilityTypeName_Utility",
        size: 95,
      },
      {
        header: "SubType",
        accessorKey: "utilityTypeConversions.utilitySubtype",
        id: "utilitySubtype_Utility",
        size: 122,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "System Unit",
        accessorKey: "utilityTypeConversions.measurementUnit",
        id: "measurementUnit_Utility",
        size: 145,
        minSize: 5,
        maxSize: 220,
        enableResizing: true,
      },
    ],
  },
  {
    id: "Type_BySite",
    header: "Type",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "EF Type",
        accessorKey: "utilityTypeConversions.emissionFactorType",
        id: "gwpMultipliers_Type_BySite ",
        size: 170,
        Cell: ({ cell }) => {
          const value = cell.getValue();

          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          if (value !== "Standard") {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value}</Box>
          
        },
      },
      {
        header: "Market Based Type",
        accessorKey: "utilityTypeConversions.emissionFactorType",
        id: "gwpSource_Type_BySite",
        size: 148,
        Cell: ({ cell }) => {
          const value: any = cell.getValue();

          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          if (value === "Standard") {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value}</Box>
          
        },
      },
    ],
  },
  {
    id: "Period_BySite",
    header: "Period",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Valid From         ",
        accessorKey: "utilityTypeConversions.validFrom",
        id: "validFrom_Period",
        Cell: ({ cell }) => {
          const value = cell.getValue();

          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }

          return dayjs(value as string)
            .utc()
            .format(getDateFormat());
        },
        size: 132,
      },
      {
        header: "Valid To",
        accessorKey: "utilityTypeConversions.validTo",
        id: "validTo_Period",
        Cell: ({ cell }) => {
          const value = cell.getValue();

          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }

          return dayjs(value as string)
            .utc()
            .format(getDateFormat());
        },
        size: 115,
      },
    ],
  },
  {
    id: "Region_BySite",
    header: "Region",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Region",
        accessorKey: "utilityTypeConversions.region",
        id: "region",
        size: 110,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
    ],
  },
  {
    id: "GWP_BySite",
    header: "GWP",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "GWP Multipliers",
        accessorKey: "utilityTypeConversions.gwpMultipliers",
        id: "gwpMultipliers_GWP",
        size: 170,
      },
      {
        header: "GWP Source",
        accessorKey: "utilityTypeConversions.gwpSource",
        id: "gwpSource_GWP",
        size: 148,
      },
    ],
  },
 
  {
    id: "Emission_Factors_BySite",
    header: "Emissions Factors",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "CO2eq EF",
        accessorKey: "utilityTypeConversions.co2ConversionRatio",
        id: "co2ConversionRatio_EF",
        Cell: ({ cell, row }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            <CustomWidthTooltip
              title={
                <Co2ConversionRatioTooltip
                  value={value}
                  tooltipvalue={row.original.co2CalculationFormula}
                />
              }
              placement="top"
              arrow
            >
              <Box>{toScientificNotation(value as number)}</Box>
            </CustomWidthTooltip>
          );
        },
        size: 130,
      },
      {
        header: "CO2eq EF Unit",
        accessorKey: "utilityTypeConversions.co2ConversionUnit",
        id: "co2ConversionUnit_EF",
        size: 160,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "CO2 EF",
        accessorKey: "utilityTypeConversions.co2EmissionFactorRatio",
        id: "co2EmissionFactorRatio_EF",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 110,
      },
      {
        header: "CO2 EF Unit",
        accessorKey: "utilityTypeConversions.co2EmissionFactorUnit",
        id: "co2EmissionFactorUnit_EF",
        size: 142,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "CH4 Fossil EF",
        accessorKey: "utilityTypeConversions.ch4FossilEmissionFactorRatio",
        id: "ch4FossilEmissionFactorRatio_EF",

        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 150,
      },
      {
        header: "CH4 Fossil EF Unit",
        accessorKey: "utilityTypeConversions.ch4FossilEmissionFactorUnit",
        id: "ch4FossilEmissionFactorUnit_EF",
        size: 152,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
      {
        header: "CH4 Non-Fossil EF",
        accessorKey: "utilityTypeConversions.ch4NonFossilEmissionFactorRatio",
        id: "ch4NonFossilEmissionFactorRatio_EF",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 150,
      },
      {
        header: "CH4 Non-Fossil EF Unit",
        accessorKey: "utilityTypeConversions.ch4NonFossilEmissionFactorUnit",
        id: "ch4NonFossilEmissionFactorUnit_EF",
        size: 152,
      },
      {
        header: "N2O EF",
        accessorKey: "utilityTypeConversions.n2oEmissionFactorRatio",
        id: "n2oEmissionFactorRatio_EF",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 110,
      },
      {
        header: "N2O EF Unit",
        accessorKey: "utilityTypeConversions.n2oEmissionFactorUnit",
        id: "n2oEmissionFactorUnit_EF",
        size: 142,
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return <Box>{value as string}</Box>;
        },
      },
    ],
  },
  {
    id: "Emission_Factors_Standardized_BySite",
    header: "Emission Factors Standardized",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "CO2eq EF",
        accessorKey: "utilityTypeConversions.standardizedCo2ConversionRatio",
        id: "standardizedCo2ConversionRatio_EFS",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          return (
            // <CustomWidthTooltip
            //   title={
            //     <Co2ConversionRatioTooltip
            //       value={value}
            //       tooltipvalue={row.original.co2CalculationFormula}
            //     />
            //   }
            //   placement="top"
            //   arrow
            // >
            //   <Box>{toScientificNotation(value as number)}</Box>
            // </CustomWidthTooltip>
            <Box>{toScientificNotation(value as number)}</Box>
          );
        },
        size: 130,
      },
      {
        header: "CO2eq EF Unit",
        accessorKey: "utilityTypeConversions.co2ConversionUnit",
        id: "co2ConversionUnit_EFS",
        size: 160,
        Cell: () => <Box>T/kWh</Box>,
      },
      {
        header: "CO2 EF",
        accessorKey: "utilityTypeConversions.standardizedCo2OnlyEmissionFactorRatio",
        id: "standardizedCo2OnlyEmissionFactorRatio_EFS",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 110,
      },
      {
        header: "CO2 EF Unit",
        accessorKey: "utilityTypeConversions.co2EmissionFactorUnit",
        id: "co2EmissionFactorUnit_EFS",
        size: 142,
        Cell: () => <Box>T/kWh</Box>,
      },
      {
        header: "CH4 Fossil EF",
        accessorKey: "utilityTypeConversions.standardizedCh4FossilEmissionFactorRatio",
        id: "standardizedCh4FossilEmissionFactorRatio_EFS",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 150,
      },
      {
        header: "CH4 Fossil EF Unit",
        accessorKey: "utilityTypeConversions.ch4FossilEmissionFactorUnit",
        id: "ch4FossilEmissionFactorUnit_EFS",
        size: 152,
        Cell: () => <Box>T/kWh</Box>,
      },
      {
        header: "CH4 Non-Fossil EF",
        accessorKey: "utilityTypeConversions.standardizedCh4NonFossilEmissionFactorRatio",
        id: "standardizedCh4NonFossilEmissionFactorRatio_EFS",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 150,
      },
      {
        header: "CH4 Non-Fossil EF Unit",
        accessorKey: "utilityTypeConversionsch4NonFossilEmissionFactorUnit",
        id: "ch4NonFossilEmissionFactorUnit_EFS",
        size: 152,
        Cell: () => <Box>T/kWh</Box>,
      },
      {
        header: "N2O EF",
        accessorKey: "utilityTypeConversions.standardizedN2oEmissionFactorRatio",
        id: "standardizedN2oEmissionFactorRatio_EFS",
        Cell: ({ cell }: any) => {
          const value = cell.getValue();
          if (isNullOrUndefined(value)) {
            return EMPTY_CELL_VALUE;
          }

          // return <Box>{toUSNumber(value as number)}</Box>;
          return <Box>{toScientificNotation(value as number)}</Box>;
        },
        size: 110,
      },
      {
        header: "N2O EF Unit",
        accessorKey: "utilityTypeConversions.n2oEmissionFactorUnit",
        id: "n2oEmissionFactorUnit_EFS",
        size: 142,
        Cell: () => <Box>T/kWh</Box>,
      },
    ],
  },
  {
    id: "Source_BySite",
    header: "Source",
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: "16px",
          sm: "16px",
          md: "16px",
          lg: "16px",
          xl: "16px",
        },
      },
    },
    columns: [
      {
        header: "Source",
        accessorKey: "utilityTypeConversions.source",
        size: 112,
      },
      {
        header: "Source Update Date",
        accessorKey: "utilityTypeConversions.sourceUpdateDate",
        size: 200,
      },
      {
        header: "ESG Flo Access Date",
        accessorKey: "utilityTypeConversions.esgFloAccessDate",
        Cell: ({ cell }) => {
          const value = cell.getValue();

          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }

          return dayjs(value as string)
            .utc()
            .format(getDateFormat());
        },
        size: 200,
      },
      {
        header: "Source Link",
        accessorKey: "utilityTypeConversions.sourceLink",
        size: 142,
        Cell: ({ cell }) => {
          const value = cell.getValue();

          if (isNullOrUndefined(value) || value === 0) {
            return EMPTY_CELL_VALUE;
          }

          return <Link to={String(value)} target="_blank" style={{ color: COLORS.Romance }}>Link</Link>
        },
      },
    ],
  },
];
