import { useQuery } from "@tanstack/react-query";

import {
  EmissionFactorDTO,
  ScopePendingQuery,
  getEmissionFactors,
  getEmissionFactorsBySite,
  getScientificUnit,
  getScopePendingConponentpopupEmissionFactors,
  getScopePendingEmissionFactors,
  getScopePendingManualDropdownDataEmissionFactors,
  getScopePendingpopupEmissionFactors,
} from "api";

import { QUERY_KEYS } from "consts";

import { DOCUMENTS_REFETCH_INTERVAL } from "./consts";
import { OptionalRefetchIntervalAndRetry } from "./types";

export const useEmissionFactors = () =>
  useQuery<EmissionFactorDTO[]>(
    [QUERY_KEYS.EMISSION_FACTORS],
    getEmissionFactors,
  );

  export const useEmissionFactorsBySite = () =>
    useQuery<EmissionFactorDTO[]>(
      [QUERY_KEYS.EMISSION_FACTOR_BY_SITE],
      getEmissionFactorsBySite,
    );

  export const useScientificUnit = () =>
  useQuery<any>(
    [QUERY_KEYS.SCIENTIFIC_UNIT],
    getScientificUnit,
  );

export const useScopePendingEmissionFactors = ({
  refetchInterval = DOCUMENTS_REFETCH_INTERVAL,
  retry = false,
  ...query
}: ScopePendingQuery & OptionalRefetchIntervalAndRetry) =>
  useQuery<any>(
    [QUERY_KEYS.SCOPE_SITES_EMISSIONS, query],
    () => getScopePendingEmissionFactors(query),
    {
      refetchInterval,
      retry,
    },
  );

export const useScopePendingpopupEmissionFactors = (id: string) =>
  useQuery<any>([QUERY_KEYS.SCOPE_SITES_POPUP_EMISSIONS, id], () =>
    getScopePendingpopupEmissionFactors(id),
  );

export const useScopePendingConponentpopupEmissionFactors = (
  id: string,
  componentId: string,
) =>
  useQuery<any>([QUERY_KEYS.SCOPE_SITES_POPUP_EMISSIONS, id, componentId], () =>
    getScopePendingConponentpopupEmissionFactors(id, componentId),
  );

export const useScopePendingManualDropdownDataEmissionFactors = () =>
  useQuery<any>([QUERY_KEYS.SCOPE_SITES_POPUP_EMISSIONS], () =>
    getScopePendingManualDropdownDataEmissionFactors(),
  );
