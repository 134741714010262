import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { changeUtilityBillStatus, getUtilityBillById, setFeedback } from "api";

import { EsgArrowDown, ThumbDown, ThumbUP } from "assets";
import "./css/style.css"

import {
  CloseButton,
  COLORS,
  DrawerView,
  Loading,
  PdfFooter,
  PdfPreview,
  RoutedTabs,
  TYPOGRAPHY_COMMON_STYLES,
  UTILITY_BILL_STATUS_TO_BACKGROUND_COLOR,
  UTILITY_BILL_STATUS_TO_MESSAGE,
} from "components";

import { useGetConfigParameter, useNotify, useUtilityBillById, useUtilityBillWSConnection } from "hooks";

import { getFromLocalStorage, getLoggedUserData, setToLocalStorage } from "utils";
import { useMutation } from "@tanstack/react-query";

import { UTILITY_BILL_PAGE } from "./consts";
import { UTILITY_BILL_ALL_DATAENTRIES_VIEW } from "./utility-bill-allDataEntries-view/consts";
import { UTILITY_BILL_COMMENTS_VIEW } from "./utility-bill-comments-view";
import { UTILITY_BILL_FORM_VIEW } from "./utility-bill-form-view";

type UtilityBillStatusKey =
  | "APPROVED"
  | "DECLARED"
  | "MATCHED"
  | "CLEANED"
  | "DECLINED"
  | "ERROR"
  | "INCOMPLETE_DATA"
  | "PARSED"
  | "PROCESSING"
  | "PENDING"
  | "VERIFICATION_FAILED"
  | "SIGNED"
  | "LOCKED"
  | "Approved (Signed Off)"
  | "Approved (Locked)";

const changableStatus = [
  {
    label: "Warning (Conversion Error)", 
    name: "Warning (Conversion Error)"
  },
  {
    label: "Warning (Missing Fields)", 
    name: "Warning (Missing Fields)"
  },
  {
    label: "Warning (Potential Error Identified)", 
    name: "Warning (Potential Error Identified)"
  },
  {
    label: "Emissions Included (User Confirmed)", 
    name: "Emissions Included (User Confirmed)"
  },
]

const changableStatus2 = [
  {
    label: "Warning (Conversion Error)", 
    name: "Warning (Conversion Error)"
  },
  {
    label: "Warning (Missing Fields)", 
    name: "Warning (Missing Fields)"
  },
  {
    label: "Warning (Potential Error Identified)", 
    name: "Warning (Potential Error Identified)"
  },
  {
    label: "Emissions Included (User Confirmed)", 
    name: "Emissions Included (User Confirmed)"
  },
  {
    label: "Reviewed", 
    name: "Reviewed"
  },
]

export const UtilityBill: React.FunctionComponent = () => {
  const { billId } = useParams<{ [UTILITY_BILL_PAGE.KEY]: string }>();
  const { data } = useGetConfigParameter();

  const approvalFlowValue = data?.find((t) => t.name === "second_layer_approval_feature")
  const isApprovalFlowActive = approvalFlowValue?.configValue
  const dropDownStatusOption = isApprovalFlowActive === "true" ? changableStatus2 : changableStatus
  const { data: utilityBill, isLoading, refetch } = useUtilityBillById(billId!);
  const { mutateAsync: updateBillStatus, isLoading: isStatusLoading } = useMutation(changeUtilityBillStatus);

  const [isOpen, setIsOpen] = useState(!!billId);
  const [scale, setScale] = useState(0.75);
  const [scaleChange, setScaleChange] = useState(false);
  const [thumbUpClicked, setThumbUpClicked] = useState(utilityBill && utilityBill.feedback === "Thumbs_Up");
  const [thumbDownClicked, setThumbDownClicked] = useState(utilityBill && utilityBill.feedback === "Thumbs_Down");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const notify = useNotify();
  const loggedUserData = getLoggedUserData();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (status: any) => {

    const statusChangePayload = {
      "id": billId,
      status
  }
    if (status) {
      updateBillStatus(statusChangePayload, {
        onSuccess: () => {
          setSelectedStatus(status);
          // notify.success("")
        }, 
        onError: (e: any) => {
          notify.error(e?.response?.data)
            refetch();
        }
      })
    }
    setAnchorEl(null);
  };

  const algorithm = getFromLocalStorage("algorithm");
  const utilityBillStatus: UtilityBillStatusKey = (utilityBill as any)?.statusFilter!; 

  useUtilityBillWSConnection(billId!);
  const navigate = useNavigate();
  setToLocalStorage("setid", utilityBill?.id);
  const getType = getFromLocalStorage("type") || "";
  const onClose = () => {
    let getURL: string = "";
    if (getType.includes("Upload")) {
      getURL = getFromLocalStorage("Upload_URL") || "";
    }
    if (getType.includes("Emissions")) {
      getURL = getFromLocalStorage("Emission_URL") || "";
    }
    if (getType.includes("Summary")) {
      getURL = getFromLocalStorage("Summary_URL") || "";
    } 

    navigate(getURL);
    localStorage.removeItem("type");
    localStorage.removeItem("Upload_URL");

    setIsOpen((state) => !state);
  };

  const addFeedback = async (type: string) => {
    if (utilityBill) {
      try {
        await setFeedback({
          id: utilityBill.id,
          feedback: type,
        });

        if (type === "Thumbs_Up") {
          setThumbUpClicked(true);
          setThumbDownClicked(false);
        } else if (type === "Thumbs_Down") {
          setThumbDownClicked(true);
          setThumbUpClicked(false);
        }
      } catch (error) {
        console.error("Error updating feedback:", error);
      }
    }
  };

  useEffect(() => {
    const fetchUtilityBill = async () => {
      if (utilityBill) {
        const response = await getUtilityBillById(utilityBill.id);
        if (response.feedback === "Thumbs_Up") {
          setThumbUpClicked(true);
          setThumbDownClicked(false);
        } else if (response.feedback === "Thumbs_Down") {
          setThumbDownClicked(true);
          setThumbUpClicked(false);
        }
      }
    };

    fetchUtilityBill();
  }, [utilityBill, billId, thumbDownClicked, thumbUpClicked]);


  useEffect(() => {
    setSelectedStatus(UTILITY_BILL_STATUS_TO_MESSAGE[utilityBillStatus])
  },[utilityBillStatus])

  if (scale <= 0.25) {
    setScale(0.75);
  }

  function checkURL(url: any) {
    return !url.includes('/Data_Entries') && !url.includes('/comments');
  }

  const isAuditor = loggedUserData?.roles?.includes("AUDITOR")
  const isDataProvider = loggedUserData?.roles?.includes("DATA_PROVIDER")
  const showDropDown = checkURL(window.location.pathname)

  return (
    <DrawerView isOpen={isOpen} onClose={onClose}>
      {(isLoading || isStatusLoading) ? (
        <Loading />
      ) : (
        utilityBill && (
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                p: 3,
                flexGrow: 1,
                flexDirection: "row",
                overflow: "auto",
              }}
            >
              <PdfPreview
                downloadUrl={utilityBill.downloadUrl}
                scale={scale}
                isScaleChange={scaleChange}
                billId={billId}
              />
              <Box
                sx={{
                  width: "40%",
                  ml: 4,
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {(algorithm === "IDP Fallback" ||
                  algorithm === "IDP A/B Testing") && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#F1F7FF",
                          padding: "20px",
                          borderRadius: "10px",
                          maxWidth: "1034px",
                          marginRight: "10px",
                          border: '1px solid',
                          borderColor: '#BFDBFE'
                        }}
                      >
                        <div
                          style={{
                            color: "#1E40AF",
                            fontSize: "18px",
                            fontWeight: "700",
                          }}
                        >
                          Data Populated with New Algorithm
                        </div>
                        <div
                          style={{
                            marginTop: "15px",
                            fontSize: "16px",
                            color: "#636976",
                            fontWeight: "500",
                            maxWidth: "960px",
                            lineHeight: "23px",
                          }}
                        >
                          We’re beta testing our new AI technology as part of our
                          product enhancement initiative. Please review the bill
                          below to ensure that the data has been extracted
                          accurately.{" "}
                        </div>
                        <div
                          style={{
                            marginTop: "15px",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "900px",
                          }}
                        >
                          If the bill is 100% accurate, please indicate with a
                          Thumbs Up. If inaccurate, please indicate with a Thumbs
                          Down and correct the data below.
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "15px",
                            gap: '1rem'
                          }}
                        >
                          <div
                            className="thumbUp"
                            role="button"
                            onClick={() => {
                              addFeedback("Thumbs_Up");
                            }}
                            tabIndex={0}
                            onKeyDown={() => { }}
                            style={{
                              backgroundColor: thumbUpClicked
                                ? "#C7E9C1"
                                : "white",
                              border: `1px solid ${thumbUpClicked ? '#5CB152' : '#E5E7EB'}`
                            }}
                          >
                            <ThumbUP
                              onClick={() => {
                                addFeedback("Thumbs_Up");
                              }}
                            />
                          </div>
                          <div
                            onClick={() => {
                              addFeedback("Thumbs_Down");
                            }}
                            tabIndex={0}
                            onKeyDown={() => { }}
                            role="button"
                            className="thumbDown"
                            style={{
                              backgroundColor: thumbDownClicked
                                ? "#F5C5C5"
                                : "white",
                              border: `1px solid ${thumbDownClicked ? '#991B1B' : '#E5E7EB'}`
                            }}
                          >
                            <ThumbDown
                              onClick={() => {
                                addFeedback("Thumbs_Down");
                              }}
                            />
                          </div>
                        </div>
                      </Box>

                      {/* <CloseButton onClick={onClose} variant="outlined" /> */}
                    </Box>
                  )}

                  <Box
                    sx={{
                      mb: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 3,
                    }}
                  >
                    {/* DropDown */}
                    {showDropDown ? <Box sx={{ display: "flex" }}>
                      <Button
                        endIcon={<EsgArrowDown />}
                        variant="outlined"
                        disabled={(utilityBillStatus === "Approved (Signed Off)" || utilityBillStatus === "Approved (Locked)") || isAuditor}
                        sx={{ alignItems: "center", gap: 2, display: "flex", justifyContent: "center", borderRadius: "8px" }}
                        onClick={handleClick}
                      >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, padding: "2px 6px", borderRadius: "12px" }}>
                          <Box
                            sx={{
                              bgcolor: UTILITY_BILL_STATUS_TO_BACKGROUND_COLOR[utilityBillStatus],
                              height: "10px",
                              width: "10px",
                              borderRadius: "50%",
                            }}
                          />
                          <Box sx={{ color: "black" }}>{selectedStatus}</Box>
                        </Box>
                      </Button>
                      <Menu 
                      anchorEl={anchorEl} 
                      open={Boolean(anchorEl)} 
                      onClose={() => handleClose(null)}
                      >
                        {dropDownStatusOption.map((status) => (
                          <MenuItem
                            disabled={isDataProvider && status?.name === "Reviewed"}
                            sx={{
                              fontWeight:
                                TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                              fontSize: 14,
                              lineHeight: "18px",
                              color: "#90959E",
                              "&:hover": {
                                backgroundColor: "#F6F8FA",
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                              "&.Mui-selected": {
                                backgroundColor: "#F6F8FA",
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                            }}
                            key={status?.name} 
                            onClick={() => handleClose(status?.name)}>
                            {status?.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box> : <Box/>}
                    {(algorithm !== "IDP Fallback" ||
                      algorithm !== "IDP A/B Testing") && (
                        <CloseButton onClick={onClose} variant="outlined" />
                      )}
                  </Box>
                <Box 
                  sx={{
                    "& > div > div:nth-child(2) > div > div:first-child > div:nth-child(2)": {
                      display: 'flex',
                      "@media (max-width: 1440px)": {
                        "& > button:first-child": {
                          marginLeft: 2,
                        },
                        "& > button": {
                          height: '45px',
                        }
                      }
                    },
                    "@media (min-width: 1440px)": {
                      "& > div > div:nth-child(2) > div > div:nth-child(2) > div": {
                        width: "100%",
                      },
                    },
                    flexGrow: 1,
                    overflow: "hidden"
                  }}
                >
                  <RoutedTabs
                    tabs={[
                      {
                        label: UTILITY_BILL_ALL_DATAENTRIES_VIEW.TITLE,
                        to: UTILITY_BILL_ALL_DATAENTRIES_VIEW.ABSOLUTE_PATH(
                          utilityBill.id,
                        ),
                        disable: false,
                      },
                      {
                        icon: UTILITY_BILL_FORM_VIEW.ICON,
                        label: UTILITY_BILL_FORM_VIEW.TITLE,
                        to: UTILITY_BILL_FORM_VIEW.ABSOLUTE_PATH(
                          utilityBill.id,
                        ),
                        disable: false,
                      },
                      {
                        icon: UTILITY_BILL_COMMENTS_VIEW.ICON,
                        label: UTILITY_BILL_COMMENTS_VIEW.TITLE(
                          utilityBill.numberOfComments,
                        ),
                        to: UTILITY_BILL_COMMENTS_VIEW.ABSOLUTE_PATH(
                          utilityBill.id,
                        ),
                        disable: false,
                      },
                    ]}
                  />
                </Box>
              </Box>
            </Box>

            <PdfFooter
              fileName={utilityBill.filename}
              setScale={setScale}
              downloadUrl={utilityBill.downloadUrl as string}
              actionsDisabled={isLoading}
              setScaleChange={setScaleChange}
            />
          </Box>
        )
      )}
    </DrawerView>
  );
};

