/* eslint-disable */
import { Box } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import React from "react";

import { Bill } from "api";

import {
  EMPTY_CELL_VALUE,
  Tooltip,
} from "components";

import { TABLE_HEADERS } from "consts";

import { theme } from "providers";

import {
  getFromLocalStorage,
  getUTCDateFormat,
  isNullOrUndefined,
  toUSNumber,
} from "utils";

// eslint-disable-next-line
import { PreviewUtilityBill } from "./preview-utility-bill";

export const DOCUMENT_UTILITY_BILLS_COLUMNS: ColumnDef<Bill>[] = [
  {
    header: () => <Tooltip title="Utility type"><Box>Utility type</Box></Tooltip>,
    accessorKey: "type",
    cell: ({ getValue }) => {
      const type = getValue() as string | null;
      if (!type) {
        return EMPTY_CELL_VALUE;
      }
      return <Tooltip title={type}>
        <Box>{type}</Box>
      </Tooltip>;
    },
    minSize: 70,
  },
  {
    header: () => <Tooltip title="Start Date"><Box>Start Date</Box></Tooltip>,
    accessorKey: "consumptionPeriodFrom",
    cell: ({ getValue, row }) => {
      const date: string = getValue() as string;
      const value = date && dayjs(date).utc().format(getUTCDateFormat());
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }

      return <Tooltip title={value}>
        <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 90,
  },
  {
    header: () => <Tooltip title="End Date"><Box>End Date</Box></Tooltip>,
    accessorKey: "consumptionPeriodTo",
    cell: ({ getValue, row }) => {
      const date: string = getValue() as string;
      const value = date && dayjs(date).utc().format(getUTCDateFormat());
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return <Tooltip title={value}>
        <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 90,
  },
  {
    header: () => <Tooltip title="Provider"><Box>Provider</Box></Tooltip>,
    accessorKey: "provider",
    cell: ({ getValue, row }) => {
      const value = getValue() as string;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return <Tooltip title={value}>
        <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 80,
  },
  {
    header: () => <Tooltip title={TABLE_HEADERS.accountNumber}><Box>{TABLE_HEADERS.accountNumber}</Box></Tooltip>,
    accessorKey: "accountNumber",
    cell: ({ getValue, row }) => {
      const value = getValue() as string;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return <Tooltip title={value}>
        <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 70,
  },
  {
    header: () => <Tooltip title={TABLE_HEADERS.meterNumber}><Box>{TABLE_HEADERS.meterNumber}</Box></Tooltip>,
    accessorKey: "meterNumber",
    cell: ({ getValue, row }) => {
      const value = getValue() as string;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return <Tooltip title={value}>
        <Box sx={{ textAlign: "center", ...style }}>{`${value}`}</Box>
      </Tooltip>;
    },
    minSize: 85,
  },
  {
    header: () => <Tooltip title="Usage"><Box>Usage</Box></Tooltip>,
    accessorKey: "measuredUsageValue",
    minSize: 55,
    cell: ({ getValue, row }) => {
      const value = getValue();
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return (
        <Tooltip title={`${toUSNumber(value as number)}${row.original?.measuredUsageUnit === null ? "" : row.original?.measuredUsageUnit}`}>
          <Box sx={{ textAlign: "center", ...style }}>
            {`${toUSNumber(value as number)} ${row.original?.measuredUsageUnit === null ? "" : row.original?.measuredUsageUnit}`}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: () => <Tooltip title="Cost"><Box>Cost</Box></Tooltip>,
    accessorKey: "amount",
    minSize: 50,
    cell: ({ getValue, row }) => {
      const value = getValue() as number;
      if (isNullOrUndefined(value)) {
        return EMPTY_CELL_VALUE;
      }
      const selectedRow: any = getFromLocalStorage("selectedRow");
      let style = {};
      if (selectedRow) {
        const rowSelected: any = JSON.parse(selectedRow);
        const rowDisplay: any = row.original;
        if (rowSelected.id === rowDisplay.id) {
          style = { backgroundColor: theme.palette.grey[300], color: "black" };
        }
      }
      return (
        <Tooltip title={`${toUSNumber(value)} ${row.original?.currency === null ? "" : row.original?.currency}`}>
          <Box sx={{ textAlign: "right", ...style }}>
            {`${toUSNumber(value)} ${row.original?.currency === null ? "" : row.original?.currency}`}</Box>
        </Tooltip>
      );
    },
  },
  {
    header: () => <Tooltip title="Status"><Box>Status</Box></Tooltip>,
    accessorKey: "statusFilter",
    cell: ({ cell, row }: any) => {
      const bills = cell?.getValue() as Array<any>;
      const statusOfDocument = bills as any;
      const missingFields = row?.original?.missingFields;

      const getStatusPriority = (status: string) => {
        if (status === "Warning (Conversion Error)") {
          return 0;
        } else if (status === "Warning (Missing Fields)") {
          return 1;
        } else if (status === "Warning (Potential Error Identified)") {
          return 2;
        } else if (status === "Emissions Included (AI Generated)") {
          return 3;
        } else if (status === "Emissions Included (User Confirmed)") {
          return 4;
        } else if (status === "Approved (Signed Off)") {
          return 5;
        } else if (status === "Approved (Locked)") {
          return 6;
        } else if (status === "Reviewed") {
          return 7;
        } else {
          return 8;
        }
      };

      const getStatusLabel = (statusKey: any) => {
        if (statusKey === "Warning (Conversion Error)") {
          return "Warning (Conversion Error)";
        } else if (statusKey === "Warning (Missing Fields)") {
          return `Warning (Missing Fields) ${missingFields?.length > 0 ? `(${missingFields?.length})` : ""}`;
        } else if (statusKey === "Warning (Potential Error Identified)") {
          return "Warning (Potential Error Identified)";
        } else if (statusKey === "Emissions Included (AI Generated)") {
          return "Emissions Included (AI Generated)";
        } else if (statusKey === "Emissions Included (User Confirmed)") {
          return "Emissions Included (User Confirmed)";
        } else if (statusKey === "Approved (Signed Off)") {
          return "Approved (Signed Off)";
        } else if (statusKey === "Approved (Locked)") {
          return "Approved (Locked)";
        } else if (statusKey === "Reviewed") {
          return "Reviewed";
        } else {
          return "";
        };
      };

      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "4px",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          {/* <Tooltip
            placement="top"
            title={
              getStatusPriority(statusOfDocument) === 0
                ? "There was an error converting the bill information to emissions data"
                : getStatusPriority(statusOfDocument) === 1
                  ? missingFields.length > 0
                    ? (
                      <div style={{ textAlign: "left" }}>
                        {missingFields.map((t: any, index: any) => (
                          <div key={index}>• {t}</div>
                        ))}
                      </div>
                    )
                    : ""
                  : getStatusPriority(statusOfDocument) === 2
                    ? <span style={{ whiteSpace: "pre-line" }}>{errorMessage}</span>
                    : getStatusPriority(statusOfDocument) === 3
                      ? "Associated emissions from 100% AI extraction have been included"
                      : getStatusPriority(statusOfDocument) === 4
                        ? "Associated emissions have been included following user confirmation of a warning message"
                        : getStatusPriority(statusOfDocument) === 5 || getStatusPriority(statusOfDocument) === 6
                          ? "This bill is for a site and time period that has been signed off or locked"
                          : ""
            }
          > */}
            <span style={{ position: "relative" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 5 5"
                fill={
                  getStatusPriority(statusOfDocument) === 0
                    ? "#DD1515"
                    : getStatusPriority(statusOfDocument) === 1
                      ? "#DD1515"
                      : getStatusPriority(statusOfDocument) === 2
                        ? "#DD1515" :
                        getStatusPriority(statusOfDocument) === 3
                          ? "#9EE766" :
                          getStatusPriority(statusOfDocument) === 4
                            ? "#9EE766" :
                            getStatusPriority(statusOfDocument) === 5
                              ? "#0E25D5" :
                              getStatusPriority(statusOfDocument) === 6
                                ? "#1D1D1D" :
                                getStatusPriority(statusOfDocument) === 7
                                  ? "#00A1FF"
                                : "none"
                }
                style={{ marginRight: "9px" }}
              >
                <circle cx="2.5" cy="2.5" r="2.5" />
              </svg>
              {getStatusLabel(statusOfDocument)}
              {/* <span>({totalMissingFields})</span> */}
            </span>
          {/* </Tooltip> */}
        </Box>
      );
    },
    minSize: 150,
  },
  {
    id: "preview",
    header: "",
    accessorKey: "id",
    cell: ({ getValue, row }) => (
      <PreviewUtilityBill
        id={getValue() as string}
        rowData={JSON.stringify(row?.original)}
      />
    ),
    minSize: 60,
  },
];
