import React, { useEffect, useState } from 'react'

import { Box } from '@mui/system';
import { Loading } from 'components';
import { DashboardTabs } from '../dashboards/components/dashboardTabs'
import { TabSite } from './tab-site';
import { RenewableEnergyPurchases } from './renwable-energy-purchases-page';


export const SitesPageV2 = () => {
  const [value, setValue] = useState<string>("Active");
  const [selectedTabName, setSelectedTabName] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const SITES = "Active";

  const tabs: any = {
    [SITES]: [
      {
        label: "Active",
        content: <TabSite state="active" />,
      },
      {
        label: "Inactive",
        content: <TabSite state="inactive" />,
      },
      {
        label: "Renewable Energy Purchases",
        content: <RenewableEnergyPurchases />,
      },
    ],
  };

  const handleTabChange = (tabName: any) => {
    sessionStorage.setItem("siteTab", JSON.stringify(tabName))
    if (tabName === 0) {
      setValue("Active");
    } else if (tabName === 1){
      setValue("Inactive");
    } else {
      setValue("Renewable Energy Purchases");
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const selectedTab = sessionStorage.getItem("siteTab");
    setSelectedTabName(selectedTab!);
    setIsLoading(false)
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (selectedTabName) {
      if (selectedTabName === "0") {
        setValue("Active")
      } else if (selectedTabName === "1"){
        setValue("Inactive")
      } else {
        setValue("Renewable Energy Purchases")
      }
    }
    setIsLoading(false)
  }, [selectedTabName])

  if (isLoading) return <Loading />;
  return (
    <Box sx={{ ml: 3 }}>
      <DashboardTabs intensity tabs={tabs[SITES]} selectedBtnOption={value} onTabChange={handleTabChange} />
    </Box>
  )
}
