import React, { ForwardedRef, forwardRef } from "react";
import { Autocomplete, AutocompleteProps } from "@mui/material";

import { COLORS } from "components/consts";
import { EsgArrowDown } from "assets";
import { TextField, TextFieldProps } from "./text-field";

export const AutocompleteField = forwardRef(
  <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
  >(
    {
      autocompleteProps,
      textFieldProps,
      disabled
    }: {
      disabled?: any;
      textFieldProps: TextFieldProps;
      autocompleteProps: Omit<
        AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
        "renderInput"
      >;
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <Autocomplete
      ref={ref}
      size="small"
      fullWidth
      clearIcon={null}
      clearOnBlur={false}
      popupIcon={<EsgArrowDown />}
      blurOnSelect
      disabled={disabled}
      sx={{
        
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "grey",
          fontFamily: "Inter"
        },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.Romance,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.Romance,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.Romance,
          },
          "& input.MuiInputBase-.Mui-disabled": {
            WebkitTextFillColor: "grey",
          },
          "& .MuiAutocomplete-input": {
            fontStyle: "Inter",
            fontSize:'14px',
            color: COLORS.Romance,
            fontWeight: 600
          },
        "& > div > div > div": {
          height: "36px",
          color: "#354950",
          borderRadius: "8px",
          "& input": {
            padding: "0 0 0 8px !important",
          }
        },
      }}
      slotProps={{
        popper: {
          sx: {
            "& .MuiAutocomplete-listbox": {
              fontFamily: "Inter !important",
              fontSize: "14px",
              color: "#354950",
            },
          },
        },
      }}
      {...autocompleteProps}
      
      renderInput={(params) => <TextField {...params} {...textFieldProps}  />}
    />
  ),
);
