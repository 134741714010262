
// import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Co2ConversionRatioTooltip = ({ tooltipvalue }: any) => {
    const valuesArray = tooltipvalue?.split('||');

    let value1 = '';
    let value2 = '';
    let value3 = '';

    valuesArray?.forEach((value: string) => {
        const trimmedValue = value?.trim() || '';

        if (trimmedValue.startsWith('CO2 EF')) {
            value1 = trimmedValue;
        } else if (trimmedValue.startsWith('CH4 EF')) {
            value2 = trimmedValue;
        } else if (trimmedValue.startsWith('N20 EF')) {
            value3 = trimmedValue;
        }
    });

    const valuesArray1 = value1?.split('+');
    const valuesArray2 = value2?.split('+');
    const valuesArray3 = value3?.split('+');

    const co2formula = valuesArray1[0]?.trim() || '';
    const co2value = valuesArray1[1]?.trim() || '';

    const ch4formula = valuesArray2[0]?.trim() || '';
    const ch4value = valuesArray2[1]?.trim() || '';
    const n2oformula = valuesArray3[0]?.trim() || '';
    const n2ovalue = valuesArray3[1]?.trim() || '';

    const tokenizeFormula = (formula: string) => {
        const tokens = formula.split(/([*/]+)/);
        return tokens.filter(token => token.trim() !== '')
    };
    const tokenizeFormula1 = (formula: string) => {
        const tokens = formula.split(/([[\]]+|[*/]+)/);
        return tokens.filter(token => token.trim() !== '');
    };

    const colorArray = ["#BCE8E5", "#FFDB96", "#C7E9C1", "#FFD6D1"];
    const colorArray1 = ["#BCE8E5", "#FFDB96", "#FFD6D1"];
    let colorIndexco2 = 0;
    let colorIndexch4 = 0;
    let colorIndexn2o = 0;
    let colorIndexco2Value = 0;
    let colorIndexch4Value = 0;
    let colorIndexn2oValue = 0;
    return (
        <Box sx={{ height: '100%', width: '100%', p: "8px" }}>
            <div style={{ height: "12px", width: "200px" }}>
                <p style={{ fontSize: "11px", fontFamily: "Manrope", fontWeight: "500" }}>CO2 Equivalent Calculations</p>
            </div>
            <hr style={{ border: '-1px solid #FFFFFF' }} />

            <Box sx={{ mt: 3 }}>
                {value1 &&
                    <p style={{ fontFamily: 'Manrope', fontWeight: '500', fontStyle: 'normal', lineHeight: 'normal' }}>
                        CO2 =
                        {tokenizeFormula(co2formula).map((part, index) => {
                            const isOperator = ['*', '/'].includes(part);
                            let color;
                            if (isOperator) {
                                color = 'white'
                            } else {

                                color = isOperator ? 'white' : colorArray1[colorIndexco2];
                                colorIndexco2 += 1
                            }

                            return (

                                <span key={`co2-${index + 1}`} style={{ color }}>
                                    &nbsp;{part}&nbsp;{index < part.length - 1 && <span style={{ color: 'white' }}>&nbsp;  </span>}
                                </span>
                            );
                        })}<br /><span style={{ marginLeft: "12%" }}>
                            {tokenizeFormula1(co2value).map((part, index) => {
                                const isOperator = ['*', '/', '[', ']'].includes(part);
                                let color;
                                if (isOperator) {
                                    color = 'white'
                                } else {

                                    color = isOperator ? 'white' : colorArray1[colorIndexco2Value];
                                    colorIndexco2Value += 1
                                }
                                return (
                                    <span key={`co2-${index + 1}`} style={{ color }}>
                                        &nbsp;{part}&nbsp;{index < part.length - 1 && <span style={{ color: 'white' }}>&nbsp;  </span>}
                                    </span>
                                );
                            })}
                        </span>
                    </p>}

                {
                    value2 && <p style={{ fontFamily: 'Manrope', fontWeight: '500', fontStyle: 'normal', lineHeight: 'normal' }}>
                        CO2eq of CH4 =
                        {tokenizeFormula(ch4formula).map((part, index) => {
                            const isOperator = ['*', '/'].includes(part);
                            let color;
                            if (isOperator) {
                                color = 'white'
                            } else {

                                color = isOperator ? 'white' : colorArray[colorIndexch4];
                                colorIndexch4 += 1
                            }

                            return (

                                <span key={`co2-${index + 1}`} style={{ color }}>
                                    &nbsp;{part}&nbsp;{index < part.length - 1 && <span style={{ color: 'white' }}>&nbsp;  </span>}
                                </span>
                            );
                        })}<br />
                        <span style={{ marginLeft: "20%" }}>
                            {tokenizeFormula1(ch4value).map((part, index) => {
                                const isOperator = ['*', '/', '[', ']'].includes(part);
                                let color;
                                if (isOperator) {
                                    color = 'white'
                                } else {

                                    color = isOperator ? 'white' : colorArray[colorIndexch4Value];
                                    colorIndexch4Value += 1
                                }
                                return (
                                    <span key={`co2-${index + 1}`} style={{ color }}>
                                        &nbsp;{part}&nbsp;{index < part.length - 1 && <span style={{ color: 'white' }}>&nbsp;  </span>}
                                    </span>
                                );
                            })}
                        </span>
                    </p>
                }
                {value3 && <p style={{ fontFamily: 'Manrope', fontWeight: '500', fontStyle: 'normal', lineHeight: 'normal' }}>
                    CO2eq of N2O =
                    {tokenizeFormula(n2oformula).map((part, index) => {
                        const isOperator = ['*', '/'].includes(part);
                        let color;
                        if (isOperator) {
                            color = 'white'
                        } else {

                            color = isOperator ? 'white' : colorArray[colorIndexn2o];
                            colorIndexn2o += 1
                        }
                        return (

                            <span key={`co2-${index + 1}`} style={{ color }}>
                                &nbsp;{part}&nbsp;{index < part.length - 1 && <span style={{ color: 'white' }}>&nbsp;  </span>}
                            </span>
                        );
                    })}<br />
                    <span style={{ marginLeft: "20%" }}>
                        {tokenizeFormula1(n2ovalue).map((part, index) => {
                            const isOperator = ['*', '/', '[', ']'].includes(part);
                            let color;
                            if (isOperator) {
                                color = 'white'
                            } else {

                                color = isOperator ? 'white' : colorArray[colorIndexn2oValue];
                                colorIndexn2oValue += 1
                            }
                            return (
                                <span key={`co2-${index + 1}`} style={{ color }}>
                                    &nbsp;{part}&nbsp;{index < part.length - 1 && <span style={{ color: 'white' }}>&nbsp;  </span>}
                                </span>
                            );
                        })}
                    </span>
                </p>}

            </Box>
        </Box>
    );
};

export default Co2ConversionRatioTooltip;

