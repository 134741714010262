import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box } from "@mui/material";
import { COLORS } from "components";

const commonStyles = {
  color: "black",
  fontFamily: "Inter",
  fontWeight: "600",
}

export const EstimatesChart = ({
  chartTitle = 'EmissionsChart',
  dateRangeData,
  dateRangeType,
  mainTab,
  type,
}: {
  chartTitle?: string;
  dateRangeData?: any;
  dateRangeType?: string;
  mainTab?: string;
  type?: string;
}) => {
  const { estimate } = dateRangeData;

  const isEmissionsChart = type === "location" || type === "market";
  const isConsumption = mainTab === "Consumption";

  const data: any[] = estimate ? Object.values(estimate).flat() : [];
  const estimateCategoriesXAxis = Array.from(new Set(data.map((entry: any) =>
    dateRangeType === "MONTHLY" && entry.year !== null
      ? entry.month + entry.year.replace("20", " '")
      : dateRangeType === "QUARTERLY"
        ? (`Q${entry.fiscalYearQuarter}`).concat(entry.fiscalYear.replace("20", " '")).concat(" FY")
        : entry.fiscalYear.concat(" FY")
  )));

  const emissionType = isEmissionsChart ? `${type}BasedEmission` : isConsumption ? "consumption" : "amount";

  const estimates: any = data.reduce((acc: any, entry: any) => {
    if (!acc[entry.estimate]) {
      acc[entry.estimate] = [];
    }
    acc[entry.estimate].push({
      ...entry,
      [emissionType]: entry[emissionType] ?? 0,
    });
    return acc;
  }, {});

  const estimatesData: any = Object.keys(estimates).reduce((acc: any, estimateKey: string) => {
    acc[estimateKey] = estimateCategoriesXAxis.map((entry: string) => estimates[estimateKey].find((item: any) => {
      if (dateRangeType === "MONTHLY") {
        return item.month === entry.substring(0, 3);
      }
      if (dateRangeType === "QUARTERLY") {
        return item.fiscalYearQuarter === entry.substring(1, 2) && item.fiscalYear.substring(2) === entry.substring(4, 6);
      }
      return item.fiscalYear === entry.substring(0, 4);
    })?.[emissionType] ?? 0);
    return acc;
  }, {});

  const estimatesTotalData: any = Object.values(estimatesData).reduce((acc: any, estimateData: any) => {
    estimateData.forEach((value: number, index: number) => {
      if (!acc[index]) {
        acc[index] = 0;
      }
      acc[index] += value;
    });
    return acc;
  }, []);

  const unitYAxis = isEmissionsChart ? "tCO2e" : data[0]?.unit ?? data[0]?.currency ?? "No Data Available";

  useEffect(() => { }, [estimate])

  const chartOptions = {
    chart: {
      type: "column",
      spacing: [30, 50, 20, 40],
      width: null,
      height: 450,
    },
    title: {
      text: chartTitle,
      align: "left",
      margin: 40,
      style: {
        ...commonStyles,
        color: COLORS.Mainblue,
        fontSize: "20px",
      },
    },
    xAxis: {
      categories: estimateCategoriesXAxis,
      labels: {
        style: commonStyles,
      },
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: {
        text: unitYAxis,
        style: commonStyles,
        offset: 50
      },
      labels: {
        style: commonStyles,
      },
    },
    series: [
      {
        name: "Total",
        data: estimatesTotalData,
        color: "#99CD71",
        fillOpacity: 0.3,
        lineWidth: 6,
        marker: { enabled: false },
      },
    ],
    plotOptions: {
      line: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
    },
    legend: {
      title: {
        style: commonStyles,
      },
      align: "left",
      verticalAlign: "bottom",
      x: 20,
      margin: 30,
      maxHeight: 100,
    },
    tooltip: {
      shared: true,
      borderRadius: 8,
      shadow: false,
      padding: 14,
      useHTML: true,
      outside: true,
      positioner(labelWidth: any, labelHeight: any, point: any) {
        return {
          x: point.plotX,
          y: point.plotY - labelHeight,
        };
      },
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return `
              <div style="font-size: 12px; padding: 8px; display: flex; flex-direction: column; gap: 5px;">
                <strong>${this!.x}</strong>
                ${this!.points
            ?.map(
              (point) => `
                  <div style="display: flex; align-items: center; justify-content: space-between; width: 180px;">
                    <div style="display: flex; align-items: center; gap: 6px;">
                      <span style="width: 10px; height: 10px; background: ${point.series.color}; border-radius: 50%; display: inline-block;"></span>
                      <span>${point.series.name}:</span>
                    </div>
                    <span style="font-weight: bold;">${Number(point.y!.toFixed(2)).toLocaleString('en-US')} ${unitYAxis}</span>
                  </div>
                `
            ).join("")}
              </div>
            `;
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      fontFamily: "Inter",
      "& > div": {
        borderRadius: 2,
      },
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </Box>
  );
};
