import { buildQueryParams } from "utils";


export const REC_API_PATHS = {
  RECsDOCUMENT: (query?: any) => {
    const baseUrl = "/recdocument";

    if (!query) {
      return baseUrl;
    }

    return `${baseUrl}?${buildQueryParams(query)}`;
  },

  SITE_REC_DATA: (siteId?: any) => {
    const baseUrl = "/recsdata";

    if (!siteId) {
      return baseUrl;
    }

    return `${baseUrl}/${(siteId)}`;
  },

  COLLECTOR_EMISSION_DATA: (collectorId?: any) => {
    const baseUrl = "emission-factors";
    return `/collector/${collectorId}/${baseUrl}`;
  },

  DELETE_COLLECTOR_EMISSION_DATA: (collectorId: any, emissionFactorId: any) => {
    const baseUrl = "emission-factor";

    return `/collector/${collectorId}/${baseUrl}/${emissionFactorId} `
  },
  SET_COLLECTOR_EMISSION: (collectorId?: any) => {
    const baseUrl = "emission-factor";
    return `/collector/${collectorId}/${baseUrl}`;
  },

  EDIT_COLLECTOR_EMISSION: (collectorId?: any, id?: any) => {
    const baseUrl = "emission-factor";
    return `/collector/${collectorId}/${baseUrl}/${id}`;
  },

  RECsDOCUMENT_UPLOADED: "/recdocument/file-uploaded",
  ADD_SITE_REC_DATA: "/recsdata",

  RECsDOCUMENT_DETAILS: (recId?: any) => {
    const baseUrl = "/recdocument";

    if (!recId) {
      return baseUrl;
    }

    return `${baseUrl}/${(recId)}`;
  },

  MULTIPLE_RECsDOCUMENT_DETAILS: "/recdocument/find-all-by-id"

  
  
};
