import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Stack,
  styled,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  EmissionsOnMonthBody,
  getEmissionsByFacilityType,
  getEmissionsByBusinessUnit,
  getEmissionsByRegion,
  getEmissionsBySite,
  getEmissionsByUtilityType,
  getEmissionsOnMonth,
  getEmissionsScopeYearly,
  getEmissionsYearly,
  getEstimatesBySite,
  getEstimatesOnMonth,
  getConsumptionsByFacilityType,
  getConsumptionsByBusinessUnit,
  getConsumptionsByRegion,
  getConsumptionsOnMonth,
  getConsumptionsYearly,
  getCountriesConsumptionData,
  getCountriesFinacialsData,
  getFinancialEmissionSpendData,
  getFinancialsByFacilityType,
  getFinancialsByRegion,
  getFinancialsBySite,
  getFinancialsByUtilityType,
  getFinancialsOnMonth,
  getFinancialsYearly,
  getScopeOnMonth,
  getUnitLevelCostData,
} from "api/dashboards";

import {
  AutocompleteField,
  Body2SemiBold,
  COLORS,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2, Loading,
  MultiSelectControlledOption,
  MultiselectControlledField,
  usePagination,
} from "components";
import { SelectControlledField } from "components/inputs/select-field";

import {
  useAccountNumbersMultiselect, useCountriesSelect, useCountryRegionsSelect,
  useFacilityTypesSelect,
  useGetConfigParameter,
  useMeterNumbersMultiselect,
  useUtilityBillsYears,
  useUtilityTypesMultiselect,
  useBusinessUnitSelect,
} from "hooks";
import { useFilteredSitesMultiselect } from "hooks/multiselect/filteredSites";
import { useScopeSelect } from "hooks/singleselect/scope";

import { LOCAL_STORAGE_KEYS, QUERY_PARAMS_KEYS } from "consts";

import { debounce } from "lodash";
import { getFirstAndLastDateOfFiscalYear } from "utils";
import { ConsumptionElectricityComponent } from "./components/consumptionElectricity";
import { ConsumptionNaturalGasComponent } from "./components/consumptionNaturalGas";
import { ConsumptionSewerComponent } from "./components/consumptionSewer";
import { ConsumptionWaterComponent } from "./components/consumptionWater";
import { DashboardTabs } from "./components/dashboardTabs";
import { OverallEmissionsComponent } from "./components/overallEmissions";
import { OverallFinancialsComponent } from "./components/overallFinancials";
import { SiteSpecificConsumption } from "./components/siteSpecificConsumption";
import { SiteSpecificEmissions } from "./components/siteSpecificEmissions";
import { DASHBOARD_PAGE } from "./consts";
import { useChartsDataStateHook } from "./hooks/useChartsDataStateHook";
import { useCustomYearsDropdownHook } from "./hooks/useCustomYearsDropdownHook";
import { filterDataWithFiscalYear } from "./utils";

const MULTISELECT_WRAPPER = { minWidth: "220px", width: "fit-content" };
const PageDiv = styled("div")(() => ({
  maxWidth: "calc(100% - 50px)",
}));
const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const btnStates = {
  GHC_EMISSIONS: "ghc-emission",
  CONSUMPTION: "consumption",
  FINANCIAL: "financial",
  SITE_SPECIFIC: "site-specific",
};

export const DashboardPage = () => {
  const { setInitialPage, updateQueryParamsWithInitialPage } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
  });

  const safeCapitalize = (str: any) => {
    if (typeof str !== "string") return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const { GHC_EMISSIONS, CONSUMPTION, FINANCIAL, SITE_SPECIFIC } = btnStates;
  const { data: availableYears } = useUtilityBillsYears();
  const { data: parameterData } = useGetConfigParameter();
  const formattedStartMonth = parameterData && parameterData.find((obj) => obj.name === "company_month_start")?.configValue;
  const formattedEndMonth = parameterData && parameterData.find((obj) => obj.name === "company_month_end")?.configValue;
  const startMonth = safeCapitalize(formattedStartMonth);
  const endMonth = safeCapitalize(formattedEndMonth);

  const currentYear = new Date().getFullYear() - 1;
  const currentFiscalYear = `FY${currentYear}`;

  const nextYear = new Date().getFullYear() + 1;

  const fiscalYears: any = availableYears && [
    ...availableYears.map((yearItem: any) => `FY${yearItem}`),
    `FY${nextYear}`,
  ];

  const [fiscalYear, setFiscalYear] = useState(currentFiscalYear);
  const [yearValue, setYearValue] = useState<any>(null);
  const [accordianTarget, setAccordianTarget] = useState<any>("")

  useEffect(() => {
    const yearNumber = parseInt(fiscalYear.replace("FY", ""), 10) as number;
    setYearValue(yearNumber);
  }, []); // eslint-disable-line

  useEffect(() => {
    const yearNumber = parseInt(fiscalYear.replace("FY", ""), 10) as number;
    setYearValue(yearNumber);
  }, [fiscalYear]);

  const [searchParams] = useSearchParams();

  const { isCountryRegionsLoading, selectedCountryRegion, setSelectedCountryRegion, countryRegionsList } = useCountryRegionsSelect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const {
    isCountriesLoading,
    selectedCountry,
    setSelectedCountry,
    countriesList,
  } = useCountriesSelect({
    selectedCountryRegion,
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const {
    isFacilityTypesLoading,
    setSelectedFalityTypeOption,
    selectedFacilityType,
    facilityTypesList,
  } = useFacilityTypesSelect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const {
    isBusinessUnitLoading,
    setSelectedBusinessUnitOption,
    selectedBusinessUnit,
    businessUnitList,
  } = useBusinessUnitSelect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const { isScopesLoading, setSelectedScope, selectedScope, scopesList } =
    useScopeSelect({
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
      withEmptyOption: false,
    });

  const { isSitesLoading, selectedSites, setSelectedSites, sitesOptions } =
    useFilteredSitesMultiselect({
      selectedCountry,
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    });

  const {
    isAccountNumbersLoading,
    selectedAccountNumbers,
    setSelectedAccountNumbers,
    accountNumbersOptions,
  } = useAccountNumbersMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const {
    isMeterNumbersLoading,
    selectedMeterNumbers,
    setSelectedMeterNumbers,
    meterNumbersOptions,
  } = useMeterNumbersMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const {
    isUtilityTypesLoading,
    selectedUtilityTypes,
    setSelectedUtilityTypes,
    utilityTypesOptions,
  } = useUtilityTypesMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  useEffect(() => {
    setSelectedCountry("");
  }, [countryRegionsList, selectedCountryRegion]); // eslint-disable-line

  useEffect(() => {
    setSelectedSites([]);
  }, [countriesList, selectedCountry]); // eslint-disable-line

  const filterScopeList = scopesList.filter((item) => item.includes("scope"));

  const filterTypes = utilityTypesOptions.filter((item) => item.label !== "-");
  const utitlityTypesForEmissions = utilityTypesOptions.filter((item: any) => item?.emissions && item.label !== "-");
  const utitlityTypesForConsumption = utilityTypesOptions.filter((item: any) => item?.consumptions && item.label !== "-");
  const utitlityTypesForFinancial = utilityTypesOptions.filter((item: any) => item?.financials && item.label !== "-",);
  const filterAccountNumbers = accountNumbersOptions.filter((item) => item.label !== "-",);
  const filterMeterNumbers = meterNumbersOptions.filter((item) => item.label !== "-");
  const {
    selectedYear: monthEmissionsYear,
    setSelectedYear: setMonthEmissionsYear,
    yearsList: monthEmYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: regionEmissionsYear,
    setSelectedYear: setRegionEmissionsYear,
    yearsList: regionEmYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthFinancialYear,
    setSelectedYear: setMonthFinancialYear,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: financialCarbonYear,
    setSelectedYear: setFinancialCarbonYear,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthConsumptionYear,
    setSelectedYear: setMonthConsumptionYear,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSWaterYear,
    setSelectedYear: setMonthCSWaterYear,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSSewerYear,
    setSelectedYear: setMonthCSSewerYear,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSElectricityYear,
    setSelectedYear: setMonthCSElectricityYear,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: scopeYear,
    setSelectedYear: setScopeYear,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: utYear,
    setSelectedYear: setUTYear,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteEmYear,
    setSelectedYear: setSiteEMyear,
    yearsList: siteEMYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteCostYear,
    setSelectedYear: setSiteCostYear,
    yearsList: siteCostYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteElecYear,
    setSelectedYear: setSiteElecYear,
    yearsList: siteElecYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteNGYear,
    setSelectedYear: setSiteNGYear,
    yearsList: siteNGYearsList,
  } = useCustomYearsDropdownHook({});

  const [selectedBtnOption, setSelectedBtnOption] = useState(GHC_EMISSIONS);

  const {
    monthlyScopeData,
    setMonthlyScopeData,
    yearlyScopeData,
    setYearlyScopeData,
    monthlyData,
    setMonthlyData,
    sitesData,
    setSitesData,
    yearlyData,
    setYearlyData,
    regionsData,
    setRegionsData,
    utilityData,
    setUtilityData,
    facilityTypesData,
    setFacilityTypesData,
    businessUnitData,
    setBusinessUnitData,
    scopesResp,
    countriesResp,
    siteSpecificAccData,
    siteSpecificConsumptionData,
    siteSpendAccData,
    monthlyFinancialData,
    setMonthlyFinancialData,
    sitesFinancialData,
    setSitesFinancialData,
    yearlyFinancialData,
    setYearlyFinancialData,
    regionsFinancialData,
    setRegionsFinancialData,
    utilityFinancialData,
    setUtilityFinancialData,
    facilityTypesFinancialData,
    setFacilityTypesFinancialData,
    countriesFinancialResp,
    setCountriesFinancialResp,
    unitLevelCost,
    setUnitLevelCost,
    financialEMSpend,
    setFinancialEMSpend,
    consumptionMonthlyData,
    setConsumptionMonthlyData,
    consumptionSitesData,
    consumptionYearlyData,
    setConsumptionYearlyData,
    consumptionCountriesResp,
    setConsumptionCountriesResp,
    consumptionFacilityTypesData,
    setConsumptionFacilityTypesData,
    setconsumptionBusinessUnitData,
    consumptionRegionsData,
    setConsumptionRegionsData,
    estimatesMonthlyData,
    setEstimatesMonthlyData,
    estimatesBySiteData,
    setEstimatesBySiteData,
  } = useChartsDataStateHook();

  // Emission Mutation
  const { mutate: getEmissionsOnMonthly, isLoading: getEmissionsOnMonthlyLoading } = useMutation(getEmissionsOnMonth);
  const { mutate: getScopeOnMonthly } = useMutation(getScopeOnMonth);
  const { mutate: getEmissionsScopeYear, isLoading: getEmissionsScopeYearLoading } = useMutation(getEmissionsScopeYearly);
  const { mutate: getEmissionsYear, isLoading: getEmissionsYearLoading } = useMutation(getEmissionsYearly);
  const { mutate: getEstimatesOnMonthly, isLoading: getEstimatesOnMonthlyLoading } = useMutation(getEstimatesOnMonth);

  const { mutate: getEmissionsFacilityType, isLoading: getEmissionsFacilityTypeLoading } = useMutation(getEmissionsByFacilityType);
  const { mutate: getEmissionsBusinessUnit, isLoading: getEmissionsBusinessUnitLoading } = useMutation(getEmissionsByBusinessUnit);
  const { mutate: getEstimatesSite, isLoading: getEstimatesSiteLoading } = useMutation(getEstimatesBySite);

  const { mutate: getEmissionsSite, isLoading: getEmissionsSiteLoading } = useMutation(getEmissionsBySite);
  const { mutate: getEmissionsUtility, isLoading: getEmissionsUtilityLoading } = useMutation(getEmissionsByUtilityType);
  const { mutate: getEmissionsRegion, isLoading: getEmissionsRegionLoading } = useMutation(getEmissionsByRegion);

  const emissionLoadingStates = useMemo(() => ({
    getEmissionsOnMonthlyLoading,
    getEmissionsRegionLoading,
    getEmissionsYearLoading,
    getEmissionsSiteLoading,
    getEmissionsScopeYearLoading,
    getEmissionsFacilityTypeLoading,
    getEmissionsBusinessUnitLoading,
    getEmissionsUtilityLoading,
    getEstimatesOnMonthlyLoading,
    getEstimatesSiteLoading,
  }), [
    getEmissionsOnMonthlyLoading,
    getEmissionsRegionLoading,
    getEmissionsYearLoading,
    getEmissionsSiteLoading,
    getEmissionsScopeYearLoading,
    getEmissionsFacilityTypeLoading,
    getEmissionsBusinessUnitLoading,
    getEmissionsUtilityLoading,
    getEstimatesOnMonthlyLoading,
    getEstimatesSiteLoading,
  ]);

  // Financial Mutation
  const { mutate: getFinancialsOnMonthly, isLoading: getFinancialsOnMonthlyLoading } = useMutation(getFinancialsOnMonth);
  const { mutate: getFinancialsSite, isLoading: getFinancialsSiteLoading } = useMutation(getFinancialsBySite);
  const { mutate: getFinancialsUtility, isLoading: getFinancialsUtilityLoading } = useMutation(getFinancialsByUtilityType);
  const { mutate: getFinancialsYear, isLoading: getFinancialsYearLoading } = useMutation(getFinancialsYearly);
  const { mutate: getFinancialsRegion, isLoading: getFinancialsRegionLoading } = useMutation(getFinancialsByRegion);
  const { mutate: getFinancialsFacilityType, isLoading: getFinancialsFacilityTypeLoading } = useMutation(getFinancialsByFacilityType);
  const { mutate: getFinancialsByCountries, isLoading: getFinancialsByCountriesLoading } = useMutation(getCountriesFinacialsData);
  const { mutate: getFinancialEMSpend, isLoading: getFinancialEMSpendLoading } = useMutation(getFinancialEmissionSpendData);
  const { mutate: getUnitLevelCost, isLoading: getUnitLevelCostLoading } = useMutation(getUnitLevelCostData);

  const financialLoadingStates = useMemo(() => ({
    getFinancialsOnMonthlyLoading,
    getFinancialsSiteLoading,
    getFinancialsUtilityLoading,
    getFinancialsYearLoading,
    getFinancialsRegionLoading,
    getFinancialsFacilityTypeLoading,
    getFinancialsByCountriesLoading,
    getFinancialEMSpendLoading,
    getUnitLevelCostLoading
  }), [
    getFinancialsOnMonthlyLoading,
    getFinancialsSiteLoading,
    getFinancialsUtilityLoading,
    getFinancialsYearLoading,
    getFinancialsRegionLoading,
    getFinancialsFacilityTypeLoading,
    getFinancialsByCountriesLoading,
    getFinancialEMSpendLoading,
    getUnitLevelCostLoading
  ]);

  // Consumption Mutation 
  const { mutate: getConsumptionOnMonthly, isLoading: getConsumptionOnMonthlyLoading } = useMutation(getConsumptionsOnMonth);
  const { mutate: getConsumptionYear, isLoading: getConsumptionYearLoading } = useMutation(getConsumptionsYearly);
  const { mutate: getConsumptionRegion, isLoading: getConsumptionRegionLoading } = useMutation(getConsumptionsByRegion);
  const { mutate: getConsumptionFacilityType, isLoading: getConsumptionFacilityTypeLoading } = useMutation(getConsumptionsByFacilityType);
  const { mutate: getConsumptionBusinessUnit, isLoading: getConsumptionBusinessUnitLoading } = useMutation(getConsumptionsByBusinessUnit);
  const { mutate: getConsumptionByCountries, isLoading: getConsumptionByCountriesLoading } = useMutation(getCountriesConsumptionData);


  const consumptionLoadingStates = useMemo(() => ({
    getConsumptionOnMonthlyLoading,
    getConsumptionYearLoading,
    getConsumptionRegionLoading,
    getConsumptionFacilityTypeLoading,
    getConsumptionBusinessUnitLoading,
    getConsumptionByCountriesLoading,
  }), [
    getConsumptionOnMonthlyLoading,
    getConsumptionYearLoading,
    getConsumptionRegionLoading,
    getConsumptionFacilityTypeLoading,
    getConsumptionBusinessUnitLoading,
    getConsumptionByCountriesLoading,
  ]);

  const year = fiscalYear.replace("FY", "");

  const [dates, setDates] = useState<{ firstDate: string; lastDate: string } | null>(null);
  useEffect(() => {
    if (year && startMonth) {
      const calculatedDates = getFirstAndLastDateOfFiscalYear(Number(year), startMonth, endMonth);
      setDates(calculatedDates);
    }
  }, [year, startMonth]); // eslint-disable-line

  const makeFilterApiCalls = (body: EmissionsOnMonthBody, action: string) => {

    const { compareFromDate, compareToDate, ...updatedData } = body; // Destructure to exclude fields

    // Emission API calls
    if (selectedBtnOption === "ghc-emission") {
      if ((accordianTarget === "panel1") || action === "FilterClick" && accordianTarget === "panel1") {
        //
        getEmissionsOnMonthly(body, {
          onSuccess: (data) => {
            const filterData: any = filterDataWithFiscalYear(
              data,
              yearValue === null
                ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
                : yearValue,
              startMonth ?? "Jan",
              endMonth ?? "Dec",
            );
            setMonthlyData(filterData);
          },
          onError: () => {
            // console.log("failed",err)
          },
        });
        // monthlyScopeData
        getScopeOnMonthly(body, {
          onSuccess: (data) => {
            const filterData: any = filterDataWithFiscalYear(
              data,
              yearValue === null
                ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
                : yearValue,
              startMonth ?? "Jan",
              endMonth ?? "Dec",
            );
            setMonthlyScopeData(filterData);
          },
          onError: () => {
            // console.log("failed",err)
          },
        });
        // yearEmissionsScope
        getEmissionsScopeYear(updatedData, {
          onSuccess: (data) => {
            setYearlyScopeData(data);
          },
          onError: () => { },
        });

        // yearEmissionsValue
        getEmissionsYear(updatedData, {
          onSuccess: (data) => {
            setYearlyData(data);
          },
          onError: () => { },
        });
        // estimatesMonthlyData
        getEstimatesOnMonthly(body, {
          onSuccess: (data) => {
            const filterData: any = filterDataWithFiscalYear(
              data,
              yearValue === null
                ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
                : yearValue,
              startMonth ?? "Jan",
              endMonth ?? "Dec",
            );
            setEstimatesMonthlyData(filterData);
          },
          onError: () => {
            // console.log("failed",err)
          },
        });
      }
      if ((accordianTarget === "panel2") || (action === "FilterClick" && accordianTarget === "panel2")) {

        //
        getEmissionsUtility(body, {
          onSuccess: (data) => {
            setUtilityData(data);
          },
          onError: () => { },
        });
      }
      if ((accordianTarget === "panel3") || (action === "FilterClick" && accordianTarget === "panel3")) {
        //
        getEmissionsFacilityType(body, {
          onSuccess: (data) => {
            setFacilityTypesData(data);
          },
          onError: () => { },
        });
      }
      if ((accordianTarget === "panel4") || (action === "FilterClick" && accordianTarget === "panel4")) {
        getEmissionsRegion(body, {
          onSuccess: (data) => {
            const filterData: any = filterDataWithFiscalYear(
              data,
              yearValue === null
                ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
                : yearValue,
              startMonth ?? "Jan",
              endMonth ?? "Dec",
            );
            setRegionsData(filterData);
          },
          onError: () => { },
        });
      }
      if ((accordianTarget === "panel5") || (action === "FilterClick" && accordianTarget === "panel5")) {
        getEmissionsSite(body, {
          onSuccess: (data) => {
            setSitesData(data);
          },
          onError: () => { },
        });
      }
      if ((accordianTarget === "panel6") || (action === "FilterClick" && accordianTarget === "panel6")) {
        getEstimatesSite(body, {
          onSuccess: (data) => {
            const filterData: any = filterDataWithFiscalYear(
              data,
              yearValue === null
                ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
                : yearValue,
              startMonth ?? "Jan",
              endMonth ?? "Dec",
            );
            setEstimatesBySiteData(filterData);
          },
          onError: () => {
            // console.log("failed",err)
          },
        });
      }
      if ((accordianTarget === "panel7") || (action === "FilterClick" && accordianTarget === "panel7")) {
        //
        getEmissionsBusinessUnit(body, {
          onSuccess: (data) => {
            setBusinessUnitData(data);
          },
          onError: () => { },
        });
      }
    }

    // Consumption API Calls
    if (selectedBtnOption === "consumption") {
      if ((accordianTarget === "panel1") || action === "FilterClick" && accordianTarget === "panel1") {
        getConsumptionOnMonthly(body, {
          onSuccess: (data) => {
            const filterData: any = filterDataWithFiscalYear(
              data,
              yearValue === null
                ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
                : yearValue,
              startMonth ?? "Jan",
              endMonth ?? "Dec",
            );
            setConsumptionMonthlyData(filterData);
          },
          onError: () => {
            // console.log("failed",err)
          },
        });

        getConsumptionYear(updatedData, {
          onSuccess: (data) => {
            setConsumptionYearlyData(data);
          },
          onError: () => { },
        });
      }

      if ((accordianTarget === "panel2") || action === "FilterClick" && accordianTarget === "panel2") {
        getConsumptionFacilityType(body, {
          onSuccess: (data) => {
            setConsumptionFacilityTypesData(data);
          },
          onError: () => { },
        });
      }

      if ((accordianTarget === "panel3") || action === "FilterClick" && accordianTarget === "panel3") {
        getConsumptionRegion(body, {
          onSuccess: (data) => {
            setConsumptionRegionsData(data);
          },
          onError: () => { },
        });

        getConsumptionByCountries(body, {
          onSuccess: (data) => {
            setConsumptionCountriesResp(data);
          },
          onError: () => { },
        });
      }

      if ((accordianTarget === "panel4") || action === "FilterClick" && accordianTarget === "panel4") {
        getConsumptionBusinessUnit(body, {
          onSuccess: (data) => {
            setconsumptionBusinessUnitData(data);
          },
          onError: () => { },
        });
      }

      if ((accordianTarget === "panel5") || action === "FilterClick" && accordianTarget === "panel5") {
        getEmissionsBusinessUnit(body, {
          onSuccess: (data) => {
            setBusinessUnitData(data);
          },
          onError: () => { },
        });
      }
    }

    // Financial API call
    if (selectedBtnOption === "financial") {
      if ((accordianTarget === "panel1") || action === "FilterClick" && accordianTarget === "panel1") {
        // 1
        getFinancialsOnMonthly(body, {
          onSuccess: (data) => {
            const filterData: any = filterDataWithFiscalYear(
              data,
              yearValue === null
                ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
                : yearValue,
              startMonth ?? "Jan",
              endMonth ?? "Dec",
            );
            setMonthlyFinancialData(filterData);
          },
          onError: () => { },
        });

        // 1
        getFinancialsYear(updatedData, {
          onSuccess: (data) => {
            setYearlyFinancialData(data);
          },
          onError: () => { },
        });

      }

      if ((accordianTarget === "panel2") || action === "FilterClick" && accordianTarget === "panel2") {
        // 2
        getFinancialsUtility(body, {
          onSuccess: (data) => {
            setUtilityFinancialData(data);
          },
          onError: () => { },
        });


      }

      if ((accordianTarget === "panel3") || action === "FilterClick" && accordianTarget === "panel4") {
        // 3
        getFinancialsSite(body, {
          onSuccess: (data) => {
            setSitesFinancialData(data);
          },
          onError: () => { },
        });
      }

      if ((accordianTarget === "panel4") || action === "FilterClick" && accordianTarget === "panel3") {
        // 3 
        getFinancialsRegion(body, {
          onSuccess: (data) => {
            setRegionsFinancialData(data);
          },
          onError: () => { },
        });

        // 3
        getFinancialsByCountries(body, {
          onSuccess: (data) => {
            setCountriesFinancialResp(data);
          },
          onError: () => { },
        });

        // 3
        getFinancialsFacilityType(body, {
          onSuccess: (data) => {
            setFacilityTypesFinancialData(data);
          },
          onError: () => { },
        });

      }

      if ((accordianTarget === "panel5") || action === "FilterClick" && accordianTarget === "panel4") {
        // 5
        getFinancialsSite(body, {
          onSuccess: (data) => {
            setSitesFinancialData(data);
          },
          onError: () => { },
        });
      }

      if ((accordianTarget === "panel6") || action === "FilterClick" && accordianTarget === "panel4") {
        getFinancialEMSpend(body, {
          onSuccess: (data) => {
            const filterData: any = filterDataWithFiscalYear(
              data,
              yearValue === null
                ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
                : yearValue,
              startMonth ?? "Jan",
              endMonth ?? "Dec",
            );
            setFinancialEMSpend(filterData);
          },
          onError: () => { },
        });

        getUnitLevelCost(body, {
          onSuccess: (data) => {
            setUnitLevelCost(data);
          },
          onError: () => { },
        });
      }

      if (accordianTarget === "panel7") {
        // 5
        getFinancialsSite(body, {
          onSuccess: (data) => {
            setSitesFinancialData(data);
          },
          onError: () => { },
        });
      }
    }
  };

  useEffect(() => {
    if (
      isSitesLoading ||
      isAccountNumbersLoading ||
      isCountriesLoading ||
      isCountryRegionsLoading ||
      isFacilityTypesLoading ||
      isScopesLoading ||
      isUtilityTypesLoading ||
      isMeterNumbersLoading ||
      isBusinessUnitLoading
    ) {
      return;
    }

    let meterNum;
    let accNum;
    let utilityType;
    let siteid;
    let counReg;
    let country;
    let facilityType;
    let businessUnit;
    let scope;

    if (sitesOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SITE_IDS,
      );

      if (selectedOptionsFromSearchParams.length) {
        siteid = sitesOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.value);
      }
    }
    if (accountNumbersOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.ACCOUNT_NUMBERS,
      );

      if (selectedOptionsFromSearchParams.length) {
        accNum = accountNumbersOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.label);
      }
    }
    if (meterNumbersOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.METER_NUMBERS,
      );

      if (selectedOptionsFromSearchParams.length) {
        meterNum = meterNumbersOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.label);
      }
    }
    if (utilityTypesOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.UTILITY_TYPE_IDS,
      );

      if (selectedOptionsFromSearchParams.length) {
        utilityType = utilityTypesOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.value);
      }
    }

    if (countryRegionsList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.COUNTRY_REGION,
      );

      if (selectedOptionsFromSearchParams.length) {
        counReg = countryRegionsList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (countriesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.COUNTRIES,
      );

      if (selectedOptionsFromSearchParams.length) {
        country = countriesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (scopesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SCOPE,
      );

      if (selectedOptionsFromSearchParams.length) {
        scope = scopesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (facilityTypesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SITE_TYPES,
      );

      if (selectedOptionsFromSearchParams.length) {
        facilityType = facilityTypesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (businessUnitList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.BUSINESS_UNITS,
      );

      if (selectedOptionsFromSearchParams.length) {
        businessUnit = businessUnitList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    const body: EmissionsOnMonthBody = {
      countryRegion: counReg || "", // || "AMER",
      countries: country ? [country] : null,
      siteIds: siteid && siteid.length ? siteid : null,
      facilityTypes: facilityType ? [facilityType] : null,
      businessUnits: businessUnit ? [businessUnit] : null,
      scope: scope || "",
      utilityTypeIds: utilityType && utilityType?.length ? utilityType : null,
      accountNumbers: accNum && accNum.length ? accNum : null,
      meterNumbers: meterNum && meterNum.length ? meterNum : null,
      compareFromDate: dates?.firstDate!,
      compareToDate: dates?.lastDate!,
    };

    setTimeout(() => {
      makeFilterApiCalls(body, "");
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordianTarget
  ]);


  const onClearFiltersClick = () => {
    setSelectedCountryRegion("");
    setSelectedCountry("");
    setSelectedFalityTypeOption("");
    setSelectedBusinessUnitOption("");
    setSelectedScope("");
    setSelectedUtilityTypes([]);
    setSelectedSites([]);
    setSelectedAccountNumbers([]);
    setSelectedMeterNumbers([]);
    setFiscalYear(currentFiscalYear);
    getData();
  };

  const getData = () => {
    const body: EmissionsOnMonthBody = {
      countryRegion: "",
      countries: null,
      siteIds: null,
      facilityTypes: null,
      businessUnits: null,
      scope: "",
      utilityTypeIds: null,
      accountNumbers: null,
      meterNumbers: null,
      compareFromDate: dates?.firstDate!,
      compareToDate: dates?.lastDate!,
    };
    setTimeout(() => {
      makeFilterApiCalls(body, "");
    }, 2000)
    const newUrl = DASHBOARD_PAGE.ABSOLUTE_PATH;
    window.history.pushState({}, "", newUrl);
  };

  const onApplyFiltersClick = () => {
    const body: EmissionsOnMonthBody = {
      countryRegion: selectedCountryRegion, // || "AMER",
      countries: selectedCountry ? [selectedCountry] : null,
      siteIds: selectedSites.length ? selectedSites.map((e) => e.value) : null,
      facilityTypes: selectedFacilityType ? [selectedFacilityType] : null,
      businessUnits: selectedBusinessUnit ? [selectedBusinessUnit] : null,
      scope: selectedScope || "",
      utilityTypeIds: selectedUtilityTypes.length
        ? selectedUtilityTypes.map((e) => e.value)
        : null,
      accountNumbers: selectedAccountNumbers.length
        ? selectedAccountNumbers.map((e) => e.label)
        : null,
      meterNumbers: selectedMeterNumbers.length
        ? selectedMeterNumbers.map((e) => e.label)
        : null,
      compareFromDate: dates?.firstDate!,
      compareToDate: dates?.lastDate!,
    };
    setTimeout(() => {
      makeFilterApiCalls(body, "FilterClick");
    }, 2000)
  };


  const handleAccordianState = (target: string) => {
    setAccordianTarget(target);
  }

  const tabs = {
    [GHC_EMISSIONS]: [
      {
        label: "Overall Emissions",
        content: useMemo(() => (
          <OverallEmissionsComponent
            emissionLoadingStates={emissionLoadingStates}
            estimatesBySiteData={estimatesBySiteData}
            estimatesMonthlyData={estimatesMonthlyData}
            monthlyScopeData={monthlyScopeData}
            monthlyData={monthlyData}
            sitesData={sitesData}
            yearlyData={yearlyData}
            yearlyScopeData={yearlyScopeData}
            regionsData={regionsData}
            utilityData={utilityData}
            scopesResp={scopesResp}
            facilityTypesData={facilityTypesData}
            countriesResp={countriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            utilityTypesOptions={utilityTypesOptions}
            monthEmYears={monthEmYears}
            regionEmYears={regionEmYears}
            monthEmissionsYear={monthEmissionsYear}
            setMonthEmissionsYear={setMonthEmissionsYear}
            regionEmissionsYear={regionEmissionsYear}
            setRegionEmissionsYear={setRegionEmissionsYear}
            scopeYear={scopeYear}
            setScopeYear={setScopeYear}
            utYear={utYear}
            setUTYear={setUTYear}
            startMonth={startMonth ?? " Jan"}
            endMonth={endMonth ?? "Dec"}
            selectedYear={yearValue}
            openAccordian={handleAccordianState}
            businessUnitData={businessUnitData}
            businessUnitList={businessUnitList}
          />
        ), [
          estimatesBySiteData, estimatesMonthlyData, monthlyScopeData, monthlyData, sitesData,
          yearlyData, yearlyScopeData, regionsData, utilityData, scopesResp, facilityTypesData,
          countriesResp, sitesOptions, countryRegionsList, facilityTypesList, utilityTypesOptions,
          monthEmYears, regionEmYears, monthEmissionsYear, regionEmissionsYear, setMonthEmissionsYear,
          setRegionEmissionsYear, scopeYear, setScopeYear, utYear, setUTYear, startMonth, endMonth, yearValue,
          businessUnitData, businessUnitList, emissionLoadingStates
        ]),
      },
    ],
    [CONSUMPTION]: [
      {
        label: "Electricity",
        content: useMemo(() => (
          <ConsumptionElectricityComponent
            consumptionLoadingStates={consumptionLoadingStates}
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSElectricityYear}
            setMonthConsumptionYear={setMonthCSElectricityYear}
            startMonth={startMonth ?? " Jan"}
            endMonth={endMonth ?? "Dec"}
            selectedYear={yearValue}
            openAccordian={handleAccordianState}
            businessUnitData={businessUnitData}
            businessUnitList={businessUnitList}
          />
        ), [
          consumptionMonthlyData, consumptionSitesData, consumptionYearlyData, consumptionRegionsData,
          consumptionFacilityTypesData, consumptionCountriesResp, sitesOptions, countryRegionsList,
          facilityTypesList, monthCSElectricityYear, setMonthCSElectricityYear, startMonth, endMonth, yearValue,
          businessUnitData, businessUnitList, consumptionLoadingStates
        ]),
      },
      {
        label: "Natural Gas",
        content: useMemo(() => (
          <ConsumptionNaturalGasComponent
            consumptionLoadingStates={consumptionLoadingStates}
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthConsumptionYear}
            setMonthConsumptionYear={setMonthConsumptionYear}
            startMonth={startMonth ?? " Jan"}
            endMonth={endMonth ?? "Dec"}
            selectedYear={yearValue}
            openAccordian={handleAccordianState}
            businessUnitData={businessUnitData}
            businessUnitList={businessUnitList}
          />
        ), [
          consumptionMonthlyData, consumptionSitesData, consumptionYearlyData, consumptionRegionsData,
          consumptionFacilityTypesData, consumptionCountriesResp, sitesOptions, countryRegionsList,
          facilityTypesList, monthConsumptionYear, setMonthConsumptionYear, startMonth, endMonth, yearValue,
          businessUnitData, businessUnitList, consumptionLoadingStates
        ]),
      },
      {
        label: "Water",
        content: useMemo(() => (
          <ConsumptionWaterComponent
            consumptionLoadingStates={consumptionLoadingStates}
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSWaterYear}
            setMonthConsumptionYear={setMonthCSWaterYear}
            startMonth={startMonth ?? " Jan"}
            endMonth={endMonth ?? "Dec"}
            selectedYear={yearValue}
            openAccordian={handleAccordianState}
            businessUnitData={businessUnitData}
            businessUnitList={businessUnitList}
          />
        ), [
          consumptionMonthlyData, consumptionSitesData, consumptionYearlyData, consumptionRegionsData,
          consumptionFacilityTypesData, consumptionCountriesResp, sitesOptions, countryRegionsList,
          facilityTypesList, monthCSWaterYear, startMonth, endMonth, yearValue,
          businessUnitData, businessUnitList, consumptionLoadingStates, setMonthCSWaterYear
        ]),
      },
      {
        label: "Sewer",
        content: useMemo(() => (
          <ConsumptionSewerComponent
            consumptionLoadingStates={consumptionLoadingStates}
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSSewerYear}
            setMonthConsumptionYear={setMonthCSSewerYear}
            startMonth={startMonth ?? " Jan"}
            endMonth={endMonth ?? "Dec"}
            selectedYear={yearValue}
            openAccordian={handleAccordianState}
            businessUnitData={businessUnitData}
            businessUnitList={businessUnitList}
          />
        ), [
          consumptionMonthlyData, consumptionSitesData, consumptionYearlyData, consumptionRegionsData,
          consumptionFacilityTypesData, consumptionCountriesResp, sitesOptions, countryRegionsList,
          facilityTypesList, monthCSSewerYear, startMonth, endMonth, yearValue,
          businessUnitData, businessUnitList, consumptionLoadingStates, setMonthCSSewerYear
        ]),
      },
    ],
    [FINANCIAL]: [
      {
        label: "Overall Financials",
        content: useMemo(() => (
          <OverallFinancialsComponent
            financialLoadingStates={financialLoadingStates}
            monthlyData={monthlyFinancialData} // 
            sitesData={sitesFinancialData}
            yearlyData={yearlyFinancialData} // 
            regionsData={regionsFinancialData}
            utilityData={utilityFinancialData}
            facilityTypesData={facilityTypesFinancialData}
            unitLevelCostData={unitLevelCost}
            EMSpendData={financialEMSpend} // 
            countriesResp={countriesFinancialResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            utilityTypesOptions={utilityTypesOptions}
            monthFinancialYear={monthFinancialYear}
            setMonthFinancialYear={setMonthFinancialYear}
            financialCarbonYear={financialCarbonYear}
            setFinancialCarbonYear={setFinancialCarbonYear}
            startMonth={startMonth ?? " Jan"}
            endMonth={endMonth ?? "Dec"}
            selectedYear={yearValue}
            openAccordian={handleAccordianState}
            businessUnitData={businessUnitData}
            businessUnitList={businessUnitList}
          />
        ), [
          monthlyFinancialData, sitesFinancialData, yearlyFinancialData, regionsFinancialData,
          utilityFinancialData, facilityTypesFinancialData, unitLevelCost, financialEMSpend,
          countriesFinancialResp, sitesOptions, countryRegionsList, facilityTypesList, utilityTypesOptions,
          monthFinancialYear, setMonthFinancialYear, financialCarbonYear, setFinancialCarbonYear,
          startMonth, endMonth, yearValue, businessUnitData, businessUnitList, financialLoadingStates
        ]),
      },
    ],
    [SITE_SPECIFIC]: [
      {
        label: "Emissions",
        content: useMemo(() => (
          <SiteSpecificEmissions
            siteSpecificAccData={siteSpecificAccData}
            siteSpendAccData={siteSpendAccData}
            siteCostYear={siteCostYear}
            siteCostYearsList={siteCostYearsList}
            setSiteCostYear={setSiteCostYear}
            siteEMYearsList={siteEMYearsList}
            siteEmYear={siteEmYear}
            setSiteEMyear={setSiteEMyear}
          />
        ), [
          siteSpecificAccData, siteSpendAccData, siteCostYear, siteCostYearsList,
          setSiteCostYear, siteEMYearsList, siteEmYear, setSiteEMyear,
        ]),
      },
      {
        label: "Consumption",
        content: useMemo(() => (
          <SiteSpecificConsumption
            siteSpecificConsumptionData={siteSpecificConsumptionData}
            setSiteElecYear={setSiteElecYear}
            siteElecYear={siteElecYear}
            siteElecYearsList={siteElecYearsList}
            setSiteNGYear={setSiteNGYear}
            siteNGYear={siteNGYear}
            siteNGYearsList={siteNGYearsList}
          />
        ), [
          siteSpecificConsumptionData, setSiteElecYear, siteElecYear, siteElecYearsList,
          setSiteNGYear, siteNGYear, siteNGYearsList,
        ]),
      },
    ],
  };

  const btnStyles = {
    fontSize: "1rem",
    color: "black",
    backgroundColor: "transparent",
  };

  const debouncedOnClick = useMemo(() => 
    debounce((e: React.MouseEvent<HTMLDivElement>) => {
      const key = (e?.target as HTMLElement)?.dataset?.key as string;
      if (key) {
        setSelectedBtnOption(key);
      }
    }, 300), // Adjust the debounce delay as needed (e.g., 300ms)
    []
  );
  
  const onStackButtonClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => debouncedOnClick(e),
    [debouncedOnClick]
  );
  

  const renderTabButtons = () => (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      marginLeft="20px"
      padding="24px 6px"
      onClick={onStackButtonClick}
    >
      {/* GHG Emission */}
      <Button
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === GHC_EMISSIONS ? COLORS.green : "transparent",
        }}
        variant="outlined"
        data-key={GHC_EMISSIONS}
      >
        GHG Emissions
      </Button>
      {/* Consumption */}
      <Button
        data-key="consumption"
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === CONSUMPTION ? COLORS.green : "transparent",
        }}
        variant="outlined"
      >
        Consumption
      </Button>
      {/* Financials */}
      <Button
        data-key="financial"
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === FINANCIAL ? COLORS.green : "transparent",
        }}
        variant="outlined"
      >
        Financials
      </Button>
    </Stack>
  );

  const renderFields = () => {
    const disableApplyFiltersBtn =
      isSitesLoading ||
      isAccountNumbersLoading ||
      isCountriesLoading ||
      isCountryRegionsLoading ||
      isFacilityTypesLoading ||
      isScopesLoading ||
      isUtilityTypesLoading ||
      isMeterNumbersLoading ||
      isBusinessUnitLoading;
    return (
      <Box
        my={3}
        mx={3}
        sx={{
          marginRight: "0px",
        }}
      >
        <Box
          sx={{
            ...FILTERS_AND_ACTIONS_WRAPPER,
            marginRight: "0px",
          }}
        >
          <Box
            sx={{
              ...FLEX_ALIGN_CENTER_GAP_2,
              alignItems: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <Box sx={MULTISELECT_WRAPPER}>
              {fiscalYears && <FormControl sx={{ minWidth: 220 }}>
                <SelectControlledField
                  label="Fiscal Year"
                  options={fiscalYears}
                  selectedValues={fiscalYear}
                  setSelectedValues={setFiscalYear}
                />
              </FormControl>}
            </Box>
            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isCountryRegionsLoading}
                label="Region"
                options={countryRegionsList}
                selectedValues={selectedCountryRegion}
                setSelectedValues={setSelectedCountryRegion}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isCountriesLoading}
                label="Country"
                options={countriesList}
                selectedValues={selectedCountry}
                setSelectedValues={setSelectedCountry}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isFacilityTypesLoading}
                label="Facility type"
                options={facilityTypesList}
                selectedValues={selectedFacilityType}
                setSelectedValues={setSelectedFalityTypeOption}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isBusinessUnitLoading}
                label="Business Unit"
                options={businessUnitList}
                selectedValues={selectedBusinessUnit}
                setSelectedValues={setSelectedBusinessUnitOption}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isScopesLoading}
                label="Scope"
                options={filterScopeList}
                selectedValues={selectedScope}
                setSelectedValues={setSelectedScope}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <AutocompleteField
                textFieldProps={{
                  label: "Utility type",
                  disabled: isUtilityTypesLoading,
                  placeholder:
                    selectedUtilityTypes.length === 0
                      ? "all selected"
                      : undefined,
                }}
                autocompleteProps={{
                  disabled: isUtilityTypesLoading,
                  multiple: true,
                  value: selectedUtilityTypes,
                  disableClearable: true,
                  blurOnSelect: false,
                  disableCloseOnSelect: true,
                  onChange: (e, data) =>
                    setSelectedUtilityTypes(
                      data as MultiSelectControlledOption[],
                    ),
                  options:
                    selectedBtnOption === GHC_EMISSIONS
                      ? utitlityTypesForEmissions
                      : selectedBtnOption === CONSUMPTION
                        ? utitlityTypesForConsumption
                        : selectedBtnOption === FINANCIAL
                          ? utitlityTypesForFinancial
                          : filterTypes,
                  getOptionLabel: (option) =>
                    (option as MultiSelectControlledOption).label,
                  isOptionEqualToValue: (opt, val) =>
                    (opt as MultiSelectControlledOption).value ===
                    (val as MultiSelectControlledOption).value,

                  renderOption: (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={checkboxIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {(option as MultiSelectControlledOption).label}
                    </li>
                  ),
                  renderTags: (value) => (
                    <Box sx={{ ml: 1 }} component="span">
                      <>
                        {value.length === 1
                          ? (value[0] as MultiSelectControlledOption).label
                          : value.length === utilityTypesOptions.length
                            ? "all selected"
                            : `${value.length} selected`}
                      </>
                    </Box>
                  ),
                }}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <AutocompleteField
                textFieldProps={{
                  label: "Sites",
                  disabled: isSitesLoading,
                  placeholder:
                    selectedSites.length === 0 ? "all selected" : undefined,
                }}
                autocompleteProps={{
                  disabled: isSitesLoading,
                  multiple: true,
                  value: selectedSites,
                  disableClearable: true,
                  blurOnSelect: false,
                  disableCloseOnSelect: true,
                  onChange: (e, data) =>
                    setSelectedSites(data as MultiSelectControlledOption[]),
                  options: sitesOptions,
                  getOptionLabel: (option) =>
                    (option as MultiSelectControlledOption).label,
                  isOptionEqualToValue: (opt, val) =>
                    (opt as MultiSelectControlledOption).value ===
                    (val as MultiSelectControlledOption).value,

                  renderOption: (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={checkboxIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {(option as MultiSelectControlledOption).label}
                    </li>
                  ),
                  renderTags: (value) => (
                    <Box sx={{ ml: 1 }} component="span">
                      <>
                        {value.length === 1
                          ? (value[0] as MultiSelectControlledOption).label
                          : value.length === sitesOptions.length
                            ? "all selected"
                            : `${value.length} selected`}
                      </>
                    </Box>
                  ),
                }}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <MultiselectControlledField
                disabled={isAccountNumbersLoading}
                isAllSelectedOnEmptySelection
                label="Account number"
                options={filterAccountNumbers}
                selectedValues={selectedAccountNumbers}
                setSelectedValues={setSelectedAccountNumbers}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <MultiselectControlledField
                disabled={isMeterNumbersLoading}
                isAllSelectedOnEmptySelection
                label="Meter number"
                options={filterMeterNumbers}
                selectedValues={selectedMeterNumbers}
                setSelectedValues={setSelectedMeterNumbers}
              />
            </Box>
          </Box>

          <Box
            sx={{
              ...FLEX_ALIGN_CENTER_GAP_2,
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={onClearFiltersClick} variant="outlined">
              Clear Filters
            </Button>
            <Button
              onClick={onApplyFiltersClick}
              disabled={disableApplyFiltersBtn}
              variant="contained"
            >
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  if (isMeterNumbersLoading || isAccountNumbersLoading || isSitesLoading) {
    return (
      <Box
        sx={{
          minWidth: 50,
          minHeight: 50,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-60%, -50%)",
        }}
      >
        <Loading />
        <Body2SemiBold sx={{ mt: '70px' }}>Data Is Being Prepared !</Body2SemiBold>
      </Box>
    )
  }
  return (
    <PageDiv
      sx={{
        ".highcharts-credits": { display: "none" },
      }}
    >
      {renderTabButtons()}
      {renderFields()}
      <DashboardTabs
        tabs={tabs[selectedBtnOption]}
        selectedBtnOption={selectedBtnOption}
      />
    </PageDiv>
  );
};
