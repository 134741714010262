import { MonthUsage } from "api";

import { isNullOrUndefined } from "utils";

import { CellColor } from "../styles/cellStyles";

export const getCellState = ({
  value,
  enabled = false,
  isBeforeStart,
  isAfterEnd,
  isBeforeDisableDate,
  isAfterDisableDate,
  isCurrentCellSelected,
  hasHistoricalData,
  hasMeterOrAccountNumber,
}: {
  value: MonthUsage;
  enabled?: boolean;
  isBeforeStart: boolean;
  isAfterEnd: boolean;
  isBeforeDisableDate: boolean;
  isAfterDisableDate: boolean;
  isCurrentCellSelected: boolean;
  hasHistoricalData: boolean;
  hasMeterOrAccountNumber: boolean;
}): { color: CellColor; showModal: boolean } => {
  if (
    !hasHistoricalData ||
    (!hasMeterOrAccountNumber && (value.usage === 0 || value.usage === null))
  ) {
    return {
      color: "deactivatedWithData",
      showModal: enabled,
    };
  }

  if (isNullOrUndefined(value.usage)) {
    if (isBeforeDisableDate) {
      return {
        color: "error",
        showModal: enabled,
      };
    }
    if (isAfterDisableDate) {
      return {
        color:
          isBeforeStart || isAfterEnd
            ? "deactivated"
            : enabled
            ? "error"
            : "deactivated",
        showModal: enabled,
      };
    }
    return {
      color:
        isBeforeStart || isAfterEnd
          ? "deactivated"
          : isCurrentCellSelected
          ? "selected"
          : enabled
          ? "error"
          : "deactivated",
      showModal: enabled,
    };
  }

  if (value.hasNullUsage) {
    if (isBeforeDisableDate) {
      return { color: "warning", showModal: false };
    }
    if (isAfterDisableDate) {
      return {
        color:
          isBeforeStart || isAfterEnd
            ? "deactivated"
            : !enabled && value.usage !== null && value.usage !== 0
            ? "warning"
            : "none",
        showModal: false,
      };
    }
    return {
      color:
        isBeforeStart || isAfterEnd
          ? "deactivatedWithData"
          : isCurrentCellSelected
          ? "selected"
          : "warning",
      showModal: false,
    };
  }

  if (
    value.billType === "INTERPOLATION" ||
    value.billType === "EXTRAPOLATION"
  ) {
    return {
      color: "info",
      showModal: false,
    };
  }

  if (isBeforeDisableDate) {
    return {
      color:
        isBeforeStart || isAfterEnd
          ? "deactivatedWithData"
          : isCurrentCellSelected
          ? "selected"
          : "none",
      showModal: false,
    };
  }
  if (isAfterDisableDate) {
    return {
      color: isBeforeStart || isAfterEnd ? "deactivatedWithData" : "none",
      showModal: false,
    };
  }

  return {
    color:
      isBeforeStart || isAfterEnd
        ? "deactivatedWithData"
        : isCurrentCellSelected
        ? "selected"
        : enabled || (value.usage !== null && value.usage !== 0)
        ? "none"
        : "deactivated",
    showModal: false,
  };
};
