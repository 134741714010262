import React, { useState } from "react";
import { Dayjs } from "dayjs";
import { AllSelects } from "./components";
import { GHG_EMISSIONS, Consumption, Financials } from "./main-tabs";

export const MainTab = ({
  currentMainTab,
  tabs,
  currentDate,
  allowedValues,
  sitesData,
  configParameter,
  collectorsData,
  fiscalYears,
  currentActiveSearch,
  setCurrentActiveSearch,
}: {
  currentMainTab: string;
  tabs: string[];
  currentDate: Dayjs;
  allowedValues: any;
  sitesData: any;
  configParameter: any;
  collectorsData: any;
  fiscalYears: string[];
  currentActiveSearch: boolean;
  setCurrentActiveSearch: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [defaultObject, setDefaultObject] = useState<any>({
    countryRegions: null,
    countries: null,
    siteIds: null,
    facilityTypes: null,
    businessUnits: null,
    scopes: null,
    utilityTypeIds: null,
    accountNumbers: null,
    meterNumbers: null,
  });

  const AllSelectsComponent = <AllSelects
    allowedValues={allowedValues}
    sitesData={sitesData}
    configParameter={configParameter}
    collectorsData={collectorsData}
    defaultObject={defaultObject}
    setDefaultObject={setDefaultObject}
    setCurrentActiveSearch={setCurrentActiveSearch}
    currentMainTab={currentMainTab}
  />;

  if (currentMainTab === "GHG Emissions") {
    return <GHG_EMISSIONS
      tabs={tabs}
      currentMainTab={currentMainTab}
      fiscalYears={fiscalYears}
      currentDate={currentDate}
      defaultObject={defaultObject}
      setDefaultObject={setDefaultObject}
      currentActiveSearch={currentActiveSearch}
      setCurrentActiveSearch={setCurrentActiveSearch}
    >
      {AllSelectsComponent}
    </GHG_EMISSIONS>
  }
  if (currentMainTab === "Consumption") {
    return <Consumption
      tabs={tabs}
      currentMainTab={currentMainTab}
      fiscalYears={fiscalYears}
      currentDate={currentDate}
      defaultObject={defaultObject}
      setDefaultObject={setDefaultObject}
      currentActiveSearch={currentActiveSearch}
      setCurrentActiveSearch={setCurrentActiveSearch}
    >
      {AllSelectsComponent}
    </Consumption>
  }
  return <Financials
    tabs={tabs}
    currentMainTab={currentMainTab}
    fiscalYears={fiscalYears}
    currentDate={currentDate}
    defaultObject={defaultObject}
    setDefaultObject={setDefaultObject}
    currentActiveSearch={currentActiveSearch}
    setCurrentActiveSearch={setCurrentActiveSearch}
  >
    {AllSelectsComponent}
  </Financials>
}
