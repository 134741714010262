import { Box, Grid, Paper, Tooltip } from "@mui/material";
import React, { FunctionComponent } from "react";

import { SitesEmissionsDTO } from "api";

import { InfoIcon } from "assets";

import {
  COLORS,
  Chip,
  Label12Regular, // Label12Regular,
  Label12SemiBold,
  Label14SemiBold,
  Label16Medium,
  Label16SemiBold,
  Label18SemiBold,
  UtilityBillTypeChip,
  UtilityBillTypeKey,
} from "components";

import { ScopeEmissionPieChart } from "./emission-summary-pieChart";
import { ScopeEmissionScope2PieChart } from "./emission-summary-scope2-pieChart";
import { ScopeEmissionScopePieChart } from "./emission-summary-scope-pieChart";
import { ScopeEmissionScope2MarketPieChart } from "./emission-summary-scope2-market-pieChart";

export const ConsumptionSummary: FunctionComponent<{
  sitesEmissions: Omit<SitesEmissionsDTO, "siteAggregations">;
}> = ({ sitesEmissions }) => (
    <>
      <Box>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "350px",
            p: 2,
          }}
          elevation={0}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Label18SemiBold>Summary </Label18SemiBold>
            {/* <Label12SemiBold sx={{ ml: 2 }}>{period}</Label12SemiBold> */}
          </Box>
          <Box sx={{ display: "flex", mt: 3 }}>
            {/* Sites and Other Chips */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ScopeEmissionPieChart sitesEmissionsData={sitesEmissions} />
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  marginTop: "0px",
                  marginLeft: "16px",
                }}
              >
                {/* Scope 1 */}
                <div
                  style={{
                    position: "relative",
                    height: "317px",
                    width: "264px",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "1px solid rgba(5, 65, 84, 0.5)",
                  }}
                >
                    <div
                      style={{ top: 0, position: "absolute"}}
                    >
                      <ScopeEmissionScopePieChart
                        sitesEmissionsData={sitesEmissions}
                      />
                  </div>
                </div>
                  {/* Scop 2 Location */}
                  <div style={{
                    position: "relative",
                    height: "317px",
                    width: "264px",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "1px solid rgba(5, 65, 84, 0.5)",
                  }}>
                    <div
                      style={{ top: 0, position: "absolute"}}>
                      <ScopeEmissionScope2PieChart
                        sitesEmissionsData={sitesEmissions}
                      />
                    </div>
                </div>

                {/* Scop 2 Market */}
                <div style={{
                    position: "relative",
                    height: "317px",
                    width: "264px",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    border: "1px solid rgba(5, 65, 84, 0.5)",
                  }}>
                    <div
                      style={{ top: 0, position: "absolute"}}>
                      <ScopeEmissionScope2MarketPieChart
                        sitesEmissionsData={sitesEmissions}
                      />
                    </div>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", ml: 2, mr: 2 }}
              >
                <Label12SemiBold>Sites</Label12SemiBold>
                <Box>
                  <Chip
                    label={`${sitesEmissions.selectedSites} of ${sitesEmissions.totalSites}`}
                    bgColor={COLORS.faGray}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  ml: 2, mr: 2, mt: 2
                }}
              >
                <Label12SemiBold>Consumption</Label12SemiBold>
                <Grid container gap={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {sitesEmissions.utilityTypesUsage.map((utilityType) => (
                    
                    <UtilityBillTypeChip
                      key={utilityType.utilityType}
                      utilityType={
                        utilityType.utilityType as UtilityBillTypeKey
                      }
                      usage={utilityType.usage}
                      unit={utilityType.unit}
                    />
                  ))}
                  </Grid>
              </Box>
            </div>
          </Box>
        </Paper>
        {/* intensity Metrics */}
      </Box>
      <Box sx={{ marginTop: 5 }}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "260px",
            p: 2,
          }}
          elevation={0}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Label18SemiBold
              sx={{ fontSize: 18, fontWeight: "bold",  }}
            >
              Company Intensity Metrics
            </Label18SemiBold>

            <Tooltip
              title="For site-level intensity metrics, please group the table below by the Site column"
              placement="top-end"
            >
              <InfoIcon style={{ marginLeft: "6px", color: "#18acb6",marginTop:"2px" }} />
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            {/* First */}
            <Box sx={{ mt: 2 }}>
              <div
                style={{
                  height: "180px",
                  width: "260px",
                  border: "1px solid #F0F3F7",
                  borderRadius: "3px",
                }}
                className="custom-box"
              >
                <div style={{ backgroundColor: "#F0F3F7", height: "32px" }}>
                  <Box sx={{ p: "5px" }}>
                    <Label14SemiBold>Production Unit</Label14SemiBold>
                  </Box>
                </div>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>
                    Scope 1 + Scope 2 (Location)
                  </Label16SemiBold>
                  {sitesEmissions?.totalIntensityProductionCo2Emission !==
                  null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalIntensityProductionCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityProductionUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>Scope 1 + Scope 2 (Market)</Label16SemiBold>
                  {sitesEmissions?.totalMarketIntensityProductionCo2Emission !==
                  null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {
                          sitesEmissions?.totalMarketIntensityProductionCo2Emission
                        }
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityProductionUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
              </div>
            </Box>
            {/* Second */}
            <Box sx={{ mt: 2 }}>
              <div
                style={{
                  height: "180px",
                  width: "260px",
                  border: "1px solid #EEF5EF",
                  borderRadius: "3px",
                }}
                className="custom-box"
              >
                <div style={{ backgroundColor: "#EEF5EF", height: "32px" }}>
                  <Box sx={{ p: "5px" }}>
                    <Label14SemiBold>Company Revenue</Label14SemiBold>
                  </Box>
                </div>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>
                    Scope 1 + Scope 2 (Location)
                  </Label16SemiBold>
                  {sitesEmissions?.totalIntensityRevenueCo2Emission !== null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalIntensityRevenueCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityRevenueUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>Scope 1 + Scope 2 (Market)</Label16SemiBold>
                  {sitesEmissions?.totalMarketIntensityRevenueCo2Emission !==
                  null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalMarketIntensityRevenueCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityRevenueUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
              </div>
            </Box>
            {/* Third */}
            <Box sx={{ mt: 2 }}>
              <div
                style={{
                  height: "180px",
                  width: "260px",
                  border: "1px solid #FFF1E0",
                  borderRadius: "3px",
                }}
                className="custom-box"
              >
                <div style={{ backgroundColor: "#FFF1E0", height: "32px" }}>
                  <Box sx={{ p: "5px" }}>
                    <Label14SemiBold>Area</Label14SemiBold>
                  </Box>
                </div>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>
                    Scope 1 + Scope 2 (Location)
                  </Label16SemiBold>
                  {sitesEmissions?.totalIntensityAreaCo2Emission !== null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalIntensityAreaCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityAreaUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
                <Box sx={{ mt: 1, p: "5px" }}>
                  <Label16SemiBold>Scope 1 + Scope 2 (Market)</Label16SemiBold>
                  {sitesEmissions?.totalMarketIntensityAreaCo2Emission !==
                  null ? (
                    <Box sx={{ display: "flex", mt: "7px" }}>
                      <Label16Medium>
                        {sitesEmissions?.totalMarketIntensityAreaCo2Emission}
                      </Label16Medium>
                      <span style={{ marginTop: "2px", marginLeft: "7px" }}>
                        <Label12Regular>
                          tCO2e/{sitesEmissions?.intensityAreaUnit}
                        </Label12Regular>
                      </span>
                    </Box>
                  ) : (
                    <Label12Regular
                      style={{ color: "#C1C5CE", fontStyle: "italic" }}
                    >
                      No data provided
                    </Label12Regular>
                  )}
                </Box>
              </div>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
