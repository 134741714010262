import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import React, { FunctionComponent, ReactNode, useContext, useState } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

import {
  EsgArrowDown,
  EsgArrowRight,
  EsgDropLine,
  EsgfactorIcon,
  EsgViewSummary,
  EsgDocumentUpload,
  EsgCheckMissing,
  ApprovalsIcon,
} from "assets";

import { Box } from "@mui/system";

import {
  EMISSION_FACTORS_PAGE_SCOPE3,
  SCOPE_DOCUMENT_UPLOAD_PAGE_SCOPE3,
  Scope3Context,
} from "pages";
import { COLORS } from "components/consts";

export const DocumentOptionsList: FunctionComponent<{
  isOpen: boolean;
  icon: ReactNode;
  isSelected?: boolean;
  isScope3?: boolean;
}> = ({ isOpen, icon, isSelected, isScope3 = false }) => {
  const options = isScope3
    ? [
      {
        label: "Upload",
        to: SCOPE_DOCUMENT_UPLOAD_PAGE_SCOPE3.ABSOLUTE_PATH,
        icon: <EsgDocumentUpload />,
        shortLabel: "U",
        key: 0,
      },
      {
        label: "Emission Factors",
        to: EMISSION_FACTORS_PAGE_SCOPE3.ABSOLUTE_PATH,
        icon: <EsgfactorIcon />,
        shortLabel: "U",
        key: 1,
      },
    ]
    : [
      {
        label: "Upload",
        to: "/documents",
        icon: <EsgDocumentUpload />,
        shortLabel: "U",
        key: 0,
      },
      {
        label: "Bill Entries",
        to: "/documents/summary",
        pattern: "/documents/summary/*",
        icon: <EsgViewSummary />,
        shortLabel: "V",
        key: 3,
      },
      {
        label: "Check Missing",
        to: "/documents/missing",
        icon: <EsgCheckMissing />,
        shortLabel: "C",
        key: 21,
      },
      {
        label: "Approvals",
        to: "/approvals",
        icon: <ApprovalsIcon />,
        shortLabel: "C",
        key: 5,
      },
    ];

  const { pathname } = useLocation();

  const selectedOption = options.filter((item) =>
    matchPath((item.pattern || item.to), pathname),
  );

  const initialTabIndex = selectedOption?.length ? selectedOption[0]?.key : null;

  const { activeScope } = useContext(Scope3Context);
  const setDefaultOpen = () => !(isScope3 && activeScope.length > 1);
  const [open, setOpen] = React.useState(setDefaultOpen());

  const [selectedIndex, setSelectedIndex] = useState(initialTabIndex);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  if (selectedIndex !== initialTabIndex) {
    setSelectedIndex(initialTabIndex);
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  const handleMouseEnter = (event: any) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({ top: rect.top, left: rect.right - 20 });
    setModalOpen(true);
  };

  const handleMouseLeave = () => {
    setModalOpen(false);
  };

  return (
    <div
      className="sideBar-main-div"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <ListItem
        sx={{
          display: 'grid',
          justifyContent: !isOpen ? 'center' : 'flex-start',
          py: 0,
          my: "4px",
          ...(isOpen && { pl: 0 })
        }}
      >
        <ListItemButton
          selected={isSelected}
          onClick={() => setOpen(!open)}
          sx={{
            display: 'block',
            justifyContent: 'center',
            borderRadius: 2,
            marginLeft: isOpen ? '15px' : '',
            "&.Mui-selected": {
              backgroundColor: "background.default",
              width: isOpen ? '258px' : '85px', 
              "& svg": {
                color: "primary.main",
              },
              ":hover": {
                backgroundColor: "background.default",
              },
            },
            "&.Mui-focusVisible": {
              backgroundColor: "background.default",
            },
            ":hover": {
              backgroundColor: "transparent",
              color: isSelected ? null : "primary.main",
              "& svg": {
                color: "primary.main",
              },
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ListItemIcon
              sx={{
                justifyContent: "center",
                minWidth: isOpen ? '25px' : '56px'
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary="Documents"
              sx={{
                opacity: isOpen ? 1 : 0,
                fontSize: '16px',
                marginLeft: isOpen ? '6px' : '',
                "& .MuiListItemText-primary": {
                  fontWeight: 400,
                  color: COLORS.Romance,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  lineHeight: '24px',
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                },
                ".MuiListItemButton-root": {
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                }
              }}
            />
            {isOpen && <Box sx={{ ml: '100px' }}>
              {open ? <EsgArrowDown /> : <EsgArrowRight />}
            </Box>}
          </Box>

          {!isOpen && <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            fontSize: '12px', 
            mt: '-5px',
            fontWeight: 400,
            color: COLORS.Romance,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            lineHeight: '12px'
             }}>Documents</Box>}
        </ListItemButton>

        {!isOpen && isModalOpen && (
          <div className="sideBar-main-modal" style={{ top: modalPosition.top, left: modalPosition.left }}>
            {options.map((item, index) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }} key={item.key}>
                <ListItemButton
                  onClick={(event) => {
                    handleListItemClick(event, index)
                    handleMouseLeave()
                  }}
                  selected={item.key === selectedIndex}
                  component={Link}
                  to={item.to}
                  sx={
                    {
                      height: '42px',
                      borderRadius: 2,
                      justifyContent: !isOpen ? "center" : '',
                      "&.Mui-selected": {
                        backgroundColor: "background.default",
                        ":hover": {
                          backgroundColor: "background.default",
                        },
                      },
                      ".MuiListItemButton-root": {
                        ":hover": {
                          backgroundColor: "transparent",
                        },
                      }
                    }
                  }
                  key={item.label}
                >
                  <ListItemIcon sx={!isOpen ? { minWidth: 0, mr: 1 } : {}}>
                    {item?.icon}
                  </ListItemIcon>

                  <ListItemText
                    sx={{
                      marginLeft: isOpen ? '-10px' : '',
                      "& .MuiListItemText-primary": {
                        fontSize: '12px',
                        color: COLORS.Romance,
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight:'400',
                        lineHeight: '24px'
                      }
                    }}
                    primary={item.label}
                  />
                </ListItemButton>
              </Box>
            )
            )}
          </div>
        )}
      </ListItem>

      {isOpen && <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            paddingLeft: isOpen ? "20px" : "0px",
          }}
        >
          {options.map((item, index) => (
            <Box sx={{ display: 'flex', justifyContent: 'center' }} key={item.key}>
              {isOpen && <ListItemIcon sx={{
                minWidth: "12px",
                position: 'absolute',
                left: '43px',
                mt: 0,
                zIndex: 9999,
              }}>
                <EsgDropLine />
              </ListItemIcon>}
              <ListItemButton
                onClick={(event) => handleListItemClick(event, index)}
                selected={item.key === selectedIndex}
                component={Link}
                to={item.to}
                sx={{
                  pl: isOpen ? 4 : 0,
                  height: '42px',
                  ml: 1,
                  borderRadius: 2,
                  justifyContent: !isOpen ? "center" : '',
                  "&.Mui-selected": {
                    width: '20px',
                    backgroundColor: "background.default",
                    ":hover": {
                      backgroundColor: "background.default",
                    },
                  },
                  ".MuiListItemButton-root": {
                    width: '20px',
                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  }
                }}
                key={item.label}
              >
                {isOpen &&
                  <ListItemIcon sx={{ minWidth: '40px' }}>
                    {item?.icon}
                  </ListItemIcon>}
                {isOpen && <ListItemText sx={{
                  marginLeft: isOpen ? '-10px' : '',
                  "& .MuiListItemText-primary": {
                    color: COLORS.Romance,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '24px'
                  }
                }} primary={item.label} />}
              </ListItemButton>
            </Box>
          ))}
        </List>
      </Collapse>}
    </div>
  );
};

