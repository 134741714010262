import { UseQueryResult } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useUtilityBillsYears } from "hooks";

import { QUERY_PARAMS_KEYS } from "consts";

import { HookWithQueryParams, MaybeNull } from "types";

import { getParsedQueryParams } from "utils";

export const useYearPeriodFilter = ({
  updateExternalStates,
  withQueryParams = true,
  useYears = useUtilityBillsYears,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialValue,
}: HookWithQueryParams & {
  useYears?: () => UseQueryResult<number[], unknown>;
  initialValue?: number;
} = {}) => {
  const { data: availableYears } = useYears();
  const [searchParams, setSearchParams] = useSearchParams();
  const [year, setYear] = useState<MaybeNull<Dayjs>>(null);
  const isInitialQueryReadRef = useRef<boolean>(true);

  useEffect(() => {
    setYear(dayjs())
  },[])

  useEffect(() => {
    if (availableYears && isInitialQueryReadRef.current && withQueryParams) {
      const yearFromSearchParams = Number.parseInt(
        searchParams.get(QUERY_PARAMS_KEYS.YEAR) || "",
        10,
      );

      if (
        !Number.isNaN(yearFromSearchParams) &&
        availableYears.includes(yearFromSearchParams)
      ) {
        setYear(dayjs(`${yearFromSearchParams}-01-01`));
      }

      isInitialQueryReadRef.current = false;
    }
  }, [availableYears, searchParams, withQueryParams]);

  const onYearChange = useCallback(
    (y: Dayjs | null) => {
      if (updateExternalStates) {
        updateExternalStates();
      }

      setYear(y || null);

      if (withQueryParams) {
        const queryParams = getParsedQueryParams(searchParams);

        if (!y || !availableYears || !availableYears.includes(y.year())) {
          delete queryParams[QUERY_PARAMS_KEYS.YEAR];
        } else {
          queryParams[QUERY_PARAMS_KEYS.YEAR] = String(y.year());
        }

        // setSearchParams(
        //   createSearchParams({
        //     ...(getQueryParamsWithExternalChanges
        //       ? getQueryParamsWithExternalChanges(queryParams)
        //       : queryParams),
        //   }),
        // );
      }
    },
    // eslint-disable-next-line
    [
      setSearchParams,
      availableYears,
      searchParams,
      updateExternalStates,
      withQueryParams,
    ],
  );

  return {
    year,
    yearValue:
      availableYears && year && availableYears.includes(year.year())
        ? year.year()
        : undefined,
    onYearChange,
    availableYears,
  };
};
