import React, { useRef } from "react";
import { Box } from "@mui/material";
import { RecUploadIcon } from "assets"; // Assuming you have this icon

export const PdfEmptyData: React.FunctionComponent<{
  fileName?: any;
}> = ({ fileName }) => {
  const fileInputRef: any = useRef(null);
  

  const handleFileChange = (e: any) => {
    const files = e.target.files[0];
    fileName(files)
  };


  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        position: "absolute",
        backgroundColor: 'white'

      }}
    >
      <RecUploadIcon
        width={84}
        height={84}
        onClick={handleIconClick}
        style={{ cursor: "pointer" }}
      />
      <input
        type="file"
        accept=".pdf"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Box>
  );
};

