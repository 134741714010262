import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { COLORS } from "components";
import { chartColors } from "../const";

const commonStyles = {
  color: "black",
  fontFamily: "Inter",
  fontWeight: "600",
}

export const ScopeChartYearOverYear = ({
  chartTitle = 'EmissionsChart',
  yoYData,
  mainTab,
  type,
}: {
  chartTitle?: string;
  yoYData?: any;
  mainTab?: string;
  type?: string;
}) => {
  const { scope } = yoYData;

  const isEmissionsChart = type === "location" || type === "market";
  const isConsumption = mainTab === "Consumption";

  const data: any[] = scope ? Object.values(scope) : [];

  const emissionType = isEmissionsChart ? `${type}BasedEmission` : isConsumption ? "consumption" : "amount";

  const fiscalYearsData: any = data.reduce((acc: any, entry: any) => {
    entry.forEach((item: any) => {
      if (!acc[item.fiscalYear]) {
        acc[item.fiscalYear] = [];
      }
      acc[item.fiscalYear].push({
        scope: item.scope,
        [emissionType]: item[emissionType] ?? 0
      });
    });
    return acc;
  }, {});

  const series = Object.values(fiscalYearsData)?.map((entry: any) => {
    if (entry.length === 1) {
      if (entry[0].scope === "scope1") return [entry[0]?.[emissionType], 0, 0];
      if (entry[0].scope === "scope2") return [0, entry[0]?.[emissionType], 0];
      if (entry[0].scope === "NO_SCOPE") return [0, 0, entry[0]?.[emissionType]];
    };
    return [entry[0]?.[emissionType], entry[1]?.[emissionType], entry[2]?.[emissionType]];
  });

  const unitYAxis = isEmissionsChart ? "tCO2e" : data[0]?.[0]?.unit ?? data[0]?.[0]?.currency ?? "No Data Available";

  useEffect(() => { }, [scope])

  const chartOptions = {
    chart: {
      type: "column",
      spacing: [30, 50, 20, 40],
      width: null,
    },
    title: {
      text: chartTitle,
      align: "left",
      margin: 40,
      style: {
        ...commonStyles,
        color: COLORS.Mainblue,
        fontSize: "20px",
      },
    },
    xAxis: {
      categories: ["Scope 1", "Scope 2", "No Scope"],
      labels: {
        style: commonStyles,
      },
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: {
        text: unitYAxis,
        style: commonStyles,
        offset: 50
      },
      labels: {
        style: commonStyles,
      },
    },
    series: [
      ...Object.keys(fiscalYearsData).map((fiscalYear, index) => ({
        type: "column",
        name: `FY${fiscalYear}`,
        data: series[index],
        color: chartColors[index % chartColors.length],
        fillOpacity: 0.3,
        lineWidth: 6,
        marker: { enabled: false },
      }))
    ],
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
      column: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            textOutline: "",
          },
          formatter(this: Highcharts.TooltipFormatterContextObject) {
            return `<b>${Number(this!.y?.toFixed(2)).toLocaleString('en-US')}</b>`;
          },
        },
      },
    },
    legend: {
      title: {
        style: commonStyles,
      },
      align: "left",
      verticalAlign: "bottom",
      x: 20,
      margin: 30,
    },
    tooltip: {
      shared: true,
      borderRadius: 8,
      shadow: false,
      padding: 24,
      useHTML: true,
      outside: true,
      positioner(labelWidth: any, labelHeight: any, point: any) {
        return {
          x: point.plotX,
          y: point.plotY - labelHeight,
        };
      },
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return `
            <div style="font-size: 12px; padding: 8px; display: flex; flex-direction: column; gap: 5px;">
              <strong>${this!.x}</strong>
              ${this!.points
            ?.map(
              (point) => `
              <div style="display: flex; align-items: center; justify-content: space-between; width: 180px;">
                <div style="display: flex; align-items: center; gap: 6px;">
                  <span style="width: 10px; height: 10px; background: ${point.series.color}; border-radius: 50%; display: inline-block;"></span>
                  <span>${point.series.name}:</span>
                </div>
                <span style="font-weight: bold;">${Number(point.y!.toFixed(2)).toLocaleString('en-US')} ${unitYAxis}</span>
              </div>
            `
            ).join("")}
          </div>
        `;
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      fontFamily: "Inter",
      "& > div": {
        borderRadius: 2,
      },
    }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </Box>
  );
};
