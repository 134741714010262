import React, { FunctionComponent, memo, useMemo } from "react";
import { Box, Divider, Paper } from "@mui/material";
import dayjs from "dayjs";

import { AdaptedCollectorsMissingDocuments } from "api";

import {
  CloseButton,
  Data,
  H3BoldMissing,
  H5Bold,
} from "components";

import { formatNumber } from "utils";

export const CollectorMonthView: FunctionComponent<{
  year: number;
  month: string;
  collectorMissingDocuments: AdaptedCollectorsMissingDocuments;
  onClose: () => void;
}> = memo(({ collectorMissingDocuments, year, month, onClose }) => {
  const monthValue = month.split("-")[1].padStart(2, '0');
  const monthNumber = parseInt(monthValue, 10);

  const data: Data[] = useMemo(() => {
    if (
      collectorMissingDocuments &&
      collectorMissingDocuments.days &&
      monthValue
    ) {
      return [{ id: "days", ...collectorMissingDocuments.days[monthValue] }];
    }
    return [];
  }, [collectorMissingDocuments, monthValue]);

  const daysForMonth = useMemo(() => Object.entries(data[0]).filter(item => item[0] !== "id").sort((a, b) => Number(a[0]) - Number(b[0])), [data])

  return (
    <Paper sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "96px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "24px",

            }}
          >
            <p style={{ fontSize: "12px" }}>Site</p>
            <H5Bold>{collectorMissingDocuments?.collector?.siteName || ""}</H5Bold>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "64px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",

              }}
            >
              <p style={{ fontSize: "12px" }}>Type</p>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#1A4252", borderRadius: "10px", minWidth: "72px", width: "fit-content", height: "21px" }}>
                <p style={{ fontSize: "12px", color: "#fff", margin: 0, padding: "0 8px" }}>{collectorMissingDocuments?.collector?.utilityTypeName || "-"}</p>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",

              }}
            >
              <p style={{ fontSize: "12px" }}>Account #</p>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#1A4252", borderRadius: "10px", minWidth: "72px", width: "fit-content", height: "21px" }}>
                <p style={{ fontSize: "12px", color: "#fff", margin: 0, padding: "0 8px" }}>{collectorMissingDocuments?.collector?.accountNumber || "-"}</p>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",

              }}
            >
              <p style={{ fontSize: "12px" }}>Meter #</p>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#1A4252", borderRadius: "10px", minWidth: "72px", width: "fit-content", height: "21px" }}>
                <p style={{ fontSize: "12px", color: "#fff", margin: 0, padding: "0 8px" }}>{collectorMissingDocuments?.collector?.meterNumber || "-"}</p>
              </Box>
            </Box>
          </Box>
        </Box>
        <CloseButton
          onClick={onClose}
          sx={{
            width: "40px",
            height: "40px",
            maxHeight: "40px",
            "& > svg": {
              width: "40px",
              height: "40px"
            }
          }}
        />
      </Box>
      <Divider sx={{ borderColor: "#1A4252" }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "32px", padding: "42px 40px" }}>
        <H3BoldMissing>
          {dayjs(`${year}-${monthNumber}-01`).format("MMMM YYYY")}
        </H3BoldMissing>
        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", borderTop: "1px solid #C0D0D4", borderLeft: "1px solid #C0D0D4", }}>
          {
            daysForMonth.map((day, index) => (
              <Box
                key={`${day[0]} - ${index + 1}`}
                sx={{
                  width: "10%",
                  height: "147px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #C0D0D4",
                  borderRight: "1px solid #C0D0D4",
                  padding: "16px 16px 24px",
                  backgroundColor: day[1] === null || day[1] === undefined ? "#fde5e5" : "#fff",
                  cursor: "pointer",
                }}
              >
                <Box>
                  <p style={{ fontSize: "20px", fontWeight: "400", textAlign: "right", margin: 0 }}>{day[0]}</p>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end", gap: "3px", fontWeight: "600" }}>
                  {
                    day[1] === null || day[1] === undefined
                      ? <p style={{ fontSize: "24px", margin: 0 }}>-</p>
                      : <>
                          <p style={{ fontSize: formatNumber(Number(day[1]), 4).toString().length > 6 ? "16px" : "24px", margin: 0 }}>{formatNumber(Number(day[1]), 4)}</p>
                          <p style={{ fontSize: "14px", margin: 0, marginBottom: formatNumber(Number(day[1]), 4).toString().length > 6 ? "1px" : "4px" }}>{`${collectorMissingDocuments?.unit}`}</p>
                        </>
                  }
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box>
    </Paper>
  );
});
