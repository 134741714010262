import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import {
  ConfigParameterDTO,
  addCustomSiteType,
  addEmissionFactorSetting,
  checkExistingSite,
  clearEnvironment,
  createUserSetting,
  getAbbyySkill,
  getCustomSiteType,
  getEmissionFactorSetting,
  getFiscalYearSettings,
  getUtilityThreshold,
  removeCustomSiteType,
  setAbbySkill,
  updateConfigParameter,
  updateFiscalYear,
} from "api";

import { COLORS, EsgDatePickerField, DeleteDialog, SettingPrimaryLabel, TYPOGRAPHY_COMMON_STYLES, useDialogControls, Loading, Label16Bold, SettingButtonText, Label16SemiBold, Label14Medium, Label14SemiBold, useSwitchFilter, Switch } from "components";

import { useGetAllowedValues, useGetConfigParameter, useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { getLoggedUserData, getUTCDateFormat } from "utils";
import MultiSelectComponent from "components/inputs/multiSelect-input";
import { EsgAddIcon, EsgDropDownIcon, EyeIcon } from "assets";

import { ThresholdValueModal } from "../components/modal";

interface EmissionFactorSetting {
  id: number;
  settingName: string;
  active: boolean;
}

interface Option {
  id: number;
  name: string;
}

const CustomOutlinedInput = styled(OutlinedInput)(() => ({
  '&.MuiOutlinedInput-root': {
    borderRadius: '8px',
    paddingRight: '8px', // Adjust padding to avoid extra space
    '& fieldset': {
      borderColor: '#054254',
    },
    '&:hover fieldset': {
      borderColor: '#054254',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#054254',
    },
  },
  fontSize: '16px',
  fontWeight: '600',
  color: '#054254',
  width: '242px',
  height: '36px',
  padding: '0px 12px', // Reduce padding to avoid extra space on focus
}));

const CustomSelect = styled(Select)(() => ({
  '&.MuiOutlinedInput-root': {
    borderRadius: '8px', // Apply border radius
    paddingRight: '8px',
    '& fieldset': {
      borderColor: '#054254 !important', // Ensure border color is applied
    },
    '&:hover fieldset': {
      borderColor: '#054254 !important', // Change border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#054254 !important', // Ensure border color when focused
    },
    '& .MuiSelect-icon': {
      top: '50%',
      transform: 'translateY(-50%)',
      right: '8px',
    },
  },
}));

export const UserPreferencePage = () => {
  // ---- Hooks ----
  const notify = useNotify();
  const queryClient = useQueryClient();
  const loggedUserData = getLoggedUserData();
  const deleteDialogControls: any = useDialogControls();

  // ---- Const ----
  const dateFormats = ["MM-DD-YYYY", "DD-MM-YYYY"];

  // ---- States ----
  const [selectedFormat, setSelectedFormat] = useState("");
  const [selectedEmissionFactorSetting, setSelectedEmissionFactorSetting] = useState<any>();
  const [emissionFactorSettingData, setEmissionFactorSettingData] = useState([]);
  const [isSiteExist, setIsSiteExist] = useState<boolean>(true);
  const [fiscalYearMonth, setFiscalYearMonth] = useState("");
  const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = React.useState<any>(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isThresholdValueModal, setIsThresholdValueModal] = useState(false);
  const [showException, setShowException] = useState(false);

  // ---- API calls ----
  const { data: userSettingData, mutateAsync: createDateSetting } = useMutation(createUserSetting);
  const { data } = useGetConfigParameter();
  const { data: customSiteType, mutateAsync: GetCustomSiteType } = useMutation(getCustomSiteType);
  const { mutateAsync: RemoveCustomSiteType } = useMutation(removeCustomSiteType);
  const { mutateAsync: ClearEnvironment } = useMutation(clearEnvironment);
  const { mutateAsync: updateFiscalYearRequest } = useMutation(updateFiscalYear);
  const { data: abbyySkill, mutateAsync: GetAbbyySkill } = useMutation(getAbbyySkill);
  const { data: updatedAbbyySkills, mutateAsync: updateAbbyySkills } = useMutation(setAbbySkill);
  const { mutateAsync: setEmissionFactorSetting } = useMutation(addEmissionFactorSetting);
  const mutation = useMutation(addCustomSiteType);
  const { data: allowedValues, refetch } = useGetAllowedValues();
  const { mutateAsync: updateConfigParameterRequest } = useMutation(updateConfigParameter);
  const { data: utilityThresholdData, mutateAsync: getUtilityThresholds, isLoading: thresholdDataLoading } = useMutation(getUtilityThreshold);

  const { value: toggleChange, onSwitchChange: onToggleChange } = useSwitchFilter(
    {
      key: "isUnread",
      withQueryParams: false,
    },
  );

  // ---- Page Functions ----
  const handleFormatChange = (event: any) => {
    setSelectedFormat(event.target.value);
    if (event.target.value) {
      createDateSetting(
        { dateFormat: event.target.value },
        {
          onError: () =>
            notify.error(`Some error has happen while updating date format`),
          onSuccess: () => {
            notify.success(`Successfully updated date format!`);
            queryClient.invalidateQueries([QUERY_KEYS.USER_SETTINGS]);
          },
        },
      );
    }
  };

  const handleEmissionFactorSetting = (event: any) => {
    const selectedValue = event.target.value;
    const selectedItem = emissionFactorSettingData?.find(
      (item: any) => item.settingName === selectedValue
    );

    if (selectedItem) {
      const { id, settingName: value } = selectedItem;
      setSelectedEmissionFactorSetting({ id, value });
    }
  };

  const handleEmissionFactorSettingClick = () => {
    const { id, value }: any = selectedEmissionFactorSetting
    setEmissionFactorSetting(
      {
        id,
        settingName: value
      },
      {
        onError: () =>
          notify.error(`Some error has happen while updating date format`),
        onSuccess: () => {
          notify.success(`Successfully updated Emission Factor Setting`);
          // queryClient.invalidateQueries([QUERY_KEYS.USER_SETTINGS]);
        },
      },
    );
  }

  const getEmissionSettingData = async () => {
    const emissionFactorSetting = await getEmissionFactorSetting();
    const isExistingSite = await checkExistingSite()
    setIsSiteExist(isExistingSite)
    setEmissionFactorSettingData(emissionFactorSetting)
  }

  const onDeleteClick = () => {
    ClearEnvironment()
      .then(() => {
        deleteDialogControls.close();
        notify.success(`${loggedUserData.tenant} environment is deleted!`);
      })
      .catch(() => {
        notify.error(
          `Some error has happen while deleting documents: ${loggedUserData.tenant}!`,
        );
      });
  };

  const onMonthChange = (monthValue: any) => {
    if (monthValue !== null) {
      const startMonthId = configParameter.company_month_start.id as number;
      const endMonthId = configParameter.company_month_end.id as number;
      const endMonth = dayjs(monthValue, "MMM")
        .subtract(1, "month")
        .format("MMM");
      updateFiscalYearRequest([
        {
          id: startMonthId,
          value: monthValue,
        },
        {
          id: endMonthId,
          value: endMonth,
        },
      ])
        .then(() => {
          notify.success(
            `Your fiscal year has been updated to ${monthValue} - ${endMonth}!`,
          );
        })
        .catch(() => {
          notify.error(`Some error has happened while updating!`);
        });
    }
  };

  const handleInputChange = (e: any) => {
    const { target } = e;
    const inputVal = target;
    setInputValue(inputVal.value);
    if (inputVal.value.trim() === "") {
      setError("This field is required.");
    } else {
      setError("");
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (inputValue.trim() === "") {
      setError("This field is required.");
    } else {
      updateAbbyySkills(inputValue, {
        onError: () =>
          notify.error(`Some error has happen while updating Abbyy Skills`),
        onSuccess: () => {
          notify.success(`Successfully updated Abbyy Skills!`);
        },
      });
    }
  };

  const handleSaveChange = async () => {
    setIsLoading(true);
    const values = Array.isArray(selectedValues)
      && selectedValues.map((item) => (typeof item === 'string' ? item : item.name))

    await mutation.mutateAsync({ body: values, keepDefaultSiteType: checked });

    if (mutation.isSuccess) {
      notify.success('Successfully updated Custom Site Types!');
      GetCustomSiteType();
      refetch();
    } else {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const getConfigParameterObject = (configData: ConfigParameterDTO[]) =>
    configData.reduce<Record<string, Record<string, string | number>>>(
      (acc, parameter) => {
        acc[parameter.name] = {
          id: parameter.id,
          configValue: parameter.configValue,
        };
        return acc;
      },
      {},
    );

  const displayPreferenceModal = () => {
    (window as any)?.displayPreferenceModal();
    return false;
  };

  const handleValuesChange = (newValues: any) => {
    setSelectedValues(newValues);
  };

  const handleChipRemove = (removedOption: Option) => {
    RemoveCustomSiteType(removedOption)
  };

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 200); // Reset effect after 200ms
    setIsThresholdValueModal(true);
  };

  const handleCloseThresholdValueModal = () => {
    setIsThresholdValueModal(false);
    getUtilityThresholds()
  }

  // --- Use Effects ----
  useEffect(() => {
    getEmissionSettingData();
    GetCustomSiteType();
  }, [GetCustomSiteType])

  useEffect(() => {
    const date = userSettingData === undefined ? getUTCDateFormat() : userSettingData?.data?.dateFormat
    setSelectedFormat(date)
  }, [userSettingData])

  useEffect(() => {
    if (userSettingData) {
      window.sessionStorage.setItem(
        "date_format",
        JSON.stringify(userSettingData?.data?.dateFormat),
      );
    }
  }, [userSettingData]);

  useEffect(() => {
    setIsLoading(true);
    const customSites = customSiteType
    setSelectedValues(customSites);
    setIsLoading(false);
  }, [customSiteType])

  const configParameter = useMemo(
    () => getConfigParameterObject(data || []),
    [data],
  );

  useEffect(() => {
    if (loggedUserData.superadmin === true) {
      GetAbbyySkill();
    }
  }, [updatedAbbyySkills, GetAbbyySkill]); // eslint-disable-line

  useEffect(() => {
    setIsLoading(true)
    const defaultSiteTypes = data?.find((t) => t.name === "include_default_site_types")
    const defaultSiteTypesValue = defaultSiteTypes?.configValue === "true";
    setChecked(defaultSiteTypesValue);
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  }, [data])

  useEffect(() => {
    setInputValue(abbyySkill?.data?.message);
  }, [abbyySkill]);

  useEffect(() => {
    getFiscalYearSettings().then((fiscalYearSettings: any) => {
      const startMonthValue = fiscalYearSettings.find(
        (setting: any) => setting.name === "company_month_start",
      )?.configValue;
      setFiscalYearMonth(startMonthValue);
    });
  }, [fiscalYearMonth, selectedMonth]);

  const convertMonthToDayjs = (monthString: string) => {
    const currentYear = dayjs().year();
    const fullDateString = `${monthString} 1, ${currentYear}`;
    const date = dayjs(fullDateString);
    return date;
  };

  useEffect(() => {
    setIsLoading(true)
    if (fiscalYearMonth) {
      const updatedDate = convertMonthToDayjs(fiscalYearMonth)
      setSelectedMonth(updatedDate)
    }
    setIsLoading(false)
  }, [fiscalYearMonth])

  useEffect(() => {
    // Find the active setting
    const activeSetting: any = emissionFactorSettingData.find((item: EmissionFactorSetting) => item.active === true);
    if (activeSetting) {
      setSelectedEmissionFactorSetting({ id: activeSetting?.id, value: activeSetting?.settingName });
    }
  }, [emissionFactorSettingData]);

  const reviewId = data?.find((t) => t.name === "needs_review_flag")

  useEffect(() => {
    if (reviewId?.configValue === "false") {
      onToggleChange(false)
    } else {
      onToggleChange(true)
    }
  }, [data, onToggleChange, reviewId])

  useEffect(() => {
    getUtilityThresholds()
  }, [getUtilityThresholds])

  if (isLoading || thresholdDataLoading) return <Loading />;
  return (
    <>
      <Box sx={{ mt: 3, ml: 6, display: 'flex', gap: 30 }}>
        <Box sx={{ width: '210px' }}>
          <Label16Bold>Date</Label16Bold>
          <SettingPrimaryLabel sx={{ mt: 2 }}>Customize your date format preferences.</SettingPrimaryLabel>
        </Box>
        <Box mt={3}>
          <FormControl fullWidth variant="outlined">
            <CustomSelect
              value={selectedFormat}
              onChange={handleFormatChange}
              input={<CustomOutlinedInput />}
              IconComponent={EsgDropDownIcon}
              displayEmpty
            >
              {dateFormats.map((format) => (
                <MenuItem
                  sx={{
                    fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                    fontSize: 14,
                    lineHeight: "18px",
                    color: '#90959E',
                    '&:hover': {
                      backgroundColor: '#F6F8FA',
                      borderRadius: 5,
                      color: COLORS.Romance,
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#F6F8FA',
                      borderRadius: 5,
                      color: COLORS.Romance,
                    },
                  }}
                  key={format} value={format}>
                  {format}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ my: 3, ml: 6, display: 'flex', gap: 30 }}>
        <Box sx={{ width: '210px' }}>
          <Label16Bold>Consent Management</Label16Bold>
          <SettingPrimaryLabel sx={{ mt: 2 }}>Customize your consent management preferences.</SettingPrimaryLabel>
        </Box>
        <Box mt={3}>
          <Button
            onClick={displayPreferenceModal}
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: COLORS.Romance,
              width: "242px",
              height: "36px",
              borderRadius: '8px'
            }}
          >
            <SettingButtonText>Consent Management</SettingButtonText>
          </Button>
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ mt: 3, ml: 6, display: 'flex', gap: 30 }}>
        <Box sx={{ width: '210px' }}>
          <Label16Bold>Fiscal Year Start Month </Label16Bold>
          <SettingPrimaryLabel sx={{ mt: 2 }}>Customize your fiscal year start month.</SettingPrimaryLabel>
        </Box>
        {/* Calender */}
        <Box mt={2}>
          <EsgDatePickerField
            sx={{
              maxWidth: "242px",
              height: '36px',
            }}
            views={["month"]}
            format="MMM"
            openTo="month"
            disableHighlightToday
            onChange={(date: any) => {
              const monthValue = date ? date.format("MMM") : null;
              setSelectedMonth(date);
              onMonthChange(monthValue);
            }}
            value={selectedMonth}
            clearDate={false}
            label=""
          />
        </Box>
      </Box>


      {loggedUserData.superadmin === true && (
        <>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ mt: 3, ml: 6, display: 'flex', gap: 30 }}>
            <Box sx={{ width: '210px' }}>
              <Label16Bold>Clear Environment</Label16Bold>
              <SettingPrimaryLabel sx={{ mt: 2 }}>Customize your consent management preferences.</SettingPrimaryLabel>
            </Box>
            <Box>
              <Box mt={3}>
                <Button
                  onClick={deleteDialogControls.open}
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: COLORS.Romance,
                    width: "242px",
                    height: "36px",
                    borderRadius: '8px'
                  }}
                >
                  <SettingButtonText>Clear</SettingButtonText>
                </Button>
                <DeleteDialog
                  isOpen={deleteDialogControls.isOpen}
                  onCancel={deleteDialogControls.close}
                  onDeleteClick={() => onDeleteClick()}
                  selectedCount={undefined}
                  target={`${loggedUserData.tenant} Environment`}
                  title={
                    `Delete ${loggedUserData.tenant} Environment?` as string
                  }
                />
              </Box>
            </Box>
          </Box>
        </>)}


      {loggedUserData.superadmin === true && (
        <>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ mt: 3, ml: 6, display: 'flex', gap: 30 }}>
            <Box sx={{ width: '210px' }}>
              <Label16Bold>ABBYY Skills</Label16Bold>
              <SettingPrimaryLabel sx={{ mt: 2 }}>Customize your fiscal year start month.</SettingPrimaryLabel>
            </Box>
            <Box>
              <Box>
                <TextField
                  label=""
                  variant="standard"
                  value={inputValue}
                  onChange={handleInputChange}
                  error={!!error}
                  helperText={error}
                  sx={{
                    width: "242px",
                    height: "32px",
                    '& .MuiInputBase-input': {
                      color: COLORS.Romance,
                      fontSize: '16px',
                      fontWeight: '600',
                      padding: '0px',
                      letterSpacing: '0.5px',
                      fontFamily: "Inter"
                    },
                  }}
                />
              </Box>

              <Box mt={3}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: COLORS.Romance,
                    width: "242px",
                    height: "36px",
                    borderRadius: '8px'
                  }}
                >
                  <SettingButtonText>Apply</SettingButtonText>
                </Button>
              </Box>
            </Box>
          </Box>
        </>)}


      {loggedUserData.superadmin === true && (
        <>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ mt: 3, ml: 6, display: 'flex', gap: 30 }}>
            <Box sx={{ width: '212px' }}>
              <Label16Bold>Emissions Factors Settings</Label16Bold>
              <SettingPrimaryLabel sx={{ mt: 2 }}>Customize your consent management preferences.</SettingPrimaryLabel>
            </Box>
            <Box>
              <Box mt={3.5}>
                <CustomSelect
                  IconComponent={EsgDropDownIcon}
                  disabled={isSiteExist}
                  value={selectedEmissionFactorSetting?.value}
                  onChange={handleEmissionFactorSetting}
                  input={<CustomOutlinedInput />}
                  displayEmpty
                >
                  {emissionFactorSettingData.map((item: EmissionFactorSetting) => (
                    <MenuItem
                      id={String(item?.id)}
                      sx={{
                        fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                        fontSize: 14,
                        lineHeight: "18px",
                        color: '#90959E',
                        '&:hover': {
                          backgroundColor: '#F6F8FA',
                          borderRadius: 5,
                          color: COLORS.Romance,
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#F6F8FA',
                          borderRadius: 5,
                          color: COLORS.Romance,
                        },
                      }}
                      key={item?.id} value={item?.settingName}>
                      {item?.settingName}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Box>
              <Box mt={3}>
                <Button
                  onClick={handleEmissionFactorSettingClick}
                  variant="contained"
                  disabled={isSiteExist}
                  sx={{
                    color: "white",
                    backgroundColor: COLORS.Romance,
                    width: "242px",
                    height: "36px",
                    borderRadius: '8px'
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        </>)}

      {configParameter?.custom_site_type_toggle?.configValue === "ON" && loggedUserData.superadmin === true &&
        <>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ mb: 3, ml: 6, display: 'flex', gap: 30 }}>
            <Box sx={{ width: '210px' }}>
              <Label16Bold>Custom Site Types</Label16Bold>
              <SettingPrimaryLabel sx={{ mt: 2 }}>Customize your site options.</SettingPrimaryLabel>
            </Box>
            <Box sx={{ width: '210px' }}>
              <Box >
                <Label16SemiBold sx={{ mb: "18px" }}>Current Site Types</Label16SemiBold>
                {loggedUserData.superadmin === true && (
                  <CustomSelect
                    IconComponent={EsgDropDownIcon}
                    input={<CustomOutlinedInput />}
                    displayEmpty
                  >
                    {allowedValues?.siteTypes?.map((item: any) => (
                      <MenuItem
                        id={String(item?.id)}
                        sx={{
                          fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                          fontSize: 14,
                          lineHeight: "18px",
                          color: '#90959E',
                          '&:hover': {
                            backgroundColor: '#F6F8FA',
                            borderRadius: 5,
                            color: COLORS.Romance,
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#F6F8FA',
                            borderRadius: 5,
                            color: COLORS.Romance,
                          },
                        }}
                        key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </Box>
            </Box>
            <Box sx={{ width: '400px' }}>
              <Label16SemiBold sx={{ my: "5px" }}>Use Custom Site Types</Label16SemiBold>
              <SettingPrimaryLabel sx={{ mt: "7px" }}>Please Press Enter to add more custom sites.</SettingPrimaryLabel>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  color="primary"
                  checked={checked}
                  sx={{
                    padding: 0,
                    my: '10px'
                  }}
                  onChange={(e) => {
                    setChecked(e?.target?.checked)
                  }}
                />
                <Label14Medium sx={{ m: "5px" }}>Keep all default site types in addition to my custom sites</Label14Medium>
              </div>
              {selectedValues && <MultiSelectComponent
                selectedValues={selectedValues}
                onChange={handleValuesChange}
                onRemove={handleChipRemove}  // Get the ID of the removed chip
                placeholder="Custom Sites"
              />}


              <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={() => handleSaveChange()}
                  sx={{
                    color: "white",
                    backgroundColor: COLORS.Romance,
                    width: "168px",
                    height: "36px",
                    borderRadius: '8px'
                  }}
                >
                  <SettingButtonText>Save Changes</SettingButtonText>
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      }

      {/* Threshold Value by Utility Type */}
      {loggedUserData.superadmin === true && <>
        <Divider sx={{ my: 3 }} />
        <Box sx={{ mb: 3, ml: 6, display: 'flex', gap: 30 }}>
          <Box sx={{ width: '210px' }}>
            <Label16Bold>Threshold Value by Utility Type and Site</Label16Bold>
            <SettingPrimaryLabel sx={{ mt: 2 }}>Customize threshold value by Utility type and Site.</SettingPrimaryLabel>
          </Box>
          <Box sx={{ width: '210px' }}>
            <Label16SemiBold sx={{ mb: "18px" }}>Default</Label16SemiBold>
            <Box sx={{ display: 'flex', gap: 6 }}>

              <Box>
                <Label14Medium>MoM</Label14Medium>
                <span>15%</span>
              </Box>
              <Box>
                <Label14Medium>YoY</Label14Medium>
                <span>15%</span>

              </Box>
            </Box>
          </Box>
          <Box sx={{ width: '400px' }}>
            <Label16SemiBold sx={{ my: "5px" }}>Exceptions</Label16SemiBold>
            <Box
              onClick={() => {
                setShowException(false)
                handleClick()
              }}
              sx={{ display: 'flex', gap: 1, alignItems: 'center', my: 2, cursor: 'pointer', }}>
              <EsgAddIcon color={isClicked ? "#D98484" : "#C73333"} />
              <Label14SemiBold color={isClicked ? "#D98484" : "#C73333"}>Add new exception</Label14SemiBold>
            </Box>
            {utilityThresholdData?.data?.length > 0 && <Button
              onClick={() => {
                setShowException(true)
                handleClick()
              }}
              variant="contained"
              startIcon={<EyeIcon />}
              sx={{
                color: "white",
                backgroundColor: COLORS.Romance,
                width: "242px",
                height: "36px",
                borderRadius: '8px'
              }}
            >
              <SettingButtonText>View all exceptions</SettingButtonText>
            </Button>}
          </Box>
        </Box>
      </>}
      {/* IDP Needs Review */}
      {loggedUserData.superadmin === true && <>
        <Divider sx={{ my: 3 }} />
        <Box sx={{ mb: 3, ml: 6, display: 'flex', gap: 30 }}>
          <Box sx={{ width: '210px' }}>
            <Label16Bold>IDP Needs Review</Label16Bold>
            <SettingPrimaryLabel sx={{ mt: 2 }}>Customize whether bills processed through IDP should always require review.</SettingPrimaryLabel>
          </Box>
          <Box sx={{ width: 'auto' }}>
            <Label16SemiBold sx={{ mb: "18px" }}>Always Set to Needs Review</Label16SemiBold>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Switch
                isChecked={toggleChange}
                label=""
                onChange={(value) => {

                  updateConfigParameterRequest(
                    {
                      id: reviewId?.id as number,
                      value: `${value}`,
                    },
                    {
                      onError: () =>
                        notify.error("Failed to update configuration parameters"),
                      onSuccess: () => {
                        notify.success("Successfully updated configuration parameters");
                        onToggleChange(value)
                        queryClient.invalidateQueries([QUERY_KEYS.CONFIG_PARAMETER]);
                      },
                    },
                  )
                }}
              />
            </Box>
          </Box>

        </Box>
      </>}
      <ThresholdValueModal
        isOpen={isThresholdValueModal}
        onClose={handleCloseThresholdValueModal}
        allowedValues={allowedValues}
        showException={showException}
        utilityThresholdData={utilityThresholdData?.data}

      />
    </>
  )
};