import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, FormControl, MenuItem, Select, ToggleButton, ToggleButtonGroup } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { COLORS } from "components";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDownIcon sx={{ fontSize: "2rem", color: "white" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: COLORS.Mainblue,
  flexDirection: "row-reverse",
  borderRadius: "10px",
  color: "white",
  fontWeight: "600",
  fontSize: "18px",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
  {
    transform: "rotate(180deg)",
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3),
  borderTop: "1px solid white",
}));

export const CustomAccordion = ({
  titleAccordion,
  panelNumber,
  startDate,
  endDate,
  allowDates,
  allowPeriods,
  allowYears,
  allowFiscalYears,
  setStartDate,
  setEndDate,
  children,
  dateRangeType,
  setDateRangeType,
  yoYMaxLength,
  setYoYLength,
  currentFiscalYear,
  setCurrentFiscalYear,
  allFiscalYears,
}: {
  titleAccordion: string,
  panelNumber: number,
  allowDates?: boolean;
  allowPeriods?: boolean;
  allowYears?: boolean;
  allowFiscalYears?: boolean;
  startDate?: Dayjs;
  endDate?: Dayjs;
  setStartDate?: React.Dispatch<React.SetStateAction<Dayjs>>;
  setEndDate?: React.Dispatch<React.SetStateAction<Dayjs>>;
  children: React.ReactNode;
  dateRangeType?: string;
  setDateRangeType?: React.Dispatch<React.SetStateAction<string>>;
  yoYMaxLength?: number;
  setYoYLength?: React.Dispatch<React.SetStateAction<number>>;
  currentFiscalYear?: string;
  setCurrentFiscalYear?: React.Dispatch<React.SetStateAction<string>>;
  allFiscalYears?: string[];
}) => {
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [selectedYears, setSelectedYears] = useState<number[]>([1]);

  const todayDate = dayjs.utc().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);

  const years = Array.from(Array(yoYMaxLength), (_, index) => (index + 1)); // TODO: GET from API

  const handleChangeExpanded = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleVarianceDateChange = (date: Dayjs, type: string) => {
    if (type === "startDate") {
      setStartDate!(date);
    } else {
      const chosenDate = date.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      if (chosenDate < todayDate) setEndDate!(chosenDate.date(1).add(1, "month").subtract(1, "day"));
      else setEndDate!(todayDate);
    }
  }

  const handleDateRangeTypeChange = (event: any) => {
    setDateRangeType!(event.target.value);
  }

  const handleDateRangeChange = (date: Dayjs, type: string) => {
    if (type === "startDate") {
      setStartDate!(date);
    } else {
      const chosenDate = date.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      if (chosenDate < todayDate) setEndDate!(chosenDate.date(1).add(1, "month").subtract(1, "day"));
      else setEndDate!(todayDate);
    }
  }

  const handleChangeYearOnYear = (newYear: number) => {
    const index = years.indexOf(newYear);
    if (index !== -1) {
      setSelectedYears(years.slice(0, index + 1));
      setYoYLength!(newYear);
    }
  };

  const handleChangeFiscalYear = (newYear: string) => {
    setCurrentFiscalYear!(newYear);
  };

  return (
    <Box sx={{
      fontFamily: "Inter",
      borderRadius: "10px",
      width: "100%",
      paddingRight: "4px",
      "& > div": {
        borderRadius: "10px",
        border: "none",
      },
    }}>
      <Accordion expanded={expanded === `panel${panelNumber}`} onChange={handleChangeExpanded(`panel${panelNumber}`)}>
        <AccordionSummary
          sx={{
            height: "60px",
            backgroundColor: `${COLORS.Mainblue} !important`,
            borderRadius: expanded ? "10px 10px 0 0" : "10px",
            px: 3,
          }}
          aria-controls={`panel${panelNumber}d-content`}
          id={`panel${panelNumber}d-header`}
        >
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <Typography component="span">{titleAccordion}</Typography>
            {expanded && allowDates &&
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                }}
                onClick={(event) => event.stopPropagation()}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      width: "100%",
                      "& > div": {
                        borderRadius: "8px",
                        "& input": {
                          fontFamily: "Inter",
                          height: "36px",
                          padding: "0 10px",
                        },
                      },
                    }}
                    views={['year', 'month']}
                    format="MMMM YYYY"
                    // minDate={} // TODO: take from first fiscal year
                    maxDate={dayjs()}
                    value={startDate}
                    onChange={(date) => handleVarianceDateChange(date!, "startDate")}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      width: "100%",
                      "& > div": {
                        borderRadius: "8px",
                        "& input": {
                          fontFamily: "Inter",
                          height: "36px",
                          padding: "0 10px",
                        },
                      },
                    }}
                    views={['year', 'month']}
                    format="MMMM YYYY"
                    // minDate={} // TODO: take from first fiscal year
                    maxDate={dayjs()}
                    value={endDate}
                    onChange={(date) => handleVarianceDateChange(date!, "endDate")}
                  />
                </LocalizationProvider>
              </Box>}
            {expanded && allowPeriods &&
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 5,
                }}
                onClick={(event) => event.stopPropagation()}>
                <FormControl
                  sx={{
                    width: "240px",
                    height: "36px",
                  }}
                >
                  <Select
                    sx={{
                      height: "36px",
                      borderRadius: "8px",
                      color: COLORS.Mainblue,
                    }}
                    value={dateRangeType}
                    onChange={(event) => handleDateRangeTypeChange(event)}
                  >
                    <MenuItem value="MONTHLY" sx={{ color: COLORS.Mainblue }}>Monthly</MenuItem>
                    <MenuItem value="QUARTERLY" sx={{ color: COLORS.Mainblue }}>Quarterly</MenuItem>
                    <MenuItem value="YEARLY" sx={{ color: COLORS.Mainblue }}>Yearly</MenuItem>
                  </Select>
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        width: "100%",
                        "& > div": {
                          borderRadius: "8px",
                          "& input": {
                            fontFamily: "Inter",
                            height: "36px",
                            padding: "0 10px",
                          },
                        },
                      }}
                      views={['year', 'month']}
                      format="MMMM YYYY"
                      // minDate={} // TODO: take from first fiscal year
                      maxDate={dayjs()}
                      value={startDate}
                      onChange={(date) => handleDateRangeChange(date!, "startDate")}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        width: "100%",
                        "& > div": {
                          borderRadius: "8px",
                          "& input": {
                            fontFamily: "Inter",
                            height: "36px",
                            padding: "0 10px",
                          },
                        },
                      }}
                      views={['year', 'month']}
                      format="MMMM YYYY"
                      // minDate={} // TODO: take from first fiscal year
                      maxDate={dayjs()}
                      value={endDate}
                      onChange={(date) => handleDateRangeChange(date!, "endDate")}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>}
            {expanded && allowYears &&
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 5,
                }}
                onClick={(event) => event.stopPropagation()}>
                <ToggleButtonGroup
                  value={selectedYears}
                  sx={{
                    backgroundColor: "#033c4f",
                    borderRadius: "8px",
                    padding: "2px",
                  }}
                >
                  {years.map((year) => (
                    <ToggleButton
                      key={year}
                      value={year}
                      onClick={() => handleChangeYearOnYear(year)}
                      sx={{
                        height: "36px",
                        color: COLORS.Mainblue,
                        mx: "1px",
                        backgroundColor: selectedYears.includes(year) ? "#99CD71" : "white",
                        "&.Mui-selected": {
                          backgroundColor: "#99CD71",
                          color: "white",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: "#99CD71",
                          color: COLORS.Mainblue,
                        },
                        "&:hover": {
                          backgroundColor: "#99CD71",
                        },
                      }}
                    >
                      {`${year}Y`}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>}
            {expanded && allowFiscalYears &&
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                }}
                onClick={(event) => event.stopPropagation()}>
                <FormControl
                  sx={{
                    width: "240px",
                    height: "36px",
                  }}
                >
                  <Select
                    sx={{
                      height: "36px",
                      borderRadius: "8px",
                    }}
                    value={currentFiscalYear}
                    onChange={(event) => handleChangeFiscalYear(event.target.value as string)}
                  >
                    {allFiscalYears?.map((fiscalYear) => (
                      <MenuItem key={fiscalYear} value={fiscalYear}>
                        {`FY${fiscalYear}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          backgroundColor: COLORS.Mainblue,
          color: "white",
          borderRadius: "0 0 10px 10px",
        }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
