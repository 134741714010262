import { Box, Button, Drawer, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { FunctionComponent } from "react";

import { deleteResetLearning } from "api";

import { CloseButton } from "components";
import { H5Bold } from "components/typography";

import { InvitedUsersList } from "./components";

interface ManageUsersDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ManageUsersDrawer: FunctionComponent<ManageUsersDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const { mutateAsync: deleteResetLearningRequest } =
    useMutation(deleteResetLearning);

  const resetLearning = () => {
    deleteResetLearningRequest();
  };
  return (
    <Drawer
      sx={{ zIndex: 1300 }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { width: "80vw" } }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={3}
        height="100%"
        padding="0px"
      >
        <Box
          component="header"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: "24px",
            pt: "34px"
          }}
        >
          <H5Bold>Manage users</H5Bold>
          <CloseButton onClick={onClose} />
        </Box>
        
        <InvitedUsersList shouldFetchUsers={isOpen} />
        {process.env.REACT_APP_SCOPE3_RESET_LEARNING === "true" && (
          <Button
            type="submit"
            variant="contained"
            onClick={() => resetLearning()}
          >
            Reset Learning
          </Button>
        )}
      </Stack>
    </Drawer>
  );
};
