import { httpClient } from "utils";

const defaultPathEmissions = "/emissions/v2";
const defaultPathConsumption = "/consumptions/v2";
const defaultPathFinancials = "/financials/v2";

export const DASHBOARD = {
  VARIANCE: "/variance/yoy",
  COMPARISON: "/variance/comparison",
  DATE_RANGE: "/range",
  YOY: "/yoy",
  SCOPE: "/scope",
};

export interface DefaultChartDTO {
  countryRegion: string | null;
  countries: string[] | null;
  facilityTypes: string[] | null;
  businessUnits: string[] | null;
  scope: string | null;
  utilityTypeIds: number[] | null;
  siteIds: number[] | null;
  accountNumbers: string[] | null;
  meterNumbers: string[] | null;
};

export interface VarianceChartDTO extends DefaultChartDTO {
  compareFromDate: string;
  compareToDate: string;
};

export interface VarianceComparisonDTO extends VarianceChartDTO {
  comparisonPeriod: string;
};

export interface DateRangeDTO extends VarianceChartDTO {
  groupBy: string;
};

export interface YoYDTO extends DefaultChartDTO {
  yearsToCompare: number;
};

export interface SingleYearByScopeDTO extends DefaultChartDTO {
  fiscalYear: string;
};

export const updateVarianceEmissions = (varianceRequest: VarianceChartDTO) =>
  httpClient.post<VarianceChartDTO>(`${defaultPathEmissions + DASHBOARD.VARIANCE}`, varianceRequest);

export const updateVarianceComparisonEmissions = (comparisonRequest: VarianceComparisonDTO) =>
  httpClient.post<VarianceComparisonDTO>(`${defaultPathEmissions + DASHBOARD.COMPARISON}`, comparisonRequest);

export const updateDateRangeEmissions = (dateRangeRequest: DateRangeDTO) =>
  httpClient.post<DateRangeDTO>(`${defaultPathEmissions + DASHBOARD.DATE_RANGE}`, dateRangeRequest);

export const updateYoYEmissions = (YoYRequest: YoYDTO) =>
  httpClient.post<YoYDTO>(`${defaultPathEmissions + DASHBOARD.YOY}`, YoYRequest);

export const updateSingleYearByScopeEmissions = (singleYearByScopeRequest: SingleYearByScopeDTO) =>
  httpClient.post<SingleYearByScopeDTO>(`${defaultPathEmissions + DASHBOARD.SCOPE}`, singleYearByScopeRequest);

export const updateVarianceConsumption = (varianceRequest: VarianceChartDTO) =>
  httpClient.post<VarianceChartDTO>(`${defaultPathConsumption + DASHBOARD.VARIANCE}`, varianceRequest);

export const updateVarianceComparisonConsumption = (comparisonRequest: VarianceComparisonDTO) =>
  httpClient.post<VarianceComparisonDTO>(`${defaultPathConsumption + DASHBOARD.COMPARISON}`, comparisonRequest);

export const updateDateRangeConsumption = (dateRangeRequest: DateRangeDTO) =>
  httpClient.post<DateRangeDTO>(`${defaultPathConsumption + DASHBOARD.DATE_RANGE}`, dateRangeRequest);

export const updateYoYConsumption = (YoYRequest: YoYDTO) =>
  httpClient.post<YoYDTO>(`${defaultPathConsumption + DASHBOARD.YOY}`, YoYRequest);

export const updateSingleYearByScopeConsumption = (singleYearByScopeRequest: SingleYearByScopeDTO) =>
  httpClient.post<SingleYearByScopeDTO>(`${defaultPathConsumption + DASHBOARD.SCOPE}`, singleYearByScopeRequest);

export const updateVarianceFinancials = (varianceRequest: VarianceChartDTO) =>
  httpClient.post<VarianceChartDTO>(`${defaultPathFinancials + DASHBOARD.VARIANCE}`, varianceRequest);

export const updateVarianceComparisonFinancials = (comparisonRequest: VarianceComparisonDTO) =>
  httpClient.post<VarianceComparisonDTO>(`${defaultPathFinancials + DASHBOARD.COMPARISON}`, comparisonRequest);

export const updateDateRangeFinancials = (dateRangeRequest: DateRangeDTO) =>
  httpClient.post<DateRangeDTO>(`${defaultPathFinancials + DASHBOARD.DATE_RANGE}`, dateRangeRequest);

export const updateYoYFinancials = (YoYRequest: YoYDTO) =>
  httpClient.post<YoYDTO>(`${defaultPathFinancials + DASHBOARD.YOY}`, YoYRequest);

export const updateSingleYearByScopeFinancials = (singleYearByScopeRequest: SingleYearByScopeDTO) =>
  httpClient.post<SingleYearByScopeDTO>(`${defaultPathFinancials + DASHBOARD.SCOPE}`, singleYearByScopeRequest);
