import React, { useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import { EsgMultiselectControlledField } from "components/inputs/esg-multi-select-field";
import { MultiSelectControlledOption } from "components";

export const AllSelects = (
  {
    allowedValues,
    sitesData,
    configParameter,
    collectorsData,
    defaultObject,
    setDefaultObject,
    setCurrentActiveSearch,
    currentMainTab
  }: {
    allowedValues: any;
    sitesData: any;
    configParameter: any;
    collectorsData: any;
    defaultObject: any;
    setDefaultObject: React.Dispatch<React.SetStateAction<any>>;
    setCurrentActiveSearch: React.Dispatch<React.SetStateAction<boolean>>;
    currentMainTab: string | null;
  }
) => {
  const [currentRegions, setCurrentRegions] = useState<MultiSelectControlledOption[]>([]);
  const [currentCountries, setCurrentCountries] = useState<MultiSelectControlledOption[]>([]);
  const [currentFacilityTypes, setCurrentFacilityTypes] = useState<MultiSelectControlledOption[]>([]);
  const [currentBusinessUnits, setCurrentBusinessUnits] = useState<MultiSelectControlledOption[]>([]);
  const [currentScopes, setCurrentScopes] = useState<MultiSelectControlledOption[]>([]);
  const [currentUtilityTypes, setCurrentUtilityTypes] = useState<MultiSelectControlledOption[]>([]);
  const [currentSites, setCurrentSites] = useState<MultiSelectControlledOption[]>([]);
  const [currentAccountNumbers, setCurrentAccountNumbers] = useState<MultiSelectControlledOption[]>([]);
  const [currentMeterNumbers, setCurrentMeterNumbers] = useState<MultiSelectControlledOption[]>([]);
  const [searchActive, setSearchActive] = useState<boolean>(false);

  const regions = useMemo(() => allowedValues ? allowedValues.countryRegions.map((reg: string) => ({ name: reg, label: reg, value: reg })) : [], [allowedValues]);
  const countries = useMemo(() => allowedValues ? allowedValues.countries.map((coun: string) => ({ name: coun.replaceAll("_", " "), label: coun, value: coun })) : [], [allowedValues]);
  const facilityTypes = useMemo(() => allowedValues ? allowedValues.siteTypes.map((site: string) => ({ name: site, label: site, value: site })) : [], [allowedValues]);
  const businessUnits = useMemo(() => allowedValues ? allowedValues.businessUnits.map((bunit: string) => ({ name: bunit, label: bunit, value: bunit })) : [], [allowedValues]);
  const scopes = useMemo(() => configParameter
    ? configParameter
      .filter((param: any) => param.name.includes("scope") && param.configValue === "true")
      .map((param: any) => param.name.replace(/\b\w/g, (char: string) => char.toUpperCase())
        .replace(/(\D)(\d+)/g, '$1 $2'))
      .filter((scope: string) => scope !== "Scope 3")
      .map((scope: string) => ({ name: scope, label: scope, value: scope.toLowerCase().replaceAll(" ", "") }))
    : [], [configParameter]
  );
  const utilityTypes = useMemo(() => allowedValues ? allowedValues.utilityTypes.map((ut: any) => ({ name: ut.name, label: ut.name, value: ut.id })) : [], [allowedValues]);
  const sites = useMemo(() => sitesData ? sitesData.map((site: any) => ({ name: site.name, label: site.name, value: site.id })) : [], [sitesData]);
  const accountNumbers = useMemo(() => collectorsData ? collectorsData.filter((collector: any) => collector.accountNumber !== null).map((collector: any) => ({ name: collector.accountNumber, label: collector.accountNumber, value: collector.accountNumber })) : [], [collectorsData]);
  const meterNumbers = useMemo(() => collectorsData ? collectorsData.filter((collector: any) => collector.meterNumber !== null).map((collector: any) => ({ name: collector.meterNumber, label: collector.meterNumber, value: collector.meterNumber })) : [], [collectorsData]);

  const handleClearFilters = () => {
    setCurrentRegions([]);
    setCurrentCountries([]);
    setCurrentFacilityTypes([]);
    setCurrentBusinessUnits([]);
    setCurrentScopes([]);
    setCurrentUtilityTypes([]);
    setCurrentSites([]);
    setCurrentAccountNumbers([]);
    setCurrentMeterNumbers([]);
    setDefaultObject({
      countryRegions: null,
      countries: null,
      siteIds: null,
      facilityTypes: null,
      businessUnits: null,
      scopes: null,
      utilityTypeIds: null,
      accountNumbers: null,
      meterNumbers: null,
    });
    setSearchActive(true);
  };

  const updateArr = (arr: any[], value: string | number) => {
    const currentSelection = Array.from(new Set([...arr.map((item: any) => item.value), value]))
    if (currentSelection.find((item: any) => item === "ALL") || regions.length === currentSelection.length) {
      return null;
    }
    return currentSelection;
  };

  const noEmissions = currentMainTab === "Consumption" || currentMainTab === "Financials";

  return (
    <Box sx={{
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      gap: "12px",
      paddingRight: "48px",
      mt: 3,
      "& > div": {
        width: "200px",
      }
    }}>
      <EsgMultiselectControlledField
        label="Region"
        selectedValues={currentRegions}
        setSelectedValues={setCurrentRegions}
        options={regions}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            countryRegions: updateArr(currentRegions, value),
          });
          setSearchActive(true);
        }}
      />
      <EsgMultiselectControlledField
        label="Country"
        selectedValues={currentCountries}
        setSelectedValues={setCurrentCountries}
        options={countries}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            countries: updateArr(currentCountries, value),
          });
          setSearchActive(true);
        }}
      />
      <EsgMultiselectControlledField
        label="Facility Types"
        selectedValues={currentFacilityTypes}
        setSelectedValues={setCurrentFacilityTypes}
        options={facilityTypes}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            facilityTypes: updateArr(currentFacilityTypes, value),
          });
          setSearchActive(true);
        }}
      />
      <EsgMultiselectControlledField
        label="Business Unit"
        selectedValues={currentBusinessUnits}
        setSelectedValues={setCurrentBusinessUnits}
        options={businessUnits}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            businessUnits: updateArr(currentBusinessUnits, value),
          });
          setSearchActive(true);
        }}
      />
      <EsgMultiselectControlledField
        label="Scope"
        selectedValues={currentScopes}
        setSelectedValues={setCurrentScopes}
        options={!noEmissions ? scopes : [...scopes, { name: "No Scope", label: "No Scope", value: "NO_SCOPE" }]}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            scopes: updateArr(currentScopes, value),
          });
          setSearchActive(true);
        }}
      />
      <EsgMultiselectControlledField
        label="Utility Type"
        selectedValues={currentUtilityTypes}
        setSelectedValues={setCurrentUtilityTypes}
        options={utilityTypes}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            utilityTypeIds: updateArr(currentUtilityTypes, value),
          });
          setSearchActive(true);
        }}
      />
      <EsgMultiselectControlledField
        label="Sites"
        selectedValues={currentSites}
        setSelectedValues={setCurrentSites}
        options={sites}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            siteIds: updateArr(currentSites, value),
          });
          setSearchActive(true);
        }}
      />
      <EsgMultiselectControlledField
        label="Account Number"
        selectedValues={currentAccountNumbers}
        setSelectedValues={setCurrentAccountNumbers}
        options={accountNumbers}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            accountNumbers: updateArr(currentAccountNumbers, value),
          });
          setSearchActive(true);
        }}
      />
      <EsgMultiselectControlledField
        label="Meter Number"
        selectedValues={currentMeterNumbers}
        setSelectedValues={setCurrentMeterNumbers}
        options={meterNumbers}
        isAllSelectedOnEmptySelection
        type="dashboard"
        onchangeFn={(value: string | number) => {
          setDefaultObject({
            ...defaultObject,
            meterNumbers: updateArr(currentMeterNumbers, value),
          });
          setSearchActive(true);
        }}
      />
      <Box sx={{
        width: "292px !important",
        display: "flex",
        gap: "12px",
        mt: "22px",
      }}>
        <Button
          variant={searchActive ? "contained" : "outlined"}
          onClick={() => {
            setCurrentActiveSearch(true);
            setSearchActive!(false);
          }}
          sx={{ borderRadius: "8px", width: "140px" }}
        >
          Search
        </Button>
        <Button variant="outlined" onClick={handleClearFilters} sx={{ borderRadius: "8px", width: "140px" }} >
          Clear Filters
        </Button>
      </Box>
    </Box>
  )
};
