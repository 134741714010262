import React from "react";
import MaterialReactTable from "material-react-table";

import { Loading } from "components";

import { useEmissionFactorsBySite } from "hooks";

import { EMISSION_FACTORS_BY_SITE_COLUMNS } from "./components";

export const EmissionFactorBySite = ({ ReferencesData }: { ReferencesData: any }) => {
  const { isLoading } = useEmissionFactorsBySite();

  const columns = EMISSION_FACTORS_BY_SITE_COLUMNS;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {ReferencesData && 
          <MaterialReactTable
            columns={columns}
            muiTableContainerProps={{
              sx: {
                "& thead tr:first-of-type": {
                  "th:first-of-type": {
                    paddingLeft: "20px",
                  },
                  "th > div > div > div": {
                    whiteSpace: "nowrap",
                  }
                },
                "& tbody tr td:first-of-type": {
                  paddingLeft: "20px",
                },
                "& tbody tr:first-of-type td > p": {
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                },
                height: "calc(100vh - 300px)",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
                "@media (min-width: 600px)": {
                  width: "100%",
                },
                "@media (min-width: 960px)": {
                  width: "1100px",
                },
                "@media (min-width: 1280px)": {
                  width: "1100px",
                },
                "@media (min-width: 1440px)": {
                  width: "1100px",
                },
                "@media (min-width: 1680px)": {
                  width: "1300px",
                },
                "@media (min-width: 2400px)": {
                  width: "100%",
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                lineHeight: "30px",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                "& > th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
                height: "auto",
                backgroundColor: "#F8FAFC",
              },
            }}
            muiTableBodyRowProps={{
              sx: {
                height: "45px",
                backgroundColor: "white",
              },
            }}
            data={ReferencesData}
            enableColumnResizing
            enableDensityToggle={false}
            initialState={{ density: "compact" }}
            enablePagination={false}
            enableRowVirtualization
          />}
        </>
      )}
    </div>
  );
};
