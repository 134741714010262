import React, { FunctionComponent, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RestoreIcon from "@mui/icons-material/Restore";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Box, Button, Stack } from "@mui/material";
import { downloadUtilityBillPdf, deletePurchaseDocument, SITES_API_PATHS } from "api";
import { COLORS } from "components/consts";
import { DeleteDialog, useDialogControls } from "components/dialog";
import { useNotify } from "hooks";
import { MaybeNull } from "types";
import { downloadFile, openPdfInNewTab } from "utils";


interface PurchaseActionsProps {
  documentId: MaybeNull<number>,
  actionsDisabled: boolean;
  downloadUrl: MaybeNull<string>;
  fileName?: MaybeNull<string>;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  setScaleChange: React.Dispatch<React.SetStateAction<boolean>>;
  handleFileName: any;
}

const ZOOM = 0.25;

const iconWrapperStyle = {
  padding: 0,
  minWidth: "fit-content",
  color: COLORS.white,
};

const deleteIconWrapperStyle = {
  padding: 0,
  minWidth: "fit-content",
  color: COLORS.white,
  marginLeft: "22px"
};


export const PurchaseActions: FunctionComponent<PurchaseActionsProps> = ({
  documentId,
  actionsDisabled,
  downloadUrl,
  fileName,
  setScale,
  setScaleChange,
  handleFileName,
}) => {
  const fileInputRef: any = useRef(null);
  const notify = useNotify();
  const queryClient = useQueryClient();
  const deleteDialogControls: any = useDialogControls();

  const { mutateAsync: deletePurchaseDocumentRequest } = useMutation(deletePurchaseDocument);

  const deleteDocument = async () => {
    await deletePurchaseDocumentRequest(documentId!, {
      onError: () => {
        notify.error(`Some error has happened while deleting document!`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([SITES_API_PATHS.RENEWABLE_ENERGY_PURCHASES]);
      },
    });
  };

  const handleFileChange = (e: any) => {
    const files = e.target.files[0];
    handleFileName(files)
  };

  const handleIconClick = () => {
    if (fileInputRef.current && documentId !== null) {
      fileInputRef.current.click();
    } else {
      notify.error("You have to add data to purchase and save it before add the document");
    }
  };

  const handleDeleteClick = () => {
    console.log(downloadUrl); // eslint-disable-line no-console
    if (downloadUrl !== null) {
      deleteDocument();
      deleteDialogControls.close();
      fileInputRef.current.value = null;
      handleFileName(null);
      notify.success("Document deleted!");
    } else {
      notify.error("There is no document to delete!");
      deleteDialogControls.close();
    }
  };

  return (
    <Stack direction="row">
      <Button
        disabled={actionsDisabled}
        onClick={
          downloadUrl === null
            ? () => notify.error("There is no file to download")
            : () => downloadFile(() => downloadUtilityBillPdf(downloadUrl), fileName || "renewable-energy-purchase.pdf")
        }
        sx={{ marginRight: "45px", ...iconWrapperStyle }}
        variant="text"
      >
        <CloudDownloadIcon />
      </Button>

      <Stack direction="row" spacing={4}>
        <Button
          disabled={actionsDisabled}
          onClick={() => {
            setScale((state) => state + ZOOM);
            setScaleChange(true);
          }}
          sx={iconWrapperStyle}
          variant="text"
        >
          <ZoomInIcon />
        </Button>
        <Button
          disabled={actionsDisabled}
          onClick={() => {
            setScale((state) => state - ZOOM);
            setScaleChange(true);
          }}
          sx={iconWrapperStyle}
          variant="text"
        >
          <ZoomOutIcon />
        </Button>
        <Button
          disabled={actionsDisabled}
          onClick={() => setScale(0.75)}
          sx={iconWrapperStyle}
          variant="text"
        >
          <RestoreIcon />
        </Button>
      </Stack>

      <Button
        disabled={actionsDisabled}
        onClick={
          downloadUrl === null
            ? () => notify.error("There is no file to open")
            : () => openPdfInNewTab(() => downloadUtilityBillPdf(downloadUrl))
        }
        sx={{ marginLeft: "22px", ...iconWrapperStyle }}
        variant="text"
      >
        <OpenInNewIcon />
      </Button>

      <Box
        sx={{
          textAlign: "center",
          marginTop: "5px",
          marginLeft: "22px"
        }}
      >
        <input
          type="file"
          accept=".pdf"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <UploadFileIcon
          width={84}
          height={84}
          onClick={handleIconClick}
          style={{ cursor: "pointer" }}
        />
      </Box>

      <Button
        disabled={actionsDisabled}
        onClick={deleteDialogControls.open}
        sx={deleteIconWrapperStyle}
        variant="text"
      >
        <DeleteForeverIcon />
      </Button>

      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={handleDeleteClick}
        target="Purchase"
        title="Delete Purchase document?"
      />
    </Stack>
  )
};