import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { COLORS, InputFieldWrapper, TYPOGRAPHY_COMMON_STYLES } from "components";

import { useNotify } from "hooks";

import { APPLICATION_JSON, QUERY_KEYS } from "consts";

import { checkNullValue, definedValueOrDefault, getDateFormatset } from "utils";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addEmissionFactorDataByCollector, editEmissionFactorByCollector } from "api/rec";

interface CollectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues?: any;
  columns?: any;
  editEmissionFactor?: any;
  emissionFactorData: any;
}

export const UtilitySpecificEmissionsFactorsModal: FC<CollectorModalProps> = ({
  isOpen,
  onClose,
  columns,
  allowedValues,
  editEmissionFactor,
  emissionFactorData
}) => {

  // ---- Hook ----
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { collectorId } = useParams();

  const initialFormValues = useMemo(() => {
    if (emissionFactorData) {
      return {
        providerName: emissionFactorData[0]?.providerName,
        utilityTypeName: definedValueOrDefault(emissionFactorData[0]?.utilityTypeName, ""),
        renewableEnergy: null,
        startDate: dayjs(),
        endDate: dayjs(),
        efValue: emissionFactorData[0]?.efValue,
        efUnit: emissionFactorData[0]?.efUnit,
      };
    }

    return {
      providerName: "",
      utilityTypeName: "",
      renewableEnergy: null,
      startDate: null,
      endDate: null,
      efValue: "",
      efUnit: "",

    };
  }, [emissionFactorData]);

  const { control, getValues, setValue } = useForm<any>({
    mode: "onChange",
    defaultValues: initialFormValues,
  });


  // ---- State ----
  const [values, setValues] = useState<any>(initialFormValues);
  const emissionFactorUnits: string[] = ["kg/kWh", "g/kWh", "lb/MWh"];

  useEffect(() => {
    if (isOpen) {
      setValues({
        ...values,
        startDate: null,
        endDate: null
      })
    }
    // eslint-disable-next-line
  }, [isOpen])

  // ---- Const ----
  const {
    utilityTypes = [],
  } = allowedValues || {};

  // ---- API ----
  const { mutateAsync: addEmissionFactorDocument } = useMutation(addEmissionFactorDataByCollector);
  const { mutateAsync: editEmissionFactorDocument } = useMutation(editEmissionFactorByCollector);

  // ---- Use Effect ----
  useEffect(() => {
    if (editEmissionFactor) {
      setValues({
        startDate: null,
        endDate: null
      })
      const updatedValues = columns.reduce((acc: any, column: any) => {
        const value = editEmissionFactor[column.accessorKey];
        if (column.accessorKey === "startDate" && value) {
          acc[column.accessorKey] = dayjs(value); // Convert startDate to dayjs
        } else if (column.accessorKey === "endDate" && value) {
          acc[column.accessorKey] = dayjs(value); // Convert endDate to dayjs
        } else {
          acc[column.accessorKey ?? ""] = value || "";
        }
        return acc;
      }, {} as any);

      setValues(updatedValues);
    } else {
      setValues(initialFormValues);
    }
    // eslint-disable-next-line
  }, [columns, editEmissionFactor]);

  useEffect(() => {
    if (editEmissionFactor) {
      setValue('utilityTypeName', editEmissionFactor?.utilityTypeName);
      setValue('country', editEmissionFactor?.country);
    }
  }, [editEmissionFactor, setValue]);


  // ---- Page Function ----
  const handleClose = () => {
    setValues(initialFormValues);
    onClose();
  };

  const handleAddRec = async () => {
    const createRecRequestData: any = {
      collectorId,
      utilityProvider: values.utilityProvider,
      utilityTypeName: values.utilityTypeName,
      efValue: values.efValue,
      efUnit: values.efUnit,
      renewableEnergy: values.renewableEnergy,
      startDate: checkNullValue(dayjs(values.startDate).format("YYYY-MM-DDT00:00:00[Z]")),
      endDate: checkNullValue(dayjs(values.endDate).format("YYYY-MM-DDT00:00:00[Z]")),
    };

    const formData = new FormData();

    const jsonBlob = new Blob([JSON.stringify(createRecRequestData)], {
      type: APPLICATION_JSON,
    });

    formData.append("data", jsonBlob);

    // Proceed with creating collector if all checks pass
    await addEmissionFactorDocument({ collectorId, payload: formData }, {
      onError: (error: any) => {
        if (error?.response?.data) {
          notify.error(error?.response?.data);
        } else {
          notify.error(`Some error has happened while adding Emission Factor!`);
        }
      },
      onSuccess: () => {
        notify.success(`Emission Factor have been successfully added!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        onClose();
      },
    });
  };

  const handleditEmissionFactor = async (id: string) => {
    const createRecRequestData: any = {
      collectorId,
      utilityType: getValues()?.utilityTypeName,
      utilityProvider: values.utilityProvider,
      efValue: values?.efValue,
      efUnit: values?.efUnit,
      renewableEnergy: values.renewableEnergy,
      startDate: checkNullValue(dayjs(values.startDate).format("YYYY-MM-DDT00:00:00[Z]")),
      endDate: checkNullValue(dayjs(values.endDate).format("YYYY-MM-DDT00:00:00[Z]")),
    };

    const formData = new FormData();

    const jsonBlob = new Blob([JSON.stringify(createRecRequestData)], {
      type: APPLICATION_JSON,
    });

    formData.append("data", jsonBlob);

    // Proceed with creating collector if all checks pass
    await editEmissionFactorDocument({ collectorId, payload: formData, id }, {
      onError: () => {
        notify.error(`Some error has happened while editing Emission Factor!`);
      },
      onSuccess: () => {
        notify.success(`Emission Factor have been successfully edited!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        onClose();
      },
    });
  };

  const customFieldsOrder = [
    "providerName",
    "utilityType",
    "efValue",
    "efUnit",
    "renewableEnergy",
    "startDate",
    "endDate",
  ];

  const sortedColumns = customFieldsOrder?.map((key) =>
    columns.find((column: any) => column.accessorKey === key),
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        {editEmissionFactor ? "Utility Specific Emissions Factors Details" : " New Utility Specific Emissions Factors Details"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {sortedColumns && (sortedColumns?.map((column: any) => (
              <Grid item xs={8} sm={6} key={column.header}>
                {column.accessorKey === "providerName" && (
                  <InputFieldWrapper label={column.header}>
                    <Controller
                      name="name"
                      control={control}
                      render={({
                        fieldState: { error },
                      }) => (
                        <TextField
                          id={column.accessorKey}
                          size="small"
                          disabled
                          error={!!error}
                          name={column.accessorKey}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={values[column.accessorKey] || ""}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "utilityType" && (
                  <InputFieldWrapper label={column.header}>
                    <Controller
                      name="utilityTypeName"
                      control={control}
                      render={({ field }) => (
                        <Select
                          labelId="utilityType-simple-select-label"
                          id="utilityType-simple-select"
                          autoWidth
                          fullWidth
                          disabled
                          sx={{ height: 40 }}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                minWidth: 260,
                                maxWidth: 'auto',
                                whiteSpace: 'normal',
                                backgroundColor: '#E6E6E6',
                                color: '#9e9e9e',
                              },
                            },
                          }}
                        >
                          {utilityTypes.map((t: any) => (
                            <MenuItem
                              sx={{
                                fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                fontSize: 14,
                                lineHeight: "18px",
                                color: '#90959E',
                                '&:hover': {
                                  backgroundColor: '#F6F8FA',
                                  borderRadius: 5,
                                  color: COLORS.Romance,
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#F6F8FA',
                                  borderRadius: 5,
                                  color: COLORS.Romance,
                                },
                              }} key={t?.id} value={t?.name}>
                              {t?.name}</MenuItem>
                          ))}
                        </Select>
                      )
                      }
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "efValue" && (
                  <InputFieldWrapper label="EF Value">
                    <Controller
                      name="name"
                      control={control}
                      render={({
                        fieldState: { error },
                      }) => (
                        <TextField
                          id={column.accessorKey}
                          size="small"
                          error={!!error}
                          name={column.accessorKey}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={values[column.accessorKey] || ""}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "efUnit" && (
                  <InputFieldWrapper label="EF Unit">
                    <Controller
                      name="name"
                      control={control}
                      render={() => (
                        <Select
                          displayEmpty
                          sx={{ width: "100%", height: "36px" }}
                          labelId="emission-factor-unit-label"
                          id="emission-factor-unit-select"
                          value={values[column.accessorKey] || ""}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              [column.accessorKey]: e.target.value,
                            })
                          }
                        >
                          {emissionFactorUnits.map((unit: string) => (
                            <MenuItem
                              sx={{
                                fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                                fontSize: 14,
                                lineHeight: "18px",
                                color: '#90959E',
                                '&:hover': {
                                  backgroundColor: '#F6F8FA',
                                  borderRadius: 5,
                                  color: COLORS.Romance,
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#F6F8FA',
                                  borderRadius: 5,
                                  color: COLORS.Romance,
                                },
                              }} key={unit} value={unit}>
                              {unit}</MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "renewableEnergy" && (
                  <InputFieldWrapper label="RE Percentage">
                    <Controller
                      name="name"
                      control={control}
                      render={({
                        fieldState: { error },
                      }) => (
                        <TextField
                          id={column.accessorKey}
                          size="small"
                          error={!!error}
                          name={column.accessorKey}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={values[column.accessorKey] || ""}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </InputFieldWrapper>
                )}
                {column.accessorKey === "startDate" && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                  >
                    <InputFieldWrapper label={column.header}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: {
                              width: "100%",
                            },
                          },
                        }}
                        value={values?.startDate}
                        onChange={(date) => {
                          setValues({
                            ...values,
                            [column.accessorKey]: dayjs(date),
                          })
                        }}
                        format={getDateFormatset()}
                      />
                    </InputFieldWrapper>
                  </LocalizationProvider>
                )}
                {column.accessorKey === "endDate" && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                  >
                    <InputFieldWrapper label={column.header}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: {
                              width: "100%",
                            }
                          },
                        }}
                        value={values?.endDate}
                        onChange={(date) => {
                          setValues({
                            ...values,
                            [column.accessorKey]: dayjs(date),
                          })
                        }}
                        format={getDateFormatset()}
                      />
                    </InputFieldWrapper>
                  </LocalizationProvider>
                )}
              </Grid>
            )))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          mb={2}
          ml={2}
        >
          <Grid item>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() =>
                editEmissionFactor
                  ? handleditEmissionFactor(editEmissionFactor?.id)
                  : handleAddRec()
              }
              variant="contained"
              color="primary"
              disabled={!values?.startDate || !values?.endDate || !values?.renewableEnergy}
            >
              {editEmissionFactor ? "Save" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
