import React, { useState } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useAllCollectors, useGetAllowedValues, useGetConfigParameter, useSites, useUtilityBillsYears } from "hooks";
import { DashboardTabs } from "./components/dashboardTabs";
import { MainTab } from "./tabs";

const mainTabs = {
  INITIAL_TAB: "GHG Emissions",
};

export const DashboardPageV2 = () => {
  const [currentActiveSearch, setCurrentActiveSearch] = useState<boolean>(false);

  const currentDate = dayjs.utc();

  const { data: allowedValues } = useGetAllowedValues();
  const { data: sitesData } = useSites();
  const { data: configParameter } = useGetConfigParameter();
  const { data: collectorsData } = useAllCollectors();
  const { data: availableYears } = useUtilityBillsYears();

  const fiscalYears = availableYears ? availableYears && [...availableYears.map((yearItem: any) => `${yearItem}`)] : [];

  const { INITIAL_TAB } = mainTabs;

  const tabs = ["GHG Emissions", "Consumption", "Financials"];

  const subTabs: any = {
    "GHG Emissions": ["Location", "Market"],
    "Consumption": ["Overview", "Electricity", "Natural Gas", "Water", "Sewer"],
    "Financials": ["Overview", "Electricity", "Natural Gas", "Water", "Sewer"],
  };

  const allTabs = {
    [INITIAL_TAB]: [...tabs.map((tab: any, index: number) => ({
      label: tab,
      content: <MainTab
        currentMainTab={tabs[index]}
        tabs={subTabs[tab]}
        currentDate={currentDate}
        allowedValues={allowedValues}
        sitesData={sitesData}
        configParameter={configParameter}
        collectorsData={collectorsData}
        fiscalYears={fiscalYears}
        currentActiveSearch={currentActiveSearch}
        setCurrentActiveSearch={setCurrentActiveSearch}
      />,
    }))]
  };

  return (
    <Box sx={{ ml: 3 }}>
      <DashboardTabs intensity tabs={allTabs[INITIAL_TAB]} selectedBtnOption={mainTabs.INITIAL_TAB} dashboard />
    </Box>
  );
}
