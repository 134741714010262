/* eslint-disable */
import {
  DatePicker as DatePickerMui,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { getDateFormat } from "utils";
import { TextField, TextFieldProps } from "./text-field";
import { InputAdornment } from '@mui/material'; // Import InputAdornment
import { COLORS } from "components/consts";
import { EsgCalender } from "assets";
import { fontWeight } from "@mui/system";

export const EsgDatePickerField = ({
  placeholdervalue,
  disabled,
  clearDate,
  emission,
  filterAll,
  width,
  setDate,
  format = getDateFormat(),
  textFieldProps = {},
  onKeyDown,
  ...datePickerProps
}: {
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  clearDate: boolean;
  placeholdervalue?: string;
  emission?: boolean;
  filterAll?: boolean;
  setDate?: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  width?: string;
} & Omit<
  DatePickerProps<Dayjs>,
  "renderInput" | "date" | "openPicker" | "rawValue"
>) => {

  const [value, setValue] = React.useState<any>();

  React.useEffect(() => {
    if (clearDate === true) {
      setValue(null);
    }
  }, [clearDate]);

  React.useEffect(() => {
    setValue(datePickerProps.value);
  }, []);

  const handleClear = () => {
    setValue(null);
  }

  React.useEffect(() => {
    if (disabled) {
      handleClear();
    }
  }, [disabled]);

  const calenderIcon = () => {
    return (<InputAdornment position="end"> {/* Changed to 'end' to ensure correct placement */}
      <EsgCalender />
    </InputAdornment>)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerMui
        disabled={disabled}
        format={format}
        {...datePickerProps}
        value={value}
        slotProps={{
          inputAdornment: {
            position: 'start',
          },
        }}
        slots={{
          openPickerIcon: calenderIcon,
          textField: (params: any) => (
            <TextField
              disabled={disabled}
              {...params}
              fullWidth
              onKeyDown={e => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  setDate(dayjs(`01-${params.value}`).format("DD/MM/YYYY"));
                }
              }}
              value={disabled ? emission ? params.value : "" : filterAll ? "" : params.value}
              InputProps={{
                ...params.InputProps,
                style: {
                  borderRadius: '8px',
                  height: '36px',
                  color: COLORS.Romance,
                  fontWeight: 500,
                }
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: COLORS.Romance,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: COLORS.Romance,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: COLORS.Romance,
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    border: "1px solid",
                    borderColor: COLORS.Romance,
                    fontFamily: "Inter"
                  },
                  "&:hover fieldset": {
                    borderColor: COLORS.Romance,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: COLORS.Romance,
                  },
                },
                color: COLORS.Romance,
                width: '200px'
              }}
              InputLabelProps={{
                shrink: false,
              }}
              placeholder={placeholdervalue || "Month"}
              {...textFieldProps}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};
