import { Box, Button, Divider } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";

import { COLORS, Label14Medium,Label16SemiBold, Loading, SettingPrimaryLabel } from "components";

import { useSites } from "hooks";

import { getUserRole, USER_ROLES, USER_ROLES_NAMES } from "recoils";
import { CSVLink } from "react-csv";
import { EsgExcelExport } from "assets";

import { getUsers } from "api";
import { useMutation } from "@tanstack/react-query";
import { Stack } from "@mui/system";
import { EsgMultiselectControlledField } from "components/inputs/esg-multi-select-field";

import { User } from "./user";
import { InviteUsersForm } from "./invite-users-form";

interface UsersControllerProps {
  shouldFetchUsers: boolean;
}

const userRole = [
  {
    "label": USER_ROLES.AUDITOR,
    "name": "Auditor",
    "value": 1
  },
  {
    "label": "data_provider",
    "name": "Data provider",
    "value": 2
  },
  {
    "label": "MODULE_ADMIN",
    "name": "Sustainability manager",
    "value": 3
  },
  {
    "label": "data_controller",
    "name": "Data Controller",
    "value": 5
  }
]


export const InvitedUsersList: FunctionComponent<UsersControllerProps> = () => {

  // const { data: users, isLoading: areUsersLoading } = useGetUsers(shouldFetchUsers);
  const { mutateAsync: fetchUser, data: users, isLoading: areUsersLoading } = useMutation(getUsers);

  const [dataEntriesStatus, setDataEntriesStatus] = useState<any>([]);
  const [selectedRole, setSelectedRole] = useState<any>([]);

  useEffect(() => {
    const roles = dataEntriesStatus.map((t: any) => t?.label?.toLowerCase());
    setSelectedRole(roles)
  }, [dataEntriesStatus])

  useEffect(() => {
    fetchUser(selectedRole?.join(", "))
  }, [selectedRole, fetchUser])

  const { data: sites } = useSites();

  if (areUsersLoading) {
    return <Loading />;
  }

  // Export User
  const getSiteNameById = (ids: number[]) => {
    const siteMap = sites!?.reduce((acc: any, site: any) => {
      acc[site?.id] = site?.name;
      return acc;
    }, {});

    return ids?.map(id => siteMap[id])?.filter(Boolean);
  }

  const CsvEmissionSummary: any = users && users?.map((item: any) => ({
    "First Name": item?.firstName,
    "Last Name": item?.lastName,
    "User Email": item?.email,
    "Is User Active": item?.enabled === true ? "Yes" : "No",
    "User Role": USER_ROLES_NAMES[getUserRole(item?.admin, item?.auditor)],
    "Data Provider Site": getSiteNameById(item?.sites),
  }));

  // ---- Use Effects ----
  const activeUser: any = users?.filter((t) => t.enabled === true);

  return (
    <>
      <InviteUsersForm />
      <Divider sx={{ width: '100%' }} color="#9C9C9C" />
      <Box sx={{ display: "flex", justifyContent: "space-between", px: "24px" }}>

        <Stack alignItems="center" direction="row" spacing={1}>
          {userRole && <Box sx={{ minWidth: "240px", width: "fit-content" }}>
            <EsgMultiselectControlledField
              label="View by Title"
              selectedValues={dataEntriesStatus}
              setSelectedValues={setDataEntriesStatus}
              options={userRole}
              isAllSelectedOnEmptySelection
            />
          </Box>}
        </Stack>
        {users && <CSVLink
          style={{ textDecoration: "none" }}
          data={CsvEmissionSummary}
          filename="User-List-Data.csv"
        >
          <Button
            sx={{ height: "36px", marginLeft: "3px", borderRadius: "8px", fontSize: "14px", fontFamily: "Inter", mt: "18px", width: "196px" }}
            type="submit"
            startIcon={<EsgExcelExport />}
            variant="contained"
          >
            <Label14Medium>Export User List</Label14Medium>
          </Button>
        </CSVLink>}
      </Box>
      <Divider sx={{ width: '100%' }} />
      <Box
        sx={{
          borderBottom: `1px solid ${COLORS.extraLightGray}`,
          flex: 2,
          overflowY: "auto",
          paddingBottom: 3,
          px: "24px"
        }}
      >
        <Box display="flex" alignItems="center" width="100%" marginTop="1" marginBottom="20px">
          <Box width="6%" my="8px">
            <SettingPrimaryLabel sx={{ color: COLORS.Romance, fontWeight: 500 }} />
          </Box>
          <Box width="16%" >
            <SettingPrimaryLabel sx={{ color: COLORS.Romance, fontWeight: 500 }}>
              Name
            </SettingPrimaryLabel>
          </Box>

          <Box width="30%" >
            <SettingPrimaryLabel sx={{ color: COLORS.Romance, fontWeight: 500 }}>
              Email
            </SettingPrimaryLabel>
          </Box>

          <Box width="22%" >
            <SettingPrimaryLabel sx={{ color: COLORS.Romance, fontWeight: 500 }}>
              Title
            </SettingPrimaryLabel>
          </Box>

          <Box width="20%" >
            <SettingPrimaryLabel sx={{ color: COLORS.Romance, fontWeight: 500 }}>
              Sites
            </SettingPrimaryLabel>
          </Box>
          <Box width="1%" >
            <SettingPrimaryLabel sx={{ color: COLORS.Romance, fontWeight: 500 }} />
          </Box>
        </Box>


        <Box>
          {activeUser?.length === 0 ? (
            <Box sx={{my: 4, display: "flex", justifyContent: "center"}} width="100%">
              <Label16SemiBold sx={{fontStyle: "italic"}} mt={1}>There are no users with the selected role.</Label16SemiBold>
            </Box>
          ) : (
            activeUser && activeUser!.map((user: any) => (
              <User key={user.id} userData={user} />
            ))
          )}
        </Box>
      </Box>
    </>
  );
};
