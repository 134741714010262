import React from "react";

import { FileTextIcon } from "assets";

export const UTILITY_BILLS_PAGE = {
  ABSOLUTE_PATH: "/documents/summary",
  PATH: "summary/*",
  TITLE: "Document summary",
  ICON: <FileTextIcon />,
};

export const UTILITY_BILLS_TABLE = {
  COLUMN_TO_SORT_COLUMN_MAP: {
    amount: "COST",
    co2Emission: "EMISSIONS",
    endDate: "END_DATE",
    startDate: "START_DATE",
    measuredValue: "USAGE",
  },
  INITIAL_SORTING_STATE: [{ id: "startDate", desc: true }],
};

const ANOMALY_TYPES = {
  LAST_THRESHOLD_EXCEEDED: "LAST_THRESHOLD_EXCEEDED",
  MULTIPLE_UPLOAD: "MULTIPLE_UPLOAD",
  UNIT_CHANGE: "UNIT_CHANGE",
  Y2Y_THRESHOLD_EXCEEDED: "Y2Y_THRESHOLD_EXCEEDED",
  SITE_PERIOD_BLOCKED: "SITE_PERIOD_BLOCKED",
};

export type AnomalyTypeToMessageKeys = keyof typeof ANOMALY_TYPE_TO_MESSAGE;

export const ANOMALY_TYPE_TO_MESSAGE = {
  [ANOMALY_TYPES.LAST_THRESHOLD_EXCEEDED]: "Last threshold exceeded",
  [ANOMALY_TYPES.MULTIPLE_UPLOAD]: "Multiple upload",
  [ANOMALY_TYPES.UNIT_CHANGE]: "Unit change",
  [ANOMALY_TYPES.Y2Y_THRESHOLD_EXCEEDED]: "Y2Y threshold exceeded",
  [ANOMALY_TYPES.SITE_PERIOD_BLOCKED]: "Site period blocked",
} as const;
