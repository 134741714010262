import { Button, ButtonProps } from "@mui/material";
import { COLORS } from "components/consts";
import React from "react";

interface EsgCommonButtonProps extends ButtonProps {
    onClick?: () => void;
    children: React.ReactNode;
}

export const EsgCommonButton: React.FC<EsgCommonButtonProps> = ({ onClick, children, sx, ...props }) => (
    <Button
        sx={{
            height: "36px",
            mr:2,
            paddingTop: "8px",
            marginTop: "22px",
            borderRadius: "7px",
            border: "1px solid",
            borderColor: COLORS.Romance,
            color: COLORS.Romance,
            ...sx,
        }}
        onClick={onClick}
        {...props}
    >
        {children}
    </Button>
);
