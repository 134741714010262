import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { CustomAccordion } from "../../components";
import {
  VarianceChart,
  CombinedChart,
  TotalChart,
  ScopeChart,
  RenewableNonRenewableChart,
  UtilityTypeChart,
  BusinessUnitChart,
  SiteTypeChart,
  RegionChart,
  CountryChart,
  SiteChart,
  EstimatesChart,
  ScopeChartYearOverYear,
  RenewableNonRenewableChartYearOverYear,
  UtilityTypeChartYearOverYear,
  BusinessUnitChartYearOverYear,
  RegionChartYearOverYear,
  CountryChartYearOverYear,
  SiteChartYearOverYear,
  EstimatesChartYearOverYear,
  RegionChartSingleYear,
  CountryChartSingleYear,
  BusinessUnitChartSingleYear,
  SiteTypeChartSingleYear,
} from "../../charts";

export const Tab = ({
  children,
  varianceStartDate,
  varianceEndDate,
  setVarianceStartDate,
  setVarianceEndDate,
  varianceData,
  varianceComparisonData,
  setComparisonPeriod,
  dateRangeType,
  setDateRangeType,
  dateRangeStartDate,
  dateRangeEndDate,
  setDateRangeStartDate,
  setDateRangeEndDate,
  dateRangeData,
  yoYMaxLength,
  setYoYLength,
  yoYData,
  currentFiscalYear,
  setCurrentFiscalYear,
  allFiscalYears,
  singleYearByScopeData,
  currentMainTab,
  subTypeChart,
  handleVarianceDateChange,
  handleUpdateComparisonPeriod,
  handleDateRangeChange,
  handleYoYChange,
  handleSingleYearByScopeChange,
  setDefaultObject,
}: {
  children?: React.ReactNode;
  varianceStartDate: Dayjs;
  varianceEndDate: Dayjs;
  setVarianceStartDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  setVarianceEndDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  varianceData: any;
  varianceComparisonData: any;
  setComparisonPeriod: React.Dispatch<React.SetStateAction<string>>;
  dateRangeType: string;
  setDateRangeType: React.Dispatch<React.SetStateAction<string>>;
  dateRangeStartDate: Dayjs;
  dateRangeEndDate: Dayjs;
  setDateRangeStartDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  setDateRangeEndDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  dateRangeData: any;
  setYoYLength: React.Dispatch<React.SetStateAction<number>>;
  yoYMaxLength: number;
  yoYData: any;
  currentFiscalYear: string;
  setCurrentFiscalYear: React.Dispatch<React.SetStateAction<string>>;
  allFiscalYears: string[];
  singleYearByScopeData: any;
  currentMainTab?: string;
  subTypeChart: string;
  handleVarianceDateChange?: any;
  handleUpdateComparisonPeriod?: any;
  handleDateRangeChange?: any;
  handleYoYChange?: any;
  handleSingleYearByScopeChange?: any;
  setDefaultObject: React.Dispatch<React.SetStateAction<any>>;
}) => {
  useEffect(() => {
    if (subTypeChart === "electricity") {
      setDefaultObject({
        countryRegions: null,
        countries: null,
        siteIds: null,
        facilityTypes: null,
        businessUnits: null,
        scopes: null,
        utilityTypeIds: [1],
        accountNumbers: null,
        meterNumbers: null,
      });
      handleVarianceDateChange(varianceStartDate, varianceEndDate, 1);
      handleUpdateComparisonPeriod(1);
      handleDateRangeChange(dateRangeStartDate, dateRangeEndDate, 1);
      handleYoYChange(1);
      handleSingleYearByScopeChange(1);
    }

    if (subTypeChart === "natural gas") {
      setDefaultObject({
        countryRegions: null,
        countries: null,
        siteIds: null,
        facilityTypes: null,
        businessUnits: null,
        scopes: null,
        utilityTypeIds: [2],
        accountNumbers: null,
        meterNumbers: null,
      });
      handleVarianceDateChange(varianceStartDate, varianceEndDate, 2);
      handleUpdateComparisonPeriod(2);
      handleDateRangeChange(dateRangeStartDate, dateRangeEndDate, 2);
      handleYoYChange(2);
      handleSingleYearByScopeChange(2);
    }

    if (subTypeChart === "water") {
      setDefaultObject({
        countryRegions: null,
        countries: null,
        siteIds: null,
        facilityTypes: null,
        businessUnits: null,
        scopes: null,
        utilityTypeIds: [5],
        accountNumbers: null,
        meterNumbers: null,
      });
      handleVarianceDateChange(varianceStartDate, varianceEndDate, 5);
      handleUpdateComparisonPeriod(5);
      handleDateRangeChange(dateRangeStartDate, dateRangeEndDate, 5);
      handleYoYChange(5);
      handleSingleYearByScopeChange(5);
    }

    if (subTypeChart === "sewer") {
      setDefaultObject({
        countryRegions: null,
        countries: null,
        siteIds: null,
        facilityTypes: null,
        businessUnits: null,
        scopes: null,
        utilityTypeIds: [8],
        accountNumbers: null,
        meterNumbers: null,
      });
      handleVarianceDateChange(varianceStartDate, varianceEndDate, 8);
      handleUpdateComparisonPeriod(8);
      handleDateRangeChange(dateRangeStartDate, dateRangeEndDate, 8);
      handleYoYChange(8);
      handleSingleYearByScopeChange(8);
    }
  }, []); // eslint-disable-line

  const isEmptyVarianceData = Object.values(varianceData).every(value =>
    typeof value === "object" && value !== null && Object.keys(value).length === 0
  );

  const isEmptyVarianceComparisonData = Object.values(varianceComparisonData).every(value =>
    typeof value === "object" && value !== null && Object.keys(value).length === 0
  );

  const isEmptyDateRangeData = Object.values(dateRangeData).every(value =>
    typeof value === "object" && value !== null && Object.keys(value).length === 0
  );

  const isEmptyYoYData = Object.values(yoYData).every(value =>
    typeof value === "object" && value !== null && Object.keys(value).length === 0
  );

  const isEmptySingleYearByScopeData = Object.values(singleYearByScopeData).every(value =>
    typeof value === "object" && value !== null && Object.keys(value).length === 0
  );

  return (
    <Box>
      {children}
      <Box sx={{ mt: 3, width: "calc(100vw - 145px)", display: "flex", flexDirection: "column", gap: 2 }}>
        <CustomAccordion
          titleAccordion="Variance"
          panelNumber={1}
          allowDates
          startDate={varianceStartDate}
          endDate={varianceEndDate}
          setStartDate={setVarianceStartDate}
          setEndDate={setVarianceEndDate}
        >
          {isEmptyVarianceData && isEmptyVarianceComparisonData
            ? <Box sx={{
              width: "100%",
              minHeight: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              No Data Available
            </Box>
            : <>
              <VarianceChart
                type={subTypeChart}
                varianceData={varianceData}
                chartTitle="Comparing Current Period, Same Period Last Year"
              />
              <CombinedChart
                type={subTypeChart}
                varianceData={varianceComparisonData}
                setPeriod={setComparisonPeriod}
                chartTitle="Variance Absolute and Percentage Amounts"
              />
            </>}
        </CustomAccordion>
        <CustomAccordion
          titleAccordion="Date Range"
          dateRangeType={dateRangeType}
          setDateRangeType={setDateRangeType}
          startDate={dateRangeStartDate}
          endDate={dateRangeEndDate}
          setStartDate={setDateRangeStartDate}
          setEndDate={setDateRangeEndDate}
          panelNumber={2}
          allowPeriods
        >
          {isEmptyDateRangeData
            ? <Box sx={{
              width: "100%",
              minHeight: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              No Data Available
            </Box>
            : <>
              <TotalChart
                type={subTypeChart}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Total"
              />
              <ScopeChart
                type={subTypeChart}
                mainTab={currentMainTab}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Scope"
              />
              {currentMainTab === "Consumption" || currentMainTab === "Financials"
                ? <RenewableNonRenewableChart
                  type={subTypeChart}
                  mainTab={currentMainTab}
                  dateRangeData={dateRangeData}
                  dateRangeType={dateRangeType}
                  chartTitle="Renewable vs. Non-Renewable"
                />
                : ""}
              <UtilityTypeChart
                type={subTypeChart}
                mainTab={currentMainTab}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Utility Type"
              />
              <BusinessUnitChart
                type={subTypeChart}
                mainTab={currentMainTab}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Business Unit"
              />
              <SiteTypeChart // facilityTypes
                type={subTypeChart}
                mainTab={currentMainTab}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Site Type"
              />
              <RegionChart
                type={subTypeChart}
                mainTab={currentMainTab}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Region"
              />
              <CountryChart
                type={subTypeChart}
                mainTab={currentMainTab}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Country"
              />
              <SiteChart
                type={subTypeChart}
                mainTab={currentMainTab}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Site"
              />
              <EstimatesChart
                type={subTypeChart}
                mainTab={currentMainTab}
                dateRangeData={dateRangeData}
                dateRangeType={dateRangeType}
                chartTitle="Estimates"
              />
            </>}
        </CustomAccordion>
        <CustomAccordion
          titleAccordion="Year over Year"
          yoYMaxLength={yoYMaxLength}
          setYoYLength={setYoYLength}
          panelNumber={3}
          allowYears
        >
          {isEmptyYoYData
            ? <Box sx={{
              width: "100%",
              minHeight: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              No Data Available
            </Box>
            : <>
              <ScopeChartYearOverYear
                type={subTypeChart}
                mainTab={currentMainTab}
                yoYData={yoYData}
                chartTitle="Scope"
              />
              {currentMainTab === "Consumption" || currentMainTab === "Financials"
                ? <RenewableNonRenewableChartYearOverYear
                  mainTab={currentMainTab}
                  yoYData={yoYData}
                  chartTitle="Renewable vs. Non-Renewable"
                />
                : ""}
              <UtilityTypeChartYearOverYear
                type={subTypeChart}
                mainTab={currentMainTab}
                yoYData={yoYData}
                chartTitle="Utility Type"
              />
              <BusinessUnitChartYearOverYear
                type={subTypeChart}
                mainTab={currentMainTab}
                yoYData={yoYData}
                chartTitle="Business Unit"
              />
              <RegionChartYearOverYear
                type={subTypeChart}
                mainTab={currentMainTab}
                yoYData={yoYData}
                chartTitle="Region"
              />
              <CountryChartYearOverYear
                type={subTypeChart}
                mainTab={currentMainTab}
                yoYData={yoYData}
                chartTitle="Country"
              />
              <SiteChartYearOverYear
                type={subTypeChart}
                mainTab={currentMainTab}
                yoYData={yoYData}
                chartTitle="Site"
              />
              <EstimatesChartYearOverYear
                type={subTypeChart}
                mainTab={currentMainTab}
                yoYData={yoYData}
                chartTitle="Estimates"
              />
            </>}
        </CustomAccordion>
        <CustomAccordion
          titleAccordion="Single Year by Scope"
          currentFiscalYear={currentFiscalYear}
          setCurrentFiscalYear={setCurrentFiscalYear}
          allFiscalYears={allFiscalYears}
          panelNumber={3}
          allowFiscalYears
        >
          {isEmptySingleYearByScopeData
            ? <Box sx={{
              width: "100%",
              minHeight: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              No Data Available
            </Box>
            : <>
              <RegionChartSingleYear
                chartTitle="Region"
                singleYearByScopeData={singleYearByScopeData}
                type={subTypeChart}
                mainTab={currentMainTab}
              />
              <CountryChartSingleYear
                chartTitle="Country"
                singleYearByScopeData={singleYearByScopeData}
                type={subTypeChart}
                mainTab={currentMainTab}
              />
              <BusinessUnitChartSingleYear
                chartTitle="Business Unit"
                singleYearByScopeData={singleYearByScopeData}
                type={subTypeChart}
                mainTab={currentMainTab}
              />
              <SiteTypeChartSingleYear
                chartTitle="Site Type"
                singleYearByScopeData={singleYearByScopeData}
                type={subTypeChart}
                mainTab={currentMainTab}
              />
            </>}
        </CustomAccordion>
      </Box>
    </Box>
  )
}
