import { Box } from "@mui/material";
import React, { FunctionComponent, memo, useState } from "react";

import { Anomaly as AnomalyType, getUtilityBillById } from "api";

import { AnomalyArrowIcon } from "assets";

import { Body1Regular, Body1SemiBold, COLORS } from "components";

import { setToLocalStorage } from "utils";
import { UTILITY_BILL_PAGE } from "../../consts";

type AnomalyProps = Omit<AnomalyType, "createDate">;

export const Anomaly: FunctionComponent<AnomalyProps> = memo(
  ({
    anomalyType,
    message,
    bgColor = COLORS.lightYellow,
    previousBillGuid,
  }) => {
    const [isHovered, setIsHovered] = useState(false);

    const onPreviousBillViewClick = () => {
      if (previousBillGuid) {
        window.open(`${UTILITY_BILL_PAGE.ABSOLUTE_PATH(previousBillGuid)}`);
      }
    };

    const handlePreviousBillCardClick = async (id: any) => {
      if (id) {
        window.open(`${UTILITY_BILL_PAGE.ABSOLUTE_PATH(id as string)}`);
        try {
          const response = await getUtilityBillById(id);
          setToLocalStorage(
            "document",
            JSON.stringify({
              documentGuid: response.documentGuid,
              filename: response.filename,
            }),
          );
        } catch (error) {
          console.error("Error fetching utility bill:", error);
          throw error;
        }
      }
    };

    const isUsageAnomaly = anomalyType === "Usage" && message && /-?\d+(\.\d+)?%/.test(message);
    const anomalyMessage = isUsageAnomaly ? (
      // eslint-disable-next-line
      <a
        href="#"
        style={{
          textDecoration: "none",
          cursor: "pointer",
          color: isHovered ? "black" : "#000302",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onPreviousBillViewClick}
        title={
          previousBillGuid && previousBillGuid?.length >= 1
            ? `${UTILITY_BILL_PAGE.ABSOLUTE_PATH(previousBillGuid as string)}`
            : ""
        }
      >
        {message}
      </a>
    ) : (
      message
    );

    return (
      <Box sx={{ bgcolor: bgColor, borderRadius: 1, padding: "8px 16px", cursor: 'pointer', width: "100%", maxWidth: "600px" }}
        onClick={() => handlePreviousBillCardClick(previousBillGuid)}
      >
        <Box sx={{ display: "flex", gap: 1, marginBottom: 2 }}>
          <AnomalyArrowIcon />

          <Body1SemiBold>Verification failed</Body1SemiBold>

          <Body1SemiBold>|</Body1SemiBold>

          <Body1SemiBold>{anomalyType}</Body1SemiBold>
        </Box>

        <Body1Regular>{anomalyMessage}</Body1Regular>
        <Body1Regular sx={{ marginTop: "10px" }}>
          Please review and either correct the data or select ‘confirm data’ if
          it is correct
        </Body1Regular>
      </Box>
    );
  },
);
