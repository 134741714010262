import Highcharts from "highcharts";
import React from "react";

import {
  DASHBOARDCOLORS,
  SCOPE_COLORS,
  YEARLYDASHBOARDCOLORS,
} from "components/consts";

import { toUSNumber } from "utils";
import {EsgDashBoardIcon} from "assets";

export const DASHBOARD_PAGE = {
  ABSOLUTE_PATH: "/dashboard",
  TITLE: "Dashboard",
  ICON: <EsgDashBoardIcon />,
  IS_TBD: false,
  renderMenu: false,
};

export const UTILITY_NAMES = {
  ELECTRICITY: "Electricity",
  NATURAL_GAS: "Natural Gas",
  SEWER: "Sewer",
  WATER: "Water",
  BILL: "Bill",
  ESTIMATES: "Estimate",
};

export type SiteHighChartsData = {
  chart: {
    type: string;
  };
  colors: string[];
  title: {
    text: string;
  };
  subtitle: {
    text: string;
  };
  xAxis: {
    categories: string[];
    crosshair: boolean;
  };
  yAxis: {
    min: number;
    title: {
      text: string;
    };
    stackLabels?: {
      enabled: boolean;
      style: {
        fontWeight: string;
        color: string;
        textOutline: string;
      };
      formatter?: any;
    };
  };
  tooltip: {
    headerFormat: string;
    pointFormat: string;
    footerFormat: string;
    shared: boolean;
    useHTML: boolean;
    formatter?: Highcharts.TooltipFormatterCallbackFunction;
  };
  legend?: any;
  plotOptions: any;
  series: Array<any>;
};

export type MonthlyHighChartsData = {
  chart: {
    type: string;
  };
  colors: string[];
  title: {
    text?: string;
    align?: string;
    style?: object;
  };
  subtitle: {
    text?: string;
    align?: string;
    style?: object;
  };
  xAxis: {
    categories: string[];
  };
  yAxis: {
    min: number;
    title: {
      text: string;
    };
    stackLabels: {
      enabled: boolean;
      style: {
        fontWeight: string;
        color: string;
        textOutline: string;
      };
      formatter?: any;
    };
  };
  legend?: any;
  tooltip: any;
  plotOptions: any;
  series: Array<any>;
};

export const EMMISSIONS_BY_SITE_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2018],
    YEARLYDASHBOARDCOLORS[2019],
    YEARLYDASHBOARDCOLORS[2020],
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};
export const ESTIMATED_EMISSIONS_HIGHTCHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.bill,
    DASHBOARDCOLORS.estimates,
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.water,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.refrigerants,
    DASHBOARDCOLORS.lpgas,
  ],
  title: {
    text: "",
  },
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = Highcharts.numberFormat(point.y, 2, ".", ",");

          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};
export const ESTIMATED_SITE_EMMISSIONS_BY_SITE_HIGHCHARTS_DATA1: MonthlyHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      DASHBOARDCOLORS.bill,
      DASHBOARDCOLORS.estimates,
      DASHBOARDCOLORS.electricity,
      DASHBOARDCOLORS.naturalgas,
      DASHBOARDCOLORS.water,
      DASHBOARDCOLORS.sewer,
      DASHBOARDCOLORS.refrigerants,
      DASHBOARDCOLORS.lpgas,
    ],
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "MT of CO2",
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts?.defaultOptions?.title?.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "left",
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: Highcharts.Point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = Highcharts.numberFormat(
              point.y,
              2,
              ".",
              ",",
            );

            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [],
    subtitle: {
      text: undefined,
      align: undefined,
    },
  };
export const ESTIMATED_SITE_EMMISSIONS_BY_SITE_HIGHCHARTS_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2018],
      YEARLYDASHBOARDCOLORS[2019],
      YEARLYDASHBOARDCOLORS[2020],
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "MT of CO2",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = Highcharts.numberFormat(
              point.y,
              2,
              ".",
              ",",
            );
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [],
  };

export const FINANCIAL_BY_SITE_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} $</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const FINANCIAL_MONTHLY_HIGHCHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.water,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.refrigerants,
    DASHBOARDCOLORS.lpgas,
  ],
  title: {
    text: "",
  },
  subtitle: {},
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};

export const EMISSIONS_MONTHLY_HIGHCHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.water,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.refrigerants,
    DASHBOARDCOLORS.lpgas,
  ],
  title: {
    text: "",
  },
  subtitle: {},
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
    // x: 70,
    // verticalAlign: "top",
    // y: 70,
    // floating: true,
    // backgroundColor:
    //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
    // borderColor: "#CCC",
    // borderWidth: 1,
    // shadow: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};

export const EMISSIONS_SCOP_MONTHLY_HIGHCHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.bill,
    DASHBOARDCOLORS.estimates,
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.water,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.refrigerants,
    DASHBOARDCOLORS.lpgas,
  ],
  title: {
    text: "",
  },
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = Highcharts.numberFormat(point.y, 2, ".", ",");

          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};

export const EMISSIONS_YEARLY_HIGH_CHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.refrigerants,
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.lpgas,
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.water,
  ],
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      enabled: true,
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
    // x: 70,
    // verticalAlign: "top",
    // y: 70,
    // floating: true,
    // backgroundColor:
    //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
    // borderColor: "#CCC",
    // borderWidth: 1,
    // shadow: false,
  },
  tooltip: {
    // headerFormat: "<b>{point.x}</b><br/>",
    // pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};

export const EMISSIONS_SCOPE_YEARLY_HIGH_CHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.refrigerants,
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.lpgas,
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.water,
  ],
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      enabled: true,
      style: {
        fontWeight: "bold",
        color:
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }

      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);

          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};

export const FINANCIAL_YEARLY_HIGH_CHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.lpgas,
    DASHBOARDCOLORS.water,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.refrigerants,
  ],
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
    // x: 70,
    // verticalAlign: "top",
    // y: 70,
    // floating: true,
    // backgroundColor:
    //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
    // borderColor: "#CCC",
    // borderWidth: 1,
    // shadow: false,
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};

export const EMMISSIONS_BY_REGION_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [SCOPE_COLORS.scope1, SCOPE_COLORS.scope2],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      stacking: "normal",
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};

export const FINANCIAL_BY_REGION_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },

  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};

export const EMMISSIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2018],
      YEARLYDASHBOARDCOLORS[2019],
      YEARLYDASHBOARDCOLORS[2020],
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "MT of CO2",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const FINANCIAL_BY_FACILITY_TYPES_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const EMMISSIONS_BY_BUSINESS_UNIT_HIGHCHARTS_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2018],
      YEARLYDASHBOARDCOLORS[2019],
      YEARLYDASHBOARDCOLORS[2020],
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "MT of CO2",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const FINANCIAL_BY_BUSINESS_UNIT_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const EMMISSIONS_BY_UT_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2018],
    YEARLYDASHBOARDCOLORS[2019],
    YEARLYDASHBOARDCOLORS[2020],
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const FINANCIAL_BY_UT_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} $</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const FINANCIAL_BY_UT_HIGHCHARTS_NATGAS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const EMMISSIONS_BY_COUNTRIES_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2018],
    YEARLYDASHBOARDCOLORS[2019],
    YEARLYDASHBOARDCOLORS[2020],
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const FINANCIAL_BY_COUNTRIES_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "MT of CO2",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} mt</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const CONSUMPTIONS_MONTHLY_HIGHCHARTS_ELE_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.water,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.lpgas,
    DASHBOARDCOLORS.refrigerants,
  ],

  title: {
    text: "",
  },
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    min: 0,
    title: {
      text: "kWh",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} kWh</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${
            point.series.name
          }: </td><td style="padding:0"><b>${toUSNumber(
            formattedValue,
          )} kWh</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};

export const CONSUMPTIONS_MONTHLY_HIGHCHARTS_GAS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    DASHBOARDCOLORS.naturalgas,
    DASHBOARDCOLORS.water,
    DASHBOARDCOLORS.sewer,
    DASHBOARDCOLORS.electricity,
    DASHBOARDCOLORS.lpgas,
    DASHBOARDCOLORS.refrigerants,
  ],
  title: {
    text: "",
  },
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    min: 0,
    title: {
      text: "thm",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
    // x: 70,
    // verticalAlign: "top",
    // y: 70,
    // floating: true,
    // backgroundColor:
    //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
    // borderColor: "#CCC",
    // borderWidth: 1,
    // shadow: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} thm</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} therms</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};

export const CONSUMPTIONS_BY_SITE_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "kWh",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} kWh</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ${point.point.custom?.unit}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};

export const CONSUMPTIONS_BY_SITE_GAS_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "thm",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} thm</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} thm</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};

export const CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_ELE_DATA: MonthlyHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      DASHBOARDCOLORS?.electricity,
      DASHBOARDCOLORS.water,
      DASHBOARDCOLORS.sewer,
      DASHBOARDCOLORS.naturalgas,
      DASHBOARDCOLORS.lpgas,
      DASHBOARDCOLORS.refrigerants,
    ],
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "kWh",
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return toUSNumber((this as any)?.total);
        },
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts?.defaultOptions?.title?.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "left",
      // x: 70,
      // verticalAlign: "top",
      // y: 70,
      // floating: true,
      // backgroundColor:
      //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
      // borderColor: "#CCC",
      // borderWidth: 1,
      // shadow: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} kWh</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: Highcharts.Point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} kWh</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter(): any {
            return toUSNumber((this as any)?.y);
          },
        },
      },
    },
    series: [],
    subtitle: {
      text: undefined,
      align: undefined,
    },
  };

export const CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_GAS_DATA: MonthlyHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      DASHBOARDCOLORS?.naturalgas,
      DASHBOARDCOLORS.water,
      DASHBOARDCOLORS.sewer,
      DASHBOARDCOLORS.electricity,
      DASHBOARDCOLORS.lpgas,
      DASHBOARDCOLORS.refrigerants,
    ],

    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "thm",
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return toUSNumber((this as any)?.total);
        },
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts?.defaultOptions?.title?.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "left",
      // x: 70,
      // verticalAlign: "top",
      // y: 70,
      // floating: true,
      // backgroundColor:
      //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
      // borderColor: "#CCC",
      // borderWidth: 1,
      // shadow: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} thm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: Highcharts.Point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} thm</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter(): any {
            return toUSNumber((this as any)?.y);
          },
        },
      },
    },
    series: [],
    subtitle: {
      text: undefined,
      align: undefined,
    },
  };

export const CONSUMPTIONS_BY_REGION_HIGHCHARTS_GAS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "thm",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} thm</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} thm</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};

export const CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "kWh",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} kWh</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ${point.point.custom.unit}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
};

export const CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_GAS_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "thm",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} thm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} thm</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

  export const CONSUMPTIONS_BY_BUSINESS_UNIT_HIGHCHARTS_GAS_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "thm",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} thm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} thm</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "kWh",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} kWh</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ${point.point.custom.unit}</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

  export const CONSUMPTIONS_BY_BUSINESS_UNIT_HIGHCHARTS_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "kWh",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} kWh</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ${point.point.custom.unit}</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "kWh",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} kWh</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ${point.point.custom.unit}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_GAS_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "thm",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} thm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} thm</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const CONSUMPTIONS_BY_UT_HIGHCHARTS_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "kWh",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} kWh</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      formatter(): any {
        return toUSNumber((this as any)?.y);
      },
    },
  },
  series: [],
};

export const SS_EM_ACC_HIGHCHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2018],
    YEARLYDASHBOARDCOLORS[2019],
    YEARLYDASHBOARDCOLORS[2020],
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],

  title: {
    text: "",
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: "Emissions (CO2)",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
    // x: 70,
    // verticalAlign: "top",
    // y: 70,
    // floating: true,
    // backgroundColor:
    //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
    // borderColor: "#CCC",
    // borderWidth: 1,
    // shadow: false,
  },
  // tooltip: {
  //   headerFormat: "<b>{point.x}</b><br/>",
  //   pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
  // },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },

  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};

export const SINGLE_BAR_CHART = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2018],
    YEARLYDASHBOARDCOLORS[2019],
    YEARLYDASHBOARDCOLORS[2020],
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    align: "left",
    text: "",
  },
  subtitle: {
    align: "left",
    text: "",
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    type: "category",
  },
  yAxis: {
    title: {
      text: "Emissions (CO2)",
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        // format: "{point.y:.1f}%",
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  tooltip: {
    // headerFormat: "<b>{point.x}</b><br/>",
    // pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter(): any {
      if (!(this as any).points) {
        return "";
      }
      const formattedPoints = (this as any).points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${
        (this as any).x
      }</span><table>${formattedPoints}</table>`;
    },
  },

  series: [],
};

export const FINANCIAL_SINGLE_BAR_CHART = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2018],
    YEARLYDASHBOARDCOLORS[2019],
    YEARLYDASHBOARDCOLORS[2020],
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    align: "left",
    text: "",
  },
  subtitle: {
    align: "left",
    text: "",
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    type: "category",
  },
  yAxis: {
    title: {
      text: "Emissions (CO2)",
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        // format: "{point.y:.1f}%",
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  tooltip: {
    // headerFormat: "<b>{point.x}</b><br/>",
    // pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>$ {point.y:,.2f}</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter(): any {
      if (!(this as any).points) {
        return "";
      }
      const formattedPoints = (this as any).points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${
        (this as any).x
      }</span><table>${formattedPoints}</table>`;
    },
  },

  series: [],
};

export const SSPEND_CS_ACC_HIGHCHARTS_DATA: MonthlyHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2018],
    YEARLYDASHBOARDCOLORS[2019],
    YEARLYDASHBOARDCOLORS[2020],
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: "Cost (USD)",
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return toUSNumber((this as any)?.total);
      },
      style: {
        fontWeight: "bold",
        color:
          // theme
          (Highcharts?.defaultOptions?.title?.style &&
            Highcharts.defaultOptions.title.style.color) ||
          "gray",
        textOutline: "none",
      },
    },
  },
  legend: {
    align: "left",
    // x: 70,
    // verticalAlign: "top",
    // y: 70,
    // floating: true,
    // backgroundColor:
    //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
    // borderColor: "#CCC",
    // borderWidth: 1,
    // shadow: false,
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} mt</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: Highcharts.Point) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue}</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
  },
  series: [],
  subtitle: {
    text: undefined,
    align: undefined,
  },
};

// Sewer
export const CONSUMPTIONS_MONTHLY_HIGHCHARTS_GAS_SEWER_DATA: MonthlyHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      DASHBOARDCOLORS.naturalgas,
      DASHBOARDCOLORS.water,
      DASHBOARDCOLORS.sewer,
      DASHBOARDCOLORS.electricity,
      DASHBOARDCOLORS.lpgas,
      DASHBOARDCOLORS.refrigerants,
    ],
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return toUSNumber((this as any)?.total);
        },
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts?.defaultOptions?.title?.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "left",
      // x: 70,
      // verticalAlign: "top",
      // y: 70,
      // floating: true,
      // backgroundColor:
      //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
      // borderColor: "#CCC",
      // borderWidth: 1,
      // shadow: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: Highcharts.Point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter(): any {
            return toUSNumber((this as any)?.y);
          },
        },
      },
    },
    series: [],
    subtitle: {
      text: undefined,
      align: undefined,
    },
  };

export const CONSUMPTIONS_BY_SITE_HIGHCHARTS_SEWER_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "ml",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [],
};

export const CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_SEWER_DATA: MonthlyHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      DASHBOARDCOLORS?.naturalgas,
      DASHBOARDCOLORS.water,
      DASHBOARDCOLORS.sewer,
      DASHBOARDCOLORS.electricity,
      DASHBOARDCOLORS.lpgas,
      DASHBOARDCOLORS.refrigerants,
    ],

    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return toUSNumber((this as any)?.total);
        },
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts?.defaultOptions?.title?.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "left",
      // x: 70,
      // verticalAlign: "top",
      // y: 70,
      // floating: true,
      // backgroundColor:
      //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
      // borderColor: "#CCC",
      // borderWidth: 1,
      // shadow: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: Highcharts.Point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter(): any {
            return toUSNumber((this as any)?.y);
          },
        },
      },
    },
    series: [],
    subtitle: {
      text: undefined,
      align: undefined,
    },
  };

export const CONSUMPTIONS_BY_REGION_HIGHCHARTS_SEWER_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "thm",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} thm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} thm</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_SEWER_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

  export const CONSUMPTIONS_BY_BUSINESS_UNIT_HIGHCHARTS_SEWER_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_SEWER_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

// Water

export const CONSUMPTIONS_MONTHLY_HIGHCHARTS_GAS_WATER_DATA: MonthlyHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      DASHBOARDCOLORS.naturalgas,
      DASHBOARDCOLORS.water,
      DASHBOARDCOLORS.sewer,
      DASHBOARDCOLORS.electricity,
      DASHBOARDCOLORS.lpgas,
      DASHBOARDCOLORS.refrigerants,
    ],
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return toUSNumber((this as any)?.total);
        },
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts?.defaultOptions?.title?.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "left",
      // x: 70,
      // verticalAlign: "top",
      // y: 70,
      // floating: true,
      // backgroundColor:
      //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
      // borderColor: "#CCC",
      // borderWidth: 1,
      // shadow: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: Highcharts.Point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter(): any {
            return toUSNumber((this as any)?.y);
          },
        },
      },
    },
    series: [],
    subtitle: {
      text: undefined,
      align: undefined,
    },
  };

export const CONSUMPTIONS_BY_SITE_HIGHCHARTS_WATER_DATA: SiteHighChartsData = {
  chart: {
    type: "column",
  },
  colors: [
    YEARLYDASHBOARDCOLORS[2021],
    YEARLYDASHBOARDCOLORS[2022],
    YEARLYDASHBOARDCOLORS[2023],
  ],
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "ml",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
    formatter() {
      if (!this.points) {
        return "";
      }
      const formattedPoints = this.points
        .map((point: any) => {
          if (!point.y) {
            return "";
          }
          const formattedValue = toUSNumber(point.y);
          return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
        })
        .join("");
      return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
    },
  },
  legend: {
    align: "left",
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [],
};

export const CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_WATER_DATA: MonthlyHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      DASHBOARDCOLORS?.naturalgas,
      DASHBOARDCOLORS.water,
      DASHBOARDCOLORS.sewer,
      DASHBOARDCOLORS.electricity,
      DASHBOARDCOLORS.lpgas,
      DASHBOARDCOLORS.refrigerants,
    ],

    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return toUSNumber((this as any)?.total);
        },
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts?.defaultOptions?.title?.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
          textOutline: "none",
        },
      },
    },
    legend: {
      align: "left",
      // x: 70,
      // verticalAlign: "top",
      // y: 70,
      // floating: true,
      // backgroundColor:
      //   Highcharts?.defaultOptions?.legend?.backgroundColor || "white",
      // borderColor: "#CCC",
      // borderWidth: 1,
      // shadow: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: Highcharts.Point) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          formatter(): any {
            return toUSNumber((this as any)?.y);
          },
        },
      },
    },
    series: [],
    subtitle: {
      text: undefined,
      align: undefined,
    },
  };

export const CONSUMPTIONS_BY_REGION_HIGHCHARTS_WATER_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "thm",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} thm</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_WATER_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

  export const CONSUMPTIONS_BY_BUSINESS_UNIT_HIGHCHARTS_WATER_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };

export const CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_WATER_DATA: SiteHighChartsData =
  {
    chart: {
      type: "column",
    },
    colors: [
      YEARLYDASHBOARDCOLORS[2021],
      YEARLYDASHBOARDCOLORS[2022],
      YEARLYDASHBOARDCOLORS[2023],
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "ml",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y:,.2f} ml</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
      formatter() {
        if (!this.points) {
          return "";
        }
        const formattedPoints = this.points
          .map((point: any) => {
            if (!point.y) {
              return "";
            }
            const formattedValue = toUSNumber(point.y);
            return `<tr><td style="color:${point.series.color};padding:0">${point.series.name}: </td><td style="padding:0"><b>${formattedValue} ml</b></td></tr>`;
          })
          .join("");
        return `<span style="font-size:10px">${this.x}</span><table>${formattedPoints}</table>`;
      },
    },
    legend: {
      align: "left",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        formatter(): any {
          return toUSNumber((this as any)?.y);
        },
      },
    },
    series: [],
  };
